import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { WithStyles, Theme, createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import AnalyzerBanner from "../../../components/auth/AnalyzerBanner";
import LoginSocialButtons from "../../../components/auth/LoginSocialButtons";
import FooterColors from "../../../components/auth/FooterColors";
import SignupForm from "./SignupForm";

class SignUpPage extends React.Component<
  WithStyles<
    | "root"
    | "title"
    | "orWrapper"
    | "orText"
    | "banner"
    | "bannerLink"
    | "socialsButtonsContainer"
    | "titleContainer"
  >
> {
  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <Grid container={true} direction="row" justify="center">
        <Grid item={true} xs={12} sm={6} md={5} lg={4}>
          <Slide direction="right" in={true} mountOnEnter={true} unmountOnExit={true}>
            <Paper className={classes.root} elevation={4}>
              <div className={classes.titleContainer}>
                <Typography className={classes.title} align="left" variant="h6">
                  Signup on testRTC
                </Typography>
              </div>
              <div className={classes.socialsButtonsContainer}>
                <LoginSocialButtons />
              </div>
              <div className={classes.orText}>
                <Typography variant="body2">Or sign up using email</Typography>
              </div>
              <SignupForm />
              <FooterColors />
            </Paper>
          </Slide>
        </Grid>
        <Grid item={true} xs={12} sm={5} lg={4}>
          <AnalyzerBanner />
        </Grid>
      </Grid>
    );
  }
}

const decorate = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5),
      position: "relative",
    },
    orText: {
      margin: `${theme.spacing(2)}px 0`,
      padding: "0 10px",
      textAlign: "center",
      textTransform: "uppercase",
    },
    banner: {
      height: "auto",
    },
    bannerLink: {
      marginTop: 158,
      [theme.breakpoints.down("xs")]: {
        marginTop: 30,
      },
    },
    socialsButtonsContainer: {
      paddingTop: theme.spacing(7),
    },
    title: {
      fontSize: "1.9rem",
      color: "white",
      fontWeight: 300,
      margin: 0,
      marginTop: theme.spacing(1),
      userSelect: "none",
    },
    titleContainer: {
      height: 100,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(5),
      margin: -theme.spacing(5),
      justifyContent: "center",
    },
  })
);

export default decorate(SignUpPage);
