import * as React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { FieldRenderProps } from "react-final-form";
import { ChangeEvent } from "react";

interface IRadioFieldControl {
  label?: string | HTMLElement;
  options: Array<Option>;
}

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

class RadioControl extends React.Component<FieldRenderProps & IRadioFieldControl> {
  constructor(props: FieldRenderProps & IRadioFieldControl) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  public render(): JSX.Element {
    const {
      input: { name, value },
      meta,
      label,
      options,
    } = this.props;
    const valueStr = value.toString();

    return (
      <FormControl
        component={"fieldset" as "div"}
        error={meta.touched && (meta.error || meta.submitError)}
      >
        <FormLabel component={"span"}>{label}</FormLabel>
        <RadioGroup
          aria-label={label && label.toString()}
          name={name}
          value={valueStr}
          onChange={this.handleChange}
        >
          {options.map((o: Option) => (
            <FormControlLabel
              key={o.value}
              disabled={o.disabled}
              value={o.value}
              control={<Radio color="primary" />}
              label={o.label}
            />
          ))}
        </RadioGroup>
        {meta.touched && (meta.error || meta.submitError) && (
          <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
        )}
      </FormControl>
    );
  }

  private handleChange(e: ChangeEvent<HTMLInputElement>) {
    this.props.input.onChange(e.target.value);
  }
}

export default RadioControl;
