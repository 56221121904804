import * as React from "react";
import { connect } from "react-redux";
import GridControl from "./GridControl";
import Video from "../../components/Video";
import withBreadcrumb from "../../components/withBreadcrumb";

type Props = {
  projectId: string;
};

class Tests extends React.Component<Props> {
  render() {
    return (
      <React.Fragment>
        <Video projectId={this.props.projectId} screen="tests" />
        <GridControl {...this.props} />
      </React.Fragment>
    );
  }
}

// tslint:disable-next-line:no-any
const mapStateToProps = (state: any) => ({
  projectId: state.userAuth.user.project._id,
});

// eslint:disable-next-line:no-any
export default withBreadcrumb(connect(mapStateToProps, null)(Tests));
