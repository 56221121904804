// tslint:disable:no-any
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";

import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { ITests, IBalance } from "../indexNew";
import Widget from "./Components/Widget";
import Line from "./Components/Line";
import LinearProgress from "./Components/LinearProgress";
import { showFreeDate, calculateLastUpdateTime, numberWithCommas, showPaidDate } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lastUpdateTime: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      display: "inline-block",
      fontSize: 13,
      height: 20,
      lineHeight: 1.2,
      padding: 3,
      borderRadius: 5,
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    divider: {
      margin: "16px -16px 16px -8px",
      height: "2px",
      width: "calc(100% + 16px)",
    },
  })
);

interface ITestingRTC {
  tests?: ITests;
  balance?: IBalance;
}

const TestingRTC = ({ tests, balance }: ITestingRTC) => {
  const classes = useStyles();

  if (!tests) {
    return null;
  }

  return (
    <Widget title="testingRTC">
      {balance && (
        <Line
          label="Monthly cycle date"
          value={{
            color: "primary",
            value: showFreeDate(balance.FreeBalanceValidDate),
          }}
        />
      )}
      <Line
        label="Running probes"
        value={[
          {
            color: "primary",
            value: tests.runningAgents.toLocaleString("en", {
              maximumFractionDigits: 0,
            }),
            tooltip: "Running probes",
          },
          {
            color: "primary",
            value: (tests.agentsLimit || 0).toLocaleString("en", {
              maximumFractionDigits: 0,
            }),
            tooltip: "Maximum concurrent probes",
          },
        ]}
      />
      <LinearProgress value={(tests.runningAgents / tests.agentsLimit) * 100} />
      <Divider className={classes.divider} />
      <Line label="Tests" />
      <Line
        label="Successful"
        value={[
          {
            color: "secondary",
            value: tests.success + tests.warnings,
            tooltip: "Successful tests in this cycle",
          },
        ]}
        labelStyle={{ marginLeft: 20 }}
      />
      <Line
        label="Failed"
        value={[
          {
            color: "error",
            value: tests.failure,
            tooltip: "Failed tests in this cycle",
          },
        ]}
        labelStyle={{ marginLeft: 20 }}
      />
      <Line
        label="Minutes used"
        value={[
          {
            value: numberWithCommas(tests.minutesUsed || 0),
            tooltip: "Minutes used in this cycle",
          },
        ]}
        labelStyle={{ marginLeft: 20 }}
      />
      {balance && (
        <>
          <Divider className={classes.divider} />
          <Line
            label="Balance"
            valueComponent={
              <Tooltip
                title={"Available minutes may lag behind real use by up to an hour"}
                placement="top"
              >
                <Typography variant="h4" className={classes.lastUpdateTime}>
                  {balance
                    ? calculateLastUpdateTime(balance.LastUpdateDate, balance.LastUpdateTime)
                    : "none"}
                </Typography>
              </Tooltip>
            }
          />
          <Line
            label="Monthly Allowance"
            value={{
              color: "secondary",
              value: balance ? numberWithCommas(balance.FreeCycleInitialMinutes || 0) : "none",
              tooltip: balance
                ? `Monthly minutes replenish at ${showFreeDate(balance.FreeBalanceValidDate)}`
                : "",
            }}
            labelStyle={{ marginLeft: 20 }}
          />
          <Line
            label="Monthly Left"
            value={{
              color: "secondary",
              value: balance ? numberWithCommas(balance.FreeBalance || 0) : "none",
              tooltip: balance
                ? `Monthly minutes replenish at ${showFreeDate(balance.FreeBalanceValidDate)}`
                : "",
            }}
            labelStyle={{ marginLeft: 20 }}
          />
          <Line
            label="Additional Minutes"
            value={{
              value: balance ? numberWithCommas(balance.PaidBalance || 0) : "none",
              tooltip: balance
                ? `Additional minutes available until ${showPaidDate(balance.PaidBalanceValidDate)}`
                : "",
            }}
            labelStyle={{ marginLeft: 20 }}
          />
        </>
      )}
    </Widget>
  );
};

export default TestingRTC;
