import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AxiosResponse } from "axios";

import { createStyles, makeStyles, Theme, fade } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";

import Save from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";

import LogViewer from "src/components/LogViewer";
import DbUtilsService from "src/services/DbUtilsService";
import AxiosFactory from "src/services/AxiosFactory";
import ApiPath from "src/constants/ApiPath";

type RouterParams = {
  objectId: string;
  machine: string;
  manual?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      height: "100%",
      padding: theme.spacing(3),
      paddingBottom: 0,
      marginBottom: theme.spacing(1),
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      justifyContent: "space-between",
    },
    toolbarLeft: {
      display: "flex",
      alignItems: "center",
    },
    toolbarRight: {},
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    searchInputRoot: {
      color: "inherit",
    },
    searchInputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    downloadButton: {
      marginLeft: 15,
    },
  })
);

const TestLogs = (props: RouteComponentProps<RouterParams>) => {
  const classes = useStyles();

  const [logsNames, setLogsNames] = React.useState<Array<string>>([]);
  const [files, setFiles] = React.useState<Array<File>>([]);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    loadLogsNames();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    loadFiles();
    // eslint-disable-next-line
  }, [logsNames]);

  const loadLogsNames = async () => {
    try {
      const axiosFactory = new AxiosFactory();
      const result: AxiosResponse = await axiosFactory.axios.get(
        `${ApiPath.api.testRuns}/logs/${props.match.params.objectId}/names`
      );
      setLogsNames(result.data);
    } catch (err) {
      console.log("Could not load logs names", err.message);
    }
  };

  const loadFiles = async () => {
    if (logsNames.length === 0) {
      return;
    }
    const params = {
      params: {
        log: "main",
        start: 0,
        limit: 100000,
        machine:
          props.match.params.machine === "undefined" ? undefined : props.match.params.machine,
      },
      responseType: "blob",
    };

    const result: AxiosResponse = await DbUtilsService.rpc(
      "test_runs",
      "logs",
      props.match.params.objectId,
      "lines",
      params
    );
    const blob = result.data;
    const file = new File([blob], "main", { type: blob.type });
    setFiles([...files, file]);
  };

  const downloadHandler = (_logName: string, type: string, extended?: boolean) => {
    const href =
      process.env.REACT_APP_API_PATH +
      "/api/test_runs/logs/" +
      props.match.params.objectId +
      "/" +
      type +
      "?log=" +
      encodeURIComponent(logsNames.join(":")) +
      "&extended=" +
      !!extended;
    window.location.href = href;
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <div style={{ height: "calc(100vh - 184px)" }}>
      {files.map((file, index) => {
        const key = file.name;
        return (
          <Paper key={index} className={classes.logWrapper}>
            <div className={classes.toolbar}>
              <div className={classes.toolbarLeft}>
                <Typography variant="h5">Logs {key}</Typography>
                <Button
                  onClick={() => downloadHandler(key, "json")}
                  variant="contained"
                  className={classes.downloadButton}
                >
                  <Save />
                  {"Json"}
                </Button>
                <Button
                  onClick={() => downloadHandler(key, "text")}
                  variant="contained"
                  className={classes.downloadButton}
                >
                  <Save />
                  {"Text"}
                </Button>
                <Button
                  onClick={() => downloadHandler(key, "text", true)}
                  variant="contained"
                  className={classes.downloadButton}
                >
                  <Save />
                  {"Text (extended)"}
                </Button>
              </div>
              <div>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    value={search}
                    disabled={!file}
                    onChange={onSearch}
                    placeholder="Search…"
                    classes={{
                      root: classes.searchInputRoot,
                      input: classes.searchInputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
              </div>
            </div>
            <LogViewer
              file={file}
              chuckSize={0.1}
              search={search}
              setSearch={setSearch}
              searchLimit={300}
              searchMinLength={3}
            />
          </Paper>
        );
      })}
    </div>
  );
};

export default TestLogs;
