import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Theme, WithStyles, createStyles } from "@material-ui/core/styles";
import Error from "@material-ui/icons/Error";
import { NavbarHeight } from "../../../styles/Constants";

class AppError extends React.Component<WithStyles> {
  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <div className={classes.errorContainer}>
        <Error className={classes.errorIcon} />
      </div>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    errorContainer: {
      width: "100%",
      height: `calc(100% - ${NavbarHeight}px)`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    errorIcon: {
      color: "#a22a21",
      fontSize: 70,
    },
  });

const decorate = withStyles(styles);

export default decorate(AppError);
