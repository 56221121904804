import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import NavBar from "../Auth/LoginLayout/components/NavBar";
import WorkingSection from "../Auth/LoginLayout/components/WorkingSection";
import { Route, Switch } from "react-router";
import { Shared } from "../../constants/RoutesNames";

import AsyncComponent from "../../components/asyncComponent";
const AsyncOnPremiseProbeCharts = AsyncComponent(() => import("../OnPremiseProbeCharts"));
const AsyncOnPremiseProbeResults = AsyncComponent(() => import("../OnPremiseProbeResults"));
const AsyncEmbedQualityRtcInviteTests = AsyncComponent(
  () => import("../EmbedQualityRTCInviteTests")
);

class SharedLayout extends React.Component<RouteComponentProps<{}>> {
  render() {
    return (
      <>
        <NavBar />
        <WorkingSection>
          <Switch>
            <Route
              exact={true}
              path={Shared.ProbeRtcViewId}
              component={AsyncOnPremiseProbeCharts}
            />
            <Route
              exact={true}
              path={Shared.ProbeRtcResultsId}
              component={AsyncOnPremiseProbeResults}
            />
            <Route
              exact={true}
              path={Shared.QualityRTCInviteTests}
              component={AsyncEmbedQualityRtcInviteTests}
            />
          </Switch>
        </WorkingSection>
      </>
    );
  }
}

export default withRouter<RouteComponentProps<{}>>(SharedLayout);
