import * as React from "react";
import Button from "@material-ui/core/Button";
import { Hidden } from "@material-ui/core";
import ConfirmDialog from "../../components/Dialog/Confirm";

interface ActionButtonsProps {
  classes: any;
  newMode: boolean;
  deleteDialogOpen: boolean;
  isRunning: boolean;
  onRun(e: React.MouseEvent<HTMLElement>): void;
  onDuplicate(): any;
  onExport(): any;
  toggleDeleteDialog(): any;
  onDelete(): any;
}

const ActionButtons: React.SFC<ActionButtonsProps> = ({
  classes,
  newMode,
  isRunning,
  onRun,
  toggleDeleteDialog,
  onExport,
  onDuplicate,
  deleteDialogOpen,
  onDelete,
}) => {
  return (
    <React.Fragment>
      {!newMode && (
        <div className={classes.actionButtonsLeftContainer}>
          <Hidden xsDown={false}>
            <Button
              type="button"
              color="primary"
              className={classes.greenBtn}
              onClick={toggleDeleteDialog}
            >
              Delete
            </Button>
            <Button type="button" color="primary" className={classes.greenBtn} onClick={onExport}>
              Export
            </Button>
            <Button
              type="button"
              color="primary"
              className={classes.greenBtn}
              onClick={onDuplicate}
              data-cy="duplicate-test-btn"
            >
              Duplicate
            </Button>
            <ConfirmDialog
              open={deleteDialogOpen}
              title="Are you sure?"
              content="You will not be able to recover this test!"
              handleConfirm={onDelete}
              handleClose={toggleDeleteDialog}
            />
          </Hidden>
        </div>
      )}
      <div className={classes.actionButtonsRightContainer}>
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          className={classes.greenBtn}
          data-cy="save-test-btn"
        >
          Save
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          className={classes.runBtn}
          disabled={isRunning}
          onClick={onRun}
        >
          Run
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ActionButtons;
