// tslint:disable:no-any

import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { Theme, withStyles, createStyles } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

import { AnalyzeDumpState } from "./index";

import DropSection from "./DropSection";
import DumpHistory from "./DumpHistory";
import AnalyzeRTCHistory from "./AnalyzeRTCHistory";
import AnalyzeRTCSection from "./AnalyzeRTCSection";
import { AppControllerState } from "../AppController";

type ViewProps = {
  classes: any;
  showAnalyzeRtcTable: boolean;
  handleDrop: (accepted: any, rejected: any) => void;
  handleResults: (file: any, item: any) => void;
  toggleAnalyzeRtcTable: () => void;
  setNotification: (message: string) => void;
};

const View = (props: ViewProps & AnalyzeDumpState & AppControllerState) => {
  const { classes, toggleAnalyzeRtcTable, setNotification, showAnalyzeRtcTable } = props;
  return (
    <Fade in={true}>
      <>
        <Grid item={true} xs={12}>
          <Card className={classes.card}>
            <DropSection {...props} />
            {props.selectedProject?.analyzertc_enable && (
              <AnalyzeRTCSection
                toggleTable={toggleAnalyzeRtcTable}
                setNotification={setNotification}
                isHistoryVisible={showAnalyzeRtcTable}
              />
            )}
          </Card>
        </Grid>
        {props.selectedProject?.analyzertc_enable && showAnalyzeRtcTable && (
          <AnalyzeRTCHistory setNotification={setNotification} />
        )}
        <DumpHistory {...props} />
      </>
    </Fade>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
    },
  });

const decorate = withStyles(styles);

export default decorate(View);
