// tslint:disable:typedef no-any noImplicitAny

import * as _ from "lodash";

export default class SeriesBuilder {
  _colorPull: Array<string>;
  numOfSeries: number;
  series: Array<Array<any>>;
  datasetObj: any;
  labels: Array<string>;
  offset: any;
  globalConfig: any;
  perSeriesConfig: Array<any>;
  hPoint: any;
  helperData: Array<any>;
  seriesColors: Array<string>;
  handleColors: boolean;

  constructor(handleColors = false) {
    // hope these colors will be enough to not duplicate
    this._colorPull = [
      "#a22a21",
      "#4682c0",
      "#f1cd2b",
      "#559542",
      "#dd7127",
      "#745671",
      "#ddcc27",
      "#567459",
      "#dd2738",
      "#262d31",
      "#b2f12b",
      "#c08446",
      "#cd2bf1",
      "#a22159",
      "#F08080",
      "#E9967A",
      "#DC143C",
      "#8B0000",
      "#FFC0CB",
      "#FF69B4",
      "#DB7093",
      "#FF4500",
      "#FF8C00",
      "#FFD700",
      "#BDB76B",
      "#EE82EE",
      "#FF00FF",
      "#663399",
      "#4B0082",
      "#7B68EE",
      "#32CD32",
      "#006400",
      "#808000",
      "#008080",
      "#1E90FF",
      "#0000CD",
      "#191970",
      "#FFE4C4",
      "#800000",
      "#8B4513",
      "#778899",
      "#2F4F4F",
      "#A9A9A9",
      "#2F4F4F",
    ];
    // set empty series etc
    this.numOfSeries = 0;

    // TODO: this ternaty condition is deprecated - drop it later
    this.series = this.numOfSeries > 0 ? this.fillArrayWithEmptyArrays(this.numOfSeries) : [];

    this.datasetObj = null;
    // actually here always be empty array but did it for future functionality
    this.labels = this.numOfSeries > 0 ? this.generateDumbArrayNames(0, this.numOfSeries) : [];

    // offset for audio
    this.offset = 0;

    // if setConfigForAll has been invoked
    this.globalConfig = null;

    // per each seria configs | empty array
    this.perSeriesConfig = [];

    // highest point on the chart
    this.hPoint = 0;

    // help data per series -> array
    this.helperData = [];

    // series' colors
    // each index it's series id in series array above
    this.seriesColors = [];

    // if true -> force start using _colorPull
    this.handleColors = handleColors;
  }

  // makes simple plot object
  // you must pass here prepared array of series like [[0, 10], [1, 20]]
  // returns an object
  public setupDataset(series: any, labels: any) {
    // TODO: make labels not necessary
    if (series.length !== labels.length && (!Array.isArray(series) || !Array.isArray(labels))) {
      throw new Error("[series] and [labels] must to be an array and have equal length");
    }

    let idx;
    const numOfSeries = series.length;
    const arrayOfSeries: Array<any> = [];
    for (idx = 0; idx < numOfSeries; idx++) {
      ([] as any).prototype.push.call(arrayOfSeries, {
        label: labels[idx],
        data: ([] as any).prototype.slice.call(series, 0),
      });
    }

    return arrayOfSeries;
  }

  // TODO: add here conditions for isarray, ranges etc
  // pass source array by reference
  // returns array
  public fillArrayWithEmptyArrays(numOfPairs: any) {
    const sourceArray = [];

    let idx;
    for (idx = 0; idx < numOfPairs; idx++) {
      sourceArray[sourceArray.length] = [];
    }

    return sourceArray;
  }

  // do not check for 'from' &'to' because we believe in me
  public generateDumbArrayNames(_from: any, to: any) {
    const startWord = "Label #";
    const arrayOfLabels = [];

    let idx;
    for (idx = 0; idx < to; idx++) {
      arrayOfLabels.push(startWord + idx);
    }

    return arrayOfLabels;
  }

  // var SeriesBuilder SeriesBuilder() {
  //   SeriesConfig.call(this);
  // }

  // SeriesBuilder.prototype = SeriesConfig.prototype;
  // SeriesBuilder.constructor constructor;

  // each series presented like simple array of numbers
  // we need to transform it to [ [step, seria element] ]
  // this method is not chainable it itself like .addSeries().addSeries()
  public addSeries(series: any, labels: any, dataFrequency: number) {
    const currentSeriesLength = series.length;
    const push = Array.prototype.push;

    if (!Array.isArray(series)) {
      throw new Error("[series] has to be an array, you've passed ");
    }

    // fill new number of arrays with empty pairs
    const numOfIter = currentSeriesLength;
    // because we may meet an error but will increase series length
    const tempNumOfSeries = this.series.length + currentSeriesLength;

    // add labels first
    if (!labels) {
      push.apply(
        this.labels,
        this.generateDumbArrayNames(this.series.length - numOfIter, tempNumOfSeries)
      );
    } else {
      this.addLabels(labels);
    }

    let idx;
    for (idx = 0; idx < numOfIter; idx++) {
      // returns an array and pushes to series
      push.call(this.series, this.fillArrayWithEmptyArrays(series[idx].length));
    }

    // once success we increase real series counter
    this.numOfSeries = tempNumOfSeries; /* the same as this.series.length */

    let seriaIdx, seriaElementIdx;
    for (seriaIdx = this.numOfSeries - series.length; seriaIdx < this.numOfSeries; seriaIdx++) {
      const seriaElementsLength = series[seriaIdx].length;
      for (seriaElementIdx = 0; seriaElementIdx < seriaElementsLength; seriaElementIdx++) {
        this.series[seriaIdx][seriaElementIdx] = [
          seriaElementIdx * dataFrequency,
          series[seriaIdx][seriaElementIdx],
        ];
      }
    }

    // fill offset
    // this.offsetToAllSeries();

    return this;
  }

  // add series one by one
  // it can be chained
  public addSingleSeries(series: any, label: any) {
    const push = Array.prototype.push;

    if (!Array.isArray(series)) {
      throw new Error("[series] has to be an array of 1 item, you've passed ");
    }

    // because we may meet an error but will increase series length
    const tempNumOfSeries = this.series.length + 1;

    // add labels first
    if (label) {
      this.addLabel(label);
    }

    // returns an array and pushes to series
    push.call(this.series, this.fillArrayWithEmptyArrays(series[0].length));

    // once success we increase real series counter
    this.numOfSeries = tempNumOfSeries; /* the same as this.series.length */

    let seriaElementIdx = 0;
    const seriaElementsLength = this.series[this.numOfSeries - 1].length;
    for (seriaElementIdx = 0; seriaElementIdx < seriaElementsLength; seriaElementIdx++) {
      this.series[this.numOfSeries - 1][seriaElementIdx] = [
        seriaElementIdx,
        series[seriaElementIdx],
      ];
    }

    return this;
  }

  // here we just push it to series array
  public addCustomSeries(series: any) {
    if (!Array.isArray(series)) {
      throw new Error("[series] is not an array");
    }

    this.series[this.series.length] = Array.prototype.slice.call(series);
    return this;
  }

  // simply add label to labels array
  public addLabel(label: any) {
    this.labels.push(label);

    return this;
  }

  // the same as addLabel but for array of labels
  public addLabels(labels: any) {
    if (!Array.isArray(labels)) {
      throw new Error("[labels] has to be an array of strings");
    }

    Array.prototype.push.apply(this.labels, labels);

    return this;
  }

  // you do not have access by reference here
  // and thus can't modify sereies
  public getAllSeries(rangeFrom: any, rangeTo: any /* not necessary */) {
    const seriesLength = this.series.length;

    if (rangeFrom && rangeTo) {
      if (
        rangeFrom < 0 ||
        rangeFrom > seriesLength ||
        rangeTo < rangeFrom ||
        rangeTo === rangeFrom ||
        rangeTo > seriesLength
      ) {
        throw new Error("Check your ranges, it has to be in range of 0..seriesLength");
      }

      return this.series.slice(rangeFrom, rangeTo);
    } else {
      return this.series.slice(0);
    }
  }

  // right now it is merging series and labels into list of plot objects
  public preparePlotObject(series: any, labels: any) {
    const listOfPlotObjects = [];
    const configObj = this.globalConfig !== null ? this.globalConfig : this.perSeriesConfig;

    if (!(configObj !== null || Array.isArray(configObj))) {
      throw new Error("You did not pass config object");
    }

    let plotObjIdx = 0;
    const seriesLength = series.length;
    for (plotObjIdx = 0; plotObjIdx < seriesLength; plotObjIdx++) {
      const plotObject = {
        label: labels[plotObjIdx],
        data: series[plotObjIdx],
        helpers: this.helperData[plotObjIdx] ? this.helperData[plotObjIdx] : [],
      } as any;

      // TODO: need to ask Muly about colors, whether we have some pull of
      // agreed colors because random colors looks bad IMHO
      if (this.handleColors) {
        plotObject.color = !!this.seriesColors.length
          ? this.seriesColors[plotObjIdx]
          : this._colorPull[plotObjIdx % this._colorPull.length];
      }

      if (!Array.isArray(configObj)) {
        for (const cfProp in configObj) {
          if (cfProp) {
            plotObject[cfProp] = configObj[cfProp];
          }
        }
      } else {
        for (const cfProp in configObj[plotObjIdx]) {
          if (cfProp) {
            plotObject[cfProp] = configObj[plotObjIdx][cfProp];
          }
        }
      }

      listOfPlotObjects[listOfPlotObjects.length] = plotObject;
    }

    this.datasetObj = listOfPlotObjects;

    return this;
  }

  // just produce complete plot object
  public build() {
    this.preparePlotObject(this.series, this.labels);
    if (!this.datasetObj) {
      throw new Error("Unexpected error. Check your method chaining");
    }

    return this.datasetObj;
  }

  public setOffset(offset: any) {
    this.offset = offset || 0;

    return this;
  }

  // add offset to certain series by index
  public addOffset(offset: any, seriesIdx: any) {
    const newSeries = [];
    let idx = 0;
    const len = this.series[seriesIdx].length;
    // shift chart indecies by offset number
    for (idx = 0; idx < len; idx++) {
      const sEl = this.series[seriesIdx][idx];
      // TODO: apply here spread operator later
      if (sEl.length > 2) {
        this.series[seriesIdx][idx] = [sEl[0] + offset, sEl[1], sEl[2], sEl[3]];
      } else {
        this.series[seriesIdx][idx] = [sEl[0] + offset, sEl[1]];
      }
    }

    for (idx = 0; idx < offset; idx++) {
      newSeries[idx] = [idx, 0];
    }

    Array.prototype.push.apply(newSeries, this.series[seriesIdx]);
    this.series[seriesIdx] = newSeries;

    return this;
  }

  // call specified {fn} public with {This} context
  // by default we use ChartBuilder context
  public applyFn(fn: any, This: any) {
    fn.call(This ? This : this);

    return this;
  }

  // make offset for audio series
  public setOffsetToSeries(start: any, end: any) {
    // set offset for .addSeries (by default it is 0)
    const seriesLen = end;
    for (let currentSeries = start; currentSeries < seriesLen; currentSeries++) {
      const originSerie = this.series[currentSeries].slice();
      const preparedHead = [];

      originSerie.forEach((pair) => {
        pair[0] = this.offset + pair[0];
      });
      for (let howMuch = 0; howMuch < this.offset; howMuch++) {
        preparedHead[howMuch] = [howMuch, 0];
      }

      Array.prototype.push.apply(preparedHead, originSerie);
      this.series[currentSeries] = preparedHead;
    }
    return this;
  }

  public setConfigForAll(configObj: any) {
    if (this.perSeriesConfig.length) {
      throw new Error("You can use either addConfig or setConfigToAll but not both");
    }

    this.globalConfig = configObj;

    return this;
  }

  // Adds single config for current or next series
  // depends on either you created the series or going to
  public addConfig(configObj: any) {
    if (typeof configObj === "object" && (configObj == null || Array.isArray(configObj))) {
      throw new Error("You have to pass object, you have passed ");
    }

    if (this.globalConfig !== null) {
      throw new Error("You can use either addConfig or setConfigToAll but not both");
    }

    this.perSeriesConfig.push(configObj);

    return this;
  }

  // Adds multiple configs for series
  public addConfigs(configArr: any) {
    if (!Array.isArray(configArr)) {
      throw new Error("[configArr] must to be an array, you have passed ");
    }

    Array.prototype.push.apply(this.perSeriesConfig, configArr);

    return this;
  }

  // find the highest point on the chart
  public alignEvents(eventSeriesIdx: any, _positions?: any) {
    // recalculate hPoint
    this.findHighestPoint(0, eventSeriesIdx);

    for (const pair of this.series[eventSeriesIdx]) {
      if (pair) {
        pair[1] = this.hPoint + (this.hPoint / 100) * 10;
      }
    }

    return this;
  }

  public findHighestPoint(fromRange: any, toRangeExcld: any) {
    // recalculate hPoint
    this.hPoint = 0;

    const tmpSeries = this.series.slice(fromRange, toRangeExcld);
    for (const series in tmpSeries) {
      if (series) {
        for (const sElm in tmpSeries[series]) {
          if (this.hPoint < tmpSeries[series][sElm][1]) {
            this.hPoint = tmpSeries[series][sElm][1];
          }
        }
      }
    }

    // in case we have all 0s then just assign 1
    // so we will be able to see bars (events)
    if (this.hPoint < 1) {
      this.hPoint = 1;
    }

    return this;
  }

  public findHighestPointIncl(fromRange: any, toRangeIncl: any) {
    return this.findHighestPoint.call(this, fromRange, toRangeIncl + 1);
  }

  public getSeriesLenght(idx: any) {
    if (Array.isArray(this.series[idx])) {
      return this.series[idx].length;
    } else {
      throw new Error("Series with such index does not exists");
    }
  }

  // INFO: get max value not max length of series
  public getMaxSeriesLength() {
    const len = this.series.length;
    let max = 0;
    for (let idx = 0; idx < len; idx++) {
      for (let vIdx = 0, sLen = this.series[idx].length; vIdx < sLen; vIdx++) {
        if (max < this.series[idx][vIdx][0]) {
          max = this.series[idx][vIdx][0];
        }
      }
    }

    return max;
  }

  public getSeriesCount() {
    return this.series.length;
  }

  // pass help data to some series by its index {sIdx}
  public addHelperData(dataObj: any, sIdx: any) {
    // This if causes braeak of app with no need
    // if (dataObj.toString() === '[object Object]' && typeof dataObj !== null) {
    //     throw new Error('addHelperData accepts sIdx as number and dataObj as object');
    // }

    if (sIdx && !this.series[sIdx]) {
      throw new Error("There is not such series index");
    }

    if (_.isNumber(sIdx)) {
      this.helperData[sIdx] = dataObj;
    } else {
      this.helperData[this.series.length - 1] = dataObj;
    }

    return this;
  }

  /* COLORS */

  /* private methods */

  // simply adds one color to color array
  public addColor(color: string) {
    if (!color && _.isString(color)) {
      throw new Error("[addColorBulk] expects color which has to be a string");
    }

    this.seriesColors.push(color);

    return this;
  }

  // add many colors at once
  public addColorBulk(...colors: Array<string>) {
    if (!_.isArray(colors)) {
      throw new Error("[addColorBulk] expects for colors as params to public function");
    }

    this.seriesColors.push(...colors);

    return this;
  }
}
