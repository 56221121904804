import * as React from "react";
import View from "./View";
import dbUtils from "../../../../../services/DbUtilsService";

export interface NotificationType {
  label: string;
  value: string;
}

export const TypesMap = {
  err: "Error",
  warn: "Warning",
  info: "Info",
};

export const NotificationTypes: Array<NotificationType> = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Errors",
    value: "err",
  },
  {
    label: "Warnings",
    value: "warn",
  },
  {
    label: "Info",
    value: "info",
  },
];

export interface LogToolbarProps {
  testRun: any;
  testIteration: any;
  assetsFileNames: Array<string>;
  pushToFileNames: (item: string) => void;
}

export interface LogToolbarState {
  isV07: boolean;
  isShowStdout: boolean;
  logHasErrors: boolean;
  isReport: boolean;
  warningErr: boolean;
  limitMap: any;
  notiFilter: any;
  totalNotifications: number;
  currentTab: number;
  warningsCount: number;
  hasLogs: boolean;
  stdout: any;
  stdoutLoaded: boolean;
  selectedType: NotificationType;
  standOutUrl?: string;
  showWarningIcon: boolean;
  isWarningsOpen: boolean;
  warnings: Array<any>;
  notificationList: Array<any>;
  mediaList: Array<any>;
  resourceList: Array<any>;
  performance: any;
}

class LogToolbar extends React.Component<LogToolbarProps, LogToolbarState> {
  private defaultState = {
    isV07: false,
    isShowStdout: false,
    logHasErrors: false,
    isReport: false,
    warningErr: false,
    limitMap: {
      all: false,
    },
    notiFilter: {},
    totalNotifications: 0,
    currentTab: 0,
    warningsCount: 0,
    hasLogs: true,
    stdout: null,
    stdoutLoaded: false,
    showWarningIcon: false,
    isWarningsOpen: false,
    selectedType: NotificationTypes[0],
    warnings: [] as Array<any>,
    notificationList: [] as Array<any>,
    mediaList: [] as Array<any>,
    resourceList: [] as Array<any>,
    performance: null,
  };

  constructor(props: LogToolbarProps) {
    super(props);

    this.state = this.defaultState;

    this.handleTabChange = this.handleTabChange.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
    this.selectType = this.selectType.bind(this);
    this.getAllWarnings = this.getAllWarnings.bind(this);
  }

  // tslint:disable-next-line:no-any
  async handleTabChange(_e: any, tab: number) {
    await this.setState({
      currentTab: tab,
    });
  }

  async componentDidMount() {
    await this.processWarnings();
  }

  changeLimit(selectedTypeValue: string) {
    this.setState({
      limitMap: {
        ...this.state.limitMap,
        [selectedTypeValue]: !this.state.limitMap[selectedTypeValue],
      },
    });
  }

  async processWarnings() {
    const { testIteration } = this.props;
    const newState = {
      ...this.defaultState,
    };

    newState.isV07 = !!testIteration.blobBaseName;
    newState.isShowStdout = false;
    newState.logHasErrors = false;
    newState.isReport = testIteration.status === "analyze" || testIteration.status === "upload";
    newState.notiFilter = {};

    newState.warningErr = false;

    newState.totalNotifications = 0;

    if (newState.isV07) {
      newState.hasLogs = true;
      newState.stdout = null;
      newState.stdoutLoaded = false;

      const setWarnings = (type: string, object: any) => {
        if (type === "err") {
          newState.warningErr = true;
        }

        if (!newState.warnings[0]) {
          newState.warnings.push({});
        }
        if (!newState.warnings[0][type]) {
          newState.warnings[0][type] = [];
        }

        newState.warnings[0][type].push(object);
      };

      newState.warningsCount = 0;
      newState.showWarningIcon = false;
      for (const key in testIteration.sysWarnings) {
        if (testIteration.sysWarnings.hasOwnProperty(key)) {
          const log = testIteration.sysWarnings[key];
          // TODO: rewrite this part with good conditions
          // TODO: check on backend side what the correct topic and
          // what are deprecated
          if (log && (log.topic === "BROWSER" || log.topic === "pass")) {
            if (
              log.alertType === "err" ||
              log.alertType === "severe" ||
              log.alertType === "error"
            ) {
              newState.logHasErrors = true;
            }
          }

          if (log && log.topic.indexOf("SECTION:") !== 0) {
            if (newState.notificationList.indexOf(log.alertType) === -1) {
              newState.notificationList.push(log.alertType);
            }

            newState.showWarningIcon = !!testIteration.sysWarnings.filter((item: any) => {
              return item.alertType === "warn";
            }).length;

            newState.warningsCount++;
            newState.limitMap[log.alertType] = false;
            setWarnings(log.alertType, log);
          }
        }
      }

      newState.isWarningsOpen = newState.warningsCount > 0;
    } else {
      newState.hasLogs = true;
      // there may be the case when it is attached manualSDK results
      // not origin results so check for dockerAgentId
      // TODO: need to test it further
      newState.stdout = testIteration.stdout || true;
      newState.stdoutLoaded = true;

      newState.warningsCount =
        (testIteration.sysWarnings ? testIteration.sysWarnings.length : 0) +
        (testIteration.rtcWarnings ? testIteration.rtcWarnings.length : 0);
      newState.isWarningsOpen = newState.warningsCount > 0;

      for (const key in testIteration.browserLog) {
        if (testIteration.browserLog.hasOwnProperty(key)) {
          const log = testIteration.browserLog[key];

          // TODO: do the same todos as above
          if (log) {
            if (
              log.level === "ERROR" ||
              log.level === "SEVERE" ||
              log.level === "TEST-ERROR" ||
              log.alertType === "ERROR"
            ) {
              newState.logHasErrors = true;
            }
          }
        }
      }
    }

    await this.setState(newState);
    await this.loadData();
  }

  async loadData() {
    const { testIteration, assetsFileNames, pushToFileNames } = this.props;
    const p = await dbUtils.rpc("test_iterations", "get-screen-shots", testIteration._id);

    if (p && p.data) {
      const newState: LogToolbarState = {
        ...this.defaultState,
        ...this.state,
      };

      const answer = p.data;

      // backward compatible with version 0.6 storage
      newState.mediaList = [];
      newState.resourceList = [];

      let reactApiPath = process.env.REACT_APP_API_PATH;
      if (reactApiPath && reactApiPath[reactApiPath.length - 1] === "/") {
        reactApiPath = reactApiPath.slice(0, -1);
      }

      for (const key in answer.screens) {
        if (answer.screens.hasOwnProperty(key)) {
          const screen = answer.screens[key];
          let url;
          const fileExtension = screen.name.split(".").pop();

          if (newState.isV07) {
            url =
              reactApiPath +
              "/api/test_iterations/get-screen-shot/" +
              encodeURIComponent(screen.url) +
              "/" +
              testIteration.machine;
          } else {
            url =
              reactApiPath +
              "/api/dockers/get-screen-shot/" +
              testIteration.dockerAgentId +
              "/" +
              answer.containerId +
              "/" +
              testIteration.machine +
              "/" +
              testIteration.runIndex +
              "/" +
              encodeURIComponent(screen.name);
          }

          if (Array.isArray(assetsFileNames)) {
            pushToFileNames(screen.name);
          }

          switch (fileExtension) {
            case "png":
              newState.mediaList.push({
                url: url + "/download",
                thumbUrl: url + "/download",
                urlCaption: screen.metadata.url || "",
                // tslint:disable-next-line:radix
                timestamp: new Date(parseInt(screen.metadata.timestamp)),
                caption: screen.name.substring(4) || "",
              });
              break;

            case "wav":
              newState.resourceList.push({
                url: url + "/view",
                downloadUrl: url + "/download",
                name: screen.name,
              });

              break;

            case "json":
            case "log":
            case "txt":
              newState.hasLogs = true;
              if (screen.name === "browser_logs.json") {
                newState.resourceList.push({
                  url:
                    "/app/logs/browser/" +
                    testIteration.testRunId +
                    "/" +
                    testIteration._id +
                    "/" +
                    testIteration.machine +
                    "/" +
                    testIteration.inSessionIdx,
                  downloadUrl: url + "/download",
                  name: screen.name,
                });
              } else if (screen.name === "webrtc_internals_dump.txt") {
                newState.resourceList.push({
                  url:
                    "/app/logs/webrtc-internals/" +
                    testIteration.testRunId +
                    "/" +
                    testIteration._id +
                    "/" +
                    testIteration.machine +
                    "/" +
                    testIteration.runIndex,
                  downloadUrl: url + "/download",
                  name: screen.name,
                });
              } else if (screen.name === "performance.json") {
                newState.performance = {
                  viewUrl: url + "/view",
                };
              } else {
                newState.resourceList.push({
                  url: url + "/view",
                  downloadUrl: url + "/download",
                  name: screen.name,
                });
              }
              break;

            default:
              newState.resourceList.push({
                url: url + "/download",
                downloadUrl: url + "/download",
                name: screen.name,
              });
              break;
          }
        }
      }

      for (const key in testIteration.sysWarnings) {
        if (testIteration.sysWarnings.hasOwnProperty(key)) {
          const log = testIteration.sysWarnings[key];
          if (log.topic.indexOf("SECTION:FILE") === 0) {
            newState.resourceList.push({
              url: null,
              name: log.message,
            });
          }
        }
      }

      if (!newState.stdoutLoaded) {
        newState.stdout = true;
        newState.standOutUrl =
          "/app/logs/nightwatch/" +
          testIteration.testRunId +
          "/" +
          testIteration._id +
          "/" +
          testIteration.machine +
          "/" +
          testIteration.runIndex;
      }

      this.setState(newState);
    }
  }

  selectType(selectedType: NotificationType) {
    this.setState({
      selectedType,
    });
  }

  getAllWarnings(): Array<any> {
    const { warnings } = this.state;

    const warningLength = warnings[0].warn || [];
    const errorLength = warnings[0].err || [];
    const infoLength = warnings[0].info || [];

    return [...errorLength, ...warningLength, ...infoLength];
  }

  render() {
    return (
      <View
        handleTabChange={this.handleTabChange}
        changeLimit={this.changeLimit}
        selectType={this.selectType}
        getAllWarnings={this.getAllWarnings}
        {...this.state}
        {...this.props}
      />
    );
  }
}

export default LogToolbar;
