import * as React from "react";
import { getLocationTitle } from "src/constants/PageStateDictionary";

interface IAppToolbarContext {
  // tslint:disable-next-line: no-any
  pageInfo: any;
  setPageInfo: (info: string) => void;
}
const AppToolbarContext = React.createContext({} as IAppToolbarContext);

// tslint:disable-next-line: no-any
const AppToolbarProvider = (props: any) => {
  const [pageInfo, updatePageInfo] = React.useState<any>();
  const setPageInfo = (info: string) => {
    const title = getLocationTitle(window.location.pathname);
    updatePageInfo({
      [title]: info,
    });
  };
  return (
    <AppToolbarContext.Provider
      value={{
        pageInfo,
        setPageInfo,
      }}
    >
      {props.children}
    </AppToolbarContext.Provider>
  );
};

export { AppToolbarProvider, AppToolbarContext };
