import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import AxiosFactory from "src/services/AxiosFactory";
import ApiPath from "src/constants/ApiPath";
import * as moment from "moment";

interface ITestCronButton {
  value: string;
}

export default function ({ value }: ITestCronButton) {
  const [open, setOpen] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [error, setError] = React.useState("");
  const onClick = async () => {
    try {
      setTimeout(() => {
        setOpen(true);
      }, 300);
      const axiosFactory = new AxiosFactory();
      const res = await axiosFactory.axios({
        url: `${ApiPath.api.monitors}/cronTest`,
        method: "POST",
        data: { value },
      });
      setList(res.data);
      if (error) {
        setError("");
      }
    } catch (err) {
      setError("Invalid cron expression");
    }
  };

  const onClose = () => {
    setOpen(false);
    setList([]);
    setError("");
  };
  return (
    <>
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>Cron Test Results</DialogTitle>
        <DialogContent>
          {error && <Typography>{error}</Typography>}
          {!error &&
            list.map((item: string) => {
              // tslint:disable-next-line: no-any
              let date: any = moment(new Date(item)).toLocaleString().split(" ");
              date.pop();
              date = date.join(" ");
              return <Typography key={item}>{date}</Typography>;
            })}
        </DialogContent>
      </Dialog>
      <Button type="button" color="primary" onClick={onClick}>
        {"Test"}
      </Button>
    </>
  );
}
