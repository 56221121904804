// tslint:disable:no-any
import * as React from "react";
import { useState } from "react";
import {
  withStyles,
  WithStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createStyles,
} from "@material-ui/core";

import Chart from "src/components/Chart";

type StyledComponent = WithStyles;

export interface GraphPanelProps {
  id: string;
  graph: any;
}

const chartCollapsedViewStyle: React.CSSProperties = {
  flexDirection: "column",
  alignItems: "flex-start",
};

const panelTitleStyle: React.CSSProperties = {
  fontSize: 15,
};

const expandedPanelViewStyle: React.CSSProperties = {
  flexDirection: "column",
};

const tableViewStyle: React.CSSProperties = {
  marginTop: 20,
};

const getAdditionalDataTitle = (key: string) => {
  switch (key) {
    case "mediaType":
      return "Media";
    case "googCodecName":
      return "Codec";
    case "codecImplementationName":
      return "Implementation";
    case "googContentType":
      return "Content";
    default:
      return "Unknown";
  }
};

const getAdditionalDataValue = (data: any) => {
  if (!data) {
    return "";
  }
  try {
    const values = JSON.parse(data.values);
    const filteredValues = values.filter((val: any) => val !== "");

    return filteredValues[0];
  } catch {
    return "";
  }
};

const ExpansionPanelAny = Accordion as any;

const GraphPanel = (props: StyledComponent & GraphPanelProps) => {
  const { classes, id, graph } = props;
  const isData = graph.dataset && graph.dataset.length > 0 && !!graph.dataset[0].data;
  const [wasOpened, setWasOpened] = useState(false);
  if (!isData) {
    graph.dataset[0] = {};
    graph.dataset[0].data = [];
  }

  return (
    <ExpansionPanelAny
      className={classes.panelRoot}
      onChange={(_e: any, expanded: boolean) => {
        if (expanded && !wasOpened) {
          setWasOpened(true);
        }
      }}
    >
      <AccordionSummary style={chartCollapsedViewStyle}>
        <Typography variant="h5" gutterBottom={true} align="center" style={panelTitleStyle}>
          {graph.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={expandedPanelViewStyle}>
        {wasOpened && (
          <Chart
            toggleAll={true}
            key={id}
            id={`logs-graph${id}`}
            dataset={graph.dataset}
            options={graph.options}
            width="100%"
            height="300px"
            hoverable={true}
            legend={true}
            showNoDataMessage={true}
          />
        )}
        {graph.additionalGraphInfo && (
          <Table style={tableViewStyle}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "20%" }} align="left">
                  {"Type"}
                </TableCell>
                <TableCell style={{ width: "80%" }} align="left">
                  {"Value"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(graph.additionalGraphInfo).map((key: string) => {
                const data = graph.additionalGraphInfo[key];
                if (!data) {
                  return null;
                }
                return (
                  <TableRow key={key}>
                    <TableCell style={{ width: "20%" }}>{getAdditionalDataTitle(key)}</TableCell>
                    <TableCell style={{ width: "80%" }}>{getAdditionalDataValue(data)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </AccordionDetails>
    </ExpansionPanelAny>
  );
};

const styles = () =>
  createStyles({
    panelRoot: {
      boxShadow: "none",
      "&::before": {
        height: 0,
      },
    },
  });

const decorate = withStyles(styles);

export default decorate(GraphPanel);
