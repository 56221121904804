import * as React from "react";
import Tooltip from "../Tooltip";
import { withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Icon, createStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

interface ResultLineProps {
  title: string;
  tooltipTitle?: string;
  pure?: boolean;
  icon?: string;
  color?: string;
  value: React.ReactElement<React.ReactNode> | string | number;
  midValue?: React.ReactElement<React.ReactNode> | string | number | null;
  midIcon?: React.ReactElement<React.ReactNode>;
  titleStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  renderComponent?: any;
}

type StyledComponent = WithStyles<
  "lineTitle" | "lineIcon" | "lineValue" | "overviewLine" | "midValueContainer"
>;

class ResultLine extends React.Component<ResultLineProps & StyledComponent> {
  shouldComponentUpdate(nextProps: ResultLineProps & StyledComponent) {
    return !!!nextProps.pure;
  }

  render() {
    const {
      tooltipTitle,
      classes,
      title,
      icon,
      color,
      value,
      midValue,
      midIcon,
      titleStyle,
      valueStyle,
      renderComponent,
    } = this.props;
    return (
      <Tooltip
        content={tooltipTitle || ""}
        display={tooltipTitle !== undefined}
        displayType="block"
      >
        <div
          style={{
            cursor: "default",
          }}
          className={classes.overviewLine}
        >
          <Typography variant="body2" className={classes.lineTitle} style={titleStyle}>
            {icon && <Icon className={classes.lineIcon}>{icon}</Icon>}
            {title}
          </Typography>
          {midValue && (
            <div className={classes.midValueContainer}>
              <Typography variant="body2" className={classes.lineTitle}>
                {midValue}
              </Typography>
              {midIcon && midIcon}
            </div>
          )}
          {renderComponent ? (
            <>{renderComponent}</>
          ) : (
            <Typography
              variant="body2"
              className={classes.lineValue}
              style={{ ...valueStyle, color: color ? color : "inherit" }}
            >
              {value}
            </Typography>
          )}
        </div>
      </Tooltip>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    lineValue: {
      fontWeight: 500,
      textAlign: "right",
    },
    lineTitle: {
      display: "flex",
      alignItems: "center",
    },
    lineIcon: {
      marginRight: 5,
      fontSize: 20,
      color: "#9a9a9a",
    },
    overviewLine: {
      height: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 5,
    },
    midValueContainer: {
      display: "flex",
      flexDirection: "row",
    },
  });

const decorate = withStyles(styles);

export default decorate(ResultLine);
