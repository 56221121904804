import * as React from "react";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextFieldControl from "../../components/form-elements/TextFieldControl";
import ConfirmDialog from "../../components/Dialog/Confirm";
import FormValidators from "../../helpers/form-validators";
import Paper from "@material-ui/core/Paper";
import FormLabel from "@material-ui/core/FormLabel";
import { MenuItem, createStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { IAssetPropertyForm } from "./index";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { RouteComponentProps, withRouter } from "react-router";
import { AssetPropertyState } from "./index";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import AxiosFactory from "../../services/AxiosFactory";
import { AxiosResponse } from "axios";
import ApiPath from "src/constants/ApiPath";
import { requireAceAutocompleter, commandsAceAutocompleter } from "../../helpers/aceHelper";
import AceEditorCommands from "../../constants/EditorCompleteCommands";
import { EXPECTATION_CODE } from "./expectation-code.xmpl";
interface RouterProps {
  objectId?: string;
}

interface AssetPropertyFormProps extends RouteComponentProps<RouterProps> {
  // tslint:disable-next-line:no-any
  initialValues?: any;
  newMode: boolean;
  onSubmit(values: IAssetPropertyForm): void;
  onDuplicateButtonClick(): void;
  onExport(): void;
  deleteHandler(): void;
  onDelete(): void;
}

interface AssetPropertyFormState {
  showEdit: boolean;
}

type StyledComponent = WithStyles<
  | "toolbar"
  | "divider"
  | "root"
  | "formRoot"
  | "addProfileBtnContainer"
  | "addProfileBtn"
  | "fieldLabel"
  | "formFieldGridItem"
  | "removeBtnContainer"
  | "removeBtn"
  | "greenBtn"
  | "saveBtnContainer"
  | "tooltipLabel"
  | "editBtn"
  | "iconSmall"
  | "checkboxContainer"
  | "alarmLabelContainer"
  | "editorWrapper"
>;

const FORM_LABEL_WIDTH = 200;

class AssetPropertyForm extends React.Component<
  AssetPropertyFormProps & StyledComponent & AssetPropertyState,
  AssetPropertyFormState
> {
  editorRef: any;

  constructor(props: AssetPropertyFormProps & StyledComponent & AssetPropertyState) {
    super(props);

    this.state = {
      showEdit: false,
    };
  }

  configureAceEditor = async (editor: any) => {
    // fetch assets names for the ace editor autocomplete
    const names = await this.fetchAssetsNames();
    // set autocompleters
    editor.completers = [
      requireAceAutocompleter(names),
      commandsAceAutocompleter(AceEditorCommands),
    ];
  };

  fetchAssetsNames = async () => {
    try {
      const axiosFactory = new AxiosFactory();

      const result: AxiosResponse<any> = await axiosFactory.axios.get(ApiPath.api.assets, {
        params: {
          limit: 500,
        },
      });
      return result.data.docs ? result.data.docs.map((doc: any) => doc.name) : [];
    } catch {
      return [];
    }
  };

  onBeautifyClick = async (func: any, value: any) => {
    try {
      const beautify = await import("js-beautify");
      func(beautify.js(value));
    } catch (err) {
      console.error("onBeautifyClick", err.message, err.stack);
    }
  };

  fillCodeField = (event: any, formValues: any, setCode: any) => {
    if (event.target.value === "expectation" && !formValues.code) {
      setCode(EXPECTATION_CODE);
    }
  };

  render() {
    const {
      classes,
      initialValues,
      onSubmit,
      newMode,
      onDuplicateButtonClick,
      onExport,
      deleteHandler,
      onDelete,
      deleteDialog,
    } = this.props;
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Paper className={classes.root}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="subtitle1">Asset Configuration</Typography>
            </Toolbar>
            <Divider className={classes.divider} />
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators,
                setAssetCodeValue: (values, state, { changeValue }) => {
                  changeValue(state, "code", () => values[0]);
                },
              }}
              render={({ handleSubmit, form, values }) => (
                <form className={classes.formRoot} onSubmit={handleSubmit}>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} className={classes.saveBtnContainer}>
                      <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        className={classes.greenBtn}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Asset Name</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          component={TextFieldControl}
                          type="text"
                          name="name"
                          validate={FormValidators.required()}
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Description</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          component={TextFieldControl}
                          type="text"
                          multiline={true}
                          rows="5"
                          name="description"
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Asset Type</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          component={TextFieldControl}
                          select={true}
                          SelectProps={{
                            autoWidth: true,
                          }}
                          name="asset_type"
                          validate={FormValidators.required()}
                          onchange={(e: any) =>
                            this.fillCodeField(e, values, form.mutators.setAssetCodeValue)
                          }
                        >
                          <MenuItem value={"code"}>{"Code"}</MenuItem>
                          <MenuItem value={"expectation"}>{"Expectation"}</MenuItem>
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Version</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          component={TextFieldControl}
                          type="text"
                          name="version"
                          validate={FormValidators.required()}
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} container={true} className={classes.editorWrapper}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Code</FormLabel>
                      </Grid>
                      <Grid
                        item={true}
                        className={classes.formFieldGridItem}
                        data-cy="script-editor"
                      >
                        <AceEditor
                          mode="javascript"
                          theme="github"
                          name="code"
                          width={"100%"}
                          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.42)" }}
                          onChange={(file: string) => form.mutators.setAssetCodeValue(file)}
                          value={values.code}
                          editorProps={{ $blockScrolling: Infinity }}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                          }}
                          onLoad={(editor) => this.configureAceEditor(editor)}
                        />
                        <Button
                          color="default"
                          variant="contained"
                          style={{ marginTop: 5 }}
                          onClick={() => {
                            this.onBeautifyClick(form.mutators.setAssetCodeValue, values.code);
                          }}
                        >
                          {"beautify"}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} className={classes.saveBtnContainer}>
                      {!newMode && (
                        <>
                          <Button
                            type="button"
                            color="primary"
                            className={classes.greenBtn}
                            onClick={deleteHandler}
                          >
                            {"Delete"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            className={classes.greenBtn}
                            onClick={onExport}
                          >
                            {"Export"}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            className={classes.greenBtn}
                            onClick={onDuplicateButtonClick}
                          >
                            {"Duplicate"}
                          </Button>
                          <ConfirmDialog
                            open={deleteDialog}
                            title="Are you sure?"
                            content="You will not be able to recover this asset!"
                            handleConfirm={onDelete}
                            handleClose={deleteHandler}
                          />
                        </>
                      )}
                      <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        className={classes.greenBtn}
                      >
                        {"Save"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const decorate = withStyles((theme) =>
  createStyles({
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "felx",
      justifyContent: "space-between",
      alignItems: "center",
    },
    root: {
      width: "100%",
      height: "auto",
      padding: theme.spacing(3),
    },
    addProfileBtnContainer: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-start",
    },
    addProfileBtn: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: "#3e76b2",
        color: "white",
      },
      "&:not(:first-child)": {
        marginLeft: theme.spacing(2),
      },
    },
    fieldLabel: {
      display: "inline-block",
      fontSize: 13,
      padding: "10px 12px 0 0",
      textAlign: "right",
      width: FORM_LABEL_WIDTH,
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    formFieldGridItem: {
      flexGrow: 1,
    },
    editorWrapper: {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    removeBtnContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    removeBtn: {
      backgroundColor: "#d2d2d2",
      borderRadius: 3,
      color: "#a22a21",
    },
    greenBtn: {
      "&:not(:first-child)": {
        marginLeft: theme.spacing(1),
      },
    },
    editBtn: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    saveBtnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    tooltipLabel: {
      color: theme.palette.primary.main,
    },
    iconSmall: {
      fontSize: 20.5,
    },
    checkboxContainer: {
      padding: "0 8px !important",
    },
    alarmLabelContainer: {
      display: "inline-block",
      fontSize: 13,
      textAlign: "right",
      width: FORM_LABEL_WIDTH,
      padding: "18px 12px 0 0",
    },
  })
);

export default withRouter(decorate(AssetPropertyForm));
