// tslint:disable:no-any

import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import FileCopy from "@material-ui/icons/FileCopyOutlined";
import PlayArrow from "@material-ui/icons/PlayArrow";
import * as moment from "moment";
import RTCGrid from "../../components/Grid";
import ApiPath from "../../constants/ApiPath";
import GridToolbar from "./GridToolbar";
import { RouteComponentProps } from "react-router";
import { TestProperty, TestRunDetails } from "../../constants/RoutesNames";
import Tooltip from "../../components/Tooltip";
import AxiosFactory from "../../services/AxiosFactory";
import { SetNotification } from "../../actions/notificationAction";
import { AxiosResponse } from "axios";
import StatusIcon from "../../components/Test/StatusIcon";
import Flag from "@material-ui/icons/Flag";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Star, StarBorder } from "@material-ui/icons";

type StyledComponent = WithStyles<
  | "root"
  | "tableRowItemHover"
  | "hoverActionBtnStyle"
  | "actionBlock"
  | "nameBlock"
  | "star"
  | "hidden"
>;

type DispatchProps = {
  setNotification: (message: string) => void;
  user: User;
};

export class GridControl extends React.Component<
  RouteComponentProps<{}> & DispatchProps & StyledComponent
> {
  grid: any = null;
  columnSchema: Array<ColumnSchema> = [
    {
      id: "favorites",
      numeric: false,
      style: {
        maxWidth: 50,
        paddingLeft: 10,
      },
      label: "",
      disablePadding: true,
      render: (dataItem: any) => (
        <div
          className={this.props.classes.star}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            this.addToFavorites(e, dataItem);
          }}
        >
          <IconButton className={this.props.classes.hoverActionBtnStyle}>
            {this.isInFavorites(dataItem) ? <Star /> : <StarBorder />}
          </IconButton>
        </div>
      ),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      style: {
        paddingLeft: 20,
        paddingRight: 20,
        maxWidth: "420px",
        position: "relative",
        width: "max-content",
      },
      render: (dataItem: any) => (
        <div className={this.props.classes.nameBlock}>
          <div title={dataItem.name}>{dataItem.name}</div>

          <div className={this.props.classes.actionBlock} data-show={true}>
            <Tooltip content="Edit">
              <IconButton
                className={`${this.props.classes.hoverActionBtnStyle} ${this.props.classes.hidden}`}
                onClick={(e) => this.onEditButtonClick(e, dataItem)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip content="Duplicate">
              <IconButton
                className={`${this.props.classes.hoverActionBtnStyle} ${this.props.classes.hidden}`}
                onClick={(e) => this.onDuplicateButtonClick(e, dataItem)}
              >
                <FileCopy />
              </IconButton>
            </Tooltip>
            <Tooltip content="Run">
              <IconButton
                className={`${this.props.classes.hoverActionBtnStyle} ${this.props.classes.hidden}`}
                onClick={(e) => this.onRunButtonClick(e, dataItem)}
              >
                <PlayArrow />
              </IconButton>
            </Tooltip>
            <Tooltip content="Open in new tab">
              <IconButton
                className={`${this.props.classes.hoverActionBtnStyle} ${this.props.classes.hidden}`}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  window.open(`${TestProperty}/${dataItem._id}`, "_blank");
                  e.stopPropagation();
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      id: "info",
      numeric: false,
      disablePadding: true,
      label: "Description",
      sortable: false,
      hideDown: "xs",
      style: { wordWrap: "break-word", padding: "0 2px", wordBreak: "break-all", maxWidth: "40%" },
    },
    {
      id: "modifiedDate",
      numeric: false,
      disablePadding: true,
      label: "Last Modified",
      render: (dataItem: any) =>
        dataItem.modifiedDate
          ? moment(dataItem.modifiedDate).format("MMM DD, YYYY - HH:mm")
          : "never",
      style: {
        whiteSpace: "nowrap",
        minWidth: "20%",
        paddingLeft: "10px",
      },
      hideDown: "sm",
    },
    {
      id: "runCount",
      numeric: true,
      disablePadding: true,
      label: "Run Count",
      style: { textAlign: "center", minWidth: "20%", whiteSpace: "nowrap" },
      hideDown: "md",
    },
    {
      id: "lastRunDate",
      numeric: false,
      disablePadding: false,
      label: "Last Run",
      render: (dataItem: any) =>
        dataItem.lastRunDate
          ? moment(dataItem.lastRunDate).format("MMM DD, YYYY - HH:mm")
          : "never",
      style: {
        whiteSpace: "nowrap",
        minWidth: "20%",
      },
      hideDown: "sm",
    },
    {
      id: "lastRunStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
      labelRender: () => <Flag />,
      // tslint:disable-next-line:no-any
      render: (dataItem: any, _index: number) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <StatusIcon status={dataItem.lastRunStatus} />
        </div>
      ),
      style: { width: "50px", padding: "0 15px" },
      hideDown: "md",
    },
  ];

  constructor(props: RouteComponentProps<{}> & DispatchProps & StyledComponent) {
    super(props);

    this.onRowClick = this.onRowClick.bind(this);
    this.onTestImport = this.onTestImport.bind(this);
  }

  isInFavorites(dataItem: any): boolean {
    return !!dataItem.favorites;
  }

  updateFavoritesLabel(id: string, statusToSet: 0 | 1) {
    this.grid.setData(
      this.grid.getData.docs.map((d: any) =>
        d._id === id
          ? {
              ...d,
              favorites: statusToSet,
            }
          : d
      )
    );
  }

  addToFavorites(e: React.MouseEvent<HTMLElement>, dataItem: any) {
    e.preventDefault();
    e.stopPropagation();
    const statusToSet = dataItem.favorites === 0 ? 1 : 0;
    this.updateFavoritesLabel(dataItem._id, statusToSet);
    const axiosFactory = new AxiosFactory();
    axiosFactory.axios
      .get(`${ApiPath.api.user.addTestToFavorites}/${dataItem._id}`)
      .then((res: AxiosResponse) => {
        this.props.setNotification(res.data.message || "Error adding to favorites");
      })
      .catch((err) => {
        console.log(err.message);
        this.updateFavoritesLabel(dataItem._id, statusToSet === 0 ? 1 : 0);
        this.props.setNotification("Error adding to favorites");
      });
  }

  onEditButtonClick(e: React.MouseEvent<HTMLElement>, dataItem: any) {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.push(`${TestProperty}/${dataItem._id}`);
    return false;
  }

  onDuplicateButtonClick(e: React.MouseEvent<HTMLElement>, dataItem: any) {
    e.preventDefault();
    e.stopPropagation();
    const axiosFactory = new AxiosFactory();
    axiosFactory.axios.get(`${ApiPath.api.copy}/${dataItem._id}`).then((res: AxiosResponse) => {
      this.props.history.push(`${TestProperty}/${res.data._id}`);
    });
    return false;
  }

  async onTestImport(file: any) {
    const formData = new FormData();
    formData.append("file", file);

    const axiosFactory = new AxiosFactory();
    try {
      const res = await axiosFactory.axios.post(`${ApiPath.api.testIterationsImport}`, formData, {
        headers: { "content-type": "multipart/form-data" },
      });
      this.grid.reloadData();
      this.props.setNotification(`Test Imported: ${res.data.name}`);
    } catch (e) {
      this.props.setNotification("Please upload correct test file.");
    }
  }

  async onRunButtonClick(e: React.MouseEvent<HTMLElement>, dataItem: any) {
    e.preventDefault();
    e.stopPropagation();
    try {
      const axiosFactory = new AxiosFactory();
      const res = (await axiosFactory.axios.get(`${ApiPath.api.run}/${dataItem._id}`)) as any;
      this.props.history.push(`${TestRunDetails}/${res.data._id}`);
    } catch (err) {
      // tslint:disable-next-line:no-console
      if (err.response && err.response.data && err.response.data.message) {
        this.props.setNotification(err.response.data.message);
      } else {
        console.error(err);
      }
    }
    return false;
  }

  onRowClick(_e: React.MouseEvent<HTMLTableRowElement>, dataItem: GridModel) {
    this.props.history.push(`${TestProperty}/${dataItem._id}`);
  }

  render() {
    const { classes, location } = this.props;

    return (
      <Paper className={classes.root}>
        <GridToolbar onTestImport={this.onTestImport} />
        <RTCGrid
          ref={(element: any) => (this.grid = element)}
          onRowClick={this.onRowClick}
          search={true}
          remoteDataBound={true}
          searchByLabel={"name/description"}
          apiRoute={ApiPath.api.testDefinitions}
          columnSchema={this.columnSchema}
          defaultSort={{
            order: "desc",
            orderBy: "favorites -lastRunDate",
          }}
          rowProps={{
            className: classes.tableRowItemHover,
          }}
          pagination={true}
          enableUrlPaging={true}
          location={location}
          bottomNavigation
        />
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    hidden: {
      visibility: "hidden",
    },
    nameBlock: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      minWidth: "380px",
    },
    tableRowItemHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
        "& button": {
          visibility: "visible",
          pointerEvents: "all",
        },
        "& div[data-show]": {
          background: "#f7f7f7",
        },
      },
    },
    actionBlock: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      right: "0px",
      padding: "0 10px",
    },
    hoverActionBtnStyle: {
      padding: "0px",
      width: 35,
      minWidth: 35,
      height: 35,
      minHeight: 35,
      color: theme.palette.primary.main,
      pointerEvents: "none",
    },
    star: {
      visibility: "visible",
    },
  });

const decorate = withStyles(styles);

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(decorate<any>(GridControl));
