// tslint:disable:no-any
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { Theme, withStyles, WithStyles, IconButton, Button, createStyles } from "@material-ui/core";
import GOOGLE_IMAGE from "src/assets/socials/google.svg";
import FACEBOOK_IMAGE from "src/assets/socials/facebook.svg";
import LINKEDIN_IMAGE from "src/assets/socials/linkedin.svg";
import TWITTER_IMAGE from "src/assets/socials/twitter.svg";
import { RouteComponentProps } from "react-router";
import { Login } from "src/constants/RoutesNames";
import SendFeedbackModal from "./SendFeedbackModal";
import AxiosFactory from "src/services/AxiosFactory";
import ApiPath from "src/constants/ApiPath";

type StyledComponent = WithStyles;

type Props = {
  reportId: string;
};

const SocialButtons = (props: Props & RouteComponentProps & StyledComponent) => {
  const { classes, reportId } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const data = {
    text: "WebRTC Internals file analyze by testRTC",
    media:
      "http://85fc8ccd760a621e9d81-e9638d787a6e32e99181ab75ac8c3328.r68.cf2.rackcdn.com/src/img/logo.png",
    url: window.location.origin + "/report/" + reportId,
    hashtags: "testrtc, webrtc",
    width: 500,
    height: 500,
  };

  const googlePlusShare = () => {
    window.open(
      "//plus.google.com/share?url=" + encodeURIComponent(data.url),
      "sharer",
      "toolbar=0,status=0,width=" + data.width + ",height=" + data.height
    );
  };
  const facebookShare = () => {
    window.open(
      "//www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(data.url),
      "sharer",
      "toolbar=0,status=0,width=" + data.width + ",height=" + data.height
    );
  };
  const linkedInShare = () => {
    window.open(
      "//www.linkedin.com/shareArticle?mini=true&url=" +
        encodeURIComponent(data.url) +
        "&title=" +
        encodeURI(data.text),
      "sharer",
      "toolbar=0,status=0,width=" + data.width + ",height=" + data.height
    );
  };
  const twitterShare = () => {
    let urlString = "//www.twitter.com/intent/tweet?";

    if (data.text) {
      urlString += "text=" + encodeURIComponent(data.text);
    }

    if (data.hashtags) {
      urlString += "&hashtags=" + encodeURI(data.hashtags);
    }

    // Default to the current page if a URL isn't specified
    urlString += "&url=" + encodeURIComponent(data.url);

    window.open(
      urlString,
      "sharer",
      "toolbar=0,status=0,width=" + data.width + ",height=" + data.height
    );
  };

  const subscribeToUpdates = () => {
    props.history.push(Login.Subscribe);
  };

  const sendFeedback = async (values: any) => {
    try {
      const { feedback } = values;
      const axiosFactory = new AxiosFactory();
      await axiosFactory.axios.post(
        `${ApiPath.api.adminUtils.freeReportFeedback}/${props.reportId}`,
        {
          feedback,
        }
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <Grid container={true} justify="space-between" alignItems="center">
      <Grid item={true} xs={"auto"}>
        {false && (
          <>
            <IconButton
              color="primary"
              aria-label="add"
              className={classes.fab}
              onClick={googlePlusShare}
            >
              <img
                draggable={false}
                className={classes.socialIcon}
                src={GOOGLE_IMAGE}
                alt="google"
              />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="add"
              className={classes.fab}
              onClick={facebookShare}
            >
              <img
                draggable={false}
                className={classes.socialIcon}
                src={FACEBOOK_IMAGE}
                alt="facebook"
              />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="add"
              className={classes.fab}
              onClick={linkedInShare}
            >
              <img
                draggable={false}
                className={classes.socialIcon}
                src={LINKEDIN_IMAGE}
                alt="linkedin"
              />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="add"
              className={classes.fab}
              onClick={twitterShare}
            >
              <img
                draggable={false}
                className={classes.socialIcon}
                src={TWITTER_IMAGE}
                alt="twitter"
              />
            </IconButton>
          </>
        )}
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true} justify="flex-end">
          <Button color="primary" aria-label="add" onClick={subscribeToUpdates}>
            {"Subscribe to updates"}
          </Button>
          <Button
            color="secondary"
            aria-label="add"
            variant="contained"
            onClick={() => setIsOpen(true)}
          >
            {"Send us feedback"}
          </Button>
        </Grid>
      </Grid>
      <SendFeedbackModal isOpen={isOpen} onClose={() => setIsOpen(false)} onSubmit={sendFeedback} />
    </Grid>
  );
};

const styles = (_theme: Theme) =>
  createStyles({
    fab: {
      "& img": {
        height: 30,
        width: 30,
      },
    },
  });

const decorate = withStyles(styles);

export default decorate(SocialButtons);
