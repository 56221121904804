// tslint:disable:no-any
import * as React from "react";
import { connect } from "react-redux";
import View from "./View";
import DbUtilsService from "src/services/DbUtilsService";
import DumbFileService from "src/services/DumbFileService";
import { RouteComponentProps, withRouter } from "react-router";
import {
  WebrtcInternalsAnalyzeRtc,
  WebrtcInternalsWatchRtc,
  TestRun as TestRunRoute,
  MonitorRun as MonitorRunRoute,
  TestIteration as TestIterationRoute,
  WatchRTCRoom,
  WatchRTCPeer,
  WatchRTCLive,
  AnalyzeDump,
} from "src/constants/RoutesNames";
import withBreadcrumb, { WithBreadcrumb } from "src/components/withBreadcrumb";
import * as _ from "lodash";
import { SetNotification } from "src/actions/notificationAction";
import { AppToolbarContext } from "src/containers/Layout/components/AppToolbar/Context";
import { saveAs } from "file-saver";
import { VisibilityRules } from "./VisibilityRules";

const useFakeData = false; // didn't work process.env.REACT_FAKE_WEBRTC_INTERNALS === "true";

const DATA_SERIES_COLORS = [
  "#058DC7",
  "#50B432",
  "#ED561B",
  "#DDDF00",
  "#24CBE5",
  "#64E572",
  "#FF9655",
  "#FFF263",
  "#6AF9C4",
];

const additionalGraphInfoKeys = {
  mediaType: "mediaType",
  googCodecName: "googCodecName",
  codecImplementationName: "codecImplementationName",
  googContentType: "googContentType",
};

interface WebRTCInternalsDispatch {
  setNotification(message: string): void;
}

interface WebRTCInternalsProps {
  user?: any;
}

export type WebRTCInternalsState = {
  webrtcInternalsGraphs: any;
  webRtcAnalytics: WebRtcAnalytics | null;
  dupmUrl: string;
  error: string;
  fileName?: string;
  isLoading: boolean;
  isWatchRTC: boolean;
};

type RouteParams = {
  testIterationId: string;
  fileName: string;
  dumpUrl?: string;
  testVariant?: string;

  testRunId?: string;
  testRunName?: string;
  testIterationName?: string;

  iterationMachine: string;
  runIndex: string;
  isAnalyzeDump?: string;
};

class WebRTCInternals extends React.Component<
  RouteComponentProps<RouteParams> &
    WithBreadcrumb &
    WebRTCInternalsDispatch &
    WebRTCInternalsProps,
  WebRTCInternalsState
> {
  static contextType = AppToolbarContext;
  constructor(props: RouteComponentProps<RouteParams> & WithBreadcrumb & WebRTCInternalsDispatch) {
    super(props);

    this.state = {
      webrtcInternalsGraphs: {},
      webRtcAnalytics: null,
      dupmUrl: "",
      error: "",
      isLoading: false,
      isWatchRTC: false,
    };
  }

  async componentDidMount() {
    // handle case with no logged in user
    if (this.context.setPageInfo) {
      this.context.setPageInfo("");
    }
    this.props.resetBreadcrumb();

    const isLive =
      this.props.location.pathname.includes(WebrtcInternalsWatchRtc) &&
      this.props.match.params.fileName === "live";
    if (isLive) {
      await this.getLiveData();
    } else {
      await this.getData();
    }
  }

  getData = async () => {
    const { match } = this.props;
    if (this.props.location.pathname.includes(WebrtcInternalsAnalyzeRtc)) {
      this.props.pushBreadcrumbNode(AnalyzeDump);
      if (match.params.testIterationId) {
        this.props.pushBreadcrumbNode(
          `${TestIterationRoute}/${match.params.testIterationId}`,
          `Test Iteration Results: ${match.params.fileName}`
        );
      } else {
        //
      }
    } else if (this.props.location.pathname.includes(WebrtcInternalsWatchRtc)) {
      this.props.pushBreadcrumbNode(WatchRTCRoom);
      this.props.pushBreadcrumbNode(
        `${WatchRTCPeer}/${match.params.testIterationId}`,
        `watchRTC Peer: ${match.params.fileName}`
      );
      this.setState({
        ...this.state,
        isWatchRTC: true,
      });
    } else {
      this.props.pushBreadcrumbNode(
        match.params.testVariant === "monitor" ? MonitorRunRoute : TestRunRoute
      );
      this.props.pushBreadcrumbNode(
        `${TestIterationRoute}/${match.params.testIterationId}`,
        `${match.params.testVariant === "monitor" ? "Monitor" : "Test"} Iteration Results`
      );
    }
    try {
      const file = await this.getDumpWithAdditionalInfo();
      const dataForGraps = this.prepareDataForGraphs(file);
      this.prepareGraphs(
        dataForGraps,
        file?.getStats?.dataFrequency || file.sessionData?.interval / 1000 || 1
      );
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status) {
        if (err.response.status === 404) {
          this.setState({
            error: "Not found",
          });
          this.props.setNotification("Webrtc-Internals Dump not found");
        } else {
          this.setState({
            error: "Could not process the data",
          });
          this.props.setNotification("Could not process the data");
        }
      }
    }
  };

  getLiveData = async () => {
    this.props.pushBreadcrumbNode(WatchRTCLive);
    this.props.pushBreadcrumbNode(
      `${WatchRTCPeer}/${this.props.match.params.testIterationId}/live`,
      "Peer"
    );
    try {
      const dumpURL = await this.generateDumpUrl();
      const response = await DumbFileService.getDumbFile(dumpURL);

      if (response.data && response.data.PeerConnections) {
        this.setState({
          webRtcAnalytics: response.data,
          fileName: this.props.match.params.fileName,
          isWatchRTC: true,
        });
      } else {
        this.setState({
          error: "Could not get live data. Try to refresh the page",
        });
        return;
      }

      const file = response.data;
      const dataForGraps = this.prepareDataForGraphs(file);
      this.prepareGraphs(
        dataForGraps,
        file?.getStats?.dataFrequency || file.sessionData?.interval / 1000 || 1
      );
      console.log("", { dumpURL, response, dataForGraps });
    } catch (err) {
      this.setState({
        error: "Could not get live data",
      });
    }
  };

  async generateDumpUrl(convertRTCStats = true) {
    const { testIterationId, iterationMachine, fileName, dumpUrl } = this.props.match.params;
    const isLive =
      this.props.location.pathname.includes(WebrtcInternalsWatchRtc) && fileName === "live";

    if (isLive) {
      return `${process.env.REACT_APP_API_PATH}/api/watchRTCLive/advance-webrtc/${testIterationId}`;
    }

    let url = "";
    if (dumpUrl && dumpUrl !== "undefined") {
      url =
        process.env.REACT_APP_API_PATH +
        "/api/test_iterations/get-advance-webrtc/" +
        dumpUrl +
        "/" +
        iterationMachine +
        `/download?preprocess=${convertRTCStats}`;
    } else {
      const resp = await DbUtilsService.rpc("test_iterations", "get-screen-shots", testIterationId);
      const result = resp.data;
      for (const key in result.screens) {
        if (result.screens.hasOwnProperty(key)) {
          const screen = result.screens[key];
          if (screen.name.indexOf(fileName) >= 0) {
            url =
              process.env.REACT_APP_API_PATH +
              "/api/test_iterations/get-advance-webrtc/" +
              encodeURIComponent(screen.url) +
              "/" +
              iterationMachine +
              `/download?preprocess=${convertRTCStats}`;
          }
        }
      }
    }
    return url;
  }

  async getClearDumpFile() {
    this.setState({ isLoading: true });
    const url = await this.generateDumpUrl(false);
    const response = await DumbFileService.getDumbFile(url);
    const blob = new Blob([JSON.stringify(response.data, null, 2)]);
    const fileName = this.props.match.params.fileName;
    saveAs(blob, `${fileName}${fileName?.indexOf(".txt") === -1 ? ".txt" : ""}`);
    this.setState({ isLoading: false });
  }

  async getDumpWithAdditionalInfo() {
    let response;
    let url;
    if (useFakeData) {
      console.error("FAKE_WEBRTC_INTERNALS getDumpWithAdditionalInfo: Reading fake data");
      url = "fake";
      response = await import("./components/webrtc-dump-new");
      // response = { data: undefined };
    } else {
      url = await this.generateDumpUrl();
      response = await DumbFileService.getDumbFile(url);
    }

    if (response.data && response.data.PeerConnections) {
      this.setState({
        dupmUrl: url,
        webRtcAnalytics: response.data,
        fileName: this.props.match.params.fileName,
      });
    } else if (response.data && !response.data.PeerConnections && response.data.trace) {
      this.setState({
        error: "Legacy getstats info, not supported",
      });
    } else {
      this.setState({
        error: "Invalid format, Could not process the data",
      });
    }

    return response.data;
  }

  prepareDataForGraphs(webRtcData: any) {
    const graphs = {};
    const grapsReportObj = {};
    for (const connid in webRtcData.PeerConnections) {
      if (webRtcData.PeerConnections.hasOwnProperty(connid)) {
        const connection = webRtcData.PeerConnections[connid];
        grapsReportObj[connid] = {};
        graphs[connid] = {};
        const reportobj = {};
        let values;
        for (const _reportName in connection.stats) {
          if (connection.stats.hasOwnProperty(_reportName)) {
            let reportName = _reportName;
            if (reportName.indexOf("-[framesReceived-") > -1) {
              reportName = reportName.replace("-[framesReceived-", "-[framesReceived_minus_");
            }
            const t = reportName.split("-");
            const comp = t.pop();

            const stat = t.join("-");
            if (!reportobj.hasOwnProperty(stat)) {
              reportobj[stat] = [];
            }
            values = JSON.parse(connection.stats[_reportName].values);
            values = values.map((currentValue: string) => {
              return currentValue;
            });
            reportobj[stat].push([comp, values]);
          }
        }

        // sort so we get a more useful order of graphs:
        // * ssrcs
        // * bwe
        // * everything else alphabetically
        let names = Object.keys(reportobj);
        const ssrcs = names
          .filter((name) => {
            return name.indexOf("ssrc_") === 0;
          })
          .sort((a, b) => {
            // sort by send/recv and ssrc
            const aParts = a.split("_");
            const bParts = b.split("_");
            if (aParts[2] === bParts[2]) {
              return parseInt(aParts[1], 10) - parseInt(bParts[1], 10);
            } else if (aParts[2] === "send") {
              return -1;
            }
            return 1;
          });

        const bwe = names.filter((name) => {
          return name === "bweforvideo";
        });
        names = names.filter((name) => {
          return name.indexOf("ssrc_") === -1 && name !== "bweforvideo";
        });
        names = ssrcs.concat(bwe, names);

        const IceCandidatesToSkip: any[] = [];
        for (const reportName of names) {
          if (/RTCIceCandidatePair/gi.test(reportName)) {
            reportobj[reportName].forEach((item: Array<any>) => {
              if (item[0] === "nominated") {
                if (!item[1].includes(true)) {
                  IceCandidatesToSkip.push(reportName);
                }
              }
            });
          }
        }

        for (const reportName of names) {
          // ignore useless graphs
          if (
            reportName.indexOf("Cand-") === 0 ||
            reportName.indexOf("Channel") === 0 ||
            reportName.indexOf("RTCCodec") === 0 ||
            reportName.indexOf("RTCCertificate") === 0 ||
            reportName.indexOf("RTCIceCandidate_") === 0 ||
            reportName.indexOf("RTCPeerConnection") === 0 ||
            reportName.indexOf("RTCRemoteInboundRtpVideoStream") === 0 ||
            IceCandidatesToSkip.includes(reportName)
          ) {
            continue;
          }

          const series: Array<any> = [];
          const reports = reportobj[reportName];

          const toRemove = VisibilityRules.remove;
          const toHide = VisibilityRules.hide;
          const mixedRules = VisibilityRules.mixed;
          const reportNames = Object.keys(toHide);
          const reportNamesReg = reportNames.map((name: string) => {
            return new RegExp(name, "ig");
          });
          reports.sort().forEach(function (report: any) {
            if (toRemove.includes(report[0])) {
              return;
            }
            if (/RTCIceCandidatePair/gi.test(reportName)) {
              if (report[0] === "currentRoundTripTime") {
                report[1] = report[1].map((item: number) => item * 1000);
              }
              if (mixedRules.RTCIceCandidatePair.visible.includes(report[0])) {
                series.push({
                  name: report[0],
                  visible: mixedRules.RTCIceCandidatePair.disabled.indexOf(report[0]) === -1,
                  data: report[1],
                });
              }
            } else {
              if (typeof report[1][0] !== "number") {
                return;
              }
              if (
                report[0] === "bytesReceived" ||
                report[0] === "bytesSent" ||
                report[0] === "packetsReceived" ||
                report[0] === "packetsSent" ||
                report[0] === "googCaptureStartNtpTimeMs"
              ) {
                return;
              }
              let done = false;
              reportNamesReg.forEach((reg, index) => {
                if (reg.test(reportName)) {
                  series.push({
                    name: report[0],
                    visible: toHide[reportNames[index]].indexOf(report[0]) === -1,
                    data: report[1],
                  });
                  done = true;
                }
              });
              if (!done) {
                const hiddenSeries = [
                  "qpSum",
                  "framesEncoded",
                  "framesDecoded",
                  "framesReceived",
                  "totalInterFrameDelay",
                  "audioInputLevel",
                  "audioOutputLevel",
                  "googEchoCancellationEchoDelayStdDev",
                  "totalSamplesDuration",
                  "totalSamplesReceived",
                  "totalAudioEnergy",
                  "totalDecodeTime",
                  "totalFramesDuration",
                  "framesSent",
                  "jitterBufferDelay",
                  "googDecodingCTN",
                  "totalPacketSendDelay",
                  "totalEncodeTime",
                  "headerBytesReceived",
                  "jitterBufferEmittedCount",
                  "jitterBufferTargetDelay",
                  "relativePacketArrivalDelay",
                  "roundTripTime",
                  "googDecodingCNG",
                  "googDecodingNormal",
                  "googDecodingPLCCNG",
                  "googDecodingCTSG",
                  "googDecodingMuted",
                ];

                series.push({
                  name: report[0],
                  visible: hiddenSeries.indexOf(report[0]) === -1,
                  data: report[1],
                });
              }
            }
            if (series.length > 0) {
              grapsReportObj[connid][reportName] = {
                title: `${reportName} (connection ${connid})`,
                series,
                additionalGraphInfo:
                  reportName.indexOf("ssrc_") !== -1
                    ? {
                        [additionalGraphInfoKeys.mediaType]:
                          connection.stats[`${reportName}-${additionalGraphInfoKeys.mediaType}`],
                        [additionalGraphInfoKeys.googCodecName]:
                          connection.stats[
                            `${reportName}-${additionalGraphInfoKeys.googCodecName}`
                          ],
                        [additionalGraphInfoKeys.codecImplementationName]:
                          connection.stats[
                            `${reportName}-${additionalGraphInfoKeys.codecImplementationName}`
                          ],
                        [additionalGraphInfoKeys.googContentType]:
                          connection.stats[
                            `${reportName}-${additionalGraphInfoKeys.googContentType}`
                          ],
                      }
                    : undefined,
              };
            }
          });
        }
      }
    }
    return grapsReportObj;
  }

  sortGraphs(graphs: Array<any>, connectionId: string) {
    const obj = {
      bweforvideo: [] as Array<any>,
      ssrc_send: [] as Array<any>,
      ssrc_recv: [] as Array<any>,
      unknown: [] as Array<any>,
    };
    for (let i = 0; i < graphs.length; i++) {
      const graph = graphs[i];
      const title = graph.title.substring(0, graph.title.indexOf("(")).replace(/\s+/, "");
      // replace common symbol to get specific type of graph
      const key = title
        // eslint-disable-next-line
        .replace(/[0-9]/g, "")
        // eslint-disable-next-line
        .replace(/\-/g, "")
        // eslint-disable-next-line
        .replace(/\_/, "");

      if (obj.hasOwnProperty(key)) {
        graph.title = this.generateGraphTitle(title, connectionId);
        obj[key].push(graph);
      } else {
        obj.unknown.push(graph);
      }
    }
    const result = obj.bweforvideo.concat(obj.ssrc_send).concat(obj.ssrc_recv).concat(obj.unknown);
    return result;
  }

  generateGraphTitle(title: string, connectionId: string) {
    if (title.toLowerCase().indexOf("bweforvideo") !== -1) {
      return `Bandwidth Estimation (${title} ${connectionId})`;
    }
    if (title.toLowerCase().indexOf("send") !== -1) {
      return `Sender (${title} ${connectionId})`;
    }

    if (title.toLowerCase().indexOf("recv") !== -1) {
      return `Receiver (${title} ${connectionId})`;
    }

    return `${title} (connection ${connectionId})`;
  }

  prepareGraphs(graphs: any, dataFrequency: number) {
    const graphsState = {};
    Object.keys(graphs).forEach((connection) => {
      const preparedGraphs: Array<any> = [];
      Object.keys(graphs[connection]).forEach((report) => {
        let idx = 0;
        const dataset = [];

        for (const serie of graphs[connection][report].series) {
          if (serie.name.includes("ssrc")) {
            continue;
          }
          const data = {
            label: serie.name,
            color: DATA_SERIES_COLORS[idx % DATA_SERIES_COLORS.length],
            visible: serie.visible,
          } as any;

          let zero = true,
            // eslint-disable-next-line prefer-const
            pointsData = serie.data.map((point: any, idx: number) => {
              if (point) {
                zero = false;
              }
              // original jitter values are seconds
              // we need them in milliseconds
              if (serie.name === "jitter") {
                return [idx * dataFrequency, point * 1000];
              }
              return [idx * dataFrequency, point];
            });

          if (!zero) {
            data.data = pointsData;
          }
          idx++;
          dataset.push(data);
        }

        // Put all zero series to the end
        dataset.sort((a: any, b: any) => {
          if (!a.data && b.data) {
            return 1;
          } else if (a.data && !b.data) {
            return -1;
          } else {
            return 0;
          }
        });

        preparedGraphs.push({
          title: graphs[connection][report].title,
          options: {
            series: {
              lines: { show: true, lineWidth: 2 },
              points: { show: true, radius: 3 },
              shadowSize: 0,
            },
            grid: {
              hoverable: true,
              mouseActiveRadius: 10,
            },
            tooltip: {
              show: true,
              content: (yval: string) => {
                return "<strong>%x</strong><br/>%s — <strong>" + yval + "</strong>";
              },
              formatter: function () {
                const self: any = this;
                return `<strong>${self.point.x}</strong><br/>${
                  self?.series?.name
                } — <strong>${_.round(self.point.y, 5)}</strong>`;
              },
            },
            xAxis: {
              type: "datetime",
              labels: {
                formatter: function (context: any) {
                  const value = context.value * 1000;
                  const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
                  const format = range < 3600 ? "%M:%S" : "%H:%M:%S";
                  if (value >= 0) {
                    return (window as any).Highcharts.dateFormat(format, value);
                  } else {
                    return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
                  }
                },
              },
            },
            yAxis: {
              formatter: (val: number) => {
                if (Math.abs(val) > 1000000) {
                  return _.round(val / 1000000, 0) + "m";
                } else if (Math.abs(val) > 1000) {
                  return _.round(val / 1000, 0) + "k";
                } else if (Math.abs(val) > 0 && Math.abs(val) < 1) {
                  return _.round(val, 5);
                } else {
                  return _.round(val, 0);
                }
              },
            },
          },
          originalDataset: dataset,
          dataset: dataset,
          additionalGraphInfo: graphs[connection][report].additionalGraphInfo,
        });
      });
      graphsState[connection] = this.sortGraphs(preparedGraphs, connection);
    });
    this.setState({ webrtcInternalsGraphs: graphsState });
  }

  replayAsWatchRTC = async () => {
    try {
      let url = await this.generateDumpUrl();
      url = url.replace(
        "get-advance-webrtc/",
        `replay-testingrtc-as-watchrtc/${this.props.match.params.testIterationId}/`
      );
      const response = await DumbFileService.replayAsWatchRTC(url);
      if (response.data?.error) {
        this.props.setNotification(response.data.message);
      } else if (response.data?.roomId) {
        window.open(`${WatchRTCRoom}/${response.data.roomId}`, "_blank");
      } else {
        this.props.setNotification("Couldn not replay data");
      }
    } catch (err) {
      this.props.setNotification("Couldn not replay data");
      console.error(err.message, { response: err.response });
    }
  };

  render() {
    const { user } = this.props;
    const isAdmin = user.role === "admin";
    return (
      <View
        {...this.state}
        getDumpFile={() => this.getClearDumpFile()}
        isAdmin={isAdmin}
        replayAsWatchRTC={this.replayAsWatchRTC}
      />
    );
  }
}
const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<RouteComponentProps<RouteParams>>(withBreadcrumb(WebRTCInternals) as any));
