import * as React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import AppLayout from "./containers/Layout";
import SharedLayout from "./containers/SharedLayout";
import LoginLayout from "./containers/Auth/LoginLayout";
import AuthProvider from "./containers/Auth/AuthProvider";
import NotFoundPage from "./containers/NotFoundPage";
import { AppRoute, NotFound, SharedRoute } from "./constants/RoutesNames";

const history = createBrowserHistory();

class RouterContainer extends React.Component<{}> {
  public render() {
    return (
      <Router history={history}>
        <AuthProvider>
          <Switch>
            <Route path={AppRoute} component={AppLayout} />
            <Route path={SharedRoute} component={SharedLayout} />
            <Route path={NotFound} component={NotFoundPage} />
            <Route path="/" component={LoginLayout} />
            <Redirect from="*" to={NotFound} />
          </Switch>
        </AuthProvider>
      </Router>
    );
  }
}

export default RouterContainer;
