import * as React from "react";
import { LegendContext } from "../index";

export const useSeries = () => {
  const { chart, series, syncLegends, setSeries } = React.useContext(LegendContext);

  const toggle = (name: string | string[], visible: boolean) => {
    if (Array.isArray(name)) {
      name.forEach((n) => {
        toggleByName(n, visible);
      });
    } else {
      toggleByName(name, visible);
    }
  };

  const toggleByName = (name: string, visible: boolean) => {
    if (syncLegends) {
      toggleSync(name, visible);
    } else {
      toggleSingle(name, visible);
    }
  };

  const toggleSync = (name: string, visible: boolean) => {
    const charts = [...(window as any).Highcharts.charts].filter((x) => x !== undefined);
    if (charts?.length) {
      charts.forEach((chart: any) => {
        toggleChartSeries(name, visible, chart);
      });
      toggleStateSeries(name, visible);
    }
  };

  const toggleSingle = (name: string, visible: boolean) => {
    toggleChartSeries(name, visible, chart);
    toggleStateSeries(name, visible);
  };

  const toggleChartSeries = (name: string, visible: boolean, chart: any) => {
    const serie = chart.series.find((s: any) => s.name === name);
    if (serie) {
      if (visible) {
        serie.show();
      } else {
        serie.hide();
      }
    }
  };

  const toggleStateSeries = (name: string, visible: boolean) => {
    const stateSeriesCopy = [...series];
    const stateSerieIndex = stateSeriesCopy.findIndex((s: any) => s.name === name);
    if (stateSerieIndex !== -1) {
      stateSeriesCopy[stateSerieIndex].visible = visible;
      setSeries(stateSeriesCopy);
    }
  };

  return { series, toggle };
};
