import * as React from "react";

import { RouteComponentProps, withRouter } from "react-router";

import { Field, Form } from "react-final-form";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import TextFieldControl from "src/components/form-elements/TextFieldControl";
import FormValidators from "src/helpers/form-validators";
import AxiosFactory from "src/services/AxiosFactory";
import ApiPath from "src/constants/ApiPath";
import { Login as LoginRoutes } from "src/constants/RoutesNames";

const ForgotPassword = (props: RouteComponentProps<{}>) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (values: any): Promise<any> => {
    setIsLoading(true);

    try {
      const axiosFactory = new AxiosFactory();
      await axiosFactory.axios.get(`${ApiPath.api.requestPassword}/${values.email}`);
      props.history.push({
        pathname: LoginRoutes.SignIn,
        state: { isPasswordRemind: true, emailRemind: values.email },
      });
    } catch (err) {
      return {
        email:
          err.response?.status === 404
            ? "User with this email not found"
            : "Could not reset password. Please contact support",
      };
    } finally {
      setIsLoading(false);
    }
    // const axiosFactory = new AxiosFactory();
    // return axiosFactory.axios
    //   .get(`${ApiPath.api.requestPassword}/${values.email}`)
    //   .then(() => {
    //     this.props.history.push({
    //       pathname: LoginRoutes.SignIn,
    //       state: { isPasswordRemind: true, emailRemind: values.email },
    //     });
    //   })
    //   .catch((_err: IForgotFormError) => {
    //     return { email: "User with this email not found" };
    //   });
  };

  return (
    <Grid container={true} direction="row" justify="center">
      <Grid item={true} xs={8} md={4}>
        <Typography variant={"h6"}>Forgot your password?</Typography>
        <br />
        <Typography variant="body2">
          Enter your email address to reset your password. You may need to check your spam folder or
          unblock support@testrtc.com
        </Typography>
        <br />

        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <Field
                    component={TextFieldControl}
                    type="text"
                    name="email"
                    label="Email"
                    validate={FormValidators.composeValidators(
                      FormValidators.required(),
                      FormValidators.isEmail
                    )}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Button type="submit" variant="contained" color="secondary" disabled={isLoading}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter<RouteComponentProps<{}>>(ForgotPassword);
