import * as React from "react";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { RouteComponentProps, withRouter } from "react-router";
import { NewTestProperty } from "../../constants/RoutesNames";

type StyledComponent = WithStyles<"root" | "newTestButton">;

type GridControlProps = {
  // tslint:disable-next-line:no-any
  onTestImport: (file: any) => void;
};

export class GridControl extends React.Component<
  RouteComponentProps<{}> & GridControlProps & StyledComponent
> {
  upload: HTMLInputElement | null = null;

  constructor(props: RouteComponentProps<{}> & GridControlProps & StyledComponent) {
    super(props);

    this.toNewTestDefinition = this.toNewTestDefinition.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(event: React.FormEvent<HTMLInputElement>) {
    const file = event.target["files"][0]; // tslint:disable-line
    this.props.onTestImport(file);
  }

  toNewTestDefinition() {
    this.props.history.push(NewTestProperty);
  }

  render() {
    const { classes } = this.props;

    return (
      <Toolbar className={classes.root}>
        <input
          type="file"
          ref={(ref) => (this.upload = ref)}
          style={{ display: "none" }}
          accept="application/json"
          onChange={this.uploadFile}
        />
        <Button
          variant="contained"
          color="default"
          onClick={() => this.upload !== null && this.upload.click()}
        >
          Import
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.newTestButton}
          onClick={this.toNewTestDefinition}
          data-cy="create-new-btn"
        >
          Create New Test
        </Button>
      </Toolbar>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "flex-end",
    },
    newTestButton: {
      marginLeft: theme.spacing(2),
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}> & GridControlProps>(decorate(GridControl));
