// tslint:disable:no-any
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import MuiLinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    progressRoot: {
      height: 12,
      borderRadius: 1000,
    },
    progressColorSecondary: {
      backgroundColor: "rgb(217, 236, 218)",
    },
    progressColorError: {
      backgroundColor: "rgb(162, 42, 33)",
    },
    errorProgressContainer: {
      position: "absolute",
      overflow: "hidden",
      width: "calc(100% - 16px)",
      transition: "transform .4s linear",
      height: 12,
      borderRadius: 1000,
      top: "calc(50% - 6px)",
    },
    errorProgressRibbon: {
      background: "rgb(162, 42, 33)",
      width: "100%",
      height: "inherit",
      borderRadius: 1000,
    },
  })
);

interface ILinearProgress {
  value: number;
  hasError?: boolean;
  errorValue?: number;
}

const LinearProgress = ({ value, hasError, errorValue }: ILinearProgress) => {
  const classes = useStyles();

  const errorTranslate = 100 - (errorValue || 100);
  return (
    <Grid item={true} xs={12} className={classes.root}>
      <MuiLinearProgress
        classes={{
          root: classes.progressRoot,
          colorSecondary: classes.progressColorSecondary,
          barColorSecondary: hasError ? classes.progressColorError : undefined,
        }}
        color="secondary"
        variant="determinate"
        value={value}
      />
      {errorValue && errorValue > 0 && (
        <div className={classes.errorProgressContainer}>
          <div
            className={classes.errorProgressRibbon}
            style={{
              transform: `translateX(-${errorTranslate}%)`,
              borderTopRightRadius: errorTranslate === 0 ? 1000 : 0,
              borderBottomRightRadius: errorTranslate === 0 ? 1000 : 0,
            }}
          ></div>
        </div>
      )}
    </Grid>
  );
};

export default LinearProgress;
