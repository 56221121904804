export default {
  auth: {
    loginLocal: "/auth/local",
  },
  api: {
    user: {
      me: "/api/users/me",
      changeProject: "/api/users/me/change-project",
      resetPassword: "/api/users/reset-password",
      confirmAccount: "/api/users/confirm-account",
      addTestToFavorites: "/api/test_definitions/like",
    },
    dashboards: "/api/dashboards",
    testDefinitions: "/api/test_definitions",
    testIterations: "/api/test_iterations",
    testIterationsUnassigned: "/api/test_iterations/unassigned",
    testIterationsImport: "/api/test_definitions/import",
    testRuns: "/api/test_runs",
    testRunsExport: "/api/test_runs/export",
    analyzeDatas: "api/analyzeDatas/report",
    analyzeDatasFree: "api/analyzeDatas/free",
    analyzeDataHistory: "api/analyzeDatas/history",
    monitorsCopy: "/api/monitors/copy",
    monitors: "/api/monitors",
    monitorRuns: "/api/test_runs/monitor",
    monitorRunsExport: "/api/test_runs/monitor/export",
    testRunsDistinct: "/api/test_runs/test/distinct",
    monitorRunsDistinct: "/api/monitors/distinct",
    qualityRTC: "/api/network-test",
    networkTestAg: "/api/network-test/ag",
    embedNetworkTestAg: "/api/network-test/embedAg",
    assets: "/api/additional-assets",
    assetDuplicate: "/api/additional-assets/duplicate",
    assetImport: "/api/additional-assets/import",
    run: "/api/test_definitions/run",
    copy: "/api/test_definitions/copy",
    userConfig: "/api/config-datas/user-config",
    emailUnique: "/api/users/unique",
    requestPassword: "/api/users/request-password",
    newsletterSubscribe: "/api/users/newsletter-subscribe",
    signup: "api/users",
    users: "api/users",
    settings: "api/users/settings",
    accountUsers: "api/users/account-users",
    accountRoles: "api/users/account-roles",
    projects: {
      root: "api/projects",
      all: "api/projects/all",
      removeUser: "api/projects/remove-user",
    },
    probes: {
      create: "api/agents/create",
      all: "api/agents/all",
      delete: "api/agents/remove",
    },
    onPromiseProbes: "api/on-premise-probe",
    onPromiseProbesHistory: "api/on-premise-probe/history",
    onPromiseProbesDuplicate: "/api/on-premise-probe/duplicate",
    onPromiseProbesImport: "/api/on-premise-probe/import",
    onPromiseProbesGenerateApiKey: "/api/on-premise-probe/generate-apikey",
    onPromiseProbeSetup: "/api/on-premise-probe/probeSetup",
    probeRTC: "api/probeRTC",
    // admin endpoints
    userAccounts: "api/be_users",
    userDetails: "api/users/group",
    companies: "api/be_users/companies",
    resetPassword: (userId: string, password: string) =>
      `api/be_users/reset-password/${userId}/${password}`,
    agentSetups: {
      root: "/api/agentSetups",
      search: "/api/agentSetups/search",
      copy: "/api/agentSetups/copy",
    },
    dynamicAgents: {
      root: "/api/dynamicAgents",
      stopAll: "/api/dynamicAgents/stop-all",
    },
    influexQuery: "/api/adminops/influxdb/query",
    statusPage: "/api/status-page",
    adminUtils: {
      globalConfig: "/api/config-datas/main/config",
      updateGlobalConfig: "/api/config-datas/main",
      testMonitorJobQueueInfo: "/api/adminops/bull-info",
      getAgentAllocation: "/api/adminops/get-agent-allocation",
      getTestEndpoint: "/api/adminops/get-test-endpoint",
      fillCalculatedFields: "/api/adminops/migration",
      exportCSV: "/api/adminops/project-all/csv",
      freeReportFeedback: "/api/adminops/free-report-feedback",
      testUpRtcEmails: "/api/adminops/test-uprtc-emails",
      gscDownload: "/api/adminops/gsc-download",
      adminCmd: "/api/adminops/admin-cmd",
    },
    billingData: "/api/test_runs/adminIndex",
    billingDataExport: "/api/test_runs/export/billingData",
    gridPreset: {
      root: "/api/gridPreset",
    },
    watchRTCRoom: "/api/watchRTCRoom",
    watchRTCPeer: "/api/watchRTCPeer",
    watchRTCHighlights: "/api/watchRTCHighlights",
    watchRTCTrends: "/api/watchRTCTrends",
    watchRTCLive: "/api/watchRTCLive",
    analyticsEvents: "/api/analytics-events",
    analyzeRTC: "/api/analyze-rtc",
    qualityRTCServer: "/api/qualityrtc-server",
    qualityRTCInvite: "/api/qualityRTCInvite",
    qualityRtcInviteFields: "/api/projects/qualityRtcInviteFields",
  },
};
