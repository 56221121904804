// tslint:disable:no-any
// tslint:disable: no-string-literal
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";

import RTCGrid from "src/components/Grid";
import { toKeyValue } from "src/helpers/objHelper";
import KeyValueTableCell from "./KeyValueTableCell";

type StyledComponent = WithStyles;

export interface UserMediaProps {
  media: Array<UserMedia>;
  origin?: string;
}

const UserMedia = (props: StyledComponent & UserMediaProps & RouteComponentProps<{}>) => {
  const { classes, media } = props;

  const parseStringToObject = (strObj: string) => {
    if (strObj === "") {
      return "true";
    }
    const jsonStr = strObj.replace(/(\w+:)|(\w+ :)/g, (s) => {
      return '"' + s.substring(0, s.length - 1) + '":';
    });
    const obj = JSON.parse(jsonStr);
    const objKeyValues = toKeyValue(obj);
    return <KeyValueTableCell data={objKeyValues} />;
  };

  const columnSchema: Array<ColumnSchema> = [
    // {
    //   id: "origin",
    //   numeric: false,
    //   disablePadding: false,
    //   sortable: false,
    //   label: "Service URL",
    //   render: (dataItem: any, _index: number) => (
    //     <a
    //       className={classes.linkStyles}
    //       href={dataItem.origin || origin}
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       {dataItem.origin || origin}
    //     </a>
    //   ),
    // },
    {
      id: "audio",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "Audio",
      render: (dataItem: any, _index: number) => (
        <React.Fragment>
          {"audio" in dataItem
            ? parseStringToObject(dataItem.audio)
            : dataItem.value?.audio === true || dataItem.value?.audio === false
            ? dataItem.value.audio.toString()
            : dataItem.value?.tracks && dataItem.value?.tracks?.find((x: any) => x.kind === "audio")
            ? dataItem.value.tracks.find((x: any) => x.kind === "audio").label || "none"
            : "none"}
        </React.Fragment>
      ),
    },
    {
      id: "video",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "Video",
      render: (dataItem: any, _index: number) => (
        <React.Fragment>
          {"video" in dataItem
            ? parseStringToObject(dataItem.video)
            : dataItem.value?.video === true || dataItem.value?.video === false
            ? dataItem.value.video.toString()
            : dataItem.value?.tracks && dataItem.value?.tracks?.find((x: any) => x.kind === "video")
            ? dataItem.value.tracks.find((x: any) => x.kind === "video").label || "none"
            : "none"}
        </React.Fragment>
      ),
    },
  ];

  return (
    <Fade in={true}>
      <Grid item={true} xs={12}>
        <Card className={classes.card}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="subtitle1">User Media</Typography>
          </Toolbar>
          <Divider className={classes.divider} />
          <Grid container={true} spacing={5} className={classes.tableContainer}>
            <RTCGrid
              localData={media}
              columnSchema={columnSchema}
              rowProps={{
                className: classes.tableRow,
              }}
            />
          </Grid>
        </Card>
      </Grid>
    </Fade>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    linkStyles: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
      "& td": {
        minWidth: 200,
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& td:first-child": {
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& td:last-child": {
        paddingRight: 8,
      },
    },
    tableContainer: {
      width: "max-content",
      minWidth: 600,
      padding: theme.spacing(3),
      display: "block",
      overflowY: "hidden",
      overflowX: "auto",
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}> & UserMediaProps>(decorate(UserMedia));
