// tslint:disable:no-any
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
// import Chart from "../../../components/Chart";
import {
  Theme,
  withStyles,
  WithStyles,
  Card,
  Divider,
  createStyles,
  Typography,
  Toolbar,
} from "@material-ui/core";
import GraphPanel from "./GraphPanel";

type StyledComponent = WithStyles;

export interface GraphsProps {
  id: string;
  webrtcInternalsGraphs: any;
}

const Graphs = (props: StyledComponent & GraphsProps & RouteComponentProps<{}>) => {
  const { id, webrtcInternalsGraphs, classes } = props;

  return (
    <Card className={classes.card}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="subtitle1">{"Statistics/Metrics"}</Typography>
      </Toolbar>
      <Divider className={classes.divider} />

      {webrtcInternalsGraphs[id] &&
        webrtcInternalsGraphs[id].map((graph: any, i: number) => {
          return (
            <React.Fragment key={i}>
              <GraphPanel id={i.toString()} graph={graph} />
              {i < webrtcInternalsGraphs[id].length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
    </Card>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    panelRoot: {
      boxShadow: "none",
      "&::before": {
        height: 0,
      },
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}> & GraphsProps>(decorate(Graphs));
