/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import * as moment from "moment";

import { IFiltersData } from "./Interfaces";

export interface IWatchRTCContext {
  isLoading: boolean;
  filtersData: IFiltersData;
  selectedFiltersData: IFiltersData;
  setIsLoading: (flag: boolean) => void;
  setFiltersData: (data: IFiltersData) => void;
  setSelectedFiltersData: (data: IFiltersData) => void;
}
export const WatchRTCContext = React.createContext<IWatchRTCContext>({} as any);

const now = new Date();
export const defaultFiltersData: IFiltersData = {
  time: {
    from: moment(now).startOf("day").toDate().getTime(),
    to: now.getTime(),
  },
  keys: [],
};

export function WatchRTCProvider(props: any) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [filtersData, setFiltersData] = React.useState<IFiltersData>(null!);
  const [selectedFiltersData, setSelectedFiltersData] = React.useState<IFiltersData>(
    defaultFiltersData
  );

  return (
    <WatchRTCContext.Provider
      value={{
        isLoading,
        filtersData,
        selectedFiltersData,
        setIsLoading,
        setFiltersData,
        setSelectedFiltersData,
      }}
    >
      {props.children}
    </WatchRTCContext.Provider>
  );
}
