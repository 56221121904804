import * as RoutesNames from "./RoutesNames";

const Names = {
  [RoutesNames.AssignToTest]: "Test Run Assignment",
  [RoutesNames.Main]: "Home",
  [RoutesNames.Tests]: "Tests",
  [RoutesNames.TestRun]: "Test Run History",
  [RoutesNames.TestRunDetails]: "Test Results",
  [RoutesNames.TestProperty]: "Test",
  [RoutesNames.TestRunGroup]: "Test Results Browser",
  [RoutesNames.MonitorProperty]: "MonitorProperty",
  [RoutesNames.TestIteration]: "Test Iteration Results",
  [RoutesNames.Monitoring]: "Monitors",
  [RoutesNames.MonitorRun]: "Monitor Run History",
  [RoutesNames.QualityRTC]: "qualityRTC History",
  [RoutesNames.QualityRTCInvites]: "qualityRTC Invites",
  [RoutesNames.QualityRTCInviteNew]: "qualityRTC Invite",
  [RoutesNames.QualityRTCInviteId]: "qualityRTC Invite",
  [RoutesNames.NetworkTestCharts]: "Network Tests Charts",
  [RoutesNames.OnPremiseProbe]: "probeRTC",
  [RoutesNames.OnPremiseProbeCharts]: "probeRTC",
  [RoutesNames.OnPremiseProbesCompare]: "probeRTC",
  [RoutesNames.OnPremiseProbeProperty]: "probeRTC",
  [RoutesNames.OnPremiseProbeHistory]: "probeRTC History",
  [RoutesNames.Assets]: "Assets",
  [RoutesNames.AnalyzeDump]: "analyzeRTC",
  [RoutesNames.NewTestProperty]: "New Test Property",
  [RoutesNames.NewMonitoring]: "Monitors",
  [RoutesNames.WebrtcInternals]: "webrtc-internals dump Results",
  [RoutesNames.NightWatch]: "Nightwatch logs Results",
  [RoutesNames.TestScript]: "test-script Results",
  [RoutesNames.Browser]: "Browser logs Results",
  [RoutesNames.Performance]: "Performance Results",
  [RoutesNames.Support]: "Support",
  [RoutesNames.TestLogs]: "Test Logs",
  [RoutesNames.UserAccounts]: "User Accounts",
  [RoutesNames.UserDetails]: "User",
  [RoutesNames.Projects]: "Projects",
  [RoutesNames.ProjectProperties]: "Project Configuration",
  [RoutesNames.Probes]: "Probes",
  [RoutesNames.ProbesSetup]: "Probes Setup",
  [RoutesNames.ProbeSetupDetails]: "Probe Setup",
  [RoutesNames.DynamicProbe]: "Dynamic Probes",
  [RoutesNames.DynamicProbeDetails]: "Dynamic Probe",
  [RoutesNames.AdminUtils]: "Admin Utils",
  [RoutesNames.UsageGraphs]: "Usage Graph",
  [RoutesNames.BillingData]: "Billing Data",
  [RoutesNames.NetworkTestAdmin]: "Network Test Admin",
  [RoutesNames.WatchRTCRoom]: "watchRTC History",
  [RoutesNames.WatchRTCPeer]: "watchRTC Peer",
  [RoutesNames.WatchRTCHighlights]: "watchRTC Highlights",
  [RoutesNames.WatchRTCTrends]: "watchRTC Trends",
  [RoutesNames.WatchRTCLive]: "watchRTC Live",
  [RoutesNames.AnalyticsEvents]: "Analytics Events",
  [RoutesNames.QualityRTCServer]: "qualityRTC Servers",
  [RoutesNames.QualityRTCServerPropertyNew]: "qualityRTC Server",
  [RoutesNames.Settings]: "Settings",
  [RoutesNames.MyProfile]: "My Profile",
  [RoutesNames.BillingData]: "Billing",
};

export default Names;

export function getLocationTitle(path: string): string {
  let title = "";
  for (const name in Names) {
    if (path.includes(name)) {
      title = Names[name];
    }
  }
  return title;
}
