import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import GOOGLE_IMAGE from "../../assets/socials/google.svg";

class LoginSocialButtons extends React.Component<
  WithStyles<"buttonContainer" | "socialButtonRoot" | "socialButtonLabel" | "socialIcon">
> {
  public render(): JSX.Element {
    const { classes } = this.props;

    return (
      <Grid container={true} justify="center">
        <Grid className={classes.buttonContainer} item={true} xs={4}>
          <Button
            onClick={() => this.LoginOAuth("google")}
            classes={{
              root: classes.socialButtonRoot,
            }}
          >
            <img draggable={false} className={classes.socialIcon} src={GOOGLE_IMAGE} alt="google" />
            <Typography variant="body2">Sign in</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  }

  private LoginOAuth(provider: string) {
    window.location.href = `${process.env.REACT_APP_API_PATH}/auth/${provider}`;
  }
}

const styles = createStyles({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  socialButtonRoot: {
    minWidth: "50px",
    minHeight: "50px",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
  socialButtonLabel: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  socialIcon: {
    width: "25px",
    marginRight: 15,
  },
});

export default withStyles(styles)(LoginSocialButtons);
