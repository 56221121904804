import * as React from "react";
import { RouteComponentProps } from "react-router";

import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Flag from "@material-ui/icons/Flag";

import ApiPath from "../../constants/ApiPath";
import RTCGrid from "../../components/Grid";

import * as _ from "lodash";
import * as moment from "moment";

import { testStatusHelper } from "../../helpers/testStatusHelper";
import { TestRunDetails } from "../../constants/RoutesNames";

interface ILastTests {
  testName: string;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    tableRowItemHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
      },
    },
  })
);

const IconsContainerStyles = {
  display: "flex",
} as React.CSSProperties;

const LastTests = ({ testName, history }: ILastTests & RouteComponentProps<{}>) => {
  const classes = useStyles();
  const columnSchema: Array<ColumnSchema> = [
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      style: { maxWidth: "5%" },
      labelRender: () => <Flag />,
      // tslint:disable-next-line:no-any
      render: (dataItem: any) => {
        return <div style={IconsContainerStyles}>{testStatusHelper(dataItem.status)}</div>;
      },
    },
    {
      id: "parameters.concurrentUsers",
      numeric: true,
      disablePadding: true,
      label: "Probes",
      isObject: true,
      style: { maxWidth: "5%", width: 5, padding: "0px 5px" },
      hideDown: "sm",
    },
    {
      id: "rank",
      numeric: true,
      disablePadding: true,
      label: "Score",
      isObject: true,
      // style: { maxWidth: "10%", padding: "0px 5px", textAlign: "right" },
      hideDown: "md",
      // tslint:disable-next-line:no-any
      render: (rank: any) => {
        return rank ? _.round(rank, 1) : "0";
      },
    },
    {
      id: "createDate",
      numeric: false,
      disablePadding: true,
      label: "Time",
      // tslint:disable-next-line:no-any
      render: (dataItem: any) =>
        dataItem.createDate ? moment(dataItem.createDate).format("MMM DD, YYYY - HH:mm") : "never",
      style: {
        whiteSpace: "nowrap",
        maxWidth: "10%",
        padding: "0px 10px",
        paddingRigh: 5,
      },
    },
    {
      id: "textError",
      numeric: false,
      disablePadding: false,
      label: "Reason",
      style: { wordWrap: "break-word", padding: "0 2px", wordBreak: "break-all" },
      hideDown: "xs",
      // tslint:disable-next-line: no-any
      render: (dataItem: any) => {
        const textError = dataItem.textError ? `${dataItem.textError.substring(0, 300)}` : "";
        const rtcSetAdditionalInfo = dataItem.rtcSetAdditionalInfo
          ? `${dataItem.rtcSetAdditionalInfo.substring(0, 300)}`
          : "";
        return textError.concat(rtcSetAdditionalInfo).substring(0, 300);
      },
    },
  ];

  const onRowClick = (_e: React.MouseEvent<HTMLTableRowElement>, dataItem: GridModel) => {
    history.push(`${TestRunDetails}/${dataItem._id}`);
  };
  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography>Latest Runs</Typography>
      </Toolbar>
      <RTCGrid
        onRowClick={onRowClick}
        remoteDataBound={true}
        apiRoute={ApiPath.api.testRuns}
        columnSchema={columnSchema}
        defaultSort={{
          order: "desc",
          orderBy: "createDate",
        }}
        defaultFilter={[
          {
            field: "name",
            value: testName,
          },
        ]}
        rowProps={{
          className: classes.tableRowItemHover,
        }}
        rowsPerPage={10}
      />
    </Paper>
  );
};

export default LastTests;
