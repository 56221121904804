import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import TestRunDetails from "../TestRunDetails";

export type RunGroupParams = {
  group: string;
  groupValue: string;
  objectId: string;
};

const groupNames = {
  machine: "Machine",
  os: "OS",
  browser: "Browser",
  location: "Location",
};

class TestRunGroup extends React.Component<RouteComponentProps<RunGroupParams>> {
  render() {
    const { match } = this.props;
    return (
      <>
        <TestRunDetails
          title={`Test Result Overview ${groupNames[match.params.group]}: ${
            match.params.groupValue
          }`}
          group={true}
          {...this.props}
        />
      </>
    );
  }
}

export default withRouter<RouteComponentProps<RunGroupParams>>(TestRunGroup);
