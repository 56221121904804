// tslint:disable:no-any
import * as React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Theme, withStyles, WithStyles, createStyles, Tooltip } from "@material-ui/core";
import { formatNumber, getPerformanceAverages } from "../../../helpers/testDetails";
import { numberWithCommas } from "../../../helpers/numbers";
import Grow from "@material-ui/core/Grow";

export interface TestStatsProps {
  data: any;
  calc: any;
}

type StyledComponent = WithStyles<
  | "cardWrapper"
  | "greenCard"
  | "blueCard"
  | "cardLabel"
  | "greenText"
  | "blueText"
  | "greyText"
  | "orangeText"
>;

class TestStats extends React.Component<TestStatsProps & StyledComponent> {
  render() {
    const { classes, calc, data } = this.props;

    const _e = (key: string, suffix: string): string => {
      if (data && data.stat) {
        const performance = getPerformanceAverages(data.stat);
        const val = performance[key];
        return val ? `${numberWithCommas(formatNumber(val, 0))} ${suffix}` : "-";
      } else {
        return "-";
      }
    };

    return (
      <Grid container={true} spacing={2}>
        {/*Score*/}
        <Grid item={true} className={classes.cardWrapper}>
          <Grow in={true}>
            <Card className={classes.greenCard}>
              <Grid container={true} spacing={2} alignItems="center" direction="column">
                <Grid item={true} xs={12}>
                  <Typography className={classes.cardLabel}>Score</Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  {calc.completeCount !== undefined &&
                  calc.rankCount !== undefined &&
                  calc.completeCount !== calc.rankCount ? (
                    <Tooltip
                      title={`Score calculated based on ${calc.rankCount} out of ${calc.completeCount} probes in this test`}
                      placement="bottom"
                    >
                      <Typography className={classes.orangeText}>{calc.score}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography className={classes.greenText}>{calc.score}</Typography>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grow>
        </Grid>

        {/*Performance*/}
        <Grid item={true} className={classes.cardWrapper}>
          <Grow in={true}>
            <Card className={classes.blueCard}>
              <Grid container={true} spacing={2} alignItems="center" direction="column">
                <Grid item={true} xs={12}>
                  <Typography className={classes.cardLabel}>Performance</Typography>
                </Grid>
                <Grid item={true} container={true} justify="space-around">
                  <Grid item={true}>
                    <Typography className={classes.greyText}>CPU</Typography>
                    <Typography className={classes.blueText}>{_e("browserCpu", "%")}</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography className={classes.greyText}>Memory</Typography>
                    <Typography className={classes.blueText}>
                      {_e("browserMemory", "MB")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grow>
        </Grid>

        {/*Bitrate*/}
        <Grid item={true} className={classes.cardWrapper}>
          <Grow in={true}>
            <Card className={classes.blueCard}>
              <Grid container={true} spacing={2} alignItems="center" direction="column">
                <Grid item={true} xs={12}>
                  <Typography className={classes.cardLabel}>Bitrate (Kbits)</Typography>
                </Grid>
                <Grid item={true} container={true}>
                  <Grid item={true} xs={4} />
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"in"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"out"}</Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"A"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.audio?.recv?.bytes, 0))}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.audio?.send?.bytes, 0))}
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"V"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.video?.recv?.bytes, 0))}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.video?.send?.bytes, 0))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grow>
        </Grid>

        {/*Packet loss*/}
        <Grid item={true} className={classes.cardWrapper}>
          <Grow in={true}>
            <Card className={classes.blueCard}>
              <Grid container={true} spacing={2} alignItems="center" direction="column">
                <Grid item={true} xs={12}>
                  <Typography className={classes.cardLabel}>Packet Loss</Typography>
                </Grid>
                <Grid item={true} container={true} xs={12}>
                  <Grid item={true} xs={4} />
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"in"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"out"}</Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"A"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {`${numberWithCommas(formatNumber(calc.audio?.recv?.packetLoss, 1))}%`}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {`${numberWithCommas(formatNumber(calc.audio?.send?.packetLoss, 1))}%`}
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"V"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {`${numberWithCommas(formatNumber(calc.video?.recv?.packetLoss, 1))}%`}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {`${numberWithCommas(formatNumber(calc.video?.send?.packetLoss, 1))}%`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grow>
        </Grid>

        {/*Jitter*/}
        <Grid item={true} className={classes.cardWrapper}>
          <Grow in={true}>
            <Card className={classes.blueCard}>
              <Grid container={true} spacing={2} alignItems="center" direction="column">
                <Grid item={true} xs={12}>
                  <Typography className={classes.cardLabel}>Jitter (ms)</Typography>
                </Grid>
                <Grid item={true} container={true} xs={12}>
                  <Grid item={true} xs={4} />
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"in"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"out"}</Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"A"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.audio?.recv?.jitter, 0))}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.audio?.send?.jitter, 0))}
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"V"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    {/* <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.video?.recv?.jitter, 0))}
                    </Typography> */}
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.video?.send?.jitter, 0))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grow>
        </Grid>

        {/*RTT*/}
        <Grid item={true} className={classes.cardWrapper}>
          <Grow in={true}>
            <Card className={classes.blueCard}>
              <Grid container={true} spacing={2} alignItems="center" direction="column">
                <Grid item={true} xs={12}>
                  <Typography className={classes.cardLabel}>RTT (ms)</Typography>
                </Grid>
                <Grid item={true} container={true}>
                  <Grid item={true} xs={4} />
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"in"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"out"}</Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"A"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.audio?.recv?.rtt, 0))}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.audio?.send?.rtt, 0))}
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"V"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.video?.recv?.rtt, 0))}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(calc.video?.send?.rtt, 0))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grow>
        </Grid>
      </Grid>
    );
  }
}

const cardStyles = {
  borderRadius: 2.5,
  padding: 8,
  borderStyle: "solid",
  height: "100%",
  boxShadow: "none",
};

const styles = (theme: Theme) =>
  createStyles({
    cardWrapper: {
      width: 100 / 6 + "%",
      [theme.breakpoints.down("md")]: {
        width: "33.3%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    cardLabel: {
      fontSize: 16,
      fontWeight: 500,
      color: "#172240",
    },
    greenCard: {
      ...cardStyles,
      border: "solid 1px #0fc5cb",
    },
    blueCard: {
      ...cardStyles,
      border: "solid 1px #1c8cd8",
    },
    greenText: {
      fontSize: 32,
      color: "#0fc5cb",
      fontWeight: 500,
      paddingTop: 13,
    },
    blueText: {
      fontSize: 17,
      color: "#1c8cd8",
      fontWeight: 500,
    },
    greyText: {
      fontSize: 13,
      color: "#c2c2c2",
      fontWeight: 500,
      paddingTop: 4,
    },
    orangeText: {
      fontSize: 32,
      color: "#dd7127",
      fontWeight: 500,
      paddingTop: 13,
    },
  });

const decorate = withStyles(styles);

export default decorate(TestStats);
