import * as React from "react";
import * as _ from "lodash";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { useSeries } from "../hooks/useSeries";
import { useLegend } from "../hooks/useLegend";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      paddingLeft: 23,
    },
    label: {
      fontSize: 12,
      fontFamily: `"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif`,
      color: "#337ab7",
      "&:hover": {
        color: "#23527c",
      },
    },
    labelUnchecked: {
      color: "#cccccc",
    },
    checkbox: {
      padding: 0,
      "& svg": {
        height: 18,
      },
    },
  })
);

const InOutToggle = () => {
  const classes = useStyles();

  const { series, toggle } = useSeries();
  const { showInOut } = useLegend();

  const [state, setState] = React.useState({
    incoming: true,
    outgoing: true,
  });
  const [seriesNames, setSeriesNames] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!series.length || !showInOut) {
      return;
    }
    const newSeriesNames = series.map((x) => x.name);
    const areSeriesNamesChanged = seriesNames.length > 0 && !_.isEqual(newSeriesNames, seriesNames);
    setSeriesNames(newSeriesNames);

    // if series names are changed
    // need to make sure that outgoing and incoming series
    // are toggled corresponding InOutToggle component state
    // and another ones are just "on" (last if statement)
    // https://redmine.testrtc.com/issues/5263
    if (areSeriesNamesChanged) {
      adjustSeriesVisibilityByInOutToggleState();
    } else {
      detectInOutToggleBySeries();
    }
  }, [series]);

  const detectInOutToggleBySeries = () => {
    let disableIncoming = false;
    if (
      series.filter((s) => s.name.toLowerCase().indexOf("incoming") !== -1).every((s) => !s.visible)
    ) {
      disableIncoming = true;
    }
    let disableOutgoing = false;
    if (
      series.filter((s) => s.name.toLowerCase().indexOf("outgoing") !== -1).every((s) => !s.visible)
    ) {
      disableOutgoing = true;
    }

    setState({
      incoming: !disableIncoming,
      outgoing: !disableOutgoing,
    });
  };

  const adjustSeriesVisibilityByInOutToggleState = () => {
    series.forEach((s) => {
      if (s.name.toLowerCase().indexOf("incoming") !== -1) {
        if (state.incoming !== s.visible) {
          toggle(s.name, !s.visible);
        }
      } else if (s.name.toLowerCase().indexOf("outgoing") !== -1) {
        if (state.outgoing !== s.visible) {
          toggle(s.name, !s.visible);
        }
      } else if (!s.visible) {
        toggle(s.name, true);
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setState({ ...state, [name]: checked });

    const seriesNames = series
      .filter((s) => s.name.toLowerCase()?.indexOf(name) !== -1)
      .map((s) => s.name);

    toggle(seriesNames, checked);
  };

  if (!showInOut) {
    return null;
  }

  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.incoming}
            onChange={handleChange}
            name="incoming"
            color="primary"
            size={"small"}
            classes={{ root: classes.checkbox }}
          />
        }
        classes={{
          label: `${classes.label} ${!state.incoming ? classes.labelUnchecked : ""}`,
        }}
        label="Incoming"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.outgoing}
            onChange={handleChange}
            name="outgoing"
            color="secondary"
            size={"small"}
            classes={{ root: classes.checkbox }}
          />
        }
        classes={{
          label: `${classes.label} ${!state.outgoing ? classes.labelUnchecked : ""}`,
        }}
        label="Outgoing"
      />
    </div>
  );
};

export default InOutToggle;
