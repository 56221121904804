// tslint:disable:no-any
import * as React from "react";

import { IMonitors } from "../indexNew";
import Widget from "./Components/Widget";
import Line from "./Components/Line";
import ActiveMonitors from "./ActiveMonitors";
import ActiveMonitorsBar from "./Components/ActiveMonitorsBar";

interface IUpRTC {
  monitors?: IMonitors;
}

const UpRTC = ({ monitors }: IUpRTC) => {
  if (!monitors || monitors.active === 0) {
    return null;
  }

  return (
    <Widget title={"upRTC"} marginBottom={8}>
      <Line
        label="Active monitors"
        value={[
          {
            value: monitors.active.toLocaleString("en", {
              maximumFractionDigits: 0,
            }),
            tooltip: "Monitors in use",
          },
          {
            value: monitors.activeLimit.toLocaleString("en", {
              maximumFractionDigits: 0,
            }),
            tooltip: "Maximum Monitors Available",
          },
        ]}
      />
      <ActiveMonitorsBar
        active={monitors.active}
        total={monitors.activeLimit}
        monitors={monitors.activeList.docs}
      />
      <ActiveMonitors monitors={monitors.activeList} />
    </Widget>
  );
};

export default UpRTC;
