import * as React from "react";
import { withStyles } from "@material-ui/core";
import styles, { Styles } from "./LogViewerStyles";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";

interface Colors {
  [key: string]: string;
}

const colors: Colors = {
  debug: "#4C4C4C",
  notice: "#FFAB2E",
  info: "#009900",
  error: "#E7351E",
  default: "#BCBAB9",
};

export interface ILogViewerRowProps {
  index: number;
  data: string;
  prevTimeStamp: number;
  isExpanded: boolean | undefined;

  getLabelColor: (label: string) => string;
  setExpanded: (isExpanded: boolean) => void;
}

const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

const ReactJson = React.lazy(() => import("react-json-view"));

const LogViewerRow = React.memo<ILogViewerRowProps & Styles>(
  ({ classes, data, index, prevTimeStamp, isExpanded, getLabelColor, setExpanded }) => {
    let rowData = null;

    if (data.length > 0) {
      try {
        rowData = JSON.parse(data);
      } catch (error) {
        console.log(error);
      }
    }

    const collapseRow = () => {
      setExpanded(false);
    };

    const expandRow = () => {
      setExpanded(true);
    };

    const isExpandable = (extraFields: any, isTerminal: boolean): boolean => {
      const keys = extraFields ? Object.keys(extraFields) : [];
      if (keys.length === 1 && keys[0] === "level") {
        return false;
      } else {
        return Object.keys(extraFields).length > 0 && !isTerminal;
      }
    };

    // tslint:disable-next-line: no-any
    const getLineColor = (line: any, isTerminal: boolean) => {
      if (isTerminal) {
        return colors.default;
      } else {
        if (line.level === "info" && line.message) {
          const text: string = line.message.toLowerCase();
          if (text.indexOf("fail") >= 0 || text.indexOf("error") >= 0) {
            return colors.error;
          } else {
            return colors.info;
          }
        } else {
          return colors[line.level] ? colors[line.level] : colors.default;
        }
      }
    };

    const renderExpandButton = () => {
      if (isExpanded) {
        return (
          <IconButton size="small" onClick={collapseRow} className={classes.expandButton}>
            <DownIcon fontSize="inherit" />
          </IconButton>
        );
      } else {
        return (
          <IconButton size="small" onClick={expandRow} className={classes.expandButton}>
            <RightIcon fontSize="inherit" />
          </IconButton>
        );
      }
    };

    if (rowData) {
      const { message, timestamp, label, loggerViewCommand, ...extraFirelds } = rowData;
      const duration = prevTimeStamp ? new Date(timestamp).getTime() - prevTimeStamp : "";
      const isTerminal = isHTML(message) || loggerViewCommand === "xterm";
      const expandable = isExpandable(extraFirelds, isTerminal);
      const messageColor = getLineColor(rowData, isTerminal);
      const labelColor = getLabelColor(label);

      return (
        <React.Fragment>
          <div className={classes.rowDuration}>{`${duration}`}</div>
          <div className={classes.rowLabel} style={{ color: labelColor }}>
            {rowData.label}
          </div>
          <div className={classes.rowMessage} style={{ color: messageColor }}>
            {isTerminal ? (
              <pre
                className={classes.terminalLog}
                dangerouslySetInnerHTML={{ __html: rowData.message }}
              />
            ) : (
              <div className={classes.log}>
                {expandable ? renderExpandButton() : null}
                {rowData.message}
              </div>
            )}
            {isExpanded ? (
              <div className={classes.treeView}>
                <React.Suspense fallback={<div>Loading...</div>}>
                  <ReactJson
                    src={extraFirelds}
                    collapsed={false}
                    enableClipboard={false}
                    displayDataTypes={false}
                  />
                </React.Suspense>
              </div>
            ) : null}
          </div>
        </React.Fragment>
      );
    } else {
      return <div className={classes.spacer}>{`Loading row #${index}`}</div>;
    }
  },
  (prevProps, nextProps) => {
    if (prevProps.data.length === 0 && nextProps.data.length > 0) {
      return false;
    } else {
      return true;
    }
  }
);

const decorate = withStyles(styles);

export default decorate(LogViewerRow);
