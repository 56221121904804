import * as React from "react";
import GridControl from "./GridControl";
import withBreadcrumb from "../../components/withBreadcrumb";

class Monitoring extends React.Component {
  render() {
    return <GridControl {...this.props} />;
  }
}

export default withBreadcrumb(Monitoring);
