// tslint:disable:no-any

import * as React from "react";
import { RouteComponentProps } from "react-router";
import TestService from "../../services/TestService";
import {
  TestRun,
  TestIteration as TestIterationRoute,
  AnalyzeDump,
  TestRunDetails,
} from "../../constants/RoutesNames";
import View from "./View";
import withBreadcrumb, { WithBreadcrumb } from "../../components/withBreadcrumb";
import AxiosFactory from "src/services/AxiosFactory";
import { AxiosResponse } from "axios";
import ApiPath from "src/constants/ApiPath";
import { AppToolbarContext } from "../Layout/components/AppToolbar/Context";

export const TestIterationContext = React.createContext({
  test: null,
  testRun: null,
  testDefinition: null,
  testEvents: [],
  testIterations: [],
  updateTest: (_test: Test) => null,
  saveTestIteration: (_test: Test) => null,
  navigateToIteration: (_id: any) => null,
  isReport: false,
  freeReport: false,
  error: "",
} as TestIterationState);

export interface TestIterationProps {
  freeReport: boolean;
  setExpired: (flag: boolean) => void;
}

export interface TestIterationState {
  test: any;
  testRun: any;
  testDefinition: any;
  testEvents: Array<any>;
  testIterations: Array<any>;
  isReport: boolean;
  freeReport: boolean;
  updateTest: (test: any) => void;
  saveTestIteration: (test: any) => void;
  navigateToIteration: (id: string) => void;
  error: string;
}

class TestIteration extends React.Component<
  TestIterationProps & RouteComponentProps<any> & WithBreadcrumb,
  TestIterationState
> {
  static contextType = AppToolbarContext;
  durationInterval: NodeJS.Timer;

  defaultState: TestIterationState = {
    test: null,
    testRun: null,
    testDefinition: null,
    testEvents: [],
    testIterations: [],
    isReport: false,
    freeReport: false,
    updateTest: this.updateTest.bind(this),
    saveTestIteration: this.saveTestIteration,
    navigateToIteration: this.navigateToIteration.bind(this),
    error: "",
  };

  constructor(props: TestIterationProps & RouteComponentProps<any> & WithBreadcrumb) {
    super(props);

    this.updateTest = this.updateTest.bind(this);
    this.fetchAllData = this.fetchAllData.bind(this);
    this.saveTestIteration = this.saveTestIteration.bind(this);

    this.state = {
      ...this.defaultState,
      freeReport: props.freeReport,
    };
  }

  async componentDidMount() {
    await this.fetchAllData();
  }

  async fetchAllData(iterationId?: string, manual?: boolean) {
    if (manual) {
      this.setState({
        ...this.state,
        test: null,
      });
    }
    try {
      const [testResult, chartDataResult] = await Promise.all([
        this.props.freeReport
          ? this.getFreeReport(iterationId || this.props.match.params.objectId)
          : this.getTestIteration(iterationId || this.props.match.params.objectId),
        this.getTestIterationChartData(
          iterationId || this.props.match.params.objectId,
          this.props.freeReport
        ),
      ]);
      const test = {
        ...testResult,
        chartData: {
          ...chartDataResult,
        },
      };
      const testRun = manual ? this.state.testRun : await this.getTestRun(test.testRunId);
      const testDefinition = manual
        ? this.state.testDefinition
        : await this.getTestDefinition(testRun.testId);
      const testEvents = manual
        ? this.state.testEvents
        : test.testRunId
        ? await this.getTestIterationEvents(test.testRunId)
        : [];
      const testIterations = manual
        ? this.state.testIterations
        : await this.getTestIterations(test.testRunId);

      const isReport = test.status === "analyze" || test.status === "upload";

      this.props.resetBreadcrumb();
      if (test.runName) {
        if (test.runName === "manual-upload") {
          this.props.pushBreadcrumbNode(AnalyzeDump, "analyzeRTC");
        } else {
          this.props.pushBreadcrumbNode(TestRun);
          this.props.pushBreadcrumbNode(
            `${TestRunDetails}/${testRun._id}`,
            `Test Results: ${test.runName}`
          );
        }
      }
      if (isReport) {
        //
      } else {
        // this.props.pushBreadcrumbNode(
        //   `${TestIterationRoute}/${test._id}`,
        //   `${test.machine} ${test.runIndex}`
        // );
        this.context.setPageInfo(`${test.machine} ${test.runIndex}`);
      }

      await this.setState({
        ...this.state,
        test,
        testRun,
        testDefinition,
        testEvents,
        testIterations,
        isReport,
        freeReport: this.props.freeReport,
      });
    } catch (err) {
      if (err.response?.status === 404) {
        this.setState({
          error: "Not found",
        });
      } else if (err.response?.status === 403) {
        this.props.history.push("/app/403");
      } else {
        this.setState({
          error: "Could not load test iteration data",
        });
      }
    }
  }

  updateTest(test: any) {
    this.setState({
      test,
    });
  }

  async getTestIteration(iterationId: string) {
    const { data } = await TestService.getTestIteration(iterationId);
    return data;
  }

  async getTestIterationChartData(iterationId: string, freeReport?: boolean) {
    const { data } = await TestService.getTestIterationChartData(iterationId, freeReport);
    return data;
  }

  async getFreeReport(reportId: string) {
    try {
      const axiosFactory = new AxiosFactory();
      const result: AxiosResponse = await axiosFactory.axios.get(
        `${ApiPath.api.testIterations}/report/${reportId}`
      );
      return result.data;
    } catch (err) {
      this.props.setExpired(true);
      throw err;
    }
  }

  async saveTestIteration(testIteration: Test) {
    try {
      const { data } = await TestService.saveTestIteration(testIteration);
      this.updateTest({
        ...this.state.test,
        ...data,
      });
    } catch (e) {
      // TODO: use global error handler
    }
  }

  async getTestRun(testId: string) {
    if (!testId) {
      return {
        testId: null,
      };
    }
    const res = await TestService.getTestRun(testId);
    if (res.status === 200) {
      return res.data;
    } else if (res.status === 403) {
      this.props.history.push("/app/403");
    } else {
      return false;
    }
  }

  async getTestDefinition(testId: string) {
    if (!testId) {
      return null;
    }
    try {
      const { data } = await TestService.getTestDefinition(testId);
      return data;
    } catch (e) {
      return false;
    }
  }

  async getTestIterationEvents(id: string) {
    const { data } = await TestService.getTestIterationEvents(id);
    return data;
  }

  async getTestIterations(id: string) {
    if (!id) {
      return null;
    }
    const { data } = await TestService.getMinimalTestIterations(id);
    return data;
  }

  async navigateToIteration(id: string) {
    if (!id) {
      return;
    }
    this.props.history.push(`${TestIterationRoute}/${id}`);
    await this.fetchAllData(id, true);
  }

  render() {
    return (
      <TestIterationContext.Provider value={this.state}>
        <View {...this.state} />
      </TestIterationContext.Provider>
    );
  }
}

export default withBreadcrumb(TestIteration);
