// tslint:disable:no-any
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import scrollIntoView from "scroll-into-view";
import * as moment from "moment";
import * as _ from "lodash";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import ForwardIcon from "@material-ui/icons/Forward";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";

import RTCGrid from "src/components/Grid";
import { toKeyValue } from "src/helpers/objHelper";
import KeyValueTableCell from "./KeyValueTableCell";
import ICECandidatesTables, { IICECandidatesTable, Channel } from "./ICECandidatesTables";
import Ciphers from "./Ciphers";

import DisplaySDP from "./DisplaySDP";

type StyledComponent = WithStyles;

export interface IConnectionProps {
  id: string;
  title: string;
  connection: IConnection;
  sessionData?: { [props: string]: any };
}

const WordBreak = {
  wordBreak: "break-all",
} as React.CSSProperties;

const LinkStyles = {
  textDecoration: "none",
  color: "#53b6ef",
  cursor: "pointer",
} as React.CSSProperties;

const renderIpRedirectCell = (address: any) => {
  let url = null;
  if (address) {
    url = `http://ip-score.com/checkip/${address.split(":")[0]}`;
  }
  if (url) {
    return (
      <span>
        <a href={url} target="_blank" style={LinkStyles} rel="noopener noreferrer">
          {address}
        </a>
      </span>
    );
  }
  return <span>none</span>;
};

const colProps = {
  numeric: false,
  disablePadding: false,
  sortable: false,
  style: { textAlign: "left", paddingLeft: "10px", paddingRight: "10px" },
};

const numericColProps = {
  numeric: true,
  disablePadding: false,
  sortable: false,
  style: { paddingLeft: "10px", paddingRight: "10px" },
};

const legacyColumnSchema: Array<ColumnSchema> = [
  {
    id: "googLocalAddress",
    label: "Local address",
    render: (dataItem: any, _index: number) => {
      return renderIpRedirectCell(dataItem.googLocalAddress);
    },
    ...numericColProps,
  },
  {
    id: "googLocalCandidateType",
    label: "Local type",
    render: (dataItem: any, _index: number) => (
      <span>{dataItem.googLocalCandidateType ? dataItem.googLocalCandidateType : "none"}</span>
    ),
    ...colProps,
  },
  {
    id: "localCandidateId",
    label: "Local id",
    render: (dataItem: any, _index: number) => (
      <span>{dataItem.localCandidateId ? dataItem.localCandidateId : "none"}</span>
    ),
    ...colProps,
  },
  {
    id: "googRemoteAddress",
    label: "Remote address",
    render: (dataItem: any, _index: number) => {
      return renderIpRedirectCell(dataItem.googRemoteAddress);
    },
    ...numericColProps,
  },
  {
    id: "googRemoteCandidateType",
    label: "Remote type",
    render: (dataItem: any, _index: number) => (
      <span>{dataItem.googRemoteCandidateType ? dataItem.googRemoteCandidateType : "none"}</span>
    ),
    ...colProps,
  },
  {
    id: "localCandidateId",
    label: "Remote id",
    render: (dataItem: any, _index: number) => (
      <span>{dataItem.localCandidateId ? dataItem.localCandidateId : "none"}</span>
    ),
    ...colProps,
  },
  {
    id: "requestsSent",
    label: "Requests sent",
    render: (dataItem: any, _index: number) => (
      <span>{dataItem.requestsSent ? dataItem.requestsSent : "none"}</span>
    ),
    ...numericColProps,
  },
  {
    id: "responsesReceived",
    label: "Responses received",
    render: (dataItem: any, _index: number) => (
      <span>{dataItem.responsesReceived ? dataItem.responsesReceived : "none"}</span>
    ),
    ...numericColProps,
  },
  {
    id: "requestsReceived",
    label: "Requests received",
    render: (dataItem: any, _index: number) => (
      <span>{dataItem.requestsReceived ? dataItem.requestsReceived : "none"}</span>
    ),
    ...numericColProps,
  },
  {
    id: "responsesSent",
    label: "Responses sent",
    render: (dataItem: any, _index: number) => (
      <span>{dataItem.responsesSent ? dataItem.responsesSent : "none"}</span>
    ),
    ...numericColProps,
  },
  {
    id: "googActiveConnection",
    label: "Active Connection",
    render: (dataItem: any, _index: number) => (
      <span>{dataItem.googActiveConnection ? "Active" : ""}</span>
    ),
    ...colProps,
  },
];

export const standardColumnSchema: Array<ColumnSchema> = [
  {
    id: "one",
    label: "",
    numeric: false,
    disablePadding: false,
    style: { maxWidth: "10%" },
  },
  {
    id: "two",
    label: "",
    numeric: false,
    disablePadding: false,
    style: { maxWidth: "20%" },
  },
  {
    id: "three",
    label: "",
    numeric: false,
    disablePadding: false,
    style: { maxWidth: "10%" },
  },
  {
    id: "four",
    label: "",
    numeric: false,
    disablePadding: false,
    style: { maxWidth: "10%" },
  },
  {
    id: "five",
    label: "",
    numeric: false,
    disablePadding: false,
    style: { maxWidth: "10%" },
  },
  {
    id: "six",
    label: "",
    numeric: false,
    disablePadding: false,
    style: { maxWidth: "5%" },
  },
  {
    id: "seven",
    label: "",
    numeric: false,
    disablePadding: false,
    style: { maxWidth: "5%" },
  },
  {
    id: "eight",
    label: "",
    numeric: false,
    disablePadding: false,
    style: { maxWidth: "2%" },
  },
  {
    id: "nine",
    label: "",
    numeric: false,
    disablePadding: false,
    style: { maxWidth: "5%" },
  },
  {
    id: "ten",
    label: "",
    numeric: false,
    disablePadding: false,
    style: { maxWidth: "5%" },
  },
];

const logMessageStylesCell = {
  position: "relative",
  maxWidth: "100%",
} as React.CSSProperties;

class Connection extends React.PureComponent<
  StyledComponent & IConnectionProps & RouteComponentProps<{}>
> {
  prevEventTime: string | null;
  background: string;
  constructor(props: StyledComponent & IConnectionProps & RouteComponentProps<{}>) {
    super(props);

    this.prevEventTime = null;
    this.background = "#fff";
  }
  processTraceEvent(event: ProcessEvent, index: number, prevEvent?: ProcessEvent) {
    this.prevEventTime = this.prevEventTime || event.time;
    if (event.time !== this.prevEventTime) {
      this.prevEventTime = event.time;
      this.background = this.background === "#f9f9f9" ? "#fff" : "#f9f9f9";
    }
    if (event.type.indexOf("Failure") !== -1) {
      this.background = "#a22a21";
    }
    if (event.type === "iceConnectionStateChange") {
      switch (event.value) {
        case "ICEConnectionStateConnected":
        case "ICEConnectionStateCompleted":
        case "kICEConnectionStateConnected":
        case "kICEConnectionStateCompleted":
          this.background = "#559542";
          break;
        case "ICEConnectionStateFailed":
        case "kICEConnectionStateFailed":
          this.background = "#a22a21";
          break;
        default:
          break;
      }
    }

    const showValue = (value: any) => {
      if (!value) {
        return "none";
      }
      if (typeof value === "object") {
        return JSON.stringify(value);
      }
      try {
        JSON.parse(value);
        return value;
      } catch {
        return value;
      }
    };

    const eventSeconds = moment(event.time).seconds();
    const prevEventSeconds = prevEvent ? moment(prevEvent?.time).seconds() : 0;
    const isSecondsSame = eventSeconds === prevEventSeconds;
    const expandable = (event.value && event.value.length > 150) || typeof event.value === "object";
    return (
      <tr
        key={index}
        id={this.generateTraceEventId(event)}
        className="log-line"
        // tslint:disable-next-line: no-shadowed-variable
        onClick={(event: any) => {
          if (!expandable) {
            return;
          }
          const expandableTd = event.currentTarget.cells[2];
          if (expandableTd.className.indexOf("expandable") === -1) {
            expandableTd.className += " expandable";
          } else {
            expandableTd.className = expandableTd.className.replace(" expandable", "");
          }
        }}
      >
        <td
          className="log-time"
          style={{
            width: "10%",
            textAlign: "left",
            color: `${index === 0 ? "grey" : isSecondsSame ? "grey" : "blue"}`,
          }}
        >
          {moment(event.time).format("YYYY-MM-DD HH:mm:ss.SSS")}
        </td>
        <td className="log-time" style={{ width: "10%", textAlign: "right", color: "black" }}>
          {event.type}
        </td>
        <td style={logMessageStylesCell} className="ellipsis">
          <label className="log-message" style={{ display: "flex", marginTop: -4 }}>
            <ExpandMoreIcon style={{ visibility: expandable ? "visible" : "hidden" }} />
            <span
              className="span-message"
              style={{
                paddingTop: 4,
                paddingLeft: 25,
              }}
            >
              {showValue(event.value)}
            </span>
            {expandable && (
              <div
                className="extra-info"
                style={{ paddingTop: 4 }}
                onClick={(e) => e.preventDefault()}
              >
                {this.processExtraInfo(event)}
              </div>
            )}
          </label>
        </td>
      </tr>
    );
  }

  processExtraInfo(event: ProcessEvent) {
    const isObject = typeof event.value === "object";
    if (isObject) {
      event.value = JSON.stringify(event.value, null, 2);
    }
    switch (event.type) {
      case "setLocalDescription":
        return <DisplaySDP event={event} />;
      case "setRemoteDescription":
        return <DisplaySDP event={event} />;
      case "createOfferOnSuccess":
        return <DisplaySDP event={event} />;
      case "createAnswerOnSuccess":
        return <DisplaySDP event={event} />;
      case "transceiverAdded":
      case "transceiverModified": {
        try {
          const json = event.value.split(/\(\)\[.*?]:{/);
          return (
            <div>
              {json[0]}
              <pre>{json[1]}</pre>
            </div>
          );
        } catch {
          return event.value;
        }
      }
      default: {
        return event.value;
      }
    }
  }

  filterStats(stats: Stats) {
    const stun = {};
    let reportname;
    for (reportname in stats) {
      if (reportname.indexOf("Conn-") === 0) {
        const t = reportname.split("-");
        const comp = t.pop();
        const st = t.join("-");
        if (!stun[st]) {
          stun[st] = {};
        }

        const filtredStats = JSON.parse(stats[reportname] && stats[reportname].values);
        switch (comp) {
          case "requestsSent":
          case "consentRequestsSent":
          case "responsesSent":
          case "requestsReceived":
          case "responsesReceived":
          case "localCandidateId":
          case "remoteCandidateId":
          case "googLocalAddress":
          case "googRemoteAddress":
          case "googLocalCandidateType":
          case "googRemoteCandidateType":
          case "googActiveConnection":
            stun[st][comp] = filtredStats[filtredStats.length - 1];
            break;
          default:
        }
      }
    }

    return stun;
  }

  convertToLegacyArray(obj: any) {
    const result = Object.keys(obj).map((key) => {
      return obj[key];
    });
    return result;
  }

  convertToStandardArray(allStats: any) {
    const transports = {};
    const pairs = {};
    const candidates = {};
    let t;
    let reportname;
    let comp;
    // tslint:disable-next-line: forin
    for (reportname in allStats) {
      t = reportname.split("-");
      comp = t.pop();
      t = t.join("-");
      const stats = JSON.parse(allStats[reportname].values);
      if (reportname.indexOf("RTCTransport") === 0) {
        if (!transports[t]) {
          transports[t] = {};
        }
        switch (comp) {
          case "bytesSent":
          case "bytesReceived":
          case "dtlsState":
          case "selectedCandidatePairId":
            transports[t][comp] = stats[stats.length - 1];
            break;
          default:
          // console.log(reportname, comp, stats);
        }
      } else if (reportname.indexOf("RTCIceCandidatePair") === 0) {
        if (!pairs[t]) {
          pairs[t] = {};
        }
        pairs[t][comp] = stats[stats.length - 1];
      } else if (reportname.indexOf("RTCIceCandidate") === 0) {
        if (!candidates[t]) {
          candidates[t] = {};
        }
        candidates[t][comp] = stats[stats.length - 1];
      }
    }

    const tables: Array<any> = [];
    // tslint:disable-next-line: forin no-shadowed-variable
    for (const t in transports) {
      const table: IICECandidatesTable = {
        name: t,
        bytesSent: transports[t].bytesSent,
        bytesReceived: transports[t].bytesReceived,
        dtlsState: transports[t].dtlsState,
        selectedCandidatePairId: transports[t].selectedCandidatePairId,
        channels: [],
      };
      const sortable = [];
      // tslint:disable-next-line: forin
      for (const p in pairs) {
        sortable.push([p, pairs[p]]);
      }

      sortable.sort((a: any, b: any) => {
        return b[1].priority - a[1].priority;
      });

      // sortable.sort((a: any, _b: any) => {
      //   return a[1].status === "succeeded" ? 1 : -1;
      // });

      const sortedPairs = {};
      sortable.forEach((item: any) => {
        sortedPairs[item[0]] = item[1];
      });

      for (const p in sortedPairs) {
        if (pairs[p].transportId !== t) {
          continue;
        }
        const pair = pairs[p];
        const localCandidate = candidates[pair.localCandidateId] || {};
        const remoteCandidate = candidates[pair.remoteCandidateId] || {};
        const channel: Channel = {
          state: pair.state,
          bytesSent: pair.bytesSent,
          bytesReceived: pair.bytesReceived,
          localType: localCandidate.candidateType,
          remoteType: remoteCandidate.candidateType,
          localProtocol: localCandidate.protocol,
          remoteProtocol: remoteCandidate.protocol,
          localRelayProtocol: localCandidate.relayProtocol,
          remoteRelayProtocol: remoteCandidate.relayProtocol,
          localPort: localCandidate.port,
          remotePort: remoteCandidate.port,

          localIdName: pair.localCandidateId,
          remoteIdName: pair.remoteCandidateId,
          localIpAddress: localCandidate.ip,
          remoteIpAddress: remoteCandidate.ip,
          pairIdName: p,
          rtt: pair.totalRoundTripTime,
          priority: pair.priority,
        };
        table.channels.push(channel);
      }
      tables.push(table);
    }
    return tables;
  }

  trimLogState(logState: string, trimFrom: string) {
    let result = logState;
    const index = logState.indexOf(trimFrom);
    if (index !== -1) {
      result = logState.substring(trimFrom.length);
    }
    return (
      <Typography style={{ fontSize: "12px" }} variant="subtitle1">
        {result}
      </Typography>
    );
  }

  makeJson(str: string) {
    let prepearedStr = str.replace(/ /g, "").replace(/"/g, "'");
    // remove curly braces at start and end
    prepearedStr = prepearedStr.substring(1);
    prepearedStr = prepearedStr.substring(0, prepearedStr.length - 1);

    const iceServers = prepearedStr
      .substring(prepearedStr.indexOf("[") + 1, prepearedStr.lastIndexOf("]"))
      .split(",");

    const result = {
      iceServers: [] as Array<any>,
    };
    for (let i = 0; i < iceServers.length; i++) {
      const key = iceServers[i].substring(0, iceServers[i].indexOf(":"));
      // replace . with {*} to correct parse object to key value object
      const value = iceServers[i].substring(iceServers[i].indexOf(":") + 1);
      const iceServer = {
        [key]: value.replace(/\./g, "{*}"),
      };
      result.iceServers.push(iceServer);
    }

    const stringKeysWithoutIceServers = prepearedStr
      .substring(prepearedStr.lastIndexOf("]") + 2)
      .split(",");
    for (let i = 0; i < stringKeysWithoutIceServers.length; i++) {
      const keyValue = stringKeysWithoutIceServers[i].split(":");
      const key = keyValue[0];
      const value = keyValue[1];
      result[key] = value;
    }
    return result;
  }

  deleteInternalFields(config: any) {
    const internalFields = [
      "rtcApiKey",
      "rtcRoomId",
      "rtcPeerId",
      "projectId",
      "interval",
      "sendInterval",
      "conferenceClousureWaitTime",
      "connectionId",
      "clientId",
      "sdkVersion",
      "endTime",
    ];
    internalFields.forEach((field: string) => {
      delete config[field];
    });
  }

  getConfiguration(config: any) {
    try {
      if (typeof config === "string") {
        const json = this.makeJson(config);
        const objKeyValues = toKeyValue(json);
        return <KeyValueTableCell data={objKeyValues} />;
      } else if (typeof config === "object") {
        if (_.isArray(config.iceServers)) {
          config.iceServers.forEach((is: any) => {
            if (_.isString(is.urls)) {
              is.urls = is.urls.replace(/\./g, "{*}");
            }
            if (_.isString(is.url)) {
              is.url = is.url.replace(/\./g, "{*}");
            }
          });
        }
        if (_.isString(config.wsServer)) {
          config.wsServer = config.wsServer.replace(/\./g, "{*}");
        }
        this.deleteInternalFields(config);

        const data = toKeyValue(config);
        return <KeyValueTableCell data={data} />;
      } else {
        return <KeyValueTableCell data={[]} />;
      }
    } catch (err) {
      return <Typography variant="body2">{JSON.stringify(config)}</Typography>;
    }
  }

  generateTraceEventId(event: ProcessEvent) {
    if (typeof event.value === "object") {
      return `${this.props.title}${event.type}${event.time}${JSON.stringify(event.value).substring(
        0,
        30
      )}`;
    }
    return `${this.props.title}${event.type}${event.time}${event.value.substring(0, 30)}`;
  }

  scrollToTraceEvent(nodeId: string) {
    const node: any = document.getElementById(nodeId);
    scrollIntoView(node, {
      time: 500,
      align: {
        top: 0,
      },
    });
  }

  render(): JSX.Element {
    const { classes, id, title, connection, sessionData } = this.props;
    let legacyGrid = true;
    let dataList = [];
    const statsResult = this.filterStats(connection.stats);
    if (Object.keys(statsResult).length === 0) {
      legacyGrid = false;
      dataList = this.convertToStandardArray(connection.stats);
    } else {
      dataList = this.convertToLegacyArray(statsResult);
    }
    return (
      <Fade in={true}>
        <Grid id={id} item={true} xs={12}>
          <Card className={classes.card}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="subtitle1" style={WordBreak}>
                Connection: {title}
              </Typography>
            </Toolbar>
            <Divider className={classes.divider} />
            <Toolbar className={classes.toolbar}>
              <div style={{ paddingRight: 5 } as React.CSSProperties}>
                <Typography variant="subtitle1">Configuration:</Typography>
              </div>
              <Grid item={true}>
                <div className={classes.focusedText}>
                  {this.getConfiguration(
                    Object.keys(connection.rtcConfiguration || {}).length > 0
                      ? connection.rtcConfiguration
                      : sessionData
                  )}
                </div>
              </Grid>
            </Toolbar>
            <Divider className={classes.divider} />
            <Ciphers data={connection.features} />
            <Toolbar className={classes.toolbar}>
              <Grid item={true} xs={12} md={2}>
                <Typography variant="subtitle1">Signaling state:</Typography>
              </Grid>
              <Grid item={true} xs={12} md={10} className={classes.flexAlign}>
                {connection.updateLog.map((event) => {
                  if (
                    event.type.toLowerCase() === "signalingstatechange" ||
                    event.type.toLowerCase() === "onsignalingstatechange"
                  ) {
                    return (
                      <React.Fragment key={`${event.value}-${Math.random() * 20}`}>
                        <ForwardIcon className={classes.forwardIcon} />
                        <div
                          className={classes.circle}
                          onClick={() => {
                            this.scrollToTraceEvent(this.generateTraceEventId(event));
                          }}
                        >
                          {this.trimLogState(event.value, "SignalingState")}
                          <Typography
                            style={{ fontSize: "12px", color: "#FC373A" }}
                            variant="subtitle1"
                          >
                            {moment(event.time).format("h:mm:ss A")}
                          </Typography>
                        </div>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Toolbar>
            <Toolbar className={classes.toolbar}>
              <Grid item={true} xs={12} md={2}>
                <Typography variant="subtitle1">ICE connection state:</Typography>
              </Grid>
              <Grid item={true} xs={12} md={10} className={classes.flexAlign}>
                {connection.updateLog.map((event) => {
                  if (
                    event.type.toLowerCase() === "iceconnectionstatechange" ||
                    event.type.toLowerCase() === "oniceconnectionstatechange"
                  ) {
                    return (
                      <React.Fragment key={event.value}>
                        <ForwardIcon className={classes.forwardIcon} />
                        <div
                          className={classes.circle}
                          onClick={() => {
                            this.scrollToTraceEvent(this.generateTraceEventId(event));
                          }}
                        >
                          {this.trimLogState(event.value, "ICEConnectionState")}
                          <Typography
                            style={{ fontSize: "12px", color: "#FC373A" }}
                            variant="subtitle1"
                          >
                            {moment(event.time).format("h:mm:ss A")}
                          </Typography>
                        </div>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Toolbar>
            <Divider className={classes.divider} />
            {legacyGrid && (
              <Grid container={true} spacing={5} className={classes.tableContainer}>
                <RTCGrid
                  onRowClick={(_e: React.MouseEvent, d: any) => console.error(d)}
                  localData={dataList}
                  columnSchema={legacyColumnSchema}
                  defaultSort={{
                    order: "desc",
                    orderBy: "lastRunDate",
                  }}
                  rowProps={{
                    className: classes.tableRow,
                  }}
                  rowColorFunc={(model: any) => {
                    return model.googActiveConnection ? "#bcbcfa" : "#fff";
                  }}
                />
              </Grid>
            )}
          </Card>
          {!legacyGrid && <ICECandidatesTables data={dataList} />}
          <Card className={classes.card}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="subtitle1">{"Events Log/API calls"}</Typography>
            </Toolbar>
            <Divider className={classes.divider} />
            <div className="logger internals-logs" style={{ cursor: "default", overflowX: "auto" }}>
              <div className="row">
                <table style={{ width: "100%" }}>
                  <tbody>
                    {connection.updateLog.map((event, index) =>
                      this.processTraceEvent(event, index, connection.updateLog[index - 1])
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Card>
        </Grid>
      </Fade>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    flexAlign: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    tableRow: {
      cursor: "pointer",
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
    },
    standardTableRow: {
      height: 20,
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
      "& td": {
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
    tableContainer: {
      paddingBottom: theme.spacing(3),
      overflowY: "hidden",
      overflowX: "auto",
      "& thead tr th": {
        textAlign: "center",
      },
    },
    standardTableContainer: {
      paddingBottom: theme.spacing(3),
      paddingTop: 4,
    },
    focusedText: {
      background: "#f3f3f3",
      boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 2px 1px -1px rgba(0, 0, 0, 0.12)`,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    circle: {
      height: "80px",
      background: "#f3f3f3",
      borderRadius: "25px",
      wordBreak: "break-all",
      textAlign: "center",
      fontSize: "11px",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 30px",
      margin: "15px",
      textTransform: "capitalize",
      boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 2px 1px -1px rgba(0, 0, 0, 0.12)`,
      flexDirection: "column",
      "&:hover": {
        cursor: "pointer",
      },
      "&:active": {
        opacity: 0.6,
      },
    },
    forwardIcon: {
      margin: "0 10px",
    },
    preStyles: {
      whiteSpace: "pre-wrap",
      wordBreak: "keep-all",
    },
    paragraphStyle: {
      fontSize: "12px",
      padding: "0 50px",
    },
    linkStyle: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      cursor: "pointer",
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}> & IConnectionProps>(decorate(Connection));
