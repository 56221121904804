import * as React from "react";
import { TableHead, TableCell, TableSortLabel, TableRow, Hidden } from "@material-ui/core";

interface EnhancedTableHeadProps {
  onRequestSort: (e: React.MouseEvent<HTMLInputElement>, property: string) => void;
  order?: SortValueType;
  orderBy?: string;
  selectable?: string;
  rowCount?: number;
  disablePadding?: boolean;
  columnSchema: Array<ColumnSchema>;
}

export enum ColumnSchemaHideDown {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

export default class EnhancedTableHead extends React.Component<EnhancedTableHeadProps> {
  constructor(props: EnhancedTableHeadProps) {
    super(props);

    this.createSortHandler = this.createSortHandler.bind(this);
  }

  createSortHandler(property: string) {
    return (event: React.MouseEvent<HTMLInputElement>) => {
      this.props.onRequestSort(event, property);
    };
  }

  render() {
    const { order, orderBy, columnSchema } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnSchema.map((column: ColumnSchema, i: number) => {
            return (
              <Hidden
                key={i}
                lgDown={column.hideDown === ColumnSchemaHideDown.lg}
                xlDown={column.hideDown === ColumnSchemaHideDown.xl}
                mdDown={column.hideDown === ColumnSchemaHideDown.md}
                smDown={column.hideDown === ColumnSchemaHideDown.sm}
                xsDown={column.hideDown === ColumnSchemaHideDown.xs}
              >
                <TableCell
                  align={column.numeric ? "right" : "left"}
                  padding={column.disablePadding ? "none" : "default"}
                  sortDirection={column.sortable !== false && orderBy === column.id ? order : false}
                  style={column.style}
                >
                  {column.sortable !== false ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.labelRender ? column.labelRender() : column.label}
                    </TableSortLabel>
                  ) : column.labelRender ? (
                    column.labelRender()
                  ) : (
                    column.label
                  )}
                </TableCell>
              </Hidden>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}
