// tslint:disable:no-any
import AxiosFactory from "./AxiosFactory";
import { AxiosResponse } from "axios";

const axiosFactory = new AxiosFactory();

export default class DumbFileService {
  static getDumbFile(url: string, config?: any): Promise<AxiosResponse> {
    return axiosFactory.axios.get(url, config);
  }

  static replayAsWatchRTC(url: string) {
    return axiosFactory.axios.get(url);
  }
}
