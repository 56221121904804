import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Grid, Typography, Card, Button } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import FormValidators from "src/helpers/form-validators";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import AxiosFactory from "src/services/AxiosFactory";
import ApiPath from "src/constants/ApiPath";
import { SignIn } from "src/constants/RoutesNames";
import { SetNotification } from "../../../actions/notificationAction";

type RouteParams = {
  token: string;
};

type State = {
  validating: boolean;
};

class ResetPassword extends React.Component<RouteComponentProps<RouteParams>, State> {
  constructor(props: RouteComponentProps<RouteParams>) {
    super(props);

    this.state = {
      validating: true,
    };
  }

  componentDidMount() {
    this.validate();
  }

  notification(message: string) {
    if ((this.props as any).setNotification) {
      (this.props as any).setNotification(message);
    } else {
      alert(message);
    }
  }

  // tslint:disable-next-line: no-any
  onSubmit = async (values: any) => {
    if (values.password !== values.repeat_password) {
      alert("Password does not match");
      return;
    }
    try {
      const axiosFactory = new AxiosFactory();
      await axiosFactory.axios.post(
        `${ApiPath.api.user.resetPassword}/${this.props.match.params.token}`,
        {
          password: values.password,
        }
      );
      this.notification("Password has been changed successfully, now you can log in.");
      this.props.history.push(SignIn);
    } catch (err) {
      this.notification(err.response.data.message || "Unexpected error. Please contact support");
    }
  };

  async validate() {
    try {
      if (!this.props.match.params.token) {
        this.props.history.push(SignIn);
        return;
      }
      await this.setState({
        validating: true,
      });
      const axiosFactory = new AxiosFactory();
      await axiosFactory.axios.get(
        `${ApiPath.api.user.resetPassword}/${this.props.match.params.token}`
      );
    } catch (err) {
      this.notification(err.response.data.message || "The request is not valid.");
      this.props.history.push(SignIn);
    } finally {
      await this.setState({
        validating: false,
      });
    }
  }

  render() {
    if (this.state.validating) {
      return null;
    }
    return (
      <Form
        // tslint:disable-next-line: no-any
        onSubmit={this.onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Card
              style={{
                padding: 30,
                width: 400,
                position: "absolute",
                left: "calc(50% - 200px)",
                top: "30%",
              }}
            >
              <Typography variant="h6" gutterBottom={true}>
                {"Please enter new password for your account."}
              </Typography>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <Field
                    component={TextFieldControl}
                    type="password"
                    name="password"
                    label="Password"
                    validate={FormValidators.composeValidators(
                      FormValidators.required(),
                      FormValidators.minValue(6)
                    )}
                    variant="outlined"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field
                    component={TextFieldControl}
                    type="password"
                    name="repeat_password"
                    label="Repeat Password"
                    validate={FormValidators.composeValidators(
                      FormValidators.required(),
                      FormValidators.minValue(6)
                    )}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item={true}
                  container={true}
                  xs={12}
                  alignContent={"flex-end"}
                  justify={"flex-end"}
                >
                  <Button type="submit" variant="contained" color="secondary">
                    {"Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </form>
        )}
      />
    );
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
