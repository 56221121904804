import * as React from "react";
import CalendarIcon from "@material-ui/icons/CalendarTodayOutlined";
import { MuiPickersUtilsProvider, KeyboardDatePicker, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DatePickerModalProps } from "material-ui-pickers/DatePicker/DatePickerModal";
import InputAdornment from "@material-ui/core/InputAdornment";

type DatePickerControlProps = {
  fullWidth?: boolean;
  clearable?: boolean;
  variant?: string;
  dateTime?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

const DatePickerControl = ({
  value,
  onChange,
  fullWidth,
  clearable,
  name,
  InputProps,
  label,
  placeholder,
  dateTime,
  minDate,
  maxDate,
}: DatePickerControlProps & DatePickerModalProps) => {
  const Picker = dateTime ? DateTimePicker : KeyboardDatePicker;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Picker
        name={name}
        label={label}
        placeholder={placeholder}
        format={dateTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
        fullWidth={fullWidth}
        value={value ? value : null}
        onChange={(date: any) => {
          if (!date) {
            onChange(null);
          } else {
            onChange(date.toDate());
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        clearable={clearable}
        showTodayButton
        variant="dialog"
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon style={{ opacity: 0.7 }} />
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerControl;
