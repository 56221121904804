import * as EmailValidator from "email-validator";

export default class FormValidators {
  static none = () => {
    return undefined;
  };

  static required = (message = "") => (value: string) => {
    let returnMessage = "This field is required";
    if (message !== "") {
      returnMessage = message;
    }
    return value ? undefined : returnMessage;
  };

  static isEmail(value: string) {
    return EmailValidator.validate(value) ? undefined : "Please enter a valid email.";
  }

  static isURL(value: string) {
    if (!value) {
      return undefined;
    }
    // eslint-disable-next-line
    let regex = /^((http(s)?|ftp)(:\/\/))?([www])?\.?[a-zA-Z0-9-_\.]+(\.[a-zA-Z0-9]{2,})([-a-zA-Z0-9:%_\+.~#?&//=]*)/gi;
    return regex.test(String(value)) ? undefined : "This is not an valid URL address";
  }

  static minValue = (length: number, message = "") => (value: string) => {
    let returnMessage = `Field must be at least ${length} characters`;
    if (message !== "") {
      returnMessage = message;
    }

    if (value === undefined) {
      return undefined;
    } else {
      return value.length < length ? returnMessage : undefined;
    }
  };

  static maxValue = (length: number, message = "") => (value: string) => {
    let returnMessage = `Field must be ${length} characters maximum`;
    if (message !== "") {
      returnMessage = message;
    }

    if (value === undefined) {
      return undefined;
    } else {
      return value.length > length ? returnMessage : undefined;
    }
  };

  static isValidJson(value: string) {
    if (!value) {
      return undefined;
    }
    try {
      JSON.parse(value);
      return undefined;
    } catch {
      return "The object is invalid json";
    }
  }

  static composeValidators = (...validators: Array<(value: string) => void>) => (value: string) =>
    validators.reduce((error, validator) => error || validator(value), undefined);
}
