import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import * as moment from "moment";
import * as _ from "lodash";

import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import FlagIcon from "@material-ui/icons/Flag";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CommentIcon from "@material-ui/icons/Comment";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import withBreadcrumb from "src/components/withBreadcrumb";
import RTCGrid, { FilterValue } from "src/components/Grid";
import ApiPath from "src/constants/ApiPath";
import { TestRunDetails, DrillDownToTest } from "src/constants/RoutesNames";
import { Statuses } from "src/constants/TestStatus";
import { testStatusHelper } from "src/helpers/testStatusHelper";
import { dispatchDataLoading } from "src/actions/dataLoadingActions";
import { FetchTestsDistinct } from "src/actions/dictionaryAction";
import SocketService from "src/services/SocketService";
import { orderBy } from "lodash";
import DrillDownToTestType from "src/constants/DrillDownToTestType";

interface ITestsHistoryDispatch {
  fetchTestsDistinct(): void;
  dataLoading(flag: boolean): any;
}

interface ITestRunHistory {
  testRuns: string[];
  projectId: string;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      overflowX: "auto",
    },
    tableRowItemHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
        "& button": {
          visibility: "visible",
          pointerEvents: "all",
        },
      },
    },
    hoverActionBtnStyle: {
      padding: "0px",
      width: 35,
      minWidth: 35,
      height: 35,
      minHeight: 35,
      visibility: "hidden",
      pointerEvents: "none",
    },
    statusCellContainer: {
      display: "flex",
    },
    bookmarkIcon: {
      color: "#a22a21",
    },
    commentIcon: {
      color: "#3577c1",
    },
    nameCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      pointerEvents: "none",
    },
  })
);

const statusFilterValues = [
  { value: "", label: "Any Result" },
  { value: Statuses.warnings, label: "Warnings" },
  { value: Statuses.error, label: "Error" },
  { value: Statuses.failure, label: "Failure" },
  { value: Statuses.timeout, label: "Timeout" },
  { value: Statuses.completed, label: "Completed" },
  { value: Statuses.serviceFailure, label: "Service failure" },
  { value: Statuses.terminated, label: "Terminated" },
  { value: Statuses.started, label: "Started" },
  { value: Statuses.retry, label: "Retry" },
  { value: Statuses.dismissed, label: "Dismissed" },
];

const createDateFilterValues = [
  { value: "", label: "Any date" },
  { value: "0", label: "Today" },
  { value: "7", label: "Last 7 days" },
  { value: "30", label: "Last 30 days" },
];

const TestRunHistory = (
  props: ITestRunHistory & RouteComponentProps<{}> & ITestsHistoryDispatch
) => {
  const gridRef = React.useRef(null);

  const classes = useStyles();
  const [testRunFilters, setTestRunFilters] = React.useState<FilterValue[]>([]);

  React.useEffect(() => {
    props.fetchTestsDistinct();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const filters: FilterValue[] = [
      { value: "", label: "Any test" },
      ...props.testRuns.map(
        (f): FilterValue => {
          return { value: f, label: f };
        }
      ),
    ];
    const sorted_filters = orderBy(filters, ["value"]);

    setTestRunFilters(sorted_filters);
    // eslint-disable-next-line
  }, [props.testRuns]);

  React.useEffect(() => {
    console.log("TestRunHistory: subscribeToUpdates");
    subscribeToUpdates();

    return () => {
      console.log("unsubscribeFromUpdates");
      unsubscribeFromUpdates();
    };
    // eslint-disable-next-line
  }, [props.projectId]);

  const columnSchema: ColumnSchema[] = [
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      style: { maxWidth: "5%" },
      labelRender: () => <FlagIcon />,
      render: (dataItem: any) => {
        const tooltip = dataItem.comments || dataItem.iterationComments;
        return (
          <div className={classes.statusCellContainer}>
            {testStatusHelper(dataItem.status)}
            {dataItem.flag && <BookmarkIcon className={classes.bookmarkIcon} />}
            {tooltip && (
              <Tooltip title={tooltip} placement="right-end">
                <CommentIcon className={classes.commentIcon} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      style: { maxWidth: 230 },
      render: (dataItem: any) => (
        <div className={classes.nameCell}>
          <span>{dataItem.name}</span>
          <div>
            <Tooltip title="Open link in new tab">
              <IconButton
                className={classes.hoverActionBtnStyle}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  const concurrentUsers = dataItem.parameters.concurrentUsers;
                  if (concurrentUsers === 1) {
                    window.open(
                      `${DrillDownToTest}/${dataItem._id}/${DrillDownToTestType.TRTC}`,
                      "_blank"
                    );
                  } else {
                    window.open(`${TestRunDetails}/${dataItem._id}`, "_blank");
                  }
                  e.stopPropagation();
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      id: "parameters.concurrentUsers",
      numeric: true,
      disablePadding: true,
      label: "Probes",
      isObject: true,
      style: { maxWidth: "5%", width: 5, padding: "0px 5px" },
      hideDown: "sm",
    },
    {
      id: "rank",
      numeric: true,
      disablePadding: true,
      label: "Score",
      isObject: true,
      style: { maxWidth: "10%", padding: "0px 5px", textAlign: "right" },
      hideDown: "md",
      render: (rank: any) => {
        return rank ? _.round(rank, 1) : "0";
      },
    },
    {
      id: "createDate",
      numeric: false,
      disablePadding: true,
      label: "Time",
      render: (dataItem: any) =>
        dataItem.createDate ? moment(dataItem.createDate).format("MMM DD, YYYY - HH:mm") : "never",
      style: {
        whiteSpace: "nowrap",
        maxWidth: "10%",
        padding: "0px 10px",
        paddingRigh: 5,
      },
      hideDown: "xs",
    },
    {
      id: "textError",
      numeric: false,
      disablePadding: false,
      label: "Reason",
      style: { wordWrap: "break-word", padding: "0 2px", wordBreak: "break-all", maxWidth: 350 },
      hideDown: "xs",
      render: (dataItem: any) => {
        const textError = dataItem.textError ? `${dataItem.textError.substring(0, 300)}` : "";
        const rtcSetAdditionalInfo = dataItem.rtcSetAdditionalInfo
          ? `${dataItem.rtcSetAdditionalInfo.substring(0, 300)}`
          : "";
        return textError.concat(rtcSetAdditionalInfo).substring(0, 300);
      },
    },
    {
      id: "runName",
      numeric: false,
      disablePadding: false,
      label: "Machines",
      style: { maxWidth: "20%", textAlign: "left", paddingLeft: 5 },
      hideDown: "sm",
    },
  ];

  const onRowClick = (_e: React.MouseEvent<HTMLTableRowElement>, dataItem: any) => {
    const concurrentUsers = dataItem.parameters?.concurrentUsers;
    if (concurrentUsers === 1) {
      props.history.push(`${DrillDownToTest}/${dataItem._id}/${DrillDownToTestType.TRTC}`);
    } else {
      props.history.push(`${TestRunDetails}/${dataItem._id}`);
    }
  };

  const subscribeToUpdates = () => {
    const messageType = "testrun.update.inProject" + props.projectId;
    SocketService._instance.on(
      messageType,
      (message: any): Promise<any> => {
        const newRunStatus = message.runStatus || message.testRunStatus;
        const testRunId = message.testRunId;

        if (gridRef.current) {
          const gridData = (gridRef.current as any).getData?.docs;

          if (gridData?.length > 0) {
            const existingRecord = (gridData as any[]).find((x) => x._id === testRunId);

            // which means test is just started and not added to table yet
            if (!existingRecord && newRunStatus === "started") {
              (gridRef.current as any).reloadData(true);

              // which means test is in a table and we need to refresh if status has changed.
              // e.g. started -> terminated
            } else if (existingRecord && existingRecord.status !== newRunStatus) {
              (gridRef.current as any).reloadData(true);
            }
          }
        }

        return Promise.resolve();
      }
    );
    SocketService._instance.subscribe(messageType);
  };

  const unsubscribeFromUpdates = () => {
    const messageType = "testrun.update.inProject" + props.projectId;
    SocketService._instance.unsubscribe(messageType);
    SocketService._instance.off(messageType);
  };

  return (
    <Paper className={classes.paper}>
      <RTCGrid
        ref={gridRef}
        columnSchema={columnSchema}
        location={props.location}
        remoteDataBound={true}
        search={true}
        pagination={true}
        enableUrlPaging={true}
        apiRoute={ApiPath.api.testRuns}
        exportUrl={ApiPath.api.testRunsExport}
        searchByLabel={"name/machine/reason"}
        rowProps={{
          className: classes.tableRowItemHover,
        }}
        defaultSort={{
          order: "desc",
          orderBy: "createDate",
        }}
        filters={[
          {
            fieldName: "status",
            filterLabel: "Result",
            filterValues: statusFilterValues,
            value: "",
            renderIcon: (status: string) => testStatusHelper(status, false),
          },
          {
            fieldName: "name",
            filterLabel: "Test",
            filterValues: testRunFilters,
            value: "",
          },
          {
            fieldName: "createDate",
            filterLabel: "Date",
            filterValues: createDateFilterValues,
            value: "",
          },
        ]}
        onRowClick={onRowClick}
        bottomNavigation
      />
    </Paper>
  );
};

const mapDispatchToProps = (dispatch: any): ITestsHistoryDispatch => ({
  fetchTestsDistinct: () => dispatch(FetchTestsDistinct()),
  dataLoading: (flag: boolean) => dispatch(dispatchDataLoading(flag)),
});

const mapStateToProps = (state: IStore) => ({
  testRuns: state.dictionary.testRuns,
  projectId: state.userAuth.user.project._id,
});

const connected = connect(mapStateToProps, mapDispatchToProps)(TestRunHistory);

export default withBreadcrumb(connected);
