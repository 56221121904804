// tslint:disable:no-any
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Support as SupportRoute } from "../../constants/RoutesNames";
import withBreadcrumb from "src/components/withBreadcrumb";
import * as ReactDOM from "react-dom";

export interface UserStoreProps {
  user: User;
}

export interface SupportState {
  subject: string;
}

class Support extends React.Component<UserStoreProps & RouteComponentProps<{}>, SupportState> {
  constructor(props: UserStoreProps & RouteComponentProps<{}>) {
    super(props);

    this.state = {
      subject: "",
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.location.state) {
      const state: any = this.props.location.state;
      if (state.subject) {
        this.setState({
          subject: state.subject,
        });
      }
    }
    this.props.history.replace({ pathname: SupportRoute, state: {} });
  }

  resizeIframe = () => {
    setTimeout(() => {
      // eslint-disable-next-line react/no-find-dom-node
      const obj = ReactDOM.findDOMNode(this) as any;
      obj.style.height = window.innerHeight - 50 - 72 - 24 - 40 + "px";
    }, 200);
  };

  public render(): JSX.Element {
    return (
      <div>
        <iframe
          title="Feedback Form"
          scrolling="no"
          // height="500px"
          height="100%"
          width="100%"
          frameBorder="0"
          onLoad={this.resizeIframe}
          src={`https://testrtc.com/ticket-embed/?email=${this.props.user.email}`}
          // src={'https://testrtc.com/ticket-embed/'} // TODO Prepared link to replace. Task #3867
          // TODO Down - old support embed
          // src={`https://testrtc.freshdesk.com/widgets/feedback_widget/new?
          // &widgetType=embedded
          // &formTitle=Submit+a+ticket
          // &submitThanks=We+have+received+your+request,+we+will+contact+you+shortly.
          // &screenshot=no
          // &searchArea=no
          // &helpdesk_ticket[requester]=${this.props.user !== null && this.props.user.email}
          // &helpdesk_ticket[subject]=${this.state.subject}&disable[requester]=true`}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

const connected = connect<UserStoreProps>(mapStateToProps)(Support);

export default withBreadcrumb(connected);
