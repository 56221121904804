import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Theme, withStyles, WithStyles, Typography, createStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ExitToApp from "@material-ui/icons/ExitToApp";
import AuthService from "../../../services/AuthService";
import { Login as LoginRoutes } from "../../../constants/RoutesNames";

type StyledComponent = WithStyles<"root" | "logOutIcon" | "text">;

class Logout extends React.Component<RouteComponentProps<{}> & StyledComponent> {
  public render(): JSX.Element {
    return (
      <Button
        onClick={() => this.logOut()}
        color="primary"
        data-cy="logout-btn"
        className={this.props.classes.root}
      >
        <ExitToApp className={this.props.classes.logOutIcon} />
        <Typography variant="body2" color="primary" className={this.props.classes.text}>
          Logout
        </Typography>
      </Button>
    );
  }

  private logOut(): void {
    AuthService.logout();
    this.props.history.push(LoginRoutes.SignIn);
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      [_theme.breakpoints.down("xs")]: {
        minWidth: 40,
        padding: 0,
      },
    },
    logOutIcon: {
      [_theme.breakpoints.up("xs")]: {
        marginRight: 5,
      },
    },
    text: {
      fontWeight: 500,
      letterSpacing: "0.02857em",
      [_theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}>>(decorate(Logout));
