import * as React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import View from "./View";
import Confirm from "../../../../components/Dialog/Confirm";
import dbUtils from "../../../../services/DbUtilsService";
import AxiosFactory from "../../../../services/AxiosFactory";
import ApiPath from "../../../../constants/ApiPath";
import { SetNotification } from "../../../../actions/notificationAction";

export type TestExecustionProps = {
  runStatus?: Array<RunStatus>;
  test: Test;
};

export type TestExecustionState = {
  cIsDisabled: boolean;
  confirmDeleteOpened: boolean;
  notifSent: boolean;
};

export type AppStoreProps = {
  user: User;
};

export type DispatchProps = {
  setNotification: (message: string) => void;
};

export class TestExecustionStatus extends React.Component<
  TestExecustionProps & DispatchProps & AppStoreProps,
  TestExecustionState
> {
  columnSchema: Array<ColumnSchema> = [
    { id: "name", numeric: false, disablePadding: false, label: "Machine", style: { width: 200 } },
    {
      id: "textStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "sessionName",
      numeric: true,
      disablePadding: false,
      label: "Session",
      style: { textAlign: "left" },
    },
    {
      id: "vnc",
      numeric: true,
      disablePadding: false,
      label: "",
      style: { textAlign: "left" },
      // tslint:disable-next-line:no-any
      render: (dataItem: any) => {
        if (dataItem.agentInfo && dataItem.agentInfo.vnc) {
          return (
            <CopyToClipboard
              text={dataItem.agentInfo.vnc}
              onCopy={() =>
                this.props.setNotification('VNC URL copied to clipboard, password is "secret"')
              }
            >
              <Button color="primary" variant="contained">
                VNC
              </Button>
            </CopyToClipboard>
          );
        } else {
          return <div />;
        }
      },
    },
  ];

  constructor(props: TestExecustionProps & AppStoreProps & DispatchProps) {
    super(props);

    this.state = {
      cIsDisabled: false,
      confirmDeleteOpened: false,
      notifSent: false,
    };

    this.handleNotify = this.handleNotify.bind(this);
    this.toggleCancelDialog = this.toggleCancelDialog.bind(this);
    this.onCancelTest = this.onCancelTest.bind(this);
  }

  toggleCancelDialog() {
    this.setState({
      cIsDisabled: false,
      confirmDeleteOpened: !this.state.confirmDeleteOpened,
    });
  }

  async onCancelTest() {
    try {
      const result = await dbUtils.rpc("test_runs", "stop", this.props.test._id);
      if (result.status === 200) {
        this.props.setNotification("Test cancellation started.");
        await this.setState({
          cIsDisabled: true,
          confirmDeleteOpened: false,
        });
      }
    } catch (e) {
      this.props.setNotification("Error occured.");
      this.setState({
        cIsDisabled: false,
      });
    }
  }

  async handleNotify() {
    const test = {
      ...this.props.test,
    };

    const email = this.props.user.email;

    if (test.emailsToSendNotification && test.emailsToSendNotification.length >= 0) {
      if (test.emailsToSendNotification.indexOf(email) === -1) {
        test.emailsToSendNotification.push(email);
      }
    } else {
      test.emailsToSendNotification = [email];
    }

    const axiosFactory = new AxiosFactory();

    try {
      const notifyResult = await axiosFactory.axios.put(
        `${ApiPath.api.testRuns}/${this.props.test._id}/notif`,
        test
      );
      if (notifyResult.status === 200) {
        this.props.setNotification("We will send you an email when this test run completes.");
        this.setState({
          notifSent: true,
        });
      }
    } catch (e) {
      this.props.setNotification("Error occured.");
    }
  }

  render() {
    return (
      <>
        <Confirm
          open={this.state.confirmDeleteOpened}
          title="Are you sure?"
          content="You will not be able to revert this action!"
          handleClose={this.toggleCancelDialog}
          handleConfirm={this.onCancelTest}
        />
        <View
          {...this.props}
          {...this.state}
          handleCancelTest={this.toggleCancelDialog}
          handleNotify={this.handleNotify}
          columnSchema={this.columnSchema}
        />
      </>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect<AppStoreProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(TestExecustionStatus);
