// tslint:disable:no-any

import * as React from "react";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import { Field, FieldRenderProps, Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import SelectFieldControl from "../form-elements/SelectFieldControl";
import { GridFilter } from "./index";
import { debounce, isEqual } from "lodash";
import TripleDateFilter from "./TripleDateFilter";

type StyledComponent = WithStyles<
  | "toolbarForm"
  | "searchField"
  | "filterField"
  | "unlabeledField"
  | "searchBtn"
  | "clearSearchBtn"
  | "searchNativeInput"
>;

export interface SearchToolbarProps {
  placeholder: string;
  onSubmit: (e: any) => void; // tslint:disable-line
  filters?: Array<GridFilter>;
  filter?: Array<IFilterServer>;
  search?: boolean;
  colSpan: number;
  searchStyle?: React.CSSProperties;
  searchValue?: string;
}

interface IFilterValue {
  [key: string]: string | number;
}

export interface ISearchToolbarInitialValues {
  filter?: Array<IFilterValue>;
  searchField?: string;
}

interface ISearchbarToolbarState {
  formInitialValues: ISearchToolbarInitialValues;
}

class SearchToolbar extends React.Component<
  SearchToolbarProps & StyledComponent,
  ISearchbarToolbarState
> {
  constructor(props: SearchToolbarProps & StyledComponent) {
    super(props);

    this.state = {
      formInitialValues: {},
    };
  }

  componentDidMount() {
    this.setData(this.props);
  }

  UNSAFE_componentWillReceiveProps(_nextProps: SearchToolbarProps & StyledComponent) {
    // this.setData(nextProps);
  }

  setData(props: any) {
    let initialValues: ISearchToolbarInitialValues = {
      searchField: props.searchValue,
    };

    const currentFilter = props.filter;
    const filterConfig =
      props.filters &&
      props.filters.map((f: any) => {
        const appliedFilter =
          currentFilter && currentFilter.find((cf: any) => cf.field === f.fieldName);
        return {
          ...f,
          value: appliedFilter ? appliedFilter.value : "",
        };
      });

    if (
      filterConfig !== undefined &&
      this.state.formInitialValues.filter === undefined &&
      filterConfig.length !== 0
    ) {
      initialValues = {
        ...initialValues,
        filter: [],
      };
      filterConfig.forEach((filter: any) => {
        if (filter.value === undefined && initialValues.filter !== undefined) {
          initialValues.filter.push({});
        } else if (initialValues.filter !== undefined) {
          const filterInitialValue = {};
          filterInitialValue[filter.fieldName] = filter.value;
          initialValues.filter.push(filterInitialValue);
        }
      });
    }
    this.setState({
      formInitialValues: initialValues,
    });
  }

  filtersAreEmpty(filters: Array<IFilterValue>) {
    return filters.every((filter) => {
      return Object.values(filter).every((value) => {
        return value === null || value === "";
      });
    });
  }

  render() {
    const { classes, onSubmit, placeholder, filters, search, searchStyle } = this.props;

    let submit: () => void;
    let change: (name: string, value?: any) => void;

    const debouncedSubmit = debounce(() => submit(), 200);

    return (
      <div>
        <Form
          onSubmit={onSubmit}
          initialValues={this.state.formInitialValues}
          mutators={{
            ...arrayMutators,
            setAutocompleteValue: (values, state, { changeValue }) => {
              const autocompleteFilter = filters?.find((x) => x.autocomplete === true);
              if (autocompleteFilter) {
                const index = filters?.indexOf(autocompleteFilter);
                changeValue(
                  state,
                  `filter[${index}].${autocompleteFilter.fieldName}`,
                  () => values[0].value
                );
              }
            },
          }}
          render={({ handleSubmit, form, values, mutators }) => {
            submit = handleSubmit;
            change = form.change;

            return (
              <form onSubmit={handleSubmit} className={classes.toolbarForm}>
                {filters !== undefined && (
                  <FieldArray name="filter">
                    {({ fields }) =>
                      fields.map((name, index) => {
                        if (filters[index].autocomplete) {
                          const Autocomplete = filters[index].component;
                          if (Autocomplete) {
                            return (
                              <div style={{ width: 250, marginRight: 8 }}>
                                <Autocomplete
                                  onChange={(e: any) => {
                                    mutators.setAutocompleteValue(e);
                                    submit();
                                  }}
                                  name={`${name}.${filters[index].fieldName}`}
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        }
                        if (filters[index].tripleDateFilter) {
                          return (
                            <TripleDateFilter
                              index={index}
                              classes={classes}
                              name={`${name}.${filters[index].fieldName}`}
                              fieldName={filters[index].fieldName}
                              label={filters[index].filterLabel || "Filter"}
                              options={filters[index].filterValues}
                            />
                          );
                        }
                        return (
                          <div className={classes.filterField} key={index}>
                            <Field
                              component={SelectFieldControl}
                              name={`${name}.${filters[index].fieldName}`}
                              label={filters[index].filterLabel || "Filter"}
                              emptyOption={false}
                              options={filters[index].filterValues}
                              renderIcon={filters[index].renderIcon}
                              disabled={filters[index].disabled}
                            />
                          </div>
                        );
                      })
                    }
                  </FieldArray>
                )}

                {search && (
                  <Field
                    type="text"
                    name={"searchField"}
                    label="Filed"
                    render={(props: FieldRenderProps) => (
                      <TextField
                        value={props.input.value}
                        onChange={props.input.onChange}
                        className={classes.searchField}
                        placeholder={placeholder}
                        style={searchStyle}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          className: classes.unlabeledField,
                          endAdornment: (
                            <InputAdornment position="end">
                              {props.input.value && (
                                <IconButton
                                  className={classes.clearSearchBtn}
                                  aria-label="clear"
                                  onClick={() => {
                                    change("searchField", "");
                                    submit();
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              )}
                              <IconButton
                                className={classes.searchBtn}
                                type="submit"
                                aria-label="search"
                              >
                                <Search />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          "data-cy": "search-toolbar",
                          className: classes.searchNativeInput,
                        }}
                      />
                    )}
                  />
                )}
                <FormSpy
                  subscription={{ values: true }}
                  onChange={(newFormState) => {
                    // do not submit because filters have not arrived yet
                    if (isEqual(newFormState.values, values)) {
                      return;
                    }
                    // do not submit because filters are empty
                    // so data will be the same
                    if (newFormState.values && newFormState.values.filter) {
                      // values.filter !== undefined of null means that
                      // is reset filter operation
                      if (this.filtersAreEmpty(newFormState.values.filter) && !values.filter) {
                        return;
                      }
                    }
                    // prevent fetching data on search text field change
                    // search field has Search button to fetch new data
                    if (!isEqual(newFormState.values, values)) {
                      if (newFormState.values.searchField !== values.searchField) {
                        return;
                      }
                    }
                    debouncedSubmit();
                  }}
                />
              </form>
            );
          }}
        />
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    toolbarForm: {
      height: 48,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingLeft: 24,
      paddingRight: 24,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 16,
        paddingRight: 16,
        flexDirection: "column",
        height: "unset",
        marginTop: 10,
      },
    },
    searchField: {
      width: "100%",
      minWidth: 148,
      margin: 0,
      display: "inline-flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        minWidth: 130,
      },
    },
    unlabeledField: {
      height: 26,
      fontSize: theme.typography.pxToRem(13),
      paddingRight: 4,
    },
    searchNativeInput: {
      padding: 4,
      paddingLeft: 16,
    },
    filterField: {
      display: "inline-flex",
      marginRight: theme.spacing(1),
      minWidth: 148,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginRight: 0,
        marginBottom: 5,
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: 130,
      },
    },
    datePickerFilterField: {
      marginRight: theme.spacing(1),
      height: 26,
      "& input": {
        padding: 4,
        fontSize: 13,
        height: 18,
        minWidth: 68,
      },
      "& svg": {
        height: 20,
      },
    },
    clearSearchBtn: {
      padding: "0px",
      width: 20,
      height: 25,
    },
    searchBtn: {
      padding: "0px",
      width: 25,
      height: 25,
    },
  });

const decorate = withStyles(styles);

export default decorate(SearchToolbar);
