import * as React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { useLegend } from "../hooks/useLegend";
import { useSeries } from "../hooks/useSeries";

interface ILegendItemProps {
  item: any;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    item: {
      "-webkit-column-break-inside": "avoid",
      "column-break-inside": "avoid",
      whiteSpace: "nowrap",
      display: "inline-block",
    },
    itemHidden: {
      "&:hover": {
        color: "#337ab7",
      },
    },
    itemContent: {
      display: "flex",
      alignItems: "center",
      paddingRight: 6,
    },
    label: {
      userSelect: "none",
      fontSize: 12,
      fontFamily: `"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif`,
      color: "#337ab7",
      "&:hover": {
        color: "#23527c",
      },
      cursor: "pointer",
    },
    labelHidden: {
      color: "#cccccc",
    },
    symbolHidden: {
      backgroundColor: "#cccccc !important",
    },
    symbol: {
      width: 12,
      height: 12,
      marginRight: 2,
    },
    line: {
      height: 2,
    },
  })
);

const LegendItem = ({ item }: ILegendItemProps) => {
  const classes = useStyles();
  const { columnWidth } = useLegend();
  const { toggle } = useSeries();

  const getSymbol = (item: any) => {
    switch (item.type) {
      case "column":
        return classes.symbol;
      case "line":
        return `${classes.symbol} ${classes.line}`;
      default:
        return classes.symbol;
    }
  };

  return (
    <div
      key={item.name}
      className={`${classes.item} ${!item.visible ? classes.itemHidden : ""}`}
      style={{
        columnWidth,
      }}
    >
      <div className={classes.itemContent}>
        <div
          onClick={() => toggle(item.name, !item.visible)}
          className={`${getSymbol(item)} ${!item.visible ? classes.symbolHidden : ""}`}
          style={{
            backgroundColor: item.options?.color || item.color,
          }}
        />
        <div
          className={`${classes.label} ${!item.visible ? classes.labelHidden : ""}`}
          onClick={() => toggle(item.name, !item.visible)}
        >
          {item.name}
        </div>
      </div>
    </div>
  );
};

export default LegendItem;
