import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { Theme, withStyles, createStyles, Typography, Toolbar, Divider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import View from "@material-ui/icons/RemoveRedEye";
import Tooltip from "../../components/Tooltip";
import RTCGrid from "../../components/Grid";
import ApiPath from "../../constants/ApiPath";
import { RouteComponentProps } from "react-router";
import * as moment from "moment";
import { connect } from "react-redux";
import { SetNotification } from "src/actions/notificationAction";
import { TestIteration, WebrtcInternalsAnalyzeRtc } from "../../constants/RoutesNames";
import { formatDuration } from "../../helpers/testDetails";

type DispatchProps = {
  setNotification: (message: string) => void;
};

type Props = {
  // tslint:disable-next-line: no-any
  classes: any;
};

export class GridControl extends React.Component<Props & RouteComponentProps<{}> & DispatchProps> {
  columnSchema: Array<ColumnSchema> = [
    {
      id: "fileName",
      numeric: false,
      disablePadding: false,
      label: "File Name",
      // tslint:disable-next-line: no-any
      render: (dataItem: any) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>{dataItem.fileName}</div>
            <Tooltip content="Advanced Analytics">
              <IconButton
                className={this.props.classes.hoverActionBtnStyle}
                onClick={(e) => this.onViewButtonClick(e, dataItem)}
              >
                <View />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      id: "createDate",
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: "Upload Time",
      // tslint:disable-next-line: no-any
      render: (dataItem: any) =>
        dataItem?.createDate
          ? moment(new Date(dataItem.createDate)).format("MMM DD, YYYY - HH:mm:ss")
          : "never",
    },
    {
      id: "rank",
      numeric: true,
      disablePadding: false,
      sortable: true,
      label: "Score",
    },
    {
      id: "mediaQuality",
      numeric: true,
      disablePadding: false,
      sortable: true,
      label: "Media Quality",
      style: { maxWidth: "15%", width: 15, padding: "0px 5px" },
    },
    {
      id: "stat.voiceStartTime",
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: "Start Time",
      // tslint:disable-next-line: no-any
      render: (dataItem: any) =>
        dataItem?.stat?.voiceStartTime
          ? moment(new Date(dataItem.stat.voiceStartTime)).format("MMM DD, YYYY - HH:mm:ss")
          : "never",
    },
    {
      id: "stat.voiceDuration",
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: "Duration",
      // tslint:disable-next-line: no-any
      render: (dataItem: any) =>
        dataItem?.stat?.voiceDuration
          ? formatDuration(dataItem.stat.voiceDuration, "DHMS")
          : "never",
    },
  ];

  constructor(props: Props & RouteComponentProps<{}> & DispatchProps) {
    super(props);

    this.state = {
      filters: [],
      testRuns: [],
    };

    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick(e: React.MouseEvent<HTMLTableRowElement>, dataItem: GridModel) {
    console.log(this.props);
    this.props.history.push(`${TestIteration}/${dataItem._id}`);
    e.stopPropagation();
  }

  // tslint:disable-next-line: no-any
  onViewButtonClick(e: React.MouseEvent<HTMLElement>, dataItem: any) {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.push(`${WebrtcInternalsAnalyzeRtc}/${dataItem._id}/${dataItem.fileName}`);
    return false;
  }

  render() {
    const { classes } = this.props;

    const createDateFilterValues = [
      { value: "", label: "Any" },
      { value: "0", label: "Today" },
      { value: "7", label: "Last 7 days" },
      { value: "30", label: "Last 30 days" },
    ];

    return (
      <Paper className={classes.root}>
        <Toolbar>
          <Typography>analyzeRTC History</Typography>
        </Toolbar>
        <Divider className={classes.divider} style={{ marginTop: 0 }} />
        <RTCGrid
          onRowClick={this.onRowClick}
          search={true}
          remoteDataBound={true}
          searchByLabel={"File name"}
          apiRoute={ApiPath.api.analyzeDataHistory}
          columnSchema={this.columnSchema}
          defaultSort={{
            order: "desc",
            orderBy: "createDate",
          }}
          filters={[
            {
              fieldName: "createDate",
              filterLabel: "Date",
              filterValues: createDateFilterValues,
              value: "7",
            },
          ]}
          rowProps={{
            className: classes.tableRowItemHover,
          }}
          pagination={true}
          // eslint-disable-next-line no-restricted-globals
          location={location}
        />
      </Paper>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
      marginTop: 20,
    },
    card: {},
    tableRowItemHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
        "& button": {
          visibility: "visible",
          pointerEvents: "all",
        },
      },
    },
    hoverActionBtnStyle: {
      padding: "0px",
      width: 35,
      minWidth: 35,
      height: 35,
      minHeight: 35,
      visibility: "hidden",
      pointerEvents: "none",
    },
  });

const decorate = withStyles(styles);

// tslint:disable-next-line: no-any
const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect(
  null,
  mapDispatchToProps
  // tslint:disable-next-line: no-any
)(decorate<any>(GridControl));
