// tslint:disable:no-any
import * as React from "react";
import { Theme, withStyles, WithStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Table, TableBody, TableCell, TablePagination, TableRow, Hidden } from "@material-ui/core";
import EnhancedTableHead, { ColumnSchemaHideDown } from "./EnhancedTableHead";
import { GridProps, GridState, GridHandlers } from "./index";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SearchToolbar from "./SearchToolbar";
import Grid from "@material-ui/core/Grid";
import GridPaginationActions from "./GridPaginationActions";
import { ReactSVG } from "react-svg";
import LoaderIcon from "../../assets/images/loading-progress.svg";

const PaginationRootComponent = (props: any) => <div {...props} />;

type StyledComponent = WithStyles<
  | "table"
  | "searchField"
  | "searchCell"
  | "noRowsCell"
  | "tableRowItem"
  | "searchBtn"
  | "progress"
  | "paginationSelectRoot"
  | "paginationSelect"
  | "paginationSelectIcon"
  | "paginationSpacer"
  | "paginationToolbar"
  | "paginationRoot"
  | "paginationRootContainer"
  | "toolbarCaption"
  | "exportBtn"
>;

type GridViewProps<T extends GridModel> = GridProps<T> & GridState<T> & GridHandlers;

class GridView<T extends GridModel> extends React.Component<GridViewProps<T> & StyledComponent> {
  render() {
    const {
      classes,
      columnSchema,
      data,
      sort,
      search,
      pagination,
      exportUrl,
      searchByLabel,
      searchStyle,
      filters,
      filter,
      dataPending,
      rowProps,
      hidePaginationWhenDataLessThan,
      hideHeader,
      rowsPerPageOptions,
      searchValue,
      bottomNavigation,
      onRequestSort,
      onChangePage,
      onChangeRowsPerPage,
      onSubmitFilterSearch,
      onRowClick,
      rowColorFunc,
      onExportClick,
    } = this.props;
    let conditionalHidePagination = false;
    if (hidePaginationWhenDataLessThan) {
      conditionalHidePagination =
        hidePaginationWhenDataLessThan > data.docs.length && data.page === 1;
    }
    const hideTableHead = hideHeader as boolean;

    const _rowsPerPageOptions = rowsPerPageOptions || [25, 50, 100];

    return (
      <React.Fragment>
        {(search || pagination) && (
          <Grid container={true}>
            {search && (
              <Grid item={true} xs={12} lg={7}>
                <SearchToolbar
                  onSubmit={onSubmitFilterSearch}
                  placeholder={`Search by ${searchByLabel}`}
                  colSpan={columnSchema.length / 2 + 1}
                  filters={filters}
                  filter={filter}
                  search={search}
                  searchStyle={searchStyle}
                  searchValue={searchValue}
                />
              </Grid>
            )}
            {pagination && !conditionalHidePagination && (
              <Grid item={true} xs={12} lg={search ? 5 : 12}>
                <TablePagination
                  classes={{
                    root: classes.paginationRoot,
                    selectRoot: classes.paginationSelectRoot,
                    select: classes.paginationSelect,
                    selectIcon: classes.paginationSelectIcon,
                    spacer: classes.paginationSpacer,
                    toolbar: classes.paginationToolbar,
                    caption: classes.toolbarCaption,
                  }}
                  component={PaginationRootComponent}
                  colSpan={columnSchema.length / 2 - 1}
                  count={data.totalDocs}
                  rowsPerPage={data.limit}
                  rowsPerPageOptions={_rowsPerPageOptions}
                  page={data.page - 1}
                  labelRowsPerPage={<span>Items</span>}
                  onChangePage={onChangePage as any}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  ActionsComponent={GridPaginationActions}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Table className={classes.table}>
          {!hideTableHead && (
            <EnhancedTableHead
              columnSchema={columnSchema}
              order={sort.order}
              orderBy={sort.orderBy}
              rowCount={data.totalDocs}
              onRequestSort={onRequestSort}
            />
          )}
          <TableBody>
            {data.docs.length > 0 ? (
              data.docs.map((model: T, i: number) => (
                <TableRow
                  onClick={(e: any) => (onRowClick ? onRowClick(e, model) : undefined)}
                  className={classes.tableRowItem}
                  key={i}
                  style={rowColorFunc !== undefined ? { background: rowColorFunc(model) } : {}}
                  {...rowProps}
                >
                  {columnSchema.map((column: ColumnSchema, index: number) => (
                    <Hidden
                      key={index}
                      lgDown={column.hideDown === ColumnSchemaHideDown.lg}
                      xlDown={column.hideDown === ColumnSchemaHideDown.xl}
                      mdDown={column.hideDown === ColumnSchemaHideDown.md}
                      smDown={column.hideDown === ColumnSchemaHideDown.sm}
                      xsDown={column.hideDown === ColumnSchemaHideDown.xs}
                    >
                      <TableCell
                        size="medium"
                        padding={column.disablePadding ? "none" : "default"}
                        align={column.numeric ? "right" : "left"}
                        style={column.style}
                      >
                        {this.cellRender(model, column, i)}
                      </TableCell>
                    </Hidden>
                  ))}
                </TableRow>
              ))
            ) : (
              <tr>
                <td className={classes.noRowsCell} colSpan={columnSchema.length}>
                  {!dataPending ? (
                    <Typography variant="body2" align="center">
                      No rows to show
                    </Typography>
                  ) : (
                    <ReactSVG src={LoaderIcon} className={classes.progress} />
                  )}
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
        {data.docs.length !== 0 && (
          <Grid
            container={true}
            spacing={0}
            justify={
              !exportUrl && bottomNavigation && data.totalDocs > _rowsPerPageOptions[0]
                ? "flex-end"
                : "space-between"
            }
          >
            {exportUrl && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.exportBtn}
                onClick={onExportClick}
                disabled={dataPending}
              >
                {dataPending ? "Exporting" : "Export"}
              </Button>
            )}
            {bottomNavigation && data.totalDocs > _rowsPerPageOptions[0] && (
              <TablePagination
                classes={{
                  root: classes.paginationRoot,
                  selectRoot: classes.paginationSelectRoot,
                  select: classes.paginationSelect,
                  selectIcon: classes.paginationSelectIcon,
                  spacer: classes.paginationSpacer,
                  toolbar: classes.paginationToolbar,
                  caption: classes.toolbarCaption,
                }}
                component={PaginationRootComponent}
                colSpan={columnSchema.length / 2 - 1}
                count={data.totalDocs}
                rowsPerPage={data.limit}
                rowsPerPageOptions={_rowsPerPageOptions}
                page={data.page - 1}
                labelRowsPerPage={<span>Items</span>}
                onChangePage={onChangePage as any}
                onChangeRowsPerPage={onChangeRowsPerPage}
                ActionsComponent={GridPaginationActions}
              />
            )}
          </Grid>
        )}
      </React.Fragment>
    );
  }

  private cellRender(model: T, column: ColumnSchema, rowIndex: number) {
    if (!column.isObject) {
      return column.render ? column.render(model, rowIndex) : model[column.id];
    } else {
      const value = column.id.split(".").reduce((a, b) => (a ? a[b] : null), model);
      return column.render ? column.render(value, rowIndex) : value;
    }
  }
}

const decorate = withStyles((theme: Theme): {
  [className: string]: CSSProperties;
} => ({
  table: {
    "& thead": {
      "& th": { color: "rgba(0, 0, 0, 0.54)", fontSize: theme.typography.pxToRem(12) },
    },
  },
  tableRowItem: {
    "& td": {
      fontSize: theme.typography.pxToRem(13),
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f7f7f7",
    },
  },
  searchField: {
    width: 245,
    marginRight: theme.spacing(1),
  },
  noRowsCell: {
    padding: theme.spacing(3),
    color: "rgba(0, 0, 0, 0.54)",
  },
  searchCell: {
    paddingRight: 0,
    minWidth: 373,
  },
  searchBtn: {
    width: 30,
    height: 30,
  },
  paginationRoot: {
    display: "flex",
    justifyContent: "flex-end",
    color: "rgba(0, 0, 0, 0.54)",
  },
  paginationSelectRoot: {
    marginRight: 8,
    paddingTop: 4,
  },
  paginationSelect: {
    paddingRight: 24,
    fontSize: theme.typography.pxToRem(12),
    minHeight: "1.5em",
  },
  paginationSelectIcon: {
    top: 4,
  },
  paginationSpacer: {
    flex: "unset",
  },
  paginationToolbar: {
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  toolbarCaption: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    letterSpacing: "0.01em",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    margin: `${theme.spacing(1)}px auto`,
    "& svg": {
      height: 60,
    },
  },
  exportBtn: {
    padding: "6px 15px",
    margin: 5,
  },
}));

export default decorate(GridView);
