// tslint:disable:no-any
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { TestIteration } from "../../../../constants/RoutesNames";
import { RunGroupParams } from "../../../TestRunGroup";
import { unGroupedColumnSchema } from "./testSessionsGridSchemas";
import { PropTypes, Theme, WithStyles, createStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import RTCGrid from "../../../../components/Grid";
import ApiPath from "../../../../constants/ApiPath";

interface GridMode {
  color: PropTypes.Color;
  apiPath: string;
  columnSchema: Array<ColumnSchema>;
}

interface ITestSessionsGridControlState {
  gridMode: GridMode;
}

interface ITestSessionsGridControlProps {
  testRunId: string;
  navigateBack: () => void;
  gridRef: any;
  remoteDataBound: boolean;
}

type StyledComponent = WithStyles<
  | "root"
  | "tableRowItemHover"
  | "hoverActionBtnStyle"
  | "modesContainer"
  | "sessionContainer"
  | "button"
>;

class TestSessionsGridControl extends React.Component<
  RouteComponentProps<RunGroupParams> & StyledComponent & ITestSessionsGridControlProps,
  ITestSessionsGridControlState
> {
  columnSchema: Array<ColumnSchema> = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Manual",
      style: { maxWidth: "5%" },
    },
  ];

  FilterIterationLink: string;

  constructor(
    props: RouteComponentProps<RunGroupParams> & StyledComponent & ITestSessionsGridControlProps
  ) {
    super(props);

    this.state = {
      gridMode: {
        color: "secondary",
        apiPath: `${ApiPath.api.testIterations}/${this.props.testRunId}/page`,
        columnSchema: unGroupedColumnSchema(this.props.classes.hoverActionBtnStyle),
      },
    };

    this.onRowClickUngrouped = this.onRowClickUngrouped.bind(this);
  }

  public render(): JSX.Element {
    const { classes, gridRef } = this.props;
    const { gridMode } = this.state;

    return (
      <div className={classes.sessionContainer}>
        <RTCGrid
          wrappedComponentRef={gridRef}
          onRowClick={this.onRowClickUngrouped}
          remoteDataBound={this.props.remoteDataBound}
          pagination={true}
          apiRoute={gridMode.apiPath}
          columnSchema={gridMode.columnSchema}
          defaultSort={{
            order: "asc",
            orderBy: "statusId,machine",
          }}
          hidePaginationWhenDataLessThan={25}
          rowProps={{
            className: classes.tableRowItemHover,
          }}
          rowColorFunc={this.rowColorUngroupped}
          rowsPerPage={50}
          rowsPerPageOptions={[50, 100, 200]}
          bottomNavigation={true}
        />
      </div>
    );
  }

  private rowColorUngroupped(model: any) {
    // tslint:disable-line
    const colourList = {
      "0": "#f6f9fc",
      "1": "#fefdf5",
      "2": "#fdf7f2",
    };

    const colIdx = model.sessionIdx % 3;

    return colourList[colIdx];
  }

  private onRowClickUngrouped(_e: React.MouseEvent<HTMLTableRowElement>, dataItem: GridModel) {
    this.props.history.push(`${TestIteration}/${dataItem._id}`);
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    tableRowItemHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
        "& button": {
          visibility: "visible",
          pointerEvents: "all",
        },
      },
    },
    hoverActionBtnStyle: {
      padding: "0px",
      width: 35,
      minWidth: 35,
      height: 35,
      minHeight: 35,
      visibility: "hidden",
      pointerEvents: "none",
    },
    button: {
      margin: theme.spacing(1) / 2,
    },
    modesContainer: {
      padding: theme.spacing(1),
    },
    sessionContainer: {
      overflowX: "auto",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(5),
      width: "100%",
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<RunGroupParams> & ITestSessionsGridControlProps>(
  decorate(TestSessionsGridControl)
);
