// tslint:disable:no-any
import * as React from "react";
import * as _ from "lodash";

import Widget from "./Components/Widget";
import Line from "./Components/Line";
import LinearProgress from "./Components/LinearProgress";

import * as moment from "moment";

export interface IWatchRTCData {
  onlineUsers: number;
  collectedMinutes: number;
  maxMinutes: number;
}
interface IWatchRTC {
  data?: IWatchRTCData;
}

const WatchRTC = ({ data }: IWatchRTC) => {
  if (!data) {
    return null;
  }

  const collectedMinutes = _.round(data.collectedMinutes / 1000, 0);
  const estimated = Math.ceil((collectedMinutes / moment().date()) * moment().daysInMonth());
  const startCycleDate = moment().startOf("month").format("Do MMM");
  return (
    <Widget title={"watchRTC"} marginBottom={8}>
      <Line
        label="Current connected users"
        value={[
          {
            value: data.onlineUsers,
          },
        ]}
      />
      <Line
        label="Monthly cycle date"
        value={{
          value: startCycleDate,
        }}
      />
      <Line
        label="Minutes collected (1000's)"
        value={[
          {
            value: collectedMinutes,
            tooltip: "Minutes collected (1000's)",
          },
          {
            value: data.maxMinutes,
            tooltip: "Maximum Minutes",
          },
        ]}
      />
      <LinearProgress value={(collectedMinutes / data.maxMinutes) * 100} />
      <Line
        label="Estimated for this cycle (1000's)"
        value={[
          {
            value: estimated,
          },
        ]}
      />
    </Widget>
  );
};

export default WatchRTC;
