// tslint:disable:no-any

import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import AnalyzeDumpForm from "./AnalyzeDumpForm";
import { Link } from "react-router-dom";
import ANALYZE_ICON from "../../../assets/images/analyze-icon.png";
import AxiosFactory from "../../../services/AxiosFactory";
import ApiPath from "../../../constants/ApiPath";
import { Login as LoginRoutes } from "../../../constants/RoutesNames";

type StyledComponent = WithStyles<"analyzeImage" | "link" | "analyzeContainer">;

type AnalyzeDumpState = {
  showConfirmationDialog: boolean;
  email: string;
  acceptedFiles: Array<any>;
  rejectedFiles: Array<any>;
  recaptchaKey: string;
};

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

class AnalyzeDumpPage extends React.Component<StyledComponent, AnalyzeDumpState> {
  formComponent = null;
  axiosFactory: AxiosFactory;
  initialState = {
    showConfirmationDialog: false,
    email: "",
    acceptedFiles: [],
    rejectedFiles: [],
    recaptchaKey: "",
  };

  constructor(props: StyledComponent) {
    super(props);

    this.state = {
      ...this.initialState,
    };

    this.axiosFactory = new AxiosFactory();

    this.onSubmit = this.onSubmit.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.onRecaptchaChange = this.onRecaptchaChange.bind(this);
  }

  public render(): JSX.Element {
    const { classes } = this.props;
    const { showConfirmationDialog, email } = this.state;

    return (
      <>
        <Grid container={true} direction="row" justify="center">
          <Grid item={true} lg={4} md={6} sm={8} xs={12} className={classes.analyzeContainer}>
            <img className={classes.analyzeImage} src={ANALYZE_ICON} alt="analyse-icon" />
            <Typography variant="h4" align="center">
              Analyze a log file
            </Typography>
            <br />
            <AnalyzeDumpForm
              onSubmit={this.onSubmit}
              handleDrop={this.handleDrop}
              onRecaptchaChange={this.onRecaptchaChange}
              {...this.state}
            />
          </Grid>
        </Grid>
        <Dialog
          open={showConfirmationDialog}
          TransitionComponent={Transition}
          keepMounted={true}
          onClose={this.onModalClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description-1"
        >
          <DialogTitle id="alert-dialog-slide-title">Upload Completed!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description-1">
              Thanks for uploading the log file.
              <br />
              We are currently preparing the report for you.
              <br />
              An email will be sent to {email} the moment it is ready.
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description-2">
              In the meantime, you can:
              <br />
              <ul>
                <li>Analyze another log file</li>
                <li>
                  <span>Visit testRTC web </span>
                  <a
                    target="_blank"
                    className={classes.link}
                    href="https://testrtc.com/"
                    rel="noopener noreferrer"
                  >
                    page
                  </a>
                </li>
                <li>
                  <Link className={classes.link} to={LoginRoutes.Subscribe}>
                    <Typography color="secondary" align="left">
                      Subscribe for updates
                    </Typography>
                  </Link>
                </li>
              </ul>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  private handleDrop(acceptedFiles: any, _rejectedFiles: any) {
    this.setState({
      acceptedFiles: [...acceptedFiles],
    });
  }

  private onModalClose() {
    this.setState({
      showConfirmationDialog: false,
    });
  }

  private onSubmit(values: any): Promise<void> | { [x: string]: string } {
    const errors = [];
    if (!!!this.state.acceptedFiles.length) {
      errors.push({
        file: "File required",
      });
    }
    if (this.state.acceptedFiles[0] && this.state.acceptedFiles[0].size / 1024 / 1024 > 5) {
      errors.push({
        fileSize: "File size should not exceed 5MB",
      });
    }
    if (!this.state.recaptchaKey) {
      errors.push({
        reCaptchaResponse: "reCaptcha Requierd",
      });
    }

    if (errors.length) {
      return errors.reduce((obj, e) => ({ ...obj, ...e }), {});
    }

    const formData = new FormData();

    const data = {
      ...values,
      reCaptchaResponse: this.state.recaptchaKey,
    };

    Object.keys(data).forEach((key: string) => formData.append(key, data[key]));
    formData.append("file", this.state.acceptedFiles[0]);

    return this.axiosFactory.axios
      .post(`${ApiPath.api.analyzeDatasFree}`, formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res: any) => {
        this.setState({
          ...this.initialState,
          showConfirmationDialog: true,
          email: values.email,
        });

        return res;
      });
  }

  private onRecaptchaChange(key: string) {
    this.setState({
      recaptchaKey: key,
    });
  }
}

const decorate = withStyles((theme) =>
  createStyles({
    analyzeImage: {
      display: "flex",
      margin: "auto",
    },
    link: {
      color: "#599647",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:active": {
        textDecoration: "none",
      },
    },
    analyzeContainer: {
      padding: theme.spacing(2),
    },
  })
);

export default decorate(AnalyzeDumpPage);
