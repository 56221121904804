export type DataType =
  | "bits"
  | "jitter"
  | "packets"
  | "loss"
  | "lossPCT"
  | "videoDelay"
  | "videoFrameRate"
  | "browserCpu"
  | "browserMemory";
export type MediaType = "audio" | "video" | "performance";
export type ByType = "time/probe" | "time/channel" | "probe";

export const byTypeArrayValues = {
  timeProbe: "time/probe",
  timeChannel: "time/channel",
  probe: "probe",
};
export const byTypeArray = [
  byTypeArrayValues.timeProbe,
  byTypeArrayValues.timeChannel,
  byTypeArrayValues.probe,
];

export const dataTypesValues = {
  bits: "bits",
  jitter: "jitter",
  packets: "packets",
  loss: "loss",
  lossPCT: "lossPCT",
  videoDelay: "videoDelay",
  videoFrameRate: "videoFrameRate",
  browserCpu: "browserCpu",
  browserMemory: "browserMemory",
};

export const mediaType = {
  audio: "audio",
  video: "video",
  performance: "performance",
};
export const medias = [mediaType.audio, mediaType.video, mediaType.performance];
export const mediaIcons = {
  [mediaType.audio]: "headset",
  [mediaType.video]: "videocam",
  [mediaType.performance]: "speed",
};

export const MediaTypeToName = {
  [mediaType.video]: "Video",
  [mediaType.audio]: "Audio",
  [mediaType.performance]: "Performance",
};

export const dataTypeToTitle = {
  [dataTypesValues.bits]: {
    title: "Outgoing and incoming {0} bitrate",
    yAxisLabel: "Kbits",
    label: "Bitrate",
  },
  [dataTypesValues.loss]: {
    title: "# of packet loss",
    yAxisLabel: "# of packets lost",
    label: "Packets loss (#)",
  },
  [dataTypesValues.lossPCT]: {
    title: "% of packet loss",
    yAxisLabel: "% of packets lost",
    label: "Packets loss (%)",
  },
  [dataTypesValues.jitter]: {
    title: "{0} jitter",
    yAxisLabel: "ms",
    label: "Jitter",
  },
  [dataTypesValues.packets]: {
    title: "Outgoing and incoming {0} packets",
    yAxisLabel: "Packets",
    label: "Packets",
  },
  [dataTypesValues.videoDelay]: {
    title: "Outgoing and incoming {0} RTT",
    yAxisLabel: "ms",
    label: "Round Trip Time",
  },
  [dataTypesValues.videoFrameRate]: {
    title: "Outgoing and incoming {0} Frame Rate",
    yAxisLabel: "fps",
    label: "Frame rate",
  },
  [dataTypesValues.browserCpu]: {
    title: "CPU Usage by browser",
    yAxisLabel: "%",
    label: "By Browser CPU",
  },
  [dataTypesValues.browserMemory]: {
    title: "Memory Usage by browser",
    yAxisLabel: "MBytes",
    label: "By Browser Memory",
  },
};

export const labels = ["Incoming", "Outgoing"];

export const chartGroup = {
  [byTypeArrayValues.timeChannel]: {
    bitrate: {
      audio: [dataTypesValues.bits],
      video: [dataTypesValues.bits],
    },
    quality: {
      audio: [
        dataTypesValues.jitter,
        dataTypesValues.videoDelay,
        dataTypesValues.lossPCT,
        dataTypesValues.loss,
      ],
      video: [
        dataTypesValues.jitter,
        dataTypesValues.videoDelay,
        dataTypesValues.lossPCT,
        dataTypesValues.loss,
        dataTypesValues.videoFrameRate,
      ],
    },
  },
  [byTypeArrayValues.timeProbe]: {
    bitrate: {
      audio: [dataTypesValues.bits],
      video: [dataTypesValues.bits],
      performance: [dataTypesValues.browserCpu],
    },
    quality: {
      audio: [
        dataTypesValues.jitter,
        dataTypesValues.videoDelay,
        dataTypesValues.lossPCT,
        dataTypesValues.loss,
      ],
      video: [
        dataTypesValues.jitter,
        dataTypesValues.videoDelay,
        dataTypesValues.lossPCT,
        dataTypesValues.loss,
        dataTypesValues.videoFrameRate,
      ],
      performance: [dataTypesValues.browserMemory],
    },
  },
  [byTypeArrayValues.probe]: {
    bitrate: {
      audio: [dataTypesValues.bits],
      video: [dataTypesValues.bits],
      performance: [dataTypesValues.browserCpu],
    },
    quality: {
      audio: [
        dataTypesValues.jitter,
        dataTypesValues.videoDelay,
        dataTypesValues.lossPCT,
        dataTypesValues.loss,
      ],
      video: [
        dataTypesValues.jitter,
        dataTypesValues.videoDelay,
        dataTypesValues.lossPCT,
        dataTypesValues.loss,
        dataTypesValues.videoFrameRate,
      ],
      performance: [dataTypesValues.browserMemory],
    },
  },
};

export const chartHeights = {
  Compact: 0.6,
  Normal: 0.8,
  Large: 1,
};

export const chartHeightsArray = [chartHeights.Compact, chartHeights.Normal, chartHeights.Large];
export const chartHeightsLabels = {
  [chartHeights.Compact]: "Compact View",
  [chartHeights.Normal]: "Normal View",
  [chartHeights.Large]: "Large View",
};
export const nextChartHeight = {
  [chartHeights.Compact]: chartHeights.Normal,
  [chartHeights.Normal]: chartHeights.Large,
  [chartHeights.Large]: chartHeights.Compact,
};
