import * as React from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { numberWithCommas } from "src/helpers/numbers";

export interface IICECandidatesTable {
  name: string;
  bytesSent: number;
  bytesReceived: number;
  dtlsState: string;
  selectedCandidatePairId: string;
  channels: Array<Channel>;
}

export interface Channel {
  state: string;
  bytesSent: number;
  bytesReceived: number;
  localType: string;
  remoteType: string;
  localProtocol: string;
  remoteProtocol: string;
  localRelayProtocol: string;
  remoteRelayProtocol: string;
  localPort: number;
  remotePort: number;

  // expanded
  pairIdName: string;
  localIdName: string;
  remoteIdName: string;
  localIpAddress: string;
  remoteIpAddress: string;
  rtt: number;
  priority: number;
}

interface ICECandidatesTables {
  data: Array<IICECandidatesTable>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      overflow: "visible",
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      marginTop: theme.spacing(2),
    },
    contentContainer: {
      padding: 10,
    },
    expansionPanel: {
      width: "100%",
      display: "block",
    },
    expandHead: {
      "& div": {
        justifyContent: "space-between",
      },
    },
    expandHeadFist: {
      display: "inline-flex",
      alignItems: "center",
    },
    expandHeadSecond: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    expandDetailHead: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "space-between",
      },

      "& p": {
        margin: 0,
      },
    },
    resultTableHead: {
      marginBottom: 5,
    },
    pairId: {
      overflow: "visible",
      textOverflow: "ellipsis",
    },
    center: {
      textAlign: "center",
    },
    xsRight: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "right",
      },
    },
  })
);

const colors = {
  waiting: "rgb(241, 205, 43)",
  "in-progress": "rgb(221, 113, 39)",
  succeeded: "rgb(85, 149, 66)",
  failed: "rgb(162, 42, 33)",
};

const ExpandChannelDetails = (props: any) => {
  const channel: Channel = props.channel;
  const state = channel.state;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(state === "succeeded");

  channel.pairIdName = channel.pairIdName.replace("RTCIceCandidatePair_", "");
  channel.localIdName = channel.pairIdName.replace("RTCIceCandidate_", "");
  channel.remoteIdName = channel.pairIdName.replace("RTCIceCandidate_", "");

  const kBytesSent = numberWithCommas(Math.ceil(channel.bytesSent / 1000));
  const kBytesReceived = numberWithCommas(Math.ceil(channel.bytesReceived / 1000));

  return (
    <Accordion
      style={{
        borderLeft: `7px solid ${colors[state]}`,
      }}
      className={classes.expansionPanel}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary className={classes.expandHead} expandIcon={<ExpandMoreIcon />}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} md={2} className={classes.pairId}>
            {channel.pairIdName}
          </Grid>
          <Grid item={true} xs={3} md={2} className={classes.center}>
            {channel.state}
          </Grid>
          <Hidden smDown={true}>
            <Grid item={true} xs={3} className={classes.center}>
              {kBytesSent}/{kBytesReceived}
            </Grid>
          </Hidden>
          <Grid item={true} xs={2}>
            {channel.localType}/{channel.localRelayProtocol || channel.localProtocol}
          </Grid>
          <Grid item={true} xs={2}>
            {channel.remoteType}/{channel.remoteRelayProtocol || channel.remoteProtocol}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={true} spacing={2} justify="space-between">
          <Grid item={true} xs={12} md={4} className={classes.expandDetailHead}>
            <Typography className={classes.resultTableHead} variant="subtitle1">
              {"Local"}
            </Typography>
            <div className={classes.xsRight}>
              <p>{channel.localIdName}</p>
              <p>
                {channel.localIpAddress}:{channel.localPort}
              </p>
              <p>
                {channel.localType}/{channel.localRelayProtocol || channel.localProtocol}
              </p>
            </div>
          </Grid>
          <Grid item={true} xs={12} md={4} className={classes.expandDetailHead}>
            <Typography className={classes.resultTableHead} variant="subtitle1">
              {"Remote"}
            </Typography>
            <div className={classes.xsRight}>
              <p>{channel.remoteIdName}</p>
              <p>
                {channel.remoteIpAddress}:{channel.remotePort}
              </p>
              <p>
                {channel.remoteType}/{channel.remoteRelayProtocol || channel.remoteProtocol}
              </p>
            </div>
          </Grid>
          <Grid item={true} xs={12} md={4} className={classes.expandDetailHead}>
            <Typography className={classes.resultTableHead} variant="subtitle1">
              {"Stats"}
            </Typography>
            <div className={classes.xsRight}>
              <div>
                <p>Sent: {kBytesSent} kb</p>
                <p>Recv: {kBytesReceived} kb</p>
                <p>Rtt: {channel.rtt * 1000} ms</p>
                <p>Priority: {channel.priority}</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const ICECandidatesTables = ({ data }: ICECandidatesTables) => {
  const classes = useStyles();
  const isData = (table: IICECandidatesTable) => table.bytesSent !== 0;
  return (
    <>
      {data.map((table: IICECandidatesTable, idx: number) => {
        return (
          <Grid key={idx} container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Card>
                <Toolbar className={classes.toolbar}>
                  <Typography variant="subtitle1">
                    ICE Candidate Pairs: {table.name} {!isData(table) && ": Empty"}
                  </Typography>
                  {isData(table) && (
                    <>
                      <Typography variant="caption">State: {table.dtlsState}</Typography>
                      <Typography variant="caption">
                        Sent: {numberWithCommas(Math.ceil(table.bytesSent / 1000))} kb
                      </Typography>
                      <Typography variant="caption">
                        Received: {numberWithCommas(Math.ceil(table.bytesReceived / 1000))} kb
                      </Typography>
                    </>
                  )}
                </Toolbar>
                {isData(table) && (
                  <>
                    <Divider className={classes.divider} />
                    <Grid className={classes.contentContainer} container={true} spacing={0}>
                      {table.channels.map((channel, i) => (
                        <ExpandChannelDetails key={i} channel={channel} />
                      ))}
                    </Grid>
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default ICECandidatesTables;
