import { createStyles, Theme, WithStyles } from "@material-ui/core";

export type Styles = WithStyles<
  | "log"
  | "wrapper"
  | "virtuoso"
  | "logRow"
  | "logRowSearch"
  | "rowLabel"
  | "rowMessage"
  | "terminalLog"
  | "treeView"
  | "spinnerWrapper"
  | "spinnerWrapperVisible"
  | "spacer"
  | "rowDuration"
  | "searchActions"
  | "resultsCount"
  | "expandButton"
>;

const styles = (_theme: Theme) =>
  createStyles({
    searchActions: {
      position: "absolute",
      right: "20px",
      bottom: "20px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      backgroundColor: "#fff",
    },
    resultsCount: {
      display: "flex",
      border: "1px solid",
      height: "36px",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "100px",
      fontSize: "18px",
      fontWeight: "bold",
    },
    spacer: {
      height: "16px",
      color: "#ccc",
      padding: "0px 10px",
    },
    spinnerWrapper: {
      pointerEvents: "none",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginLeft: "-20px",
      marginTop: "-22px",
      opacity: 0,
    },
    spinnerWrapperVisible: {
      opacity: 1,
    },
    log: {
      paddingLeft: 10,
      margin: 0,
      width: "100%",
      height: "100%",
      lineHeight: "16px",
      fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace`,
      fontSize: "11px",
    },
    terminalLog: {
      paddingLeft: 10,
      margin: 0,
      width: "100%",
      height: "100%",
      lineHeight: "16px",
      backgroundColor: "#000",
      color: "#fff",
      whiteSpace: "pre-wrap",
      fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace`,
      fontSize: "11px",
    },
    treeView: {
      paddingLeft: 10,
    },
    wrapper: {
      height: "100%",
      position: "relative",
      overflow: "auto",
    },
    virtuoso: {
      width: "100%",
    },
    logRow: {
      display: "flex",
    },
    logRowSearch: {
      backgroundColor: "yellow",
    },
    rowDuration: {
      minWidth: 80,
      fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace`,
      fontSize: "11px",
      lineHeight: "16px",
      padding: "0px 10px",
      boxSizing: "border-box",
    },
    rowLabel: {
      minWidth: 140,
      fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace`,
      fontSize: "11px",
      lineHeight: "16px",
      padding: "0px 10px",
      boxSizing: "border-box",
    },
    rowMessage: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    expandButton: {
      marginLeft: "-10px",
      padding: "0px",
    },
  });

export default styles;
