import * as moment from "moment";

export const dateParse = (str: string, time = "00:00") => {
  if (str.includes("-")) {
    return new Date(str + " " + time);
  } else {
    const y = str.substr(0, 4),
      m = str.substr(4, 2),
      d = str.substr(6, 2);
    return new Date(y + "/" + m + "/" + d + " " + time);
  }
};

export const showFreeDate = (date: string | undefined) => {
  if (date) {
    if (moment(date).isValid()) {
      return moment(date).format("Do MMM");
    }

    if (date.includes("-")) {
      return date;
    }

    const _tempDate = date;
    const _date = dateParse(_tempDate).toDateString();
    const _day = _date.substr(8, 2);

    if (_day[1] === "1") {
      return parseInt(_day, 0) + "st";
    } else if (_day[1] === "2") {
      return parseInt(_day, 0) + "nd";
    } else {
      return parseInt(_day, 0) + "th";
    }
  } else {
    return "none";
  }
};

export const calculateLastUpdateTime = (lastUpdateDate: string, lastUpdateTime: string) => {
  const parsedLastUpdateDate = moment(lastUpdateDate).format("YYYY-MM-DD");

  const lastUpdatedDateTime = moment
    .utc(`${parsedLastUpdateDate} ${lastUpdateTime}`)
    .local()
    .format("YYYY-MM-DD HH:mm");

  // diff in minutes
  let minutes = moment(new Date()).diff(moment(lastUpdatedDateTime), "m");
  if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (minutes < 60 * 24) {
    minutes = Math.floor(minutes / 60);
    return `${minutes} ${minutes === 1 ? "hour" : "hours"} ago`;
  } else {
    minutes = Math.floor(minutes / 60 / 24);
    return `${minutes} ${minutes === 1 ? "day" : "days"} ago`;
  }
};

export const showPaidDate = (date: string | undefined) => {
  if (date) {
    if (date.includes("-")) {
      return date;
    }

    const _tempDate = date;
    const _date = dateParse(_tempDate).toDateString();
    const _day = _date.substr(8, 2);
    const _month = _date.substr(4, 3);
    const _year = _date.substr(13, 2);

    return _day + "-" + _month + "-" + _year;
  } else {
    return "none";
  }
};

export const numberWithCommas = (x: string | number) => {
  if (x.toString().indexOf(",") !== -1 || x.toString().indexOf(".") !== -1) {
    return x;
  }
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
