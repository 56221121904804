// tslint:disable:no-any
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { PerformanceState } from "./index";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import Chart from "../../../components/Chart";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { ReactSVG } from "react-svg";
import LoaderIcon from "../../../assets/images/loading-progress.svg";

const View = (
  props: PerformanceState & WithStyles<"card" | "divider" | "toolbar" | "circularProgress">
) => {
  return (
    <div>
      {props.performanceData && (
        <Card className={props.classes.card}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} md={6}>
              <Chart
                id={"byProbe"}
                title={"By probe"}
                dataset={props.byProbe.dataset}
                options={props.byProbe.options}
                yAxisLabel={"%"}
                xAxisLabel={"Time"}
                width="100%"
                height="300px"
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <Chart
                id={"cpuByBrowserChart"}
                title={"By Browser"}
                dataset={props.byBrowser.dataset}
                options={props.byBrowser.options}
                yAxisLabel={"%"}
                xAxisLabel={"Time"}
                width="100%"
                height="300px"
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <Chart
                id={"networkChart_packages"}
                title={"Network usage by probe"}
                dataset={props.networkUsage_packages.dataset}
                options={props.networkUsage_packages.options}
                yAxisLabel={"Packets"}
                xAxisLabel={"Time"}
                width="100%"
                height="300px"
              />
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <Chart
                id={"networkChart_bytes"}
                title={"Network usage by probe"}
                dataset={props.networkUsage_bytes.dataset}
                options={props.networkUsage_bytes.options}
                yAxisLabel={"Kbps"}
                xAxisLabel={"Time"}
                width="100%"
                height="300px"
              />
            </Grid>
          </Grid>
        </Card>
      )}
      {!props.performanceData && !props.prepearingScreen && (
        <Typography variant="subtitle1" align="center">
          Performance data is not available
        </Typography>
      )}
      {props.prepearingScreen && (
        <div className={props.classes.circularProgress}>
          <ReactSVG src={LoaderIcon} />
        </div>
      )}
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      height: "auto",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
    },
    divider: {
      margin: "16px -16px",
    },
    circularProgress: {
      display: "flex",
      justifyContent: "center",
    },
  });

export default withStyles(styles)(View);
