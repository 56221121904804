import * as React from "react";

import NavItem from "./NavItem";

const NavList = (props: any) => {
  const { items = [], isCollapsed = false, isNested = false } = props;
  // const classes = useStyles()

  return (
    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
      {items.map((item: any, index: any) => (
        <NavItem {...item} isCollapsed={isCollapsed} isNested={isNested} key={index} />
      ))}
    </div>
  );
};

export default NavList;
