import * as React from "react";
import { WithStyles, Theme, createStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";

interface WorkingSectionProps {
  children: React.ReactNode;
}

const WorkingSection = (props: WorkingSectionProps & WithStyles<"root">) => (
  <div className={props.classes.root}>{props.children}</div>
);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 25,
      paddingBottom: 25,
      [theme.breakpoints.down("xs")]: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      height: "calc(100% - 70px)",
      backgroundColor: "#F3F3F4",
      overflowX: "hidden",
    },
  });

export default withStyles(styles)(WorkingSection);
