import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { RouteComponentProps } from "react-router";
import SubscribePageForm from "./SubscribePageForm";

class ForgotPasswordPage extends React.Component<RouteComponentProps<{}>> {
  public render(): JSX.Element {
    return (
      <Grid container={true} direction="row" justify="center">
        <Grid item={true} xs={4}>
          <Typography variant="h1">testRTC</Typography>
          <br />
          <Typography variant="h4">Subscribe to testRTC Updates</Typography>
          <br />
          <SubscribePageForm />
        </Grid>
      </Grid>
    );
  }
}

export default ForgotPasswordPage;
