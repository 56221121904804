import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "typeface-roboto";
import App from "./App";
import store from "./store";
import SocketService from "./services/SocketService";
import "./styles/app.css";
import "./styles/Badge.css";

console.log("v0.30.0");

const _socketService = new SocketService();
_socketService.connect();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root") as HTMLElement
);
