import {
  SET_DISTINCT_TESTS,
  SET_DISTINCT_MONITOR,
  SET_MONITOR_SORT,
  SET_COMPANIES,
} from "../constants/ActionTypes";
import AxiosFactory from "../services/AxiosFactory";
import ApiPath from "../constants/ApiPath";
import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

export interface ISetTestsDistinctAction {
  type: string;
  payload: Array<string>;
}

export const SetTestsDistinct = (data: Array<string>): ISetTestsDistinctAction => {
  return {
    type: SET_DISTINCT_TESTS,
    payload: data,
  };
};

export const FetchTestsDistinct = (): ThunkAction<void, IStore, null, ISetTestsDistinctAction> => (
  dispatch: Dispatch<ISetTestsDistinctAction>
) => {
  const axiosFactory = new AxiosFactory();

  return axiosFactory.axios
    .get(`${ApiPath.api.testRunsDistinct}?order=test_run_sort`)
    .then((res) => {
      dispatch(SetTestsDistinct(res.data));
    });
};

export const SetMonitorDistinct = (data: Array<string>): ISetTestsDistinctAction => {
  return {
    type: SET_DISTINCT_MONITOR,
    payload: data,
  };
};

export const SetCompanies = (data: Array<string>): ISetTestsDistinctAction => {
  return {
    type: SET_COMPANIES,
    payload: data,
  };
};

export const FetchMonitorsDistinct = (): ThunkAction<
  void,
  IStore,
  null,
  ISetTestsDistinctAction
> => (dispatch: Dispatch<ISetTestsDistinctAction>) => {
  const axiosFactory = new AxiosFactory();

  return axiosFactory.axios
    .get(`${ApiPath.api.monitorRunsDistinct}?order=testname_lower`)
    .then((res) => {
      dispatch(SetMonitorDistinct(res.data));
    });
};

export const FetchCompanies = (): ThunkAction<void, IStore, null, ISetTestsDistinctAction> => (
  dispatch: Dispatch<ISetTestsDistinctAction>
) => {
  const axiosFactory = new AxiosFactory();

  return axiosFactory.axios.get(ApiPath.api.companies).then((res) => {
    dispatch(SetCompanies(res.data));
  });
};

export const SetMonitorSort = (data: Array<string>): ISetTestsDistinctAction => {
  return {
    type: SET_MONITOR_SORT,
    payload: data,
  };
};

export const FetchMonitorsSort = (): ThunkAction<void, IStore, null, ISetTestsDistinctAction> => (
  dispatch: Dispatch<ISetTestsDistinctAction>
) => {
  const axiosFactory = new AxiosFactory();

  return axiosFactory.axios.get(`${ApiPath.api.monitors}?order=testname_lower`).then((res) => {
    dispatch(SetMonitorSort(res.data.docs));
  });
};
