// tslint:disable:no-any

import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import TestStats from "./components/TestStats";
import TestOverview from "./components/TestOverview";
import TestService from "../../services/TestService";
import TestSessions from "./components/TestSessions";
import { Statuses } from "../../constants/TestStatus";
import {
  TestRun as TestRunRoute,
  MonitorRun as MonitoringRoute,
} from "../../constants/RoutesNames";
import { AxiosResponse } from "axios";
import SocketService from "../../services/SocketService";
import * as moment from "moment";
import TestExecustionStatus from "./components/TestExecusion";
import withBreadcrumb, { WithBreadcrumb } from "../../components/withBreadcrumb";
import { ReactSVG } from "react-svg";
import LoaderIcon from "../../assets/images/loading-progress.svg";
import { AppToolbarContext } from "../Layout/components/AppToolbar/Context";
import ApiPath from "src/constants/ApiPath";
import AxiosFactory from "src/services/AxiosFactory";

type RouteParams = {
  objectId: string;
};

export interface TestRunDetailsProps {
  group?: boolean;
  title?: string;
}

export interface TestRunDetailsState {
  test?: Test | null;
  testDefinition?: any;
  events?: Array<any>;
  calc: {
    testDuration?: number;
    score?: string;
    voiceSetupTime?: any;
    audio?: {
      recv: {
        bytes: number;
        packetLoss: number;
        jitter: number;
        rtt: number;
      };
      send: {
        bytes: number;
        packetLoss: number;
        jitter: number;
        rtt: number;
      };
    };
    video?: {
      recv: {
        bytes: number;
        packetLoss: number;
        jitter: number;
        rtt: number;
      };
      send: {
        bytes: number;
        packetLoss: number;
        jitter: number;
        rtt: number;
      };
    };
    completeCount?: number;
    rankCount?: number;
    callSetupTime?: number;
  };
  audioMOS?: number;
  audioMosPanelColor?: string;
  successRate?: string;
  iterationsStats?: any;
  filtCusTestMetric?: any;
  runStatus?: Array<RunStatus>;
}

type StyledComponent = WithStyles<"circularProgress" | "detailsContainer">;

class TestRunDetails extends React.Component<
  TestRunDetailsProps & RouteComponentProps<RouteParams> & WithBreadcrumb & StyledComponent,
  TestRunDetailsState
> {
  static contextType = AppToolbarContext;
  mounted = false;
  durationInterval: any;
  grid: any = null;
  timeout: any = null;

  constructor(
    props: TestRunDetailsProps & RouteComponentProps<RouteParams> & StyledComponent & WithBreadcrumb
  ) {
    super(props);

    this.state = {
      test: null,
      calc: {},
      testDefinition: null,
    };

    this.updateTest = this.updateTest.bind(this);
    this.setGridRef = this.setGridRef.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.subscribeToUpdates();
    this.reloadTestDataDelayed(10, true);
  }

  componentWillUnmount() {
    this.unsubscribeFromUpdates();
    clearTimeout(this.timeout);
    this.mounted = false;
  }

  reloadTestDataDelayed(retry: number, initial?: boolean): Promise<any> {
    if (!this.mounted) {
      return Promise.resolve();
    }
    const { match } = this.props;

    if (retry > 0) {
      this.timeout = setTimeout(
        async () => {
          try {
            const axiosFactory = new AxiosFactory();
            const [testResult, testStatsResult] = await Promise.all([
              axiosFactory.axios.get(`${ApiPath.api.testRuns}/show/${match.params.objectId}`),
              axiosFactory.axios.get(`${ApiPath.api.testRuns}/getStats/${match.params.objectId}`),
            ]);
            if (testResult.status === 403) {
              this.props.history.push("/app/403");
            }
            let currentTest = testResult.data;
            const currentTestStats = testStatsResult.data;
            currentTest = {
              ...currentTest,
              stat: {
                ...currentTestStats,
              },
            };
            let newState: TestRunDetailsState = {
              calc: this.state.calc,
            };
            const res = this.processTestData(currentTest);
            await this.getTestIterationEvents(currentTest._id);
            await this.getTestDefinition(currentTest.testId);
            newState = { ...newState, ...res };
            await this.setState({
              ...newState,
              test: this.checkTestIterations(currentTest),
            });
            this.getSuccessRate(currentTest);

            if (initial && currentTest) {
              this.props.resetBreadcrumb();
              if (currentTest.runMode === "monitor") {
                this.props.pushBreadcrumbNode(MonitoringRoute);
                this.context.setPageInfo(
                  `Monitor Run Results: ${moment(currentTest.createDate).format(
                    "MMM DD, YYYY - HH:mm"
                  )}`
                );
                // this.props.pushBreadcrumbNode(
                //   `${TestRunDetailsRoute}/${currentTest._id}`,
                //   `Monitor Run Results: ${moment(currentTest.createDate).format(
                //     "MMM DD, YYYY - HH:mm"
                //   )}`
                // );
              }
              if (currentTest.runMode === "test") {
                this.props.pushBreadcrumbNode(TestRunRoute);
                this.context.setPageInfo(currentTest.runName);
                // this.props.pushBreadcrumbNode(
                //   `${TestRunDetailsRoute}/${currentTest._id}`,
                //   `Test Results: ${currentTest.runName}`
                // );
              }
              if (this.props.group) {
                this.context.setPageInfo((this.props.match.params as any).groupValue);
                // this.props.pushBreadcrumbNode(
                //   this.props.location.pathname,
                //   `Test Results Browser: ${(this.props.match.params as any).groupValue}`
                // );
              }
            }

            if (!this.isFinalState(currentTest)) {
              clearInterval(this.durationInterval);
              this.durationInterval = setInterval(async () => {
                await this.setState({
                  calc: {
                    ...this.state.calc,
                    testDuration: moment(new Date()).diff(moment(currentTest.createDate)),
                  },
                });
              }, 1000);

              return await this.reloadTestDataDelayed(retry);
            } else {
              this.showResultOfTest();
              if (this.grid && !initial) {
                this.grid.fetchDefaultList();
              }
              await this.getTestIterationEvents(currentTest._id);
            }
          } catch (err) {
            if (err?.response?.status === 403) {
              this.props.history.push("/app/403");
            } else if (err?.response?.status === 401) {
              // means user is logged out
              // so do nothing
            } else {
              console.log(err.response || err.message);
            }
          }
        },
        initial ? 0 : 2000
      );
    }
    return Promise.resolve();
  }

  subscribeToUpdates() {
    const { match } = this.props;

    const messageType = "testrun.update." + match.params.objectId;

    SocketService._instance.on(
      messageType,
      async (message: any): Promise<any> => {
        const runStatus = message.runStatus;

        if (!this.state.test) {
          // we got status update before we loaded the test, keep the new status
          await this.setState({
            runStatus,
          });
        } else if (
          !this.isFinalState({
            ...this.state.test,
            status: message.testRunStatus,
          })
        ) {
          await this.setState({
            // ...this.state,
            test: {
              ...this.state.test,
              stat: message.testRunStatus,
            },
            runStatus,
          });
        }
      }
    );
    SocketService._instance.subscribe(messageType);
  }

  unsubscribeFromUpdates() {
    const { match } = this.props;
    const messageType = "testrun.update." + match.params.objectId;
    SocketService._instance.unsubscribe(messageType);
    SocketService._instance.off(messageType);
  }

  async getTestDefinition(testId: string) {
    try {
      const testDefinitionResult: AxiosResponse = await TestService.getTestDefinition(testId);
      const { data } = testDefinitionResult;
      await this.setState({
        testDefinition: data,
      });
    } catch (e) {
      await this.setState({
        testDefinition: false,
      });
    }
  }

  async updateTest(test: Test) {
    await this.setState({
      test,
    });
  }

  showResultOfTest() {
    const { match } = this.props;

    const messageType = "testrun.update." + match.params.objectId;

    SocketService._instance.unsubscribe(messageType);
    SocketService._instance.off(messageType);
  }

  isFinalState(test: Test) {
    const finalStatusOptions = [
      Statuses.completed,
      Statuses.finished,
      Statuses.warnings,
      Statuses.failure,
      Statuses.serviceFailure,
      Statuses.terminated,
      Statuses.timeout,
    ];
    return finalStatusOptions.indexOf(test.status) !== -1;
  }

  checkTestIterations(test: Test): Test {
    const sRef = test.stat;
    const lastIter = Array.isArray(sRef) ? sRef[sRef.length - 1].stat : sRef;

    return {
      ...test,
      stat: lastIter,
    };
  }

  processTestData(test: Test) {
    const newState: TestRunDetailsState = {
      calc: {},
      testDefinition: {
        ...this.state.testDefinition,
      },
    };
    const sRef = test.stat;
    const lastIter = Array.isArray(sRef) ? sRef[sRef.length - 1].stat : sRef;
    if (lastIter) {
      const condition =
        test.endDate ||
        test.status === Statuses.timeout ||
        test.status === Statuses.completed ||
        test.status === Statuses.serviceFailure;
      if (condition) {
        clearInterval(this.durationInterval);
      }
      const duration = moment(test.endDate).diff(moment(test.createDate));

      newState.filtCusTestMetric = {};
      if (!isNaN(duration)) {
        newState.calc.testDuration = duration;
      }

      const score = (lastIter && lastIter.rank && lastIter.rank ? Number(lastIter.rank) : 0) || 0;
      newState.calc.score = score.toFixed(1);
      newState.calc.voiceSetupTime = lastIter.voiceSetupTime < 0 ? 0 : lastIter.voiceSetupTime;
      newState.calc.audio = this.calcChannelStat(lastIter.audio);
      newState.calc.video = this.calcChannelStat(lastIter.video);
      newState.calc.completeCount = lastIter.completeCount;
      newState.calc.rankCount = lastIter.rankCount;
      newState.calc.callSetupTime = lastIter.callSetupTime || 0;

      if (lastIter.stat && lastIter.stat.customTestMetric) {
        for (const key in lastIter.stat.customTestMetric) {
          if (lastIter.stat.customTestMetric.hasOwnProperty(key)) {
            if (key === "VoiceQuality:MOS") {
              newState.audioMOS = lastIter.stat.customTestMetric[key].value;
            }

            /*Filters out , voiceQuality:* records and creates new array*/
            if (key.substring(0, key.indexOf(":")) !== "VoiceQuality") {
              newState.filtCusTestMetric[key] = lastIter.stat.customTestMetric[key];
            }
          }
        }
      }

      if (newState.audioMOS) {
        newState.audioMosPanelColor = this.getAudioMosPanelClass(newState.audioMOS);
      }

      return {
        ...this.state,
        ...newState,
        calc: {
          ...this.state.calc,
          ...newState.calc,
        },
      };
    }
    return {};
  }

  getSuccessRate(test: any) {
    if (test?.iterationsStats) {
      const rate = Math.ceil((test.iterationsStats.success / test.iterationsStats.total) * 100);
      if (rate) {
        this.setState({
          successRate: `${rate}% (${test.iterationsStats.success}/${test.iterationsStats.total})`,
        });
      }
    }
  }

  async getTestIterationEvents(id: string) {
    const testIterationsResult: AxiosResponse = await TestService.getTestIterationEvents(id);
    const { data } = testIterationsResult;

    await this.setState({
      events: data,
    });
  }

  // tslint:disable-next-line:no-any
  calcChannelStat(stat: any) {
    return {
      recv: {
        bytes: stat?.recv ? stat.recv.bytes : 0,
        // https://redmine.testrtc.com/issues/4235
        // recalculate % only for recv
        packetLoss: stat?.recv
          ? (stat.recv.packetLoss * 100) / (stat.recv.totalPackets + stat.recv.packetLoss)
          : 0,
        jitter: stat?.recv ? stat.recv.jitter : 0,
        rtt: stat?.recv ? stat.recv.rtt : 0,
      },
      send: {
        bytes: stat?.send ? stat.send.bytes : 0,
        packetLoss: stat?.send ? (stat.send.packetLoss * 100) / stat.send.totalPackets : 0,
        jitter: stat?.send ? stat.send.jitter : 0,
        rtt: stat?.send ? stat.send.rtt : 0,
      },
    };
  }

  getAudioMosPanelClass(value: number) {
    switch (true) {
      case value >= 3:
        return "#559542";
      case value >= 2 && value < 3:
        return "#F1CD2B";
      case value < 2:
        return "#A22A21";
      default:
        return "";
    }
  }

  setGridRef(grid: any) {
    this.grid = grid;
  }

  render() {
    const { classes, ...otherProps } = this.props;
    const isFinalState = this.state.test ? this.isFinalState(this.state.test) : false;

    return (
      <React.Fragment>
        {!this.state.test ? (
          <ReactSVG src={LoaderIcon} className={classes.circularProgress} />
        ) : (
          <Fade in={true}>
            <Grid container={true} className={classes.detailsContainer}>
              {!isFinalState && (
                <TestExecustionStatus test={this.state.test} runStatus={this.state.runStatus} />
              )}
              <TestStats data={this.state.test} calc={this.state.calc} />
              <TestOverview
                {...this.state}
                {...otherProps}
                updateTest={this.updateTest}
                boxMark={true}
              />
              <TestSessions
                gridRef={this.setGridRef}
                testRunId={this.props.match.params.objectId}
                runName={this.state.test.runName}
                isFinalState={isFinalState}
              />
            </Grid>
          </Fade>
        )}
      </React.Fragment>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    circularProgress: {
      position: "fixed",
      left: "calc(50% - 64px)",
      top: "calc(50% - 64px)",
    },
    detailsContainer: {
      // maxWidth: 860,
      width: "100%",
      margin: "auto",
    },
  });

const decorate = withStyles(styles);

export default withBreadcrumb(decorate(TestRunDetails));
