// tslint:disable:no-any
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withRouter, RouteComponentProps } from "react-router";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { TestRunDetails } from "../../../../constants/RoutesNames";
import TestSessionsGridControl from "./TestSessionsGridControl";
import { RunGroupParams } from "../../../TestRunGroup";
import Button from "@material-ui/core/Button";
import AxiosFactory from "../../../../services/AxiosFactory";
import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import ApiPath from "../../../../constants/ApiPath";

type StyledComponent = WithStyles<"card" | "divider" | "toolbar" | "exportBtnContainer">;

type TestSessionsProps = {
  testRunId: string;
  runName?: string;
  groupLink?: string;
  gridRef?: any;
  isFinalState: boolean;
};

type TestSessionsState = {
  groupLink: string;
};

type Props = StyledComponent & TestSessionsProps & RouteComponentProps<RunGroupParams>;

class TestSessions extends React.Component<Props, TestSessionsState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      groupLink: props.groupLink || props.match.params.group,
    };

    this.navigateBack = this.navigateBack.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.match.params.group !== nextProps.match.params.group) {
      this.setState({
        groupLink: nextProps.match.params.group,
      });
    }
  }

  navigateBack() {
    this.props.history.push(`${TestRunDetails}/${this.props.match.params.objectId}`);
  }

  onExportClick = () => {
    const { testRunId, runName } = this.props;
    const axiosFactory = new AxiosFactory();

    axiosFactory.axios
      .get(`${ApiPath.api.testIterations}/${testRunId}/csv`)
      .then((res: AxiosResponse) => {
        const blob = new Blob([res.data]);
        saveAs(blob, runName ? `${runName}.csv` : "testrtc-export.csv");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  public render(): JSX.Element {
    const { classes } = this.props;

    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Card className={classes.card}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="subtitle1">{"Test Sessions / Probes"}</Typography>
              <div className={classes.exportBtnContainer}>
                <Button color="primary" variant="contained" onClick={this.onExportClick}>
                  Export all
                </Button>
              </div>
            </Toolbar>
            {/* <Divider className={classes.divider} /> */}
            <Grid container={true} spacing={5}>
              <TestSessionsGridControl
                gridRef={this.props.gridRef}
                remoteDataBound={this.props.isFinalState}
                testRunId={this.props.testRunId}
                navigateBack={this.navigateBack}
              />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    exportBtnContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });

const decorate = withStyles(styles);

export default withRouter<TestSessionsProps & RouteComponentProps<RunGroupParams>>(
  decorate(TestSessions)
);
