import * as React from "react";
import { Field, Form } from "react-final-form";
import TextFieldControl from "../../../components/form-elements/TextFieldControl";
import FormValidators from "../../../helpers/form-validators";
import { RouteComponentProps, withRouter } from "react-router";
import { Login as LoginRoutes } from "../../../constants/RoutesNames";
import AxiosFactory from "../../../services/AxiosFactory";
import ApiPath from "../../../constants/ApiPath";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

interface IForgotForm {
  email: string;
}

class ForgotPasswordForm extends React.Component<RouteComponentProps<{}>> {
  axiosFactory: AxiosFactory;

  constructor(props: RouteComponentProps<{}>) {
    super(props);

    this.axiosFactory = new AxiosFactory();
    this.onSubmit = this.onSubmit.bind(this);
  }

  public render(): JSX.Element {
    return (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="email"
                  label="Email"
                  validate={FormValidators.composeValidators(
                    FormValidators.required(),
                    FormValidators.isEmail
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Button type="submit" variant="contained" color="secondary" disabled={invalid}>
                  Notify Me
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }

  private async onSubmit(values: IForgotForm): Promise<void | { [x: string]: string }> {
    try {
      await this.axiosFactory.axios.get(`${ApiPath.api.newsletterSubscribe}/${values.email}`);
      this.props.history.push({
        pathname: LoginRoutes.SignIn,
        state: { isSubscribeSent: true, emailRemind: values.email },
      });
    } catch (_err) {
      return { email: "Sorry, could not subscribe you for updates. Please try later." };
    }
  }
}

export default withRouter<RouteComponentProps<{}>>(ForgotPasswordForm);
