// tslint:disable:no-any
import * as React from "react";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";

import ArticleIcon from "@material-ui/icons/Description";

import { makeStyles, createStyles, Theme } from "@material-ui/core";

import * as moment from "moment";

import { IFeed } from "../indexNew";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 10,
      height: 18,
      padding: 0,
      display: "felx",
      alignItems: "center",
    },
    feed: {
      display: "flex",
      marginBottom: theme.spacing(1),
      textDecoration: "none",
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
  })
);

interface IUpdates {
  feeds: Array<IFeed> | undefined;
}

const Updates = ({ feeds }: IUpdates) => {
  const classes = useStyles();

  if (!feeds || feeds.length === 0) {
    return null;
  }
  return (
    <Fade in={true}>
      <Grid item={true} xs={12}>
        <Card className={classes.card}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="subtitle1">Updates</Typography>
          </Toolbar>
          <Divider className={classes.divider} />
          <Grid container={true} spacing={5}>
            <Grid item={true} xs={12}>
              {feeds.map((feed: IFeed) => (
                <Grid key={feed.title} item={true} container={true} direction="row">
                  <Grid xs={8} item={true}>
                    <Typography className={classes.feed}>
                      <ArticleIcon color="primary" />
                      <Link href={feed.link} target="_blank">
                        {feed.title}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid
                    key={feed.title}
                    xs={4}
                    item={true}
                    container={true}
                    direction="row"
                    justify="flex-end"
                  >
                    <Grid item={true}>
                      <Typography variant="body1">
                        {feed.publicationDate &&
                          moment(feed.publicationDate).format("MMM DD, YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Fade>
  );
};

export default Updates;
