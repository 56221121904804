import * as React from "react";
import { connect } from "react-redux";
import withBreadcrumb from "../../components/withBreadcrumb";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import FormValidators from "src/helpers/form-validators";
import CheckboxControl from "src/components/form-elements/CheckboxControl";
import AuthService from "src/services/AuthService";
import AxiosFactory from "src/services/AxiosFactory";
import ApiPath from "src/constants/ApiPath";

type Props = {
  // tslint:disable-next-line: no-any
  user: any;
};

type State = {
  // tslint:disable-next-line: no-any
  open: boolean;
  phone: string;
  company: string;
  acceptTerms: boolean;
};

class Tests extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const company = props?.user?.company || "";

    this.state = {
      open: true,
      phone: "",
      company: company,
      acceptTerms: false,
    };
  }

  componentDidMount() {
    this.toggleAppBackground(false);
  }

  toggleAppBackground = (show: boolean) => {
    const root = document.getElementById("root") as HTMLElement;
    // tslint:disable-next-line: no-any
    const rootView = root.children[0] as any;
    if (!show) {
      rootView.style.display = "none";
    } else {
      rootView.style.display = "flex";
    }
  };

  handleClose = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  // tslint:disable-next-line: no-any
  onSubmit = async (values: any) => {
    try {
      const user = {
        ...this.props.user,
        termsDate: new Date(),
        acceptTerms: values.acceptTerms,
        phone: values.phone,
        company: values.company,
      };
      const axiosFactory = new AxiosFactory();
      await axiosFactory.axios.put(ApiPath.api.user.me, user);
      window.location.href = "/";
    } catch (err) {
      alert("Could not save the user");
    }
  };

  onLogout = () => {
    AuthService.logout();
    window.location.href = "/";
  };

  render() {
    if (!this.props.user) {
      return null;
    }
    return (
      <Dialog open={this.state.open}>
        <DialogTitle>testRTC - User Settings</DialogTitle>
        <Form
          onSubmit={this.onSubmit}
          initialValues={{
            phone: this.state.phone,
            company: this.state.company,
            acceptTerms: false,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText>
                  {`To use testRTC, you must first accept our terms of use and provide the following 
                  information.`}
                </DialogContentText>
                <Grid container={true} spacing={2} style={{ marginTop: 10 }}>
                  <Grid item={true} xs={12}>
                    <Field
                      component={TextFieldControl}
                      type="tel"
                      name="phone"
                      label="Phone Number"
                      validate={FormValidators.composeValidators(
                        FormValidators.required(),
                        FormValidators.minValue(7)
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Field
                      component={TextFieldControl}
                      type="text"
                      name="company"
                      label="Company"
                      validate={FormValidators.required()}
                    />
                  </Grid>
                  <Grid item={true} xs={12} style={{ position: "relative" }}>
                    <Grid container={true} alignItems="center">
                      <Grid item={true}>
                        <Field
                          component={CheckboxControl}
                          type="checkbox"
                          name="acceptTerms"
                          validate={FormValidators.required()}
                        />
                        <Typography
                          variant="body1"
                          style={{
                            position: "absolute",
                            top: 21,
                            left: 40,
                          }}
                        >
                          I agree to the{" "}
                          <a
                            href="http://testrtc.com/app-terms/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms of Service
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={this.onLogout} color="primary" variant="contained">
                    logout
                  </Button>
                  <Button type="submit" color="secondary" variant="contained">
                    continue
                  </Button>
                </DialogActions>
              </DialogContent>
            </form>
          )}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

// tslint:disable-next-line:no-any
export default withBreadcrumb(connect(mapStateToProps, null)(Tests));
