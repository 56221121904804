import axios, { AxiosInstance } from "axios";
import AuthService from "./AuthService";

export default class AxiosFactory {
  axios: AxiosInstance;

  constructor() {
    let headers = {};

    if (AuthService.loggedIn()) {
      const token = AuthService.getToken();
      headers = { Authorization: `Bearer ${token}` };
    }

    this.axios = axios.create({
      baseURL: `${process.env.REACT_APP_API_PATH}/`,
      headers: headers,
    });
  }
}
