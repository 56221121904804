// tslint:disable:no-any
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { Icon, createStyles } from "@material-ui/core";
import { Theme, withStyles, WithStyles } from "@material-ui/core";

type StyledComponent = WithStyles;

export interface QuickLinksProps {
  webRtcAnalytics: WebRtcAnalytics;
  getDumpFile: () => void;
  replayAsWatchRTC: () => void;
  isLoading: boolean;
  isAdmin: boolean;
  isWatchRTC: boolean;
}

const DownloadStyles = {
  display: "inline-flex",
  cursor: "pointer",
} as React.CSSProperties;

const QuickLinks = (props: StyledComponent & QuickLinksProps & RouteComponentProps<{}>) => {
  const {
    classes,
    webRtcAnalytics,
    getDumpFile,
    replayAsWatchRTC,
    isLoading,
    isAdmin,
    isWatchRTC,
  } = props;
  return (
    <Fade in={true}>
      <Grid item={true} xs={12}>
        <Card className={classes.card}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="subtitle1">Connection Quick Links</Typography>
            {isAdmin && (
              <Grid container style={{ width: "unset" }} spacing={2} alignItems={"center"}>
                {!isWatchRTC && (
                  <Button variant="contained" style={{ marginRight: 5 }} onClick={replayAsWatchRTC}>
                    Replay as WatchRTC
                  </Button>
                )}
                <Typography
                  variant="subtitle1"
                  className={`${!isLoading ? classes.fl : classes.flDisabled}`}
                >
                  <span onClick={getDumpFile} style={DownloadStyles}>
                    Download webrtc-internals
                    <Icon className={classes.alignIcon}>file_download</Icon>
                  </span>
                </Typography>
              </Grid>
            )}
          </Toolbar>
          <Divider className={classes.divider} />
          <Grid container={true} spacing={5} className={classes.tableContainer}>
            {Object.keys(webRtcAnalytics.PeerConnections).map((connection: string) => {
              return (
                <a key={connection} className={classes.link} href={`#${connection}`}>
                  <Typography variant="subtitle1">{connection}</Typography>
                </a>
              );
            })}
          </Grid>
        </Card>
      </Grid>
    </Fade>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableRow: {
      cursor: "pointer",
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
    },
    tableContainer: {
      padding: `0 ${theme.spacing(3)}px`,
      overflowY: "hidden",
      overflowX: "auto",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      cursor: "pointer",
      padding: theme.spacing(2),
    },
    fl: {
      display: "flex",
    },
    flDisabled: {
      display: "flex",
      pointerEvents: "none",
      color: "#ccc",
    },
    alignIcon: {
      marginLeft: "5px",
      cursor: "pointer",
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}> & QuickLinksProps>(decorate(QuickLinks));
