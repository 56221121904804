// @ts-ignore
"use strict";

// SDP helpers.
const SDPUtils: any = {};

// // Generate an alphanumeric identifier for cname or mids.
// // TODO: use UUIDs instead? https://gist.github.com/jed/982883
// SDPUtils.generateIdentifier = function () {
//   return Math.random().toString(36).substr(2, 10);
// };
//
// // The RTCP CNAME used by all peerconnections from the same JS.
// SDPUtils.localCName = SDPUtils.generateIdentifier();

// Splits SDP into lines, dealing with both CRLF and LF.
SDPUtils.splitLines = function (blob: any) {
  return blob
    .trim()
    .split("\n")
    .map((line: string) => line.trim());
};
// Splits SDP into sessionpart and mediasections. Ensures CRLF.
SDPUtils.splitSections = function (blob: any) {
  const parts = blob.split("\nm=");
  return parts.map(
    (part: string, index: number) => (index > 0 ? "m=" + part : part).trim() + "\r\n"
  );
};
//
// // Returns the session description.
// SDPUtils.getDescription = function (blob: any) {
//   const sections = SDPUtils.splitSections(blob);
//   return sections && sections[0];
// };
//
// // Returns the individual media sections.
// SDPUtils.getMediaSections = function (blob: any) {
//   const sections = SDPUtils.splitSections(blob);
//   sections.shift();
//   return sections;
// };
//
// // Returns lines that start with a certain prefix.
// SDPUtils.matchPrefix = function (blob: any, prefix: any) {
//   return SDPUtils.splitLines(blob).filter((line: any) => line.indexOf(prefix) === 0);
// };
//
// // Parses an ICE candidate line. Sample input:
// // candidate:702786350 2 udp 41819902 8.8.8.8 60769 typ relay raddr 8.8.8.8
// // rport 55996"
// // Input can be prefixed with a=.
// SDPUtils.parseCandidate = function (line: any) {
//   let parts;
//   // Parse both variants.
//   if (line.indexOf("a=candidate:") === 0) {
//     parts = line.substring(12).split(" ");
//   } else {
//     parts = line.substring(10).split(" ");
//   }
//
//   const candidate: any = {
//     foundation: parts[0],
//     component: { 1: "rtp", 2: "rtcp" }[parts[1]] || parts[1],
//     protocol: parts[2].toLowerCase(),
//     priority: parseInt(parts[3], 10),
//     ip: parts[4],
//     address: parts[4], // address is an alias for ip.
//     port: parseInt(parts[5], 10),
//     // skip parts[6] == 'typ'
//     type: parts[7],
//   };
//
//   for (let i = 8; i < parts.length; i += 2) {
//     switch (parts[i]) {
//       case "raddr":
//         candidate.relatedAddress = parts[i + 1];
//         break;
//       case "rport":
//         candidate.relatedPort = parseInt(parts[i + 1], 10);
//         break;
//       case "tcptype":
//         candidate.tcpType = parts[i + 1];
//         break;
//       case "ufrag":
//         candidate.ufrag = parts[i + 1]; // for backward compatibility.
//         candidate.usernameFragment = parts[i + 1];
//         break;
//       default:
//         // extension handling, in particular ufrag. Don't overwrite.
//         if (candidate[parts[i]] === undefined) {
//           candidate[parts[i]] = parts[i + 1];
//         }
//         break;
//     }
//   }
//   return candidate;
// };
//
// // Translates a candidate object into SDP candidate attribute.
// // This does not include the a= prefix!
// SDPUtils.writeCandidate = function (candidate: any) {
//   const sdp = [];
//   sdp.push(candidate.foundation);
//
//   const component = candidate.component;
//   if (component === "rtp") {
//     sdp.push(1);
//   } else if (component === "rtcp") {
//     sdp.push(2);
//   } else {
//     sdp.push(component);
//   }
//   sdp.push(candidate.protocol.toUpperCase());
//   sdp.push(candidate.priority);
//   sdp.push(candidate.address || candidate.ip);
//   sdp.push(candidate.port);
//
//   const type = candidate.type;
//   sdp.push("typ");
//   sdp.push(type);
//   if (type !== "host" && candidate.relatedAddress && candidate.relatedPort) {
//     sdp.push("raddr");
//     sdp.push(candidate.relatedAddress);
//     sdp.push("rport");
//     sdp.push(candidate.relatedPort);
//   }
//   if (candidate.tcpType && candidate.protocol.toLowerCase() === "tcp") {
//     sdp.push("tcptype");
//     sdp.push(candidate.tcpType);
//   }
//   if (candidate.usernameFragment || candidate.ufrag) {
//     sdp.push("ufrag");
//     sdp.push(candidate.usernameFragment || candidate.ufrag);
//   }
//   return "candidate:" + sdp.join(" ");
// };
//
// // Parses an ice-options line, returns an array of option tags.
// // Sample input:
// // a=ice-options:foo bar
// SDPUtils.parseIceOptions = function (line: any) {
//   return line.substr(14).split(" ");
// };
//
// // Parses a rtpmap line, returns RTCRtpCoddecParameters. Sample input:
// // a=rtpmap:111 opus/48000/2
// SDPUtils.parseRtpMap = function (line: any) {
//   let parts = line.substr(9).split(" ");
//   const parsed: any = {
//     payloadType: parseInt(parts.shift(), 10), // was: id
//   };
//
//   parts = parts[0].split("/");
//
//   parsed.name = parts[0];
//   parsed.clockRate = parseInt(parts[1], 10); // was: clockrate
//   parsed.channels = parts.length === 3 ? parseInt(parts[2], 10) : 1;
//   // legacy alias, got renamed back to channels in ORTC.
//   parsed.numChannels = parsed.channels;
//   return parsed;
// };
//
// // Generates a rtpmap line from RTCRtpCodecCapability or
// // RTCRtpCodecParameters.
// SDPUtils.writeRtpMap = function (codec: any) {
//   let pt = codec.payloadType;
//   if (codec.preferredPayloadType !== undefined) {
//     pt = codec.preferredPayloadType;
//   }
//   const channels = codec.channels || codec.numChannels || 1;
//   return (
//     "a=rtpmap:" +
//     pt +
//     " " +
//     codec.name +
//     "/" +
//     codec.clockRate +
//     (channels !== 1 ? "/" + channels : "") +
//     "\r\n"
//   );
// };
//
// // Parses a extmap line (headerextension from RFC 5285). Sample input:
// // a=extmap:2 urn:ietf:params:rtp-hdrext:toffset
// // a=extmap:2/sendonly urn:ietf:params:rtp-hdrext:toffset
// SDPUtils.parseExtmap = function (line: any) {
//   const parts = line.substr(9).split(" ");
//   return {
//     id: parseInt(parts[0], 10),
//     direction: parts[0].indexOf("/") > 0 ? parts[0].split("/")[1] : "sendrecv",
//     uri: parts[1],
//   };
// };
//
// // Generates an extmap line from RTCRtpHeaderExtensionParameters or
// // RTCRtpHeaderExtension.
// SDPUtils.writeExtmap = function (headerExtension: any) {
//   return (
//     "a=extmap:" +
//     (headerExtension.id || headerExtension.preferredId) +
//     (headerExtension.direction && headerExtension.direction !== "sendrecv"
//       ? "/" + headerExtension.direction
//       : "") +
//     " " +
//     headerExtension.uri +
//     "\r\n"
//   );
// };
//
// // Parses a fmtp line, returns dictionary. Sample input:
// // a=fmtp:96 vbr=on;cng=on
// // Also deals with vbr=on; cng=on
// SDPUtils.parseFmtp = function (line: any) {
//   const parsed = {};
//   let kv;
//   const parts = line.substr(line.indexOf(" ") + 1).split(";");
//   for (let j = 0; j < parts.length; j++) {
//     kv = parts[j].trim().split("=");
//     parsed[kv[0].trim()] = kv[1];
//   }
//   return parsed;
// };
//
// // Generates a fmtp line from RTCRtpCodecCapability or RTCRtpCodecParameters.
// SDPUtils.writeFmtp = function (codec: any) {
//   let line = "";
//   let pt = codec.payloadType;
//   if (codec.preferredPayloadType !== undefined) {
//     pt = codec.preferredPayloadType;
//   }
//   if (codec.parameters && Object.keys(codec.parameters).length) {
//     const params: any = [];
//     Object.keys(codec.parameters).forEach((param) => {
//       if (codec.parameters[param]) {
//         params.push(param + "=" + codec.parameters[param]);
//       } else {
//         params.push(param);
//       }
//     });
//     line += "a=fmtp:" + pt + " " + params.join(";") + "\r\n";
//   }
//   return line;
// };
//
// // Parses a rtcp-fb line, returns RTCPRtcpFeedback object. Sample input:
// // a=rtcp-fb:98 nack rpsi
// SDPUtils.parseRtcpFb = function (line: any) {
//   const parts = line.substr(line.indexOf(" ") + 1).split(" ");
//   return {
//     type: parts.shift(),
//     parameter: parts.join(" "),
//   };
// };
//
// // Generate a=rtcp-fb lines from RTCRtpCodecCapability or RTCRtpCodecParameters.
// SDPUtils.writeRtcpFb = function (codec: any) {
//   let lines = "";
//   let pt = codec.payloadType;
//   if (codec.preferredPayloadType !== undefined) {
//     pt = codec.preferredPayloadType;
//   }
//   if (codec.rtcpFeedback && codec.rtcpFeedback.length) {
//     // FIXME: special handling for trr-int?
//     codec.rtcpFeedback.forEach((fb: any) => {
//       lines +=
//         "a=rtcp-fb:" +
//         pt +
//         " " +
//         fb.type +
//         (fb.parameter && fb.parameter.length ? " " + fb.parameter : "") +
//         "\r\n";
//     });
//   }
//   return lines;
// };
//
// // Parses a RFC 5576 ssrc media attribute. Sample input:
// // a=ssrc:3735928559 cname:something
// SDPUtils.parseSsrcMedia = function (line: any) {
//   const sp = line.indexOf(" ");
//   const parts: any = {
//     ssrc: parseInt(line.substr(7, sp - 7), 10),
//   };
//   const colon = line.indexOf(":", sp);
//   if (colon > -1) {
//     parts.attribute = line.substr(sp + 1, colon - sp - 1);
//     parts.value = line.substr(colon + 1);
//   } else {
//     parts.attribute = line.substr(sp + 1);
//   }
//   return parts;
// };
//
// // Parse a ssrc-group line (see RFC 5576). Sample input:
// // a=ssrc-group:semantics 12 34
// SDPUtils.parseSsrcGroup = function (line: any) {
//   const parts = line.substr(13).split(" ");
//   return {
//     semantics: parts.shift(),
//     ssrcs: parts.map((ssrc: any) => parseInt(ssrc, 10)),
//   };
// };
//
// // Extracts the MID (RFC 5888) from a media section.
// // Returns the MID or undefined if no mid line was found.
// SDPUtils.getMid = function (mediaSection: any) {
//   const mid = SDPUtils.matchPrefix(mediaSection, "a=mid:")[0];
//   if (mid) {
//     return mid.substr(6);
//   }
// };
//
// // Parses a fingerprint line for DTLS-SRTP.
// SDPUtils.parseFingerprint = function (line: any) {
//   const parts = line.substr(14).split(" ");
//   return {
//     algorithm: parts[0].toLowerCase(), // algorithm is case-sensitive in Edge.
//     value: parts[1].toUpperCase(), // the definition is upper-case in RFC 4572.
//   };
// };
//
// // Extracts DTLS parameters from SDP media section or sessionpart.
// // FIXME: for consistency with other functions this should only
// //   get the fingerprint line as input. See also getIceParameters.
// SDPUtils.getDtlsParameters = function (mediaSection: any, sessionpart: any) {
//   const lines = SDPUtils.matchPrefix(mediaSection + sessionpart, "a=fingerprint:");
//   // Note: a=setup line is ignored since we use the 'auto' role in Edge.
//   return {
//     role: "auto",
//     fingerprints: lines.map(SDPUtils.parseFingerprint),
//   };
// };
//
// // Serializes DTLS parameters to SDP.
// SDPUtils.writeDtlsParameters = function (params: any, setupType: any) {
//   let sdp = "a=setup:" + setupType + "\r\n";
//   params.fingerprints.forEach((fp: any) => {
//     sdp += "a=fingerprint:" + fp.algorithm + " " + fp.value + "\r\n";
//   });
//   return sdp;
// };
//
// // Parses a=crypto lines into
// //   https://rawgit.com/aboba/edgertc/master/msortc-rs4.html#dictionary-rtcsrtpsdesparameters-members
// SDPUtils.parseCryptoLine = function (line: any) {
//   const parts = line.substr(9).split(" ");
//   return {
//     tag: parseInt(parts[0], 10),
//     cryptoSuite: parts[1],
//     keyParams: parts[2],
//     sessionParams: parts.slice(3),
//   };
// };
//
// SDPUtils.writeCryptoLine = function (parameters: any) {
//   return (
//     "a=crypto:" +
//     parameters.tag +
//     " " +
//     parameters.cryptoSuite +
//     " " +
//     (typeof parameters.keyParams === "object"
//       ? SDPUtils.writeCryptoKeyParams(parameters.keyParams)
//       : parameters.keyParams) +
//     (parameters.sessionParams ? " " + parameters.sessionParams.join(" ") : "") +
//     "\r\n"
//   );
// };
//
// // Parses the crypto key parameters into
// //   https://rawgit.com/aboba/edgertc/master/msortc-rs4.html#rtcsrtpkeyparam*
// SDPUtils.parseCryptoKeyParams = function (keyParams: any) {
//   if (keyParams.indexOf("inline:") !== 0) {
//     return null;
//   }
//   const parts = keyParams.substr(7).split("|");
//   return {
//     keyMethod: "inline",
//     keySalt: parts[0],
//     lifeTime: parts[1],
//     mkiValue: parts[2] ? parts[2].split(":")[0] : undefined,
//     mkiLength: parts[2] ? parts[2].split(":")[1] : undefined,
//   };
// };
//
// SDPUtils.writeCryptoKeyParams = function (keyParams: any) {
//   return (
//     keyParams.keyMethod +
//     ":" +
//     keyParams.keySalt +
//     (keyParams.lifeTime ? "|" + keyParams.lifeTime : "") +
//     (keyParams.mkiValue && keyParams.mkiLength
//       ? "|" + keyParams.mkiValue + ":" + keyParams.mkiLength
//       : "")
//   );
// };
//
// // Extracts all SDES parameters.
// SDPUtils.getCryptoParameters = function (mediaSection: any, sessionpart: any) {
//   const lines = SDPUtils.matchPrefix(mediaSection + sessionpart, "a=crypto:");
//   return lines.map(SDPUtils.parseCryptoLine);
// };
//
// // Parses ICE information from SDP media section or sessionpart.
// // FIXME: for consistency with other functions this should only
// //   get the ice-ufrag and ice-pwd lines as input.
// SDPUtils.getIceParameters = function (mediaSection: any, sessionpart: any) {
//   const ufrag = SDPUtils.matchPrefix(mediaSection + sessionpart, "a=ice-ufrag:")[0];
//   const pwd = SDPUtils.matchPrefix(mediaSection + sessionpart, "a=ice-pwd:")[0];
//   if (!(ufrag && pwd)) {
//     return null;
//   }
//   return {
//     usernameFragment: ufrag.substr(12),
//     password: pwd.substr(10),
//   };
// };
//
// // Serializes ICE parameters to SDP.
// SDPUtils.writeIceParameters = function (params: any) {
//   let sdp =
//     "a=ice-ufrag:" + params.usernameFragment + "\r\n" + "a=ice-pwd:" + params.password + "\r\n";
//   if (params.iceLite) {
//     sdp += "a=ice-lite\r\n";
//   }
//   return sdp;
// };
//
// // Parses the SDP media section and returns RTCRtpParameters.
// SDPUtils.parseRtpParameters = function (mediaSection: any) {
//   const description: any = {
//     codecs: [],
//     headerExtensions: [],
//     fecMechanisms: [],
//     rtcp: [],
//   };
//   const lines = SDPUtils.splitLines(mediaSection);
//   const mline = lines[0].split(" ");
//   for (let i = 3; i < mline.length; i++) {
//     // find all codecs from mline[3..]
//     const pt = mline[i];
//     const rtpmapline = SDPUtils.matchPrefix(mediaSection, "a=rtpmap:" + pt + " ")[0];
//     if (rtpmapline) {
//       const codec = SDPUtils.parseRtpMap(rtpmapline);
//       const fmtps = SDPUtils.matchPrefix(mediaSection, "a=fmtp:" + pt + " ");
//       // Only the first a=fmtp:<pt> is considered.
//       codec.parameters = fmtps.length ? SDPUtils.parseFmtp(fmtps[0]) : {};
//       codec.rtcpFeedback = SDPUtils.matchPrefix(mediaSection, "a=rtcp-fb:" + pt + " ").map(
//         SDPUtils.parseRtcpFb
//       );
//       description.codecs.push(codec);
//       // parse FEC mechanisms from rtpmap lines.
//       switch (codec.name.toUpperCase()) {
//         case "RED":
//         case "ULPFEC":
//           description.fecMechanisms.push(codec.name.toUpperCase());
//           break;
//         default:
//           // only RED and ULPFEC are recognized as FEC mechanisms.
//           break;
//       }
//     }
//   }
//   SDPUtils.matchPrefix(mediaSection, "a=extmap:").forEach((line: any) => {
//     description.headerExtensions.push(SDPUtils.parseExtmap(line));
//   });
//   // FIXME: parse rtcp.
//   return description;
// };
//
// // Generates parts of the SDP media section describing the capabilities /
// // parameters.
// SDPUtils.writeRtpDescription = function (kind: any, caps: any) {
//   let sdp = "";
//
//   // Build the mline.
//   sdp += "m=" + kind + " ";
//   sdp += caps.codecs.length > 0 ? "9" : "0"; // reject if no codecs.
//   sdp += " UDP/TLS/RTP/SAVPF ";
//   sdp +=
//     caps.codecs
//       .map((codec: any) => {
//         if (codec.preferredPayloadType !== undefined) {
//           return codec.preferredPayloadType;
//         }
//         return codec.payloadType;
//       })
//       .join(" ") + "\r\n";
//
//   sdp += "c=IN IP4 0.0.0.0\r\n";
//   sdp += "a=rtcp:9 IN IP4 0.0.0.0\r\n";
//
//   // Add a=rtpmap lines for each codec. Also fmtp and rtcp-fb.
//   caps.codecs.forEach((codec: any) => {
//     sdp += SDPUtils.writeRtpMap(codec);
//     sdp += SDPUtils.writeFmtp(codec);
//     sdp += SDPUtils.writeRtcpFb(codec);
//   });
//   let maxptime = 0;
//   caps.codecs.forEach((codec: any) => {
//     if (codec.maxptime > maxptime) {
//       maxptime = codec.maxptime;
//     }
//   });
//   if (maxptime > 0) {
//     sdp += "a=maxptime:" + maxptime + "\r\n";
//   }
//
//   if (caps.headerExtensions) {
//     caps.headerExtensions.forEach((extension: any) => {
//       sdp += SDPUtils.writeExtmap(extension);
//     });
//   }
//   // FIXME: write fecMechanisms.
//   return sdp;
// };
//
// // Parses the SDP media section and returns an array of
// // RTCRtpEncodingParameters.
// SDPUtils.parseRtpEncodingParameters = function (mediaSection: any) {
//   const encodingParameters = [];
//   const description = SDPUtils.parseRtpParameters(mediaSection);
//   const hasRed = description.fecMechanisms.indexOf("RED") !== -1;
//   const hasUlpfec = description.fecMechanisms.indexOf("ULPFEC") !== -1;
//
//   // filter a=ssrc:... cname:, ignore PlanB-msid
//   const ssrcs = SDPUtils.matchPrefix(mediaSection, "a=ssrc:")
//     .map((line: any) => SDPUtils.parseSsrcMedia(line))
//     .filter((parts: any) => parts.attribute === "cname");
//   const primarySsrc = ssrcs.length > 0 && ssrcs[0].ssrc;
//   let secondarySsrc: any;
//
//   const flows = SDPUtils.matchPrefix(mediaSection, "a=ssrc-group:FID").map((line: string) => {
//     const parts = line.substr(17).split(" ");
//     return parts.map((part: string) => parseInt(part, 10));
//   });
//   if (flows.length > 0 && flows[0].length > 1 && flows[0][0] === primarySsrc) {
//     secondarySsrc = flows[0][1];
//   }
//
//   description.codecs.forEach((codec: any) => {
//     if (codec.name.toUpperCase() === "RTX" && codec.parameters.apt) {
//       let encParam: any = {
//         ssrc: primarySsrc,
//         codecPayloadType: parseInt(codec.parameters.apt, 10),
//       };
//       if (primarySsrc && secondarySsrc) {
//         encParam.rtx = { ssrc: secondarySsrc };
//       }
//       encodingParameters.push(encParam);
//       if (hasRed) {
//         encParam = JSON.parse(JSON.stringify(encParam));
//         encParam.fec = {
//           ssrc: primarySsrc,
//           mechanism: hasUlpfec ? "red+ulpfec" : "red",
//         };
//         encodingParameters.push(encParam);
//       }
//     }
//   });
//   if (encodingParameters.length === 0 && primarySsrc) {
//     encodingParameters.push({
//       ssrc: primarySsrc,
//     });
//   }
//
//   // we support both b=AS and b=TIAS but interpret AS as TIAS.
//   let bandwidth = SDPUtils.matchPrefix(mediaSection, "b=");
//   if (bandwidth.length) {
//     if (bandwidth[0].indexOf("b=TIAS:") === 0) {
//       bandwidth = parseInt(bandwidth[0].substr(7), 10);
//     } else if (bandwidth[0].indexOf("b=AS:") === 0) {
//       // use formula from JSEP to convert b=AS to TIAS value.
//       bandwidth = parseInt(bandwidth[0].substr(5), 10) * 1000 * 0.95 - 50 * 40 * 8;
//     } else {
//       bandwidth = undefined;
//     }
//     encodingParameters.forEach((params: any) => {
//       params.maxBitrate = bandwidth;
//     });
//   }
//   return encodingParameters;
// };
//
// // parses http://draft.ortc.org/#rtcrtcpparameters*
// SDPUtils.parseRtcpParameters = function (mediaSection: any) {
//   const rtcpParameters: any = {};
//
//   // Gets the first SSRC. Note that with RTX there might be multiple
//   // SSRCs.
//   const remoteSsrc = SDPUtils.matchPrefix(mediaSection, "a=ssrc:")
//     .map((line: any) => SDPUtils.parseSsrcMedia(line))
//     .filter((obj: any) => obj.attribute === "cname")[0];
//   if (remoteSsrc) {
//     rtcpParameters.cname = remoteSsrc.value;
//     rtcpParameters.ssrc = remoteSsrc.ssrc;
//   }
//
//   // Edge uses the compound attribute instead of reducedSize
//   // compound is !reducedSize
//   const rsize = SDPUtils.matchPrefix(mediaSection, "a=rtcp-rsize");
//   rtcpParameters.reducedSize = rsize.length > 0;
//   rtcpParameters.compound = rsize.length === 0;
//
//   // parses the rtcp-mux attrіbute.
//   // Note that Edge does not support unmuxed RTCP.
//   const mux = SDPUtils.matchPrefix(mediaSection, "a=rtcp-mux");
//   rtcpParameters.mux = mux.length > 0;
//
//   return rtcpParameters;
// };
//
// SDPUtils.writeRtcpParameters = function (rtcpParameters: any) {
//   let sdp = "";
//   if (rtcpParameters.reducedSize) {
//     sdp += "a=rtcp-rsize\r\n";
//   }
//   if (rtcpParameters.mux) {
//     sdp += "a=rtcp-mux\r\n";
//   }
//   if (rtcpParameters.ssrc !== undefined && rtcpParameters.cname) {
//     sdp += "a=ssrc:" + rtcpParameters.ssrc + " cname:" + rtcpParameters.cname + "\r\n";
//   }
//   return sdp;
// };
//
// // parses either a=msid: or a=ssrc:... msid lines and returns
// // the id of the MediaStream and MediaStreamTrack.
// // @ts-ignore
// SDPUtils.parseMsid = function (mediaSection: any) {
//   let parts;
//   const spec = SDPUtils.matchPrefix(mediaSection, "a=msid:");
//   if (spec.length === 1) {
//     parts = spec[0].substr(7).split(" ");
//     return { stream: parts[0], track: parts[1] };
//   }
//   const planB = SDPUtils.matchPrefix(mediaSection, "a=ssrc:")
//     .map((line: any) => SDPUtils.parseSsrcMedia(line))
//     .filter((msidParts: any) => msidParts.attribute === "msid");
//   if (planB.length > 0) {
//     parts = planB[0].value.split(" ");
//     return { stream: parts[0], track: parts[1] };
//   }
// };
//
// // SCTP
// // parses draft-ietf-mmusic-sctp-sdp-26 first and falls back
// // to draft-ietf-mmusic-sctp-sdp-05
// // @ts-ignore
// SDPUtils.parseSctpDescription = function (mediaSection: any) {
//   const mline = SDPUtils.parseMLine(mediaSection);
//   const maxSizeLine = SDPUtils.matchPrefix(mediaSection, "a=max-message-size:");
//   let maxMessageSize;
//   if (maxSizeLine.length > 0) {
//     maxMessageSize = parseInt(maxSizeLine[0].substr(19), 10);
//   }
//   if (maxMessageSize && isNaN(maxMessageSize)) {
//     maxMessageSize = 65536;
//   }
//   const sctpPort = SDPUtils.matchPrefix(mediaSection, "a=sctp-port:");
//   if (sctpPort.length > 0) {
//     return {
//       port: parseInt(sctpPort[0].substr(12), 10),
//       protocol: mline.fmt,
//       maxMessageSize,
//     };
//   }
//   const sctpMapLines = SDPUtils.matchPrefix(mediaSection, "a=sctpmap:");
//   if (sctpMapLines.length > 0) {
//     const parts = sctpMapLines[0].substr(10).split(" ");
//     return {
//       port: parseInt(parts[0], 10),
//       protocol: parts[1],
//       maxMessageSize,
//     };
//   }
// };
//
// // SCTP
// // outputs the draft-ietf-mmusic-sctp-sdp-26 version that all browsers
// // support by now receiving in this format, unless we originally parsed
// // as the draft-ietf-mmusic-sctp-sdp-05 format (indicated by the m-line
// // protocol of DTLS/SCTP -- without UDP/ or TCP/)
// SDPUtils.writeSctpDescription = function (media: any, sctp: any) {
//   let output = [];
//   if (media.protocol !== "DTLS/SCTP") {
//     output = [
//       "m=" + media.kind + " 9 " + media.protocol + " " + sctp.protocol + "\r\n",
//       "c=IN IP4 0.0.0.0\r\n",
//       "a=sctp-port:" + sctp.port + "\r\n",
//     ];
//   } else {
//     output = [
//       "m=" + media.kind + " 9 " + media.protocol + " " + sctp.port + "\r\n",
//       "c=IN IP4 0.0.0.0\r\n",
//       "a=sctpmap:" + sctp.port + " " + sctp.protocol + " 65535\r\n",
//     ];
//   }
//   if (sctp.maxMessageSize !== undefined) {
//     output.push("a=max-message-size:" + sctp.maxMessageSize + "\r\n");
//   }
//   return output.join("");
// };
//
// // Generate a session ID for SDP.
// // https://tools.ietf.org/html/draft-ietf-rtcweb-jsep-20#section-5.2.1
// // recommends using a cryptographically random +ve 64-bit value
// // but right now this should be acceptable and within the right range
// SDPUtils.generateSessionId = function () {
//   return Math.random().toString().substr(2, 21);
// };
//
// // Write boiler plate for start of SDP
// // sessId argument is optional - if not supplied it will
// // be generated randomly
// // sessVersion is optional and defaults to 2
// // sessUser is optional and defaults to 'thisisadapterortc'
// SDPUtils.writeSessionBoilerplate = function (sessId: any, sessVer: any, sessUser: string) {
//   let sessionId;
//   const version = sessVer !== undefined ? sessVer : 2;
//   if (sessId) {
//     sessionId = sessId;
//   } else {
//     sessionId = SDPUtils.generateSessionId();
//   }
//   const user = sessUser || "thisisadapterortc";
//   // FIXME: sess-id should be an NTP timestamp.
//   return (
//     "v=0\r\n" +
//     "o=" +
//     user +
//     " " +
//     sessionId +
//     " " +
//     version +
//     " IN IP4 127.0.0.1\r\n" +
//     "s=-\r\n" +
//     "t=0 0\r\n"
//   );
// };
//
// // Gets the direction from the mediaSection or the sessionpart.
// SDPUtils.getDirection = function (mediaSection: any, sessionpart: any) {
//   // Look for sendrecv, sendonly, recvonly, inactive, default to sendrecv.
//   const lines = SDPUtils.splitLines(mediaSection);
//   for (let i = 0; i < lines.length; i++) {
//     switch (lines[i]) {
//       case "a=sendrecv":
//       case "a=sendonly":
//       case "a=recvonly":
//       case "a=inactive":
//         return lines[i].substr(2);
//       default:
//       // FIXME: What should happen here?
//     }
//   }
//   if (sessionpart) {
//     return SDPUtils.getDirection(sessionpart);
//   }
//   return "sendrecv";
// };
//
// SDPUtils.getKind = function (mediaSection: any) {
//   const lines = SDPUtils.splitLines(mediaSection);
//   const mline = lines[0].split(" ");
//   return mline[0].substr(2);
// };
//
// SDPUtils.isRejected = function (mediaSection: any) {
//   return mediaSection.split(" ", 2)[1] === "0";
// };
//
// SDPUtils.parseMLine = function (mediaSection: any) {
//   const lines = SDPUtils.splitLines(mediaSection);
//   const parts = lines[0].substr(2).split(" ");
//   return {
//     kind: parts[0],
//     port: parseInt(parts[1], 10),
//     protocol: parts[2],
//     fmt: parts.slice(3).join(" "),
//   };
// };
//
// SDPUtils.parseOLine = function (mediaSection: any) {
//   const line = SDPUtils.matchPrefix(mediaSection, "o=")[0];
//   const parts = line.substr(2).split(" ");
//   return {
//     username: parts[0],
//     sessionId: parts[1],
//     sessionVersion: parseInt(parts[2], 10),
//     netType: parts[3],
//     addressType: parts[4],
//     address: parts[5],
//   };
// };
//
// // a very naive interpretation of a valid SDP.
// SDPUtils.isValidSDP = function (blob: any) {
//   if (typeof blob !== "string" || blob.length === 0) {
//     return false;
//   }
//   const lines = SDPUtils.splitLines(blob);
//   for (let i = 0; i < lines.length; i++) {
//     if (lines[i].length < 2 || lines[i].charAt(1) !== "=") {
//       return false;
//     }
//     // TODO: check the modifier a bit more.
//   }
//   return true;
// };

export default SDPUtils;
