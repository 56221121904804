export const EXPECTATION_CODE = `// This code will be called on each test iteration and on each channel.
// Next data you will have per each data channel: 

// direction: "send" | "recv"      - direction of data in channel
// channelType: "audio" | "video";
// startTimestamp: number;         - channel start
// endTimestamp: number;           - channel end
// interval: number;               - data frequency ( 1 means 1 value/sec )
// metricValues: {
//   bits: number[];
//   packets: number[];
//   loss: number[];
//   jitter: number[];
// };

// keep this
const result = {
    passed: true,
    errMessage: ""
};

// example calculations
const array = metricValues.loss;

let avgLoss = array.reduce((total, value) => total + value, 0) / array.length;

if (avgLoss > 1) {      
    result.passed = false;
    result.errMessage = "Data loss is too big!";
}

// keep this
result; 
`;
