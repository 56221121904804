import * as React from "react";
import { Statuses } from "../../constants/TestStatus";

import Build from "@material-ui/icons/Build";
import Done from "@material-ui/icons/Done";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Replay from "@material-ui/icons/Replay";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import Warning from "@material-ui/icons/Warning";
import Error from "@material-ui/icons/Error";
import Cancel from "@material-ui/icons/Cancel";
import Timer from "@material-ui/icons/Timer";
import "./statusicon.css";

interface StatusIconSwitchProps {
  status: string;
  style?: React.CSSProperties;
}

export default (props: StatusIconSwitchProps): JSX.Element => {
  switch (props.status) {
    case Statuses.warnings:
      return <Warning style={{ color: "#F1CD2B", ...props.style }} />;
    case Statuses.error:
      return <RemoveCircle style={{ color: "#a22a21", ...props.style }} />;
    case Statuses.failure:
      return <Error style={{ color: "#A22A21", ...props.style }} />;
    case Statuses.timeout:
      return <Timer style={{ color: "#c4c4c4", ...props.style }} />;
    case Statuses.completed:
      return <Done style={{ color: "#559542", ...props.style }} />;
    case Statuses.serviceFailure:
      return <Build style={{ color: "#c4c4c4", ...props.style }} />;
    case Statuses.terminated:
      return <Cancel style={{ color: "#676A6C", ...props.style }} />;
    case Statuses.started:
      return <Replay style={{ color: "#3577c1", ...props.style }} className="loader-icon" />;
    case Statuses.retry:
      return <Replay style={{ color: "#c4c4c4", ...props.style }} />;
    case Statuses.dismissed:
      return <ExitToApp style={{ color: "#c4c4c4", ...props.style }} />;
    default:
      return <div style={{ width: 24 }} />;
  }
};
