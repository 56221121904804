import * as _ from "lodash";

export default class ChartHelper {
  static flatEvents(events: Array<any>) {
    const flattenArrayOfEvents: Array<any> = [];

    events.forEach((eventsArray) => {
      Array.prototype.push.apply(flattenArrayOfEvents, eventsArray);
    });

    return flattenArrayOfEvents;
  }

  // just sum two arrays
  static sumArray(left: any, right: any, isAvg: boolean) {
    // tslint:disable-next-line:no-shadowed-variable
    let idx;
    let len = 0;
    const slice = Array.prototype.slice;

    // use slice to not store reference to origin arrays
    // because it may change value right there
    const lArr = slice.call(left),
      rArr = slice.call(right);

    // if lArr larger then we sum values there
    // in other case vice versa
    let dst, src;
    if (lArr.length > rArr.length) {
      dst = lArr;
      src = rArr;
    } else {
      src = lArr;
      dst = rArr;
    }

    len = src.length;
    for (idx = 0; idx < len; idx++) {
      if (isAvg) {
        /* undefined will be coerced to false */
        dst[idx] = (+dst[idx] + +src[idx]) / 2;
      } else {
        dst[idx] += +src[idx];
      }
    }

    return dst;
  }

  // we get data like array of objects
  // for chart we need array of numbers
  static prepareFilterOptions(filterObj: any, _sortBy = "probeId") {
    const outObj = {
      audio: { send: {}, recv: {} },
      video: { send: {}, recv: {} },
      data: { send: {}, recv: {} },
    };

    if (filterObj) {
      const mediaRefs = Object.keys(filterObj);
      const directionRefs =
        _sortBy === "send"
          ? Object.keys(filterObj[mediaRefs[0]]).reverse()
          : Object.keys(filterObj[mediaRefs[0]]);

      // some stuff to sort by send and receive
      // var sortObj = {
      //   audio: { send: {}, recv: {} },
      //   video: { send: {}, recv: {} },
      //   data: { send: {}, recv: {} },
      // };

      for (const mRef of mediaRefs) {
        for (const dRef of directionRefs) {
          // tslint:disable-next-line: forin
          for (const type in filterObj[mRef][dRef]) {
            if (type) {
              if (!Array.isArray(outObj[mRef][dRef][type])) {
                outObj[mRef][dRef][type] = [];
              }

              let array = filterObj[mRef][dRef][type];
              if (_sortBy === "probeId") {
                array = array.sort((a: any, b: any) => {
                  return a?.probeId?.localeCompare(b.probeId, undefined, {
                    numeric: true,
                    sensitivity: "base",
                  });
                });
              }

              // some stuff to sort by send and receive
              // if (_sortBy !== "probeId") {
              //   if (_sortBy === dRef) {
              //     sortObj[mRef][dRef][type] = array
              //       .sort((a: any, b: any) => {
              //         const first =
              //           a !== null
              //             ? a.value && a.value.hasOwnProperty("average")
              //               ? a.value.average
              //               : 0
              //             : 0;
              //         const second =
              //           b !== null
              //             ? b.value && b.value.hasOwnProperty("average")
              //               ? b.value.average
              //               : 0
              //             : 0;
              //         return first - second;
              //       })
              //       .map((v: any) => v.probeId);
              //   } else {
              //     console.log("else", sortObj);
              //   }
              // }

              const tmpTypeArray = array.map((el: any, _idx: any, _arr: any) => {
                if (el !== null) {
                  const val =
                    el.value && el.value.hasOwnProperty("average") ? el.value.average : el.value;
                  return _.round(val, 2);
                } else {
                  return 0;
                }
              });

              Array.prototype.push.apply(outObj[mRef][dRef][type], tmpTypeArray);
            }
          }
        }
      }
    }

    return outObj;
  }

  // private fns end border

  static prepareGlobalEvents(eventsObj: any) {
    const type = "global";
    // like [ [evt, evt], [...] ]
    const arrayOfEventsArray = eventsObj.map((obj: any) => {
      // let onlyGlobal = obj.events.filter( evt => {
      //   return evt.type === 'global';
      // });

      // INFO: adding runIndex for multi iterations
      // eslint-disable-next-line
      let globalEventsOnly = (obj.events || []).map((evt: any) => {
        if (evt.type === type) {
          evt.runIndex = obj.runIndex;
          return { ...evt, inSessionIdx: obj.inSessionIdx, machine: obj.machine };
        }
      });

      // return onlyGlobal;
      return globalEventsOnly;
    });

    return this.flatEvents(arrayOfEventsArray);
  }

  // TODO: I think better to make it private and use here not in public
  // still thinking about it, will see what's better
  // no return due to sort behavior
  static sortEventsAsc(events: Array<any>) {
    events.sort((a, b) => {
      return (new Date(a.timestamp) as any) - (new Date(b.timestamp) as any);
    });
  }

  // small trick ;)
  // do not chain because sort do its job in place
  static sortEventsDesc(events: Array<any>) {
    this.sortEventsAsc(events);
    events.reverse();
  }

  // merge audio and video info all
  // which will contain all summed data
  static audioVideoMerge(info: any, isAvg: boolean) {
    const dst = _.cloneDeep(info);
    // prepare property which we will put inside global summed object
    const all = { send: {}, recv: {} };

    // travers send and recv fields and sum them | all
    if (dst.audio) {
      ["recv", "send"].forEach((direction: any) => {
        // let us suppose that send and recv has the same properties
        //  which has to be true in all cases
        for (const prop in dst.audio[direction]) {
          if (dst.audio[direction].hasOwnProperty(prop)) {
            // just to be sure
            if (prop === "loss") {
              this.prepareLoss(dst.audio[direction][prop]);
              if (dst.video && dst.video[direction] && dst.video[direction].hasOwnProperty(prop)) {
                this.prepareLoss(dst.video[direction][prop]);
              }
            }

            const audio =
              dst.audio && dst.audio[direction] && dst.audio[direction][prop]
                ? dst.audio[direction][prop]
                : [];
            const video =
              dst.video && dst.video[direction] && dst.video[direction][prop]
                ? dst.video[direction][prop]
                : [];

            all[direction][prop] = this.sumArray(audio, video, isAvg);
          }
        }
      });
    }

    // modify the source object
    dst.all = all;
    return dst;
  }

  static prepareLoss(loss: Array<any>) {
    let prevValue = 0;
    for (let i = 0; i < loss.length - 1; i++) {
      const temp = loss[i];
      loss[i] = loss[i] - prevValue < 0 ? 0 : loss[i] - prevValue;

      prevValue = temp;
    }

    if (loss[loss.length - 1] < 0) {
      loss[loss.length - 1] = 0;
    }
  }

  // find percents for loss packets chart
  // https://redmine.testrtc.com/issues/4235
  // recalculate % only for recv
  static calculatePercents(fullPackets: any, lostPackets: any, recv = false) {
    // 20 / 200 * 100
    const output = [];

    let idx = 0;
    const len = lostPackets.length;
    let percent = 0;
    for (idx = 0; idx < len; idx++) {
      if (recv) {
        percent = (lostPackets[idx] / (lostPackets[idx] + fullPackets[idx])) * 100;
      } else {
        percent = (lostPackets[idx] / fullPackets[idx]) * 100;
      }
      output.push(percent);
    }

    return output;
  }

  static prepareFilterPerformance(perfData: any) {
    const res = {};
    ["browserCpu", "browserMemory"].forEach((prop) => {
      if (perfData.hasOwnProperty(prop)) {
        res[prop] = perfData[prop];
      } else {
        res[prop] = {
          avg: [],
          min: [],
          max: [],
        };
      }
    });
    return res;
  }

  static prepareFilterProbePerformance(probePerf: any) {
    const res = {};
    ["browserCpu", "browserMemory"].forEach((prop) => {
      res[prop] = {
        avg: [],
        min: [],
        max: [],
      };
      if (probePerf.hasOwnProperty(prop)) {
        const arr = probePerf[prop].sort((a: any, b: any) => {
          return a?.probeId?.localeCompare(b.probeId, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        });
        arr.forEach((probeData: any) => {
          ["avg", "max", "min"].forEach((metric) => {
            res[prop][metric].push(probeData.values[metric]);
          });
        });
      }
    });
    return res;
  }
}
