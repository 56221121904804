import * as React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Breadcrumb from "../Breadcrumb";
import * as Routes from "../../../../constants/RoutesNames";

const testingRTCRoutes = [
  {
    route: Routes.Tests,
    label: "Scripts",
  },
  {
    route: Routes.TestRun,
    label: "Hitory",
  },
  {
    route: Routes.Assets,
    label: "Assets",
  },
];

export const tree = {
  [Routes.Tests]: testingRTCRoutes,
  [Routes.TestRun]: testingRTCRoutes,
  [Routes.Assets]: testingRTCRoutes,
};

const a11yProps = (index: number) => {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
};

const LinkTab = (props: any) => {
  const handleClick = () => {
    props.history.push(props.href);
  };
  return (
    <Tab
      component="a"
      onClick={(event: any) => {
        event.preventDefault();
        handleClick();
      }}
      {...props}
    />
  );
};

const AdvancedToolbar = (props: any) => {
  const { classes, headText, location } = props;
  const [value, setValue] = React.useState(0);
  const tabs = tree[location.pathname];

  // just stub
  if (tabs) {
    //
  }
  // React.useEffect(() => {
  //   const pathname = location.pathname;
  //   if (tabs) {
  //     const currentRouteIndex = tabs.findIndex((t: any) => t.route === pathname);
  //     if (currentRouteIndex !== -1) {
  //       setValue(currentRouteIndex);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [location]);

  if (true) {
    return (
      <Toolbar className={classes.toolbar}>
        <Breadcrumb />
        <Typography
          data-cy={`headText-${headText}`}
          className={classes.headText}
          variant="subtitle1"
          color="inherit"
        >
          {headText}
        </Typography>
      </Toolbar>
    );
  }

  const handleChange = (_event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <div className={classes.tabsWrapper}>
      <Breadcrumb />
      <Tabs variant="standard" value={value} onChange={handleChange} aria-label="nav-tabs">
        {tabs.map((t: any, index: number) => {
          return (
            <LinkTab
              key={index}
              label={t.label}
              href={t.route}
              {...a11yProps(index)}
              history={props.history}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

export default AdvancedToolbar;
