import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { Theme, WithStyles, createStyles } from "@material-ui/core/styles";
import AppToolbar from "./AppToolbar";
import AppError from "./AppError";
import { NavbarHeight, DrawerWidth } from "../../../styles/Constants";
import { ProjectsContext, AppControllerState } from "../../AppController";

type AppContentProps = {
  error: Error | null;
};

class AppContent extends React.Component<
  WithStyles<"contentProperty" | "contentWrapper"> & RouteComponentProps<{}> & AppContentProps
> {
  public render(): JSX.Element {
    const { classes, error } = this.props;

    return (
      <main className={classes.contentProperty}>
        <ProjectsContext.Consumer>
          {(projectProps: AppControllerState) => (
            <>
              <AppToolbar selectedProject={projectProps.selectedProject} />
              {!error && <div className={classes.contentWrapper}>{this.props.children}</div>}
              {error && <AppError />}
            </>
          )}
        </ProjectsContext.Consumer>
      </main>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    contentProperty: {
      width: "100%",
      // height: `calc(100% - ${NavbarHeight}px)`,
      [theme.breakpoints.up("md")]: {
        position: "relative",
        width: `calc(100% - ${DrawerWidth}px)`,
      },
      minHeight: `calc(100vh - ${NavbarHeight}px)`,
      flexGrow: 1,
      backgroundColor: "#D6D7DB",
      padding: 0,
      marginTop: NavbarHeight,
      overflowY: "auto",
    },
    contentWrapper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(8),
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}> & AppContentProps>(decorate(AppContent));
