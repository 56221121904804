import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import NavBar from "./components/NavBar";
import WorkingSection from "./components/WorkingSection";
import { Redirect, Route, Switch } from "react-router";
import SignInPage from "../SignInPage";
import SignUpPage from "../SignUpPage";
import {
  Main,
  Login as LoginRoutes,
  WebrtcInternalsFreeAnalyzeRtcId,
} from "../../../constants/RoutesNames";
import SignUpConfirmPage from "../SignUpConfirmPage";
import ForgotPasswordPage from "../ForgotPasswordPage/index";
import ResetPassword from "../ResetPassword";
import SubscribePage from "../SubscribePage/index";
import AnalyzeDumpPage from "../AnalyzeDumpPage/index";
import AuthService from "../../../services/AuthService";
import AuthProvider from "../AuthProvider";
import ConfirmAccount from "../ConfirmAccount";
import Report from "../Report";
import WebRTCInternals from "../../LogsContainer/WebRTCInternals";

class LoginLayout extends React.Component<RouteComponentProps<{}>> {
  componentDidMount() {
    if (AuthService.loggedIn()) {
      this.props.history.push(Main);
    } else {
      console.log("LoginLayout componentDidMount not loggin");
    }
  }

  render() {
    return (
      <AuthProvider>
        <NavBar />
        <WorkingSection>
          <Switch>
            <Route exact={true} path={LoginRoutes.SignIn} component={SignInPage} />
            <Route exact={true} path={LoginRoutes.SignUp} component={SignUpPage} />
            <Route exact={true} path={LoginRoutes.ForgotPassword} component={ForgotPasswordPage} />
            <Route exact={true} path={LoginRoutes.ResetPasswordId} component={ResetPassword} />
            <Route exact={true} path={LoginRoutes.ConfirmAccountId} component={ConfirmAccount} />
            <Route exact={true} path={LoginRoutes.Subscribe} component={SubscribePage} />
            <Route exact={true} path={LoginRoutes.Analyze} component={AnalyzeDumpPage} />
            <Route exact={true} path={LoginRoutes.ReportFreeId} component={Report} />
            <Route
              exact={true}
              path={WebrtcInternalsFreeAnalyzeRtcId}
              component={WebRTCInternals}
            />
            <Route
              exact={true}
              path={`${LoginRoutes.SignUpConfirm}/:email`}
              component={SignUpConfirmPage}
            />
            <Redirect exact={true} from="/" to={LoginRoutes.SignIn} />
          </Switch>
        </WorkingSection>
      </AuthProvider>
    );
  }
}

export default withRouter<RouteComponentProps<{}>>(LoginLayout);
