import * as React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";

type TooltipProps = {
  children: React.ReactNode;
  content?: string | React.ReactNode;
  display?: boolean;
  displayType?: string;
};

interface ITooltipState {
  visible: boolean;
}

type StyledComponent = WithStyles<typeof styles>;

class Tooltip extends React.Component<TooltipProps & StyledComponent, ITooltipState> {
  element: HTMLElement;
  mainWrapper: HTMLElement | null;

  constructor(props: TooltipProps & StyledComponent) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  show = () => this.setVisibility(true);

  hide = () => this.setVisibility(false);

  setVisibility = (visible: boolean) => {
    this.setState({
      visible,
    });
  };

  render() {
    const { classes, display, displayType } = this.props;

    if (display !== undefined && !display) {
      return <span>{this.props.children}</span>;
    }

    return (
      <div
        onMouseEnter={this.show}
        onMouseLeave={this.hide}
        className={classes.wrapper}
        ref={(el) => (this.element = el as HTMLElement)}
        style={{
          display: displayType === undefined ? "inline-block" : displayType,
        }}
      >
        {this.props.children}
        {this.state.visible && (
          <div className={classes.tooltip}>
            <div className={classes.contentProperty}>{this.props.content}</div>
            <div className={classes.arrow} />
            <div className={classes.gapProperty} />
          </div>
        )}
      </div>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
      zIndex: 98999,
      color: "#555",
      cursor: "pointer",
    },
    tooltip: {
      whiteSpace: "nowrap",
      position: "absolute",
      zIndex: 99999,
      minWidth: "60px",
      maxWidth: "max-content",
      background: "rgba(0, 0, 0, 0.7)",
      borderRadius: "2px",
      bottom: "100%",
      left: "50%",
      marginBottom: "4px",
      padding: "5px",
      webkitTransform: "translateX(-50%)",
      msTransform: "translateX(-50%)",
      OTransform: "translateX(-50%)",
      transform: "translateX(-50%)",
    },
    contentProperty: {
      color: "#fff",
      display: "inline",
      fontSize: ".85em",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      padding: ".3em 1em",
    },
    arrow: {
      position: "absolute",
      width: "0",
      height: "0",
      bottom: "-5px",
      left: "50%",
      marginLeft: "-5px",
      borderLeft: "solid transparent 5px",
      borderRight: "solid transparent 5px",
      borderTop: "solid rgba(0, 0, 0, 0.7) 5px",
    },
    gapProperty: {
      position: "absolute",
      width: "100%",
      height: "20px",
      bottom: "-20px",
      pointerEvents: "none",
    },
  });

export default withStyles(styles)(Tooltip);
