// tslint:disable:typedef no-any noImplicitAny
// tslint:disable: member-ordering
import * as React from "react";
import * as _ from "lodash";
import * as moment from "moment";

import Typography from "@material-ui/core/Typography";
import TitleLinkIcon from "@material-ui/icons/HelpOutline";

import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";

import highchartsMore from "highcharts/highcharts-more";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import boost from "highcharts/modules/boost";

import Legend, { ILegendProps } from "./components/Legend";

NoDataToDisplay(Highcharts);
highchartsMore(Highcharts);
boost(Highcharts);

(window as any).Highcharts = Highcharts;

export interface ChartState {
  chartOptions: any;
  hideChart: boolean;
}

export interface ChartProps extends ILegendProps {
  id: string;
  title?: string;
  titleLink?: string;
  dataset: Array<any>;
  options: any;
  width?: string;
  height?: number | string;
  style?: React.CSSProperties;
  yAxisLabel?: string;
  xAxisLabel?: string;
  iteration?: string | number;
  byTimeActivated?: boolean;
  byProbeActivated?: boolean;
  hoverable?: boolean;
  filterOption?: string;
  legend?: boolean;
  /**
   * @deprecated Seconds are redrawing automatically. Can remove
   */
  xAxisRedrawSeconds?: boolean;
  unsetMinY?: boolean;
  onHideChart?: (flag: boolean) => void;
  showNoDataMessage?: boolean;
  allowZeros?: boolean;
  hideSeriesIfEmpty?: boolean;
}

const headStyles: React.CSSProperties = {
  textTransform: "capitalize",
  fontSize: 15,
};

const titleContainerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "end",
  justifyContent: "center",
};

const titleIconStyle: React.CSSProperties = {
  color: "grey",
  marginLeft: 5,
};

export default class Chart extends React.Component<ChartProps & HighchartsReact.Props, ChartState> {
  defaultChartOptions = {} as any;
  chartRef = null as any;
  static x: any;
  static y: any;
  static series: any;
  static points: Array<any>;

  static customTooltips: Array<any> = [];

  static formatTooltip(_tooltip: any, x = this.x, y = this.y, series = this.series) {
    return `<strong>${x}</strong><br/>${series.name} — <strong>${y.toFixed(0)}</strong>`;
  }

  static formatTooltipProbe(_tooltip: any, points = this.points) {
    return points.reduce((s: any, point: any) => {
      return s + "<br/>" + point.series.name + ": " + point.y;
      // eslint-disable-next-line
    }, "<b>" + "Probe #" + this.x + "</b>");
  }

  static formatUsageGraphTooltip(_tooltip: any, points = this.points) {
    return points.reduce((s: any, point: any) => {
      const suffix = point.series.tooltipOptions?.valueSuffix || "";
      const toFixed = point.series.tooltipOptions?.toFixed || 0;
      return (
        s +
        "<br/>" +
        point.series.name +
        ": " +
        `<strong>${point.y.toFixed(toFixed)} ${suffix}</strong>`
      );
      // eslint-disable-next-line
    }, `<strong>${moment(this.x).format("MMM DD HH:mm A")}</strong>`);
  }

  static formatDateTooltip(_tooltip: any, x = this.x, y = this.y, series = this.series) {
    const suffix = series.tooltipOptions?.valueSuffix || "";
    const toFixed = series.tooltipOptions?.toFixed || 0;
    return `<strong>${moment(x).format("MMM DD HH:mm A")}</strong><br/>${
      series.name
    } — <strong>${y.toFixed(toFixed)} ${suffix}</strong>`;
  }

  // find out only this approach to map old custom tooltips in new chart lib
  static formatTooltipByProbeActived(_tooltip: any, points = this.points) {
    let showTooltip = true;
    let tooltip = "<span>";
    points.forEach((point, _index: number) => {
      const tooltips = Chart.customTooltips[point.series.name];
      if (!tooltips) {
        tooltip += `<br/>${point.series.name} (Probe #${point.x} - ${point.y.toFixed(3)})`;
      } else {
        const correspondingTooltip = tooltips.filter((t: any) => {
          if (!t.value) {
            return false;
          }
          if (_.isNumber(t.value)) {
            return t.value.toFixed(3) === point.y.toFixed(3);
          }
          if (_.isObject(t.value) && Object.keys(t.value).length === 0) {
            return false;
          }
          return t.value.average.toFixed(3) === point.y.toFixed(3);
        })[0];
        if (!correspondingTooltip || !correspondingTooltip.probeId) {
          showTooltip = false;
          return;
        }
        if (correspondingTooltip && correspondingTooltip.value) {
          if (
            Object.keys(correspondingTooltip.value).every(
              (t) => correspondingTooltip.value[t] === 0
            )
          ) {
            showTooltip = false;
            return;
          }
        }
        tooltip += `<br/>${point.series.name} (Probe #${
          correspondingTooltip.probeId
        } - ${point.y.toFixed(3)})`;
      }
    });
    tooltip += "</span>";
    if (!showTooltip) {
      return false;
    }
    return tooltip;
  }

  constructor(props: ChartProps & HighchartsReact.Props) {
    super(props);

    this.defaultChartOptions = {
      chart: {
        // height: 200,
        borderWidth: 0,
        borderColor: "#000",
      },
      lang: {
        noData: "No data to display",
      },
      noData: {
        style: {
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
          fontSize: 15,
          fontWeight: "normal",
        },
      },
      title: { text: null },
      colors: ["#edc240", "#afd8f8", "#cb4b4b", "#4da74d", "#9440ed"],
      legend: {
        enabled: false,
      },
      yAxis: {
        labels: {
          formatter: this.yAxisFormatter,
          tickDecimals: 2,
          tickInterval: 1,
        },
        maxPadding: 0,
        min: 0,
      },
      xAxis: {
        labels: {
          tickDecimals: 2,
          tickInterval: 1,
        },
      },
      series: [],
      plotOptions: {
        line: {
          lineWidth: 5,
        },
        enableMouseTracking: true,
        series: {
          opacity: 1,
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
          lineWidth: "5px",
        },
      },
      tooltip: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
          },
        ],
      },
    };
    this.state = {
      // To avoid unnecessary update keep all options in the state.
      chartOptions: _.cloneDeep(this.defaultChartOptions),
      hideChart: false,
    };
  }

  componentDidMount() {
    this.setUpChart(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: ChartProps & HighchartsReact.Props) {
    if (
      !_.isEqual(this.props.options, nextProps.options) ||
      !_.isEqual(this.props.dataset, nextProps.dataset)
    ) {
      this.setUpChart(nextProps);
    }
  }

  setUpChart(props: ChartProps & HighchartsReact.Props) {
    // console.log(props);
    const {
      options,
      height,
      hoverable,
      xAxisLabel,
      yAxisLabel,
      unsetMinY,
      allowZeros = false,
      hideSeriesIfEmpty,
      title,
      syncLegends,
    } = props;
    const series: Array<any> = [];
    let dataset: Array<any> = [];

    // Dirty hack for now
    const isProbeRTCChart = options.tooltip?.usageGraph;
    const enableBoost = props.dataset?.length > 10;

    dataset = props.dataset;
    if (
      dataset.every(
        (ds) =>
          !ds.data || ds.data.length === 0 || ds.data.every((d: any) => d[1] === 0 || d[1] === null)
      ) &&
      (!allowZeros || (allowZeros && dataset.length === 0))
    ) {
      console.log(`yura: Chart/index.tsx\n${title} data is empty.`, dataset);
      this.hideChart(true);
      return;
    } else if (this.state.hideChart) {
      this.setState({
        ...this.state,
        hideChart: false,
      });
      this.hideChart(false);
    }

    if (!isProbeRTCChart) {
      dataset = this.prepareData(dataset);
    }

    dataset.forEach((ds) => {
      if (!ds.data || ds.data.length === 0) {
        return;
      }
      if (hideSeriesIfEmpty) {
        if (ds.data.every((d: any) => d[1] === 0 || d[1] === null)) {
          return;
        }
      }
      if (
        ds.label.indexOf("Global ") !== -1 ||
        ds.label.indexOf("Local ") !== -1 ||
        ds.label.indexOf("Call end") !== -1 ||
        ds.label.indexOf("Join ") !== -1 ||
        ds.label.indexOf("Leave ") !== -1
      ) {
        // https://www.highcharts.com/forum/viewtopic.php?t=34522#p121130
        // http://jsfiddle.net/ghuzdjhb/
        if (_.isArray(options.yAxis)) {
          ds.yAxis = 1;
        }
        try {
          // distinct arrays of array(points)
          const distincted: Array<any> = ds.data
            // get X values
            .map((d: any) => d[0])
            // distinct array of X values
            .filter((value: any, index: any, self: any) => {
              return self.indexOf(value) === index;
            })
            // map to new array(point)
            // 2nd value(Y value) is not matter, but must exists
            // it sets in setMaxPointForVerticalLines function
            .map((d: any) => [d, _.isArray(options.yAxis) ? 1000000 : 0]);

          ds.data = distincted;
          if (!this.props.byProbeActivated) {
            ds.data = ds.data.sort((a: Array<any>, b: Array<any>) => Number(a[0]) - Number(b[0]));
          }
        } catch (err) {
          console.error(`Chart series: ${ds.label} has incorrect data`);
          return;
        }
      }
      const type = this.getSeriesType(ds);
      let serie;

      if (enableBoost) {
        serie = {
          boostThreshold: 100,
          data: ds.data,
          name: ds.label,
          lineWidth: type === "arearange" ? 0 : 2,
          type,
        } as any;
      } else {
        serie = {
          name: ds.label,
          data: ds.data,
          type,
          zIndex: type === "arearange" ? 0 : 1,
          fillOpacity: type === "arearange" || type === "column" ? 0.5 : 1,
          lineWidth: type === "arearange" ? 0 : 2,
          tooltip: ds.tooltip,
        } as any;
      }

      // do not set visible as undefined
      // as it resets toggled legend item on swith between sources
      if (ds.visible !== undefined) {
        serie.visible = ds.visible;
      }

      if (ds.color) {
        serie.color = ds.color;
      }
      if (ds.yAxis || ds.yAxis === 0) {
        serie.yAxis = ds.yAxis;
      }
      series.push(serie);

      if (ds.helpers && ds.helpers.tooltip) {
        Chart.customTooltips[ds.label] = ds.helpers.tooltip;
      }
    });

    const categories = this.props.byProbeActivated
      ? dataset[0]?.helpers?.tooltip
          .map((t: any, idx: number) => t.probeId || (idx + 1).toString())
          .sort((a: any, b: any) => {
            return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
          })
      : false;

    // if series with columns has more than 5 bars in there then
    // and it is not mobile
    // allow chart adjust bar with automatically
    const barWidth =
      window.innerWidth > 600 &&
      this.props.byProbeActivated &&
      series?.filter((x) => x.type === "column").every((x) => x.data?.length < 5)
        ? 100
        : undefined;

    const newChartOptions = {
      boost: enableBoost
        ? {
            // Chart-level boost when there are more than 15 series in the chart
            seriesThreshold: 15,
          }
        : undefined,
      time: {
        ...options.time,
      },
      chart: {
        ...options.chart,
        height: height || null,
      },
      xAxis: {
        ...options.xAxis,
        title: {
          ...options.xAxis?.title,
          text: xAxisLabel || options.xAxis?.axisLabel || "",
        },
        labels: {
          ...options.xAxis?.labels,
          formatter: options.xAxis?.labels?.formatter || options.xAxis?.formatter,
        },
        crosshair:
          options.xAxis?.crosshair || this.props.byProbeActivated || isProbeRTCChart ? true : false,
        categories: options.xAxis?.categories || categories,
      },
      yAxis: _.isArray(options.yAxis)
        ? options.yAxis
        : {
            ...options.yAxis,
            title: {
              ...options.yAxis?.title,
              text: yAxisLabel || options.yAxis?.axisLabel || "",
            },
            labels: {
              formatter: (context: any) => {
                return options.yAxis?.formatter
                  ? options.yAxis.formatter(context.value, context)
                  : this.yAxisFormatter(context);
              },
            },
            min: unsetMinY ? null : 0,
          },
      series,
      plotOptions: {
        ...options.plotOptions,
        series: {
          events: {
            ...options.plotOptions?.series?.events,
            legendItemClick: options?.plotOptions?.series?.events?.legendItemClick
              ? options?.plotOptions?.series?.events?.legendItemClick
              : syncLegends
              ? function (this: any, _event: any) {
                  const _this = this as any;
                  const charts = [...Highcharts?.charts];
                  if (charts?.length) {
                    charts.pop();
                    charts.forEach((c) => {
                      if (c?.series[_this.index]) {
                        if (_this.visible) {
                          c?.series[_this.index].hide();
                        } else {
                          c?.series[_this.index].show();
                        }
                      }
                    });
                  }
                }
              : undefined,
          },
          line: {
            states: {
              hover: {
                enabled: options.grid?.hoverable || hoverable,
              },
            },
          },
          states: {
            hover: {
              enabled: options.grid?.hoverable || hoverable,
            },
          },
          marker: options.plotOptions?.marker || {
            enabled: false,
            symbol: null,
          },
          pointWidth: options.plotOptions?.series?.pointWidth || barWidth,
        },
        column: this.props.byProbeActivated
          ? {
              grouping: false,
              shadow: false,
              opacity: 0.7,
              pointPadding: 0,
              groupPadding: 0,
              borderWidth: 1,
            }
          : options.plotOptions?.column
          ? options.plotOptions?.column
          : {},
      },
      tooltip: {
        enabled: options.tooltip?.enabled || options.tooltip?.show || this.props.byProbeActivated,
        formatter: options.tooltip?.formatter
          ? options.tooltip.formatter
          : this.props.byProbeActivated
          ? Chart.formatTooltipProbe
          : isProbeRTCChart
          ? Chart.formatUsageGraphTooltip
          : Chart.formatTooltip,
        headerFormat: "",
        shared:
          this.props.byProbeActivated || isProbeRTCChart
            ? true
            : options.tooltip?.shared
            ? true
            : false,
        split: options.tooltip?.split,
      },
    };

    const mergedChartOptions = _.merge({}, this.defaultChartOptions, newChartOptions);
    // console.log(mergedChartOptions);
    this.setState({
      chartOptions: mergedChartOptions,
    });
  }

  hideChart = (flag: boolean) => {
    const { onHideChart } = this.props;
    this.setState({
      ...this.state,
      hideChart: flag,
    });
    if (onHideChart) {
      onHideChart(flag);
    }
  };

  // set global/local/call end events as full chart height
  setMaxPointForVerticalLines(chart: any) {
    if (!chart?.yAxis) {
      return;
    }
    const extremes = chart.yAxis[0].getExtremes();
    if (!extremes.max) {
      return;
    }
    let changed = false;
    const options = this.state.chartOptions;
    options.series.forEach((s: any) => {
      if (
        s.name &&
        (s.name.indexOf("Global") !== -1 ||
          s.name.indexOf("Local") !== -1 ||
          s.name.indexOf("Call end") !== -1 ||
          s.name.indexOf("Session") !== -1 ||
          s.name.indexOf("Join") !== -1 ||
          s.name.indexOf("Leave") !== -1)
      ) {
        s.data.forEach((d: Array<any>) => {
          if (_.isArray(d)) {
            if (d[1] !== extremes.max) {
              changed = true;
            }
            d[1] = extremes.max;
          }
        });
      }
    });
    if (changed) {
      this.setState({ chartOptions: { ...this.state.chartOptions, options } });
    }
  }

  yAxisFormatter = (context: any) => {
    const splittedVal = context.value.toString().split(".");
    if (!splittedVal[1]) {
      return splittedVal[0];
    } else if (splittedVal[1].length > 2 || Number(context.value) < 1) {
      return context.value.toFixed(2);
    } else {
      return context.value;
    }
  };

  getSeriesType(serie: any) {
    if (serie.type) {
      return serie.type;
    }
    if (serie.bars && serie.bars.show) {
      return "column";
    }
    return serie.data &&
      serie.data.length &&
      serie.data[serie.data.length - 1].length &&
      serie.data[serie.data.length - 1].length === 3
      ? "arearange"
      : "line";
  }

  getMaxY(dataset: Array<any>) {
    let max = 0;
    dataset.forEach((ds) => {
      if (!ds.data) {
        return;
      }
      ds.data.forEach((point: Array<any>) => {
        // second value in point is Y
        // check if Y exist
        if (!point[1] || !_.isNumber(point[1]) || !isFinite(point[1])) {
          return;
        }
        if (point[1] > max) {
          max = point[1];
        }
      });
    });

    return max;
  }

  // replace Y axis Infinity/NaN values
  prepareData(dataset: Array<any>) {
    const maxY = this.getMaxY(dataset);
    dataset.forEach((ds) => {
      if (!ds.data) {
        return;
      }
      ds.data.forEach((point: Array<any>) => {
        if (!_.isArray(point)) {
          point = [0, 0];
        }
        if (isNaN(point[1])) {
          point[1] = 0;
        }
        if (point[1] && !isFinite(point[1])) {
          if (ds.label.indexOf("band") !== -1) {
            point[1] = maxY;
          }
        }
      });
    });
    return dataset;
  }

  render() {
    const { chartOptions, hideChart } = this.state;
    const { title, titleLink, showNoDataMessage, syncLegends, legend } = this.props;
    let isLegend = true;

    // by default legend is ON for all Charts
    if (legend !== undefined) {
      isLegend = legend;
    }

    if (hideChart) {
      if (showNoDataMessage) {
        return (
          <Typography style={headStyles} variant="subtitle1" gutterBottom={true} align="center">
            {"No data"}
          </Typography>
        );
      }
      return null;
    }
    return (
      <div style={{ width: "100%" }}>
        {title && (
          <div style={titleContainerStyle}>
            <Typography style={headStyles} variant="subtitle1" align="center">
              {title}
            </Typography>
            {titleLink && (
              <a href={titleLink} target="_blank" rel="noopener noreferrer" style={titleIconStyle}>
                <TitleLinkIcon />
              </a>
            )}
          </div>
        )}
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          ref={(ref: any) => (this.chartRef = ref)}
        />
        {isLegend && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 16,
            }}
          >
            <Legend
              chartRef={this.chartRef}
              syncLegends={syncLegends}
              suppressDirectionSortToLegend={!this.props.suppressDirectionSortToLegend}
              toggleAll={this.props.toggleAll}
            />
          </div>
        )}
      </div>
    );
  }
}
