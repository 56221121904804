import * as React from "react";
import Paper from "@material-ui/core/Paper";
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
  Tooltip as MUITooltip,
} from "@material-ui/core";
import RTCGrid, { FilterValue, GridFilter } from "../../components/Grid";
import ApiPath from "../../constants/ApiPath";
import { RouteComponentProps } from "react-router";
import { TestRunDetails } from "../../constants/RoutesNames";
import Flag from "@material-ui/icons/Flag";
import Bookmark from "@material-ui/icons/Bookmark";
import Comment from "@material-ui/icons/Comment";
import * as moment from "moment";
import Tooltip from "../../components/Tooltip";
import { connect } from "react-redux";
import { FetchMonitorsDistinct } from "../../actions/dictionaryAction";
import { dispatchDataLoading } from "../../actions/dataLoadingActions";
import IconButton from "@material-ui/core/IconButton";
import OpenInNew from "@material-ui/icons/OpenInNew";
import { Statuses } from "../../constants/TestStatus";
import { testStatusHelper } from "../../helpers/testStatusHelper";
import * as _ from "lodash";
import { orderBy } from "lodash";

interface IMonitorHistoryState {
  filters: Array<GridFilter>;
  testRuns: Array<FilterValue>;
}

interface ITestsHistoryDispatch {
  fetchMonitorsDistinct(): void;
  dataLoading(flag: boolean): any; // tslint:disable-line
}

interface IMonitorHistoryProps {
  testRuns: Array<string>;
}

const tooltipHelper = (
  title: string,
  icon: React.ReactElement<React.ReactNode>
): React.ReactNode => {
  return <Tooltip content={title}>{icon}</Tooltip>;
};

const BookMarkStyles = {
  color: "#a22a21",
} as React.CSSProperties;

const CommentStyles = {
  color: "#3577c1",
} as React.CSSProperties;

const IconsContainerStyles = {
  display: "flex",
} as React.CSSProperties;

type StyledComponent = WithStyles<"root" | "tableRowItemHover" | "hoverActionBtnStyle">;

export class GridControl extends React.Component<
  IMonitorHistoryProps & ITestsHistoryDispatch & RouteComponentProps<{}> & StyledComponent,
  IMonitorHistoryState
> {
  columnSchema: Array<ColumnSchema> = [
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      style: { width: "50px", padding: "0 15px" },
      labelRender: () => <Flag />,
      // tslint:disable-next-line:no-any
      render: (dataItem: any) => {
        const tooltip = dataItem.comments || dataItem.iterationComments;
        return (
          <div style={IconsContainerStyles}>
            {dataItem.flag && <Bookmark style={BookMarkStyles} />}
            {testStatusHelper(dataItem.status)}
            {tooltip && (
              <MUITooltip title={tooltip} placement="right-end">
                <Comment style={CommentStyles} />
              </MUITooltip>
            )}
          </div>
        );
      },
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      style: { maxWidth: 230 },
      render: (
        dataItem: any // tslint:disable-line
      ) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pointerEvents: "none",
          }}
        >
          <span>{dataItem.name}</span>
          <div>
            {tooltipHelper(
              "Open link in new tab",
              <IconButton
                className={this.props.classes.hoverActionBtnStyle}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  window.open(`${TestRunDetails}/${dataItem._id}`, "_blank");
                  e.stopPropagation();
                }}
              >
                <OpenInNew />
              </IconButton>
            )}
          </div>
        </div>
      ),
    },
    {
      id: "rank",
      numeric: true,
      disablePadding: true,
      sortable: true,
      label: "Score",
      style: { width: "10px", maxWidth: "10%", padding: "0px 0px" },
      hideDown: "md",
      isObject: true,
      // tslint:disable-next-line:no-any
      render: (rank: any) => {
        return rank ? _.round(rank, 1) : "0";
      },
    },
    {
      id: "textError",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "Reason",
      style: { maxWidth: 350, wordWrap: "break-word" },
      hideDown: "xs",
      // tslint:disable-next-line: no-any
      render: (dataItem: any) => {
        const textError = dataItem.textError ? `${dataItem.textError.substring(0, 300)}` : "";
        const rtcSetAdditionalInfo = dataItem.rtcSetAdditionalInfo
          ? `${dataItem.rtcSetAdditionalInfo.substring(0, 300)}`
          : "";
        return textError.concat(rtcSetAdditionalInfo).substring(0, 300);
      },
    },
    {
      id: "createDate",
      numeric: false,
      disablePadding: false,
      label: "Run",
      // tslint:disable-next-line:no-any
      render: (dataItem: any) =>
        dataItem.createDate ? moment(dataItem.createDate).format("MMM DD, YYYY - HH:mm") : "never",
      style: {
        whiteSpace: "nowrap",
        maxWidth: "10%",
        padding: "0px 10px",
      },
      hideDown: "xs",
    },
    {
      id: "runName",
      numeric: false,
      disablePadding: false,
      label: "Machines",
      style: { maxWidth: "20%", textAlign: "left" },
      hideDown: "md",
    },
  ];

  constructor(
    props: IMonitorHistoryProps & ITestsHistoryDispatch & RouteComponentProps<{}> & StyledComponent
  ) {
    super(props);

    this.state = {
      filters: [],
      testRuns: [],
    };

    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchMonitorsDistinct();
  }

  UNSAFE_componentWillReceiveProps(nextProps: IMonitorHistoryProps) {
    if (nextProps.testRuns.length !== 0) {
      const testRunsFilter: Array<FilterValue> = [{ value: "", label: "Any monitor" }];
      // eslint-disable-next-line
      nextProps.testRuns.map((tr: any) => {
        const testRunFilterSingle: FilterValue = {
          value: tr.name,
          label: tr.name,
          condition: tr.active,
        };
        testRunsFilter.push(testRunFilterSingle);
      });
      const sortedMonitors = orderBy(testRunsFilter, ["value"]);
      this.setState({
        testRuns: sortedMonitors,
      });
    }
  }

  onRowClick(_e: React.MouseEvent<HTMLTableRowElement>, dataItem: GridModel) {
    this.props.history.push(`${TestRunDetails}/${dataItem._id}`);
  }

  render() {
    const { classes, location, dataLoading } = this.props;

    const statusFilterValues = [
      { value: "", label: "Any Result" },
      { value: Statuses.completed, label: "Completed" },
      { value: Statuses.warnings, label: "Warnings" },
      { value: Statuses.serviceFailure, label: "Service failure" },
      { value: Statuses.failure, label: "Failure" },
      { value: Statuses.timeout, label: "Timeout" },
    ];

    const createDateFilterValues = [
      { value: "", label: "Any date" },
      { value: "0", label: "Today" },
      { value: "7", label: "Last 7 days" },
      { value: "30", label: "Last 30 days" },
    ];

    return (
      <Paper className={classes.root}>
        <RTCGrid
          onRowClick={this.onRowClick}
          search={true}
          exportUrl={ApiPath.api.monitorRunsExport}
          remoteDataBound={true}
          searchByLabel={"name/machine/reason"}
          apiRoute={ApiPath.api.monitorRuns}
          columnSchema={this.columnSchema}
          defaultSort={{
            order: "desc",
            orderBy: "createDate",
          }}
          filters={[
            {
              fieldName: "status",
              filterLabel: "Result",
              filterValues: statusFilterValues,
              value: "",
              renderIcon: (status: string) => testStatusHelper(status, false),
            },
            {
              fieldName: "monitorName",
              filterLabel: "Monitor",
              filterValues: this.state.testRuns,
              value: "",
              disabled: this.state.testRuns.length === 0,
            },
            {
              fieldName: "createDate",
              filterLabel: "Date",
              filterValues: createDateFilterValues,
              value: "",
            },
          ]}
          rowProps={{
            className: classes.tableRowItemHover,
          }}
          pagination={true}
          location={location}
          appContentSpinner={(flag: boolean) => dataLoading(flag)}
          enableUrlPaging={true}
          bottomNavigation
        />
      </Paper>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    tableRowItemHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
        "& button": {
          visibility: "visible",
          pointerEvents: "all",
        },
      },
    },
    hoverActionBtnStyle: {
      padding: "0px",
      width: 35,
      minWidth: 35,
      height: 35,
      minHeight: 35,
      visibility: "hidden",
      pointerEvents: "none",
    },
  });

const decorate = withStyles(styles);

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): ITestsHistoryDispatch => ({
  fetchMonitorsDistinct: () => dispatch(FetchMonitorsDistinct()),
  dataLoading: (flag: boolean) => dispatch(dispatchDataLoading(flag)),
});

const mapStateToProps = (state: IStore) => ({
  testRuns: state.dictionary.monitorRuns,
});

// tslint:disable-next-line:no-any
export default connect<IMonitorHistoryProps, ITestsHistoryDispatch, any>(
  mapStateToProps,
  mapDispatchToProps
)(decorate(GridControl));
