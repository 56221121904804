import * as React from "react";
import AssetPropertyForm from "./AssetPropertyForm";
import { AxiosResponse } from "axios";
import { connect } from "react-redux";
import AxiosFactory from "../../services/AxiosFactory";
import ApiPath from "../../constants/ApiPath";
import { Assets, NewAssetProperty } from "../../constants/RoutesNames";
import { RouteComponentProps } from "react-router";
import { SetNotification } from "../../actions/notificationAction";
import withBreadcrumb, { WithBreadcrumb } from "../../components/withBreadcrumb";
import fileDownload from "js-file-download";
import { AppToolbarContext } from "../Layout/components/AppToolbar/Context";

type RouteParams = {
  objectId: string;
};

interface AssetPropertyProps {
  setNotification(message: string): void;
}

export type AssetPropertyState = {
  newMode: boolean;
  // tslint:disable-next-line:no-any
  initialValues: any;
  deleteDialog: boolean;
};

export interface IAssetPropertyForm {
  _id: string;
  projectId: string;
  internal_version: number;
  name: string;
  description?: string;
  asset_type: string;
  version: string;
  code: string;
  updated: string;
}

class AssetProperty extends React.Component<
  AssetPropertyProps & RouteComponentProps<RouteParams> & WithBreadcrumb,
  AssetPropertyState
> {
  static contextType = AppToolbarContext;
  constructor(props: AssetPropertyProps & RouteComponentProps<RouteParams> & WithBreadcrumb) {
    super(props);

    this.state = {
      newMode: true,
      initialValues: {
        asset_type: "code",
        version: "Initial version",
      },
      deleteDialog: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onDuplicateButtonClick = this.onDuplicateButtonClick.bind(this);
    this.openSnackbar = this.openSnackbar.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  componentDidMount() {
    // this.props.pushBreadcrumbNode(this.props.location.pathname, "Asset Configuration");

    const newMode = this.props.location.pathname.includes(NewAssetProperty);
    this.setState(
      {
        newMode,
      },
      async () => {
        if (!newMode) {
          await this.fetchItemValues();
        }
      }
    );
  }

  async fetchItemValues() {
    const axiosFactory = new AxiosFactory();
    const result: AxiosResponse = await axiosFactory.axios.get(
      `${ApiPath.api.assets}/${this.props.match.params.objectId}`
    );
    this.setState({
      initialValues: result.data,
    });
    this.context.setPageInfo(result.data.name);
  }

  async onDuplicateButtonClick() {
    try {
      const id = this.props.match.params.objectId;
      const axiosFactory = new AxiosFactory();
      const result: AxiosResponse = await axiosFactory.axios.get(
        `${ApiPath.api.assetDuplicate}/${id}`
      );
      this.openSnackbar(`Asset copied: ${result.data.name}`);
      this.props.history.push(`${Assets}/${result.data._id}`);
      this.setState({
        initialValues: result.data,
      });
      this.context.setPageInfo(result.data.name);
    } catch (err) {
      console.error(err.message);
      this.openSnackbar("Could not copy asset:");
    }
  }

  onExport() {
    // tslint:disable-next-line: no-any
    const json = JSON.stringify(this.state.initialValues, 0 as any, 4);
    const data = new Blob([json], { type: "application/json" });

    // tslint:disable-next-line: no-any
    fileDownload(data as any, `${this.state.initialValues.name}.json`);
  }

  openSnackbar(message: string) {
    this.props.setNotification(message);
  }

  deleteHandler() {
    this.setState({
      deleteDialog: !this.state.deleteDialog,
    });
  }

  async onDelete() {
    const id = this.props.match.params.objectId;
    const axiosFactory = new AxiosFactory();
    try {
      await axiosFactory.axios.delete(`${ApiPath.api.assets}/${id}`);
      this.openSnackbar("Asset deleted");
    } catch (e) {
      this.openSnackbar("Failed to delete asset");
    }
    this.props.history.push(`${Assets}`);
  }

  render() {
    return (
      <AssetPropertyForm
        onSubmit={this.onSubmit}
        onDuplicateButtonClick={this.onDuplicateButtonClick}
        deleteHandler={this.deleteHandler}
        onDelete={this.onDelete}
        onExport={this.onExport}
        {...this.state}
      />
    );
  }

  private async onSubmit(values: IAssetPropertyForm) {
    try {
      const axiosFactory = new AxiosFactory();
      await axiosFactory.axios({
        url: this.state.newMode ? `${ApiPath.api.assets}` : `${ApiPath.api.assets}/${values._id}`,
        method: this.state.newMode ? "POST" : "PUT",
        data: values,
      });
      if (this.state.newMode) {
        this.props.history.push(Assets);
      } else {
        this.openSnackbar("Asset updated");
      }
    } catch (err) {
      if (err.response && err.response.status === 409) {
        this.openSnackbar(err.response.data.message);
      }
    }
  }
}

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

const BreadcrumbNodes: Array<BreadcrumbNode> = [
  {
    link: Assets,
  },
];

export default withBreadcrumb(connect(null, mapDispatchToProps)(AssetProperty), BreadcrumbNodes);
