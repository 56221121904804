import * as React from "react";

import Channels from "./Channels";
import Overview from "./Overview";
import TestStats from "./TestStats";
import { ChartsState } from "./index";
import { TestIterationContext } from "../index";
// import SyncCharts from "src/components/Chart/SyncCharts";
// import Audio from "./Audio";
// import Video from "./Video";
import Charts from "./Charts";

interface ViewProps {
  pushToFileNames: (item: string) => void;
  changePacketLMAudio: (e: React.ChangeEvent<HTMLInputElement>) => void;
  changePacketLMVideo: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default (props: ChartsState & ViewProps) => (
  <React.Fragment>
    <TestIterationContext.Consumer>
      {({ test, testRun, testDefinition, saveTestIteration, updateTest, isReport, freeReport }) => (
        <>
          <TestStats
            {...props}
            isReport={isReport}
            freeReport={freeReport}
            manualUpload={test?.runName === "manual-upload"}
          />
          <Overview
            test={test}
            testRun={testRun}
            testDefinition={testDefinition}
            saveTestIteration={saveTestIteration}
            updateTest={updateTest}
            isReport={isReport}
            {...props}
          />
          {test.stat && <Channels test={test} testRun={testRun} isReport={isReport} {...props} />}
          {/* <SyncCharts width={"100%"} zoomOnly={true}> */}
          {(props.audio || props.video) && (
            <Charts
              audio={props.audio}
              video={props.video}
              showAudio={props.showAudio}
              showVideo={props.showVideo}
            />
          )}
          {/* </SyncCharts> */}

          {/* {(props.showAudio || props.showVideo) && (
            <>
                {props.showAudio && <Audio {...props} />}
                {props.showVideo && <Video {...props} />}
              </SyncCharts>
            </>
          )} */}
        </>
      )}
    </TestIterationContext.Consumer>
  </React.Fragment>
);
