import * as React from "react";
import { connect } from "react-redux";
import { AxiosResponse, AxiosRequestConfig } from "axios";

import Grid from "@material-ui/core/Grid";

import Video from "../../components/Video";
import UsageGraph from "../../helpers/usageGraphUtils";
import AxiosFactory from "../../services/AxiosFactory";
import ApiPath from "../../constants/ApiPath";

import Updates from "./Widgets/Updates";
import TestingRTC from "./Widgets/TestingRTC";
import UpRTC from "./Widgets/UpRTC";
import ProbeRTC from "./Widgets/ProbeRTC";
import QualityRTC from "./Widgets/QualityRTC";
import WatchRTC, { IWatchRTCData } from "./Widgets/WatchRTC";

import { ReactSVG } from "react-svg";
import LoaderIcon from "../../assets/images/loading-progress.svg";

export interface ITests {
  success: number;
  warnings: number;
  failure: number;
  total: number;
  runningAgents: number;
  agentsLimit: number;
  minutesUsed: number;
}

export interface IActiveMonitorList {
  docs: any[];
}

export interface IMonitors {
  success: number;
  warnings: number;
  failure: number;
  total: number;
  active: number;
  activeList: IActiveMonitorList;
  activeLimit: number;
}

export interface IBalance {
  FreeBalance: string;
  FreeBalanceValidDate: string;
  FreeCycleInitialMinutes: string;
  LastUpdateDate: string;
  LastUpdateTime: string;
  PaidBalance: string;
  PaidBalanceValidDate: string;
  ProjectId: string;
  ProjectName: string;
}

export interface IProbes {
  active: number;
  activeLimit: number;
  stoppedProbeRuns: number;
}

export interface INetworkTests {
  count: number;
  limit: number;
}

export interface IFeed {
  title: string;
  link: string;
  publicationDate: string;
}

export interface IDashboardData {
  project?: Project;
  tests?: ITests;
  monitors?: IMonitors;
  probes?: IProbes;
  networkTests?: INetworkTests;
  feeds?: Array<IFeed> | undefined;
  watchRTC?: IWatchRTCData;
}

interface MainProps {
  user: User;
}

const Loader = () => {
  return (
    <ReactSVG
      src={LoaderIcon}
      style={{
        position: "fixed",
        left: "calc(50% - 64px)",
        top: "calc(50% - 64px)",
      }}
    />
  );
};

const Main = ({ user }: MainProps) => {
  const [selectedPeriod, setSelectedPeriod] = React.useState(UsageGraph.planPeriods()[2].value);
  const [dashboardData, setDashboardData] = React.useState<IDashboardData | undefined>(undefined);
  const [balance, setBalance] = React.useState<IBalance | undefined>(undefined);

  React.useEffect(() => {
    setSelectedPeriod(UsageGraph.planPeriods()[2].value);
    fetchBalance();
    fetchDashboard();
    // eslint-disable-next-line
  }, []);

  const fetchDashboard = async (time?: string) => {
    if (user?.role === "pending" || !user?.project) {
      return;
    }
    const axiosFactory = new AxiosFactory();
    const axiosConfig: AxiosRequestConfig = {
      params: {
        time: time || selectedPeriod,
      },
    };
    const [project, tests, monitors, probes, networkTests, watchRTC, rss] = await Promise.all([
      axiosFactory.axios
        .get(`${ApiPath.api.projects.root}/${user?.project._id}`)
        .catch((_err: any) => false),
      axiosFactory.axios.get(`${ApiPath.api.dashboards}/testingRTC`, axiosConfig),
      axiosFactory.axios.get(`${ApiPath.api.dashboards}/upRTC`, axiosConfig),
      axiosFactory.axios.get(`${ApiPath.api.dashboards}/probeRTC`, axiosConfig),
      axiosFactory.axios.get(`${ApiPath.api.dashboards}/qualityRTC`, axiosConfig),
      axiosFactory.axios.get(`${ApiPath.api.dashboards}/watchRTC`, axiosConfig),
      axiosFactory.axios.get(`${ApiPath.api.dashboards}/rss`),
    ]);

    const data = {
      tests: tests["data"],
      feeds: rss["data"],
      monitors: monitors["data"],
      probes: probes["data"],
      networkTests: networkTests["data"],
      watchRTC: {
        ...watchRTC["data"],
        maxMinutes: project ? project["data"].watchrtc_maxMinutes || 0 : 0,
      },
    };

    if (project) {
      setDashboardData({
        project: project["data"],
        ...data,
      });
    } else {
      await fetchProject(data);
    }
  };

  const fetchProject = async (other_data: Partial<IDashboardData>) => {
    console.log("Try to get project data again.");
    const axiosFactory = new AxiosFactory();
    const result = await axiosFactory.axios
      .get(`${ApiPath.api.projects.root}/${user?.project._id}`)
      .then((res) => res)
      .catch((_err: any) => false);

    if (result) {
      setDashboardData({
        ...other_data,
        watchRTC: {
          ...(other_data.watchRTC as any),
          maxMinutes: result["data"].watchrtc_maxMinutes || 0,
        },
        project: result["data"],
      });
    } else {
      console.error("yaroslav:fetchProject failed", user, result);
    }
  };

  const fetchBalance = async () => {
    try {
      const axiosFactory = new AxiosFactory();
      const answer: AxiosResponse<any> = await axiosFactory.axios.get(
        `${ApiPath.api.projects.root}/${user?.project._id}/balance`
      );
      const result = answer.data[user?.project._id];
      if (typeof result === "object") {
        setBalance(result);
        return;
      }
      setBalance(undefined);
    } catch (err) {
      // setBalance({
      //   FreeBalance: "0",
      //   FreeBalanceValidDate: "2-Dec-2018",
      //   FreeCycleInitialMinutes: "1000",
      //   LastUpdateDate: "20200622",
      //   LastUpdateTime: "06:30",
      //   PaidBalance: "-13097723",
      //   PaidBalanceValidDate: "20191018",
      //   ProjectId: "57d6d481eb5ecc1200981183",
      //   ProjectName: "DA",
      // });
    } finally {
      //
    }
  };

  if (!dashboardData) {
    return <Loader />;
  }

  return (
    <Grid data-cy="Dashboard" container={true} spacing={2}>
      {dashboardData?.project?._id && (
        <Grid item={true} xs={12}>
          <Video
            projectId={dashboardData.project._id}
            screen="dashboard"
            additionalCondition={dashboardData?.monitors && dashboardData?.monitors.active === 0}
          />
        </Grid>
      )}
      <Grid item={true} xs={12} md={6}>
        {(dashboardData?.project?.maxAudioSessions || 0) > 0 && (
          <TestingRTC tests={dashboardData?.tests} balance={balance} />
        )}
        <Updates feeds={dashboardData?.feeds} />
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <UpRTC monitors={dashboardData?.monitors} />
        {dashboardData?.project?.watchrtc_enable && <WatchRTC data={dashboardData?.watchRTC} />}
        {dashboardData?.project?.opp_enable && <ProbeRTC probes={dashboardData?.probes} />}
        {dashboardData?.project?.qualityrtc_enable && (
          <QualityRTC networkTests={dashboardData?.networkTests} />
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

export default connect(mapStateToProps)(Main);
