import * as React from "react";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles, WithStyles, createStyles } from "@material-ui/core";
import { HideNotification } from "../../actions/notificationAction";

type StoreProps = {
  notification: string;
};

type DispatchProps = {
  hideNotification: () => void;
};

class SnackbarNotifier extends React.Component<StoreProps & DispatchProps & WithStyles> {
  isHtml(str: string) {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  }

  // tslint:disable-next-line: no-any
  autoHide(notification: any) {
    if (!!notification) {
      setTimeout(this.props.hideNotification, 10000);
    }
  }

  render() {
    const { notification, hideNotification, classes } = this.props;
    const message = this.isHtml(notification) ? (
      <span id="notification-snackbar" dangerouslySetInnerHTML={{ __html: notification }} />
    ) : (
      <span id="notification-snackbar">{notification}</span>
    );
    this.autoHide(notification);
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={!!notification}
        data-cy="snack-message"
        onClose={hideNotification}
        className={classes.snackbarContainer}
        message={message}
      />
    );
  }
}

const styles = () =>
  createStyles({
    snackbarContainer: {
      zIndex: 99998,
    },
  });

const decorate = withStyles(styles);

const mapStateToProps = (store: IStore) => ({
  notification: store.notification.notification,
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any) => ({
  hideNotification: () => dispatch(HideNotification()),
});

export default connect<StoreProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(decorate(SnackbarNotifier));
