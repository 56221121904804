// tslint:disable:no-any
import * as React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import * as moment from "moment";
import Typography from "@material-ui/core/Typography";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import Tooltip from "../../../../components/Tooltip";
import ResultLine from "../../../../components/Test/ResultLine";
import { TestProperty, TestLogs } from "../../../../constants/RoutesNames";
import ErrorLine from "../../../../components/Test/ErrorLine";
import LogToolbar from "./LogToolbar/index";
import { formatDuration } from "../../../../helpers/testDetails";
import { TestOverviewStoreProps, TestOverviewState, TestOverviewProps } from "./index";
import BoxMark, { BoxMarkTypes } from "../../../../components/Test/BoxMark";
import { ChartsState } from "../index";
import { TestIterationState } from "../../index";
import { TestIterationContext } from "../../index";
import StatusIcon from "../../../../components/Test/StatusIcon";
import MediaQuality from "./MediaQuality";

type StyledComponent = WithStyles<
  | "card"
  | "overviewLine"
  | "link"
  | "lineValue"
  | "lineIcon"
  | "lineTitle"
  | "divider"
  | "statusValue"
  | "errorStyle"
  | "toolbar"
  | "resultTableHead"
  | "customMetricsContainer"
  | "redText"
>;

type ViewProps = {
  setRating: (rating: number) => void;
  onStatusClick: (e: React.MouseEvent<HTMLElement>) => void | boolean;
};

const View = (
  props: TestOverviewStoreProps &
    TestOverviewState &
    ViewProps &
    ChartsState &
    TestOverviewProps &
    StyledComponent
) => {
  const {
    test,
    testRun,
    testDefinition,
    calc,
    audioMOS,
    filtCusTestMetric,
    pushToFileNames,
    assetsFileNames,
    user,
    classes,
    isReport,
    setRating,
    pendingRequest,
    onStatusClick,
    session,
  } = props;
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <Card className={classes.card}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="subtitle1">Overview</Typography>
            {
              <TestIterationContext.Consumer>
                {(contextProps: TestIterationState) => {
                  return contextProps.test ? (
                    <BoxMark
                      type={BoxMarkTypes.iteration}
                      record={contextProps.test}
                      testIterations={contextProps.testIterations}
                      updateRecord={contextProps.updateTest}
                      hiddenShare={true}
                      navigateToIteration={contextProps.navigateToIteration}
                    />
                  ) : null;
                }}
              </TestIterationContext.Consumer>
            }
          </Toolbar>
          <Divider className={classes.divider} />
          <Grid container={true} spacing={5}>
            {!isReport && (
              <>
                <Grid
                  item={true}
                  lg={4}
                  sm={6}
                  xs={12}
                  style={{
                    paddingTop: 0,
                    marginTop: 20,
                    paddingBottom: 0,
                    marginBottom: 20,
                    borderRight: "1px solid rgba(0, 0, 0, .2)",
                  }}
                >
                  <ResultLine
                    title="Test name"
                    pure={false}
                    tooltipTitle={testDefinition ? "Click to open test script" : undefined}
                    value={
                      !!testDefinition ? (
                        <Link className={classes.link} to={`${TestProperty}/${testRun.testId}`}>
                          {testRun.name}
                        </Link>
                      ) : (
                        <>
                          <span>{test.name || testRun.name}</span>
                          {testDefinition === false && (
                            <span className={classes.redText}>{" (Deleted)"}</span>
                          )}
                        </>
                      )
                    }
                  />
                  {testRun && !testRun.name && (
                    <ResultLine
                      title="Test name"
                      value={
                        <span>
                          <span className={classes.errorStyle}>(No assigned test run)</span>
                        </span>
                      }
                    />
                  )}
                  {user && user.role === "admin" && test.dockerAgentId && (
                    <ResultLine title="Machine" value={test.dockerAgentId} />
                  )}
                  {test.machineIP && (
                    <ResultLine
                      title="IP"
                      tooltipTitle="Machine IP address"
                      value={test.machineIP}
                    />
                  )}
                  {test.machine && (
                    <ResultLine
                      title="Probe"
                      tooltipTitle="Click the probe for more details"
                      value={test.machine}
                    />
                  )}
                  {test.os && (
                    <ResultLine title="OS" tooltipTitle="Operating system" value={test.os} />
                  )}
                  {test.browser && (
                    <ResultLine title="Browser" tooltipTitle="Browser used" value={test.browser} />
                  )}
                  <Hidden lgUp={true}>
                    {test.location && (
                      <ResultLine
                        title="Location"
                        tooltipTitle="Where did we run this machine"
                        value={test.location}
                      />
                    )}
                    {test.networkProfile && (
                      <ResultLine
                        title="Network Profile"
                        tooltipTitle="Network limitation"
                        value={test.networkProfile}
                      />
                    )}
                    {test.firewallProfile && (
                      <ResultLine
                        title="Firewall Profile"
                        tooltipTitle="Firewall limitation"
                        value={test.firewallProfile}
                      />
                    )}
                    {test.mediaProfile && (
                      <ResultLine title="Media" tooltipTitle="Media" value={test.mediaProfile} />
                    )}
                    {session.sessionIdx && (
                      <ResultLine
                        title="Session #"
                        tooltipTitle="Session number"
                        value={session.sessionIdx}
                      />
                    )}
                    {session.sessionSize && (
                      <ResultLine
                        title="Session Size"
                        tooltipTitle="Session size"
                        value={session.sessionSize}
                      />
                    )}
                    {session.inSessionIdx && (
                      <ResultLine
                        title="Session Index"
                        tooltipTitle="Session index"
                        value={session.inSessionIdx}
                      />
                    )}
                  </Hidden>
                </Grid>
                <Hidden mdDown={true}>
                  <Grid
                    item={true}
                    lg={4}
                    style={{
                      paddingTop: 0,
                      marginTop: 20,
                      paddingBottom: 0,
                      marginBottom: 20,
                      borderRight: "1px solid rgba(0, 0, 0, .2)",
                    }}
                  >
                    {test.location && (
                      <ResultLine
                        title="Location"
                        tooltipTitle="Where did we run this machine"
                        value={test.location}
                      />
                    )}
                    {test.networkProfile && (
                      <ResultLine
                        title="Network Profile"
                        tooltipTitle="Network limitation"
                        value={test.networkProfile}
                      />
                    )}
                    {test.firewallProfile && (
                      <ResultLine
                        title="Firewall Profile"
                        tooltipTitle="Firewall limitation"
                        value={test.firewallProfile}
                      />
                    )}
                    {test.mediaProfile && (
                      <ResultLine title="Media" tooltipTitle="Media" value={test.mediaProfile} />
                    )}
                    {session.sessionIdx && (
                      <ResultLine
                        title="Session #"
                        tooltipTitle="Session number"
                        value={session.sessionIdx}
                      />
                    )}
                    {session.sessionSize && (
                      <ResultLine
                        title="Session Size"
                        tooltipTitle="Session size"
                        value={session.sessionSize}
                      />
                    )}
                    {session.inSessionIdx && (
                      <ResultLine
                        title="Session Index"
                        tooltipTitle="Session index"
                        value={session.inSessionIdx}
                      />
                    )}
                  </Grid>
                </Hidden>
              </>
            )}

            {!isReport && (
              <Grid item={true} lg={4} sm={6} xs={12}>
                {testRun && (
                  <ResultLine
                    title="Status"
                    tooltipTitle="Test completion status"
                    value={
                      <span className={classes.statusValue}>
                        <StatusIcon status={test.status} />
                        {testRun.logUrls ? (
                          <Link
                            className={classes.link}
                            to={`${TestLogs}/${testRun._id}/${test.machine}`}
                            onClick={onStatusClick as any}
                          >
                            {test.status}
                          </Link>
                        ) : (
                          test.status
                        )}
                      </span>
                    }
                  />
                )}
                {!isReport && test && (
                  <div>
                    {test.startDate && (
                      <ResultLine
                        title="Test start time"
                        tooltipTitle="Time the test started"
                        value={moment(test.startDate).format("MM/DD/YYYY @ h:mm:ssA")}
                      />
                    )}
                    {test.testDuration && (
                      <ResultLine
                        title="Test duration"
                        tooltipTitle="Total time of test execution"
                        value={formatDuration(test.testDuration, "DHMS")}
                      />
                    )}
                    {calc.voiceStartTime && (
                      <ResultLine
                        title="Connection start time"
                        tooltipTitle="Time the communication channel was first open"
                        value={
                          moment(calc.voiceStartTime).isValid()
                            ? moment(calc.voiceStartTime).format("h:mm:ssa")
                            : "null"
                        }
                      />
                    )}
                    {test.stat && (
                      <ResultLine
                        title="Connection duration"
                        tooltipTitle="Total time communication channel was open"
                        value={formatDuration(test.stat.voiceDuration, "DHMSms")}
                      />
                    )}
                    {audioMOS && (
                      <ResultLine
                        title="Audio MOS"
                        value={test.audioMOS.toLocaleString("en", {
                          maximumFractionDigits: 0,
                        })}
                      />
                    )}
                    <ResultLine
                      title="Call Setup Time"
                      tooltipTitle="Time the call was set up"
                      value={formatDuration(calc.callSetupTime, "DHMSms")}
                    />
                  </div>
                )}
              </Grid>
            )}
            {isReport && test && (
              <Grid item={true} lg={4} sm={6} xs={12}>
                <div>
                  <ResultLine title="File Name" value={test.fileName} />
                  <ResultLine
                    title="Media Quality Rank"
                    value={
                      <MediaQuality
                        rating={test.mediaQuality}
                        setRating={setRating}
                        disabled={pendingRequest}
                      />
                    }
                  />
                </div>
              </Grid>
            )}
            {isReport && test && (
              <Grid item={true} lg={4} sm={6} xs={12}>
                <div>
                  <ResultLine
                    title="Connection start time"
                    value={
                      moment(calc.voiceStartTime).isValid()
                        ? moment(calc.voiceStartTime).format("YYYY MMM DD ddd h:mm:SSA")
                        : "null"
                    }
                  />
                  <ResultLine
                    title="Connection duration"
                    value={test.stat && formatDuration(test.stat.voiceDuration, "DHMSms")}
                  />
                </div>
              </Grid>
            )}
            {!isReport && (
              <Grid item={true} xs={12}>
                <ErrorLine data={test} />
              </Grid>
            )}
            {Object.keys(filtCusTestMetric).length > 0 && (
              <Grid item={true} lg={4} sm={6} xs={12} className={classes.customMetricsContainer}>
                <Tooltip
                  content={"Custom Metrics sect by rtcSetMetric()"}
                  display={true}
                  displayType="block"
                >
                  <Typography className={classes.resultTableHead} variant="subtitle1">
                    Custom Metrics
                  </Typography>
                </Tooltip>
                {Object.keys(filtCusTestMetric).map((key, i) => {
                  const value = filtCusTestMetric[key].varValue;
                  return (
                    <ResultLine
                      key={i}
                      title={key}
                      value={
                        value && value !== 0 && isFinite(value)
                          ? value.toLocaleString("en", {
                              maximumFractionDigits: 2,
                            })
                          : "None"
                      }
                    />
                  );
                })}
              </Grid>
            )}
          </Grid>
          {!isReport && (
            <LogToolbar
              testRun={testRun}
              testIteration={test}
              pushToFileNames={pushToFileNames}
              assetsFileNames={assetsFileNames}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
    },
    link: {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    divider: {
      margin: "16px -16px",
    },
    statusValue: {
      display: "flex",
      alignItems: "center",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "felx",
      justifyContent: "space-between",
      alignItems: "center",
    },
    errorStyle: {
      color: "#a22a21",
      fontWeight: 500,
    },
    resultTableHead: {
      marginBottom: 5,
    },
    customMetricsContainer: {
      paddingTop: "0px !important",
    },
    redText: {
      color: "#a22a21",
      fontWeight: 500,
    },
  });

const decorate = withStyles(styles);

export default decorate(View);
