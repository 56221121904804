import * as React from "react";
import { Statuses } from "../constants/TestStatus";
import { Tooltip } from "@material-ui/core";
import Build from "@material-ui/icons/Build";
import Done from "@material-ui/icons/Done";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Replay from "@material-ui/icons/Replay";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import Warning from "@material-ui/icons/Warning";
import Cancel from "@material-ui/icons/Cancel";
import Error from "@material-ui/icons/Error";
import Timer from "@material-ui/icons/Timer";
import "./statusicon.css";

export const tooltipHelper = (
  title: string,
  icon: React.ReactElement<any> // tslint:disable-line
): React.ReactNode => {
  return (
    <Tooltip title={title} placement="top">
      {icon}
    </Tooltip>
  );
};

export function testStatusHelper(status: string, tooltip = true): React.ReactNode {
  switch (status) {
    case Statuses.warnings:
      const icon = <Warning style={{ color: "#F1CD2B" }} />;
      if (tooltip) {
        return tooltipHelper("Warnings", icon);
      }
      return icon;
    case Statuses.error:
      return tooltipHelper("Error", <RemoveCircle style={{ color: "#a22a21" }} />);
    case Statuses.failure:
      return tooltipHelper("Failure", <Error style={{ color: "#A22A21" }} />);
    case Statuses.timeout:
      return tooltipHelper("Timeout", <Timer style={{ color: "#c4c4c4" }} />);
    case Statuses.completed:
      return tooltipHelper("Completed", <Done style={{ color: "#559542" }} />);
    case Statuses.serviceFailure:
      return tooltipHelper("Service failure", <Build style={{ color: "#c4c4c4" }} />);
    case Statuses.terminated:
      return tooltipHelper("Terminated", <Cancel style={{ color: "#676A6C" }} />);
    case Statuses.started:
      return tooltipHelper(
        "Started",
        <Replay style={{ color: "#3577c1" }} className="loader-icon" />
      );
    case Statuses.retry:
      return tooltipHelper("Retry", <Replay style={{ color: "#c4c4c4" }} />);
    case Statuses.dismissed:
      return tooltipHelper("Dismissed", <ExitToApp style={{ color: "#c4c4c4" }} />);
    default:
      return <span>{status}</span>;
  }
}
