import * as React from "react";
import MonitoringPropertyForm from "./MonitoringPropertyForm";
import { AxiosResponse } from "axios";
import { connect } from "react-redux";
import AxiosFactory from "../../services/AxiosFactory";
import ApiPath from "../../constants/ApiPath";
import { Monitoring, NewMonitoring } from "../../constants/RoutesNames";
import { RouteComponentProps } from "react-router";
import TestService from "../../services/TestService";
import { SetNotification } from "../../actions/notificationAction";
import withBreadcrumb, { WithBreadcrumb } from "../../components/withBreadcrumb";
import { AppToolbarContext } from "../Layout/components/AppToolbar/Context";

interface TestParameters {
  concurrentUsers: number;
  duration: number;
  iterationMode: "string";
  loopCount: number;
}

interface TestProfile {
  browser: string;
  firewall: string;
  location: string;
  media: string;
  network: string;
}

type RouteParams = {
  objectId: string;
};

interface MonitorPropertyProps {
  setNotification(message: string): void;
}

export type MonitorPropertyState = {
  // TODO: remove any
  // tslint:disable-next-line:no-any
  userConfig: any;
  newMode: boolean;
  // tslint:disable-next-line:no-any
  initialValues: any;
  deleteDialog: boolean;
  tests: Array<Test>;
};

export interface IMonitorPropertyForm {
  _id: string;
  name: string;
  info?: string;
  parameters: TestParameters;
  testProfiles: Array<TestProfile>;
  project: string;
  runOptions: string;
  scheduleMode: string;
  createDate: string;
  serviceUrl: string;
}

class MonitorProperty extends React.Component<
  MonitorPropertyProps & RouteComponentProps<RouteParams> & WithBreadcrumb,
  MonitorPropertyState
> {
  static contextType = AppToolbarContext;
  constructor(props: MonitorPropertyProps & RouteComponentProps<RouteParams> & WithBreadcrumb) {
    super(props);

    this.state = {
      newMode: true,
      userConfig: null,
      initialValues: {
        active: true,
        scheduleMode: "h",
        testProfile: {
          alarm: {
            testFailed: true,
          },
        },
      },
      deleteDialog: false,
      tests: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.openSnackbar = this.openSnackbar.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    const newMode = this.props.location.pathname.includes(NewMonitoring);
    this.setState(
      {
        newMode,
      },
      async () => {
        if (!newMode) {
          await this.fetchItemValues();
        }
        await this.fetchUserConfig();
        await this.fetchTestDefinitions();
      }
    );
  }

  async fetchItemValues() {
    const axiosFactory = new AxiosFactory();
    const result: AxiosResponse = await axiosFactory.axios.get(
      `${ApiPath.api.monitors}/${this.props.match.params.objectId}`
    );
    this.setState({
      initialValues: result.data,
    });
    this.context.setPageInfo(result.data.name);
  }

  async fetchUserConfig() {
    const axiosFactory = new AxiosFactory();
    const result: AxiosResponse = await axiosFactory.axios.get(ApiPath.api.userConfig);
    this.setState({
      userConfig: result.data,
    });
  }

  async fetchTestDefinitions() {
    const result = await TestService.getAllTestDefinitions();
    const tests = result.data;
    tests.sort((testA: Test, testB: Test) => {
      const nameA = testA.name.toLowerCase();
      const nameB = testB.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    await this.setState({
      tests,
    });
  }

  openSnackbar(message: string) {
    this.props.setNotification(message);
  }

  deleteHandler() {
    this.setState({
      deleteDialog: !this.state.deleteDialog,
    });
  }

  async onDelete() {
    const id = this.props.match.params.objectId;
    const axiosFactory = new AxiosFactory();
    try {
      await axiosFactory.axios.delete(`${ApiPath.api.monitors}/${id}`);
      this.openSnackbar("Monitor deleted");
    } catch (e) {
      this.openSnackbar("Failed to delete current monitor");
    }
    this.props.history.push(`${Monitoring}`);
  }

  render() {
    return (
      <>
        {this.state.userConfig && (
          <MonitoringPropertyForm
            onSubmit={this.onSubmit}
            deleteHandler={this.deleteHandler}
            onDelete={this.onDelete}
            {...this.state}
          />
        )}
      </>
    );
  }

  private async onSubmit(values: IMonitorPropertyForm) {
    try {
      if (values.scheduleMode === "string") {
        this.props.setNotification("Could not save monitor. Bad frequency!");
        return;
      }
      const axiosFactory = new AxiosFactory();
      await axiosFactory.axios({
        url: `${ApiPath.api.monitors}${!this.state.newMode ? `/${values._id}` : ""}`,
        method: this.state.newMode ? "POST" : "PUT",
        data: values,
      });
      this.props.history.push(Monitoring);
      return;
    } catch (err) {
      if (err.response && err.response.data) {
        if (err.response.data.field) {
          return { [err.response.data.field]: err.response.data.message };
        }
        if (err.response.data.message) {
          this.props.setNotification(err.response.data.message);
          return;
        }
        this.props.setNotification("Could not save monitor.");
        return;
      }
      return {};
    }
  }
}

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

const BreadcrumbNodes: Array<BreadcrumbNode> = [
  {
    link: Monitoring,
  },
];

export default withBreadcrumb(connect(null, mapDispatchToProps)(MonitorProperty), BreadcrumbNodes);
