import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";

class AnalyzerBanner extends React.Component<WithStyles<"banner" | "bannerLink">> {
  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <Grid className={classes.banner} container={true} alignItems="center" justify="center">
        <Link className={classes.bannerLink} to={"/analyze"}>
          <img src="assets/images/analyze-banner.png" alt="banner" />
        </Link>
      </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    banner: {
      height: "auto",
    },
    bannerLink: {
      marginTop: 158,
      [theme.breakpoints.down("xs")]: {
        marginTop: 30,
      },
    },
  });

export default withStyles(styles)(AnalyzerBanner);
