import * as React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import withStyles from "@material-ui/core/styles/withStyles";
import { Theme, WithStyles, createStyles } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LOGO_IMG from "../../../assets/images/logo.svg";
import Logout from "./Logout";
import Support from "./Support";
import Docs from "./Docs";
import { AppControllerState } from "../../AppController";

interface NavBarProps {
  handleDrawerToggle(): void;
}

type StyledComponent = WithStyles<
  | "appBar"
  | "navIconHide"
  | "menuIcon"
  | "toolbar"
  | "logoImg"
  | "projectSelect"
  | "controlsContainer"
>;

class NavBar extends React.Component<
  NavBarProps & AppControllerState & RouteComponentProps<{}> & StyledComponent
> {
  public render(): JSX.Element {
    const {
      classes,
      handleDrawerToggle,
      projects,
      selectedProject,
      setSelectedProject,
    } = this.props;
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container={true}>
            <Grid item={true} xs={5}>
              <IconButton
                className={classes.navIconHide}
                color="inherit"
                aria-label="Menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
              <img className={classes.logoImg} src={LOGO_IMG} alt="logo-img" />
            </Grid>
            <Grid item={true} xs={7} className={classes.controlsContainer}>
              {projects && (
                <TextField
                  id="select-poject"
                  select={true}
                  className={classes.projectSelect}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
                  ) => setSelectedProject && setSelectedProject(e.target.value)}
                  value={selectedProject ? selectedProject._id : ""}
                  margin="normal"
                >
                  {projects.map((p: Project) => (
                    <MenuItem key={p._id} value={p._id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <Support />
              <Docs />
              <Logout />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: "#FDFDFD",
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      top: 0,
      left: 0,
      width: "100%",
    },
    navIconHide: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    menuIcon: {
      color: "black",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      minHeight: 50,
    },
    logoImg: {
      display: "inline-block",
      verticalAlign: "middle",
      height: 35,
      [theme.breakpoints.down("xs")]: {
        height: 28,
      },
    },
    projectSelect: {
      marginTop: 0,
      marginBottom: 0,
    },
    controlsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}> & NavBarProps & AppControllerState>(
  decorate(NavBar)
);
