// tslint:disable:no-any
import * as React from "react";
import * as queryString from "query-string";
import View from "./View";
import AxiosFactory from "../../services/AxiosFactory";
import { AxiosResponse } from "axios";
import { ISearchToolbarInitialValues } from "./SearchToolbar";
import { saveAs } from "file-saver";
import ApiPath from "src/constants/ApiPath";

export interface FilterValue {
  value: number | string | boolean;
  label: string;
  condition?: boolean;
}

export interface GridFilter {
  fieldName: string;
  filterLabel?: string;
  filterValues: Array<FilterValue>;
  value?: string | number;
  renderIcon?: (status: string) => React.ReactNode;
  disabled?: boolean;
  autocomplete?: boolean;
  component?: any;
  tripleDateFilter?: boolean;
}

export interface GridProps<T extends GridModel> {
  columnSchema: Array<ColumnSchema>;
  apiRoute?: string;
  defaultSort?: SortDescriptor;
  defaultFilter?: Array<IFilterServer>;
  remoteDataBound?: boolean;
  plainPayload?: boolean;
  search?: boolean;
  pagination?: boolean;
  exportUrl?: string;
  searchByLabel?: string;
  searchStyle?: React.CSSProperties;
  rowProps?: any;
  localData?: Array<T>;
  filters?: Array<GridFilter>;
  wrappedComponentRef?: any;
  location?: any;
  enableUrlPaging?: boolean;
  hidePaginationWhenDataLessThan?: number;
  hideHeader?: boolean;
  rowsPerPage?: number;
  rowsPerPageOptions?: Array<number>;
  bottomNavigation?: boolean;
  appContentSpinner?: (flag: boolean) => any;
  onRowClick?: (e: React.MouseEvent<HTMLTableRowElement>, dataItem: T) => void;
  rowColorFunc?: (e: any) => string;
}

export interface GridState<T extends GridModel> {
  data: DataDescriptor<T>;
  sort: SortDescriptor;
  searchValue?: string;
  filter: Array<IFilterServer>;
  dataPending: boolean;
}

export interface GridHandlers {
  onRequestSort: (e: React.MouseEvent<HTMLInputElement>, property: string) => void;
  onChangePage: (e: React.MouseEvent<HTMLButtonElement> | null, pageNumber: number) => void;
  onChangeRowsPerPage: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onRowClick: (e: React.MouseEvent<HTMLTableRowElement>, dataItem: any) => void;
  onSubmitFilterSearch: (e: any) => void;
  onExportClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export interface IFilterFormValues extends ISearchToolbarInitialValues {
  searchField?: string;
}

export default class Grid<T extends GridModel> extends React.Component<GridProps<T>, GridState<T>> {
  handlers: GridHandlers;
  defaultState: GridState<T>;

  constructor(props: GridProps<T>) {
    super(props);

    const urlFilter = this.getUrlFilters();
    const urlSearch = this.getUrlSearch();
    this.defaultState = {
      data: {
        docs: props.localData ? props.localData : [],
        totalDocs: 0,
        limit: props.rowsPerPage || 25,
        page: 1,
      },
      sort: {
        order: props.defaultSort ? props.defaultSort.order : "asc",
        orderBy: props.defaultSort ? props.defaultSort.orderBy : "_id",
      },
      searchValue: urlSearch || "",
      filter: props.defaultFilter || urlFilter || [],
      dataPending: false,
    };

    this.state = this.defaultState;

    this.onRequestSort = this.onRequestSort.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onSubmitFilterSearch = this.onSubmitFilterSearch.bind(this);
    this.onExportClick = this.onExportClick.bind(this);

    this.handlers = {
      onRequestSort: this.onRequestSort,
      onChangePage: this.onChangePage,
      onChangeRowsPerPage: this.onChangeRowsPerPage,
      onRowClick: this.onRowClick,
      onSubmitFilterSearch: this.onSubmitFilterSearch,
      onExportClick: this.onExportClick,
    };
  }

  componentDidMount() {
    if (this.props.remoteDataBound) {
      // it means that we handle page manualy from search query
      if (this.props.enableUrlPaging) {
        const urlPage = this.getUrlPage();
        if (urlPage) {
          this.onChangePage(null, urlPage - 1);
        } else {
          this.fetchDefaultList();
        }
      } else {
        this.fetchDefaultList();
      }
    }
    if (this.props.wrappedComponentRef) {
      this.props.wrappedComponentRef(this);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: GridProps<T>) {
    if (nextProps.localData !== undefined && !nextProps.remoteDataBound) {
      const prevState = { ...this.state };
      prevState.data.docs = nextProps.localData;
      this.setState({
        data: prevState.data,
      });
    }
  }

  getUrlFilters(): Array<any> | null {
    try {
      return JSON.parse(atob(queryString.parse(this.props.location.search).filter as string));
    } catch (e) {
      return null;
    }
  }

  getUrlSearch(): string | null {
    try {
      return atob(queryString.parse(this.props.location.search).search as string);
    } catch (e) {
      return null;
    }
  }

  getUrlPage(): number | null {
    try {
      const value = JSON.parse(queryString.parse(this.props.location.search).page as string);
      return value < 0 ? 1 : value;
    } catch (e) {
      return null;
    }
  }

  updateUrl(paramName: string, value: string): void {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    if (searchParams.has(paramName)) {
      if (value) {
        searchParams.set(paramName, value);
      } else {
        searchParams.delete(paramName);
      }
    } else if (value) {
      searchParams.append(paramName, value);
    } else {
      searchParams.delete(paramName);
    }
    window.history.replaceState({}, "", url.href);
  }

  fetchDefaultList(hideAppSpinner?: boolean) {
    this.fetchListInfo(
      {
        page: this.state.data.page,
        limit: this.state.data.limit,
        order: this.getSortOrder(),
        filter: this.state.filter.length !== 0 ? JSON.stringify(this.state.filter) : undefined,
        search: this.state.searchValue || undefined,
      },
      hideAppSpinner
    );
  }

  fetchListInfo(config: DataFetchDescriptor, hideAppSpinner?: boolean) {
    const axiosFactory = new AxiosFactory();
    const { appContentSpinner } = this.props;
    if (appContentSpinner && !!hideAppSpinner) {
      const { data } = this.state;
      if (data.docs.length !== 0) {
        appContentSpinner(true);
      }
    }
    this.setState(
      {
        dataPending: true,
      },
      () => {
        return (
          this.props.apiRoute &&
          axiosFactory.axios
            .get(this.props.apiRoute, {
              params: {
                order: config.order,
                limit: config.limit,
                page: config.page,
                search: config.search,
                filter: config.filter,
              },
            })
            .then((res: AxiosResponse) => {
              if (this.props.plainPayload === undefined || !this.props.plainPayload) {
                if (config.page > res.data.pages) {
                  this.fetchDefaultList();
                  return;
                }
                this.setState({
                  data: res.data,
                  dataPending: false,
                });
              } else {
                this.setState({
                  data: {
                    docs: res.data,
                    totalDocs: res.data.length,
                    limit: 25,
                    page: 1,
                  },
                  dataPending: false,
                });
              }
              if (this.props.enableUrlPaging) {
                this.updateUrl("page", this.state.data.page.toString());
              }
              if (appContentSpinner) {
                appContentSpinner(false);
              }
            })
            .catch((_err) => {
              this.setState({
                dataPending: false,
              });
              if (appContentSpinner) {
                appContentSpinner(false);
              }
            })
        );
      }
    );
  }

  async onSubmitFilterSearch(values: React.FormEvent<HTMLFormElement>) {
    const searchValues = values as IFilterFormValues;
    const newFilters: Array<IFilterServer> = [];

    if (searchValues.filter !== undefined) {
      // eslint-disable-next-line
      searchValues.filter.map((filterObj) => {
        const objKeys = Object.keys(filterObj);
        objKeys.forEach((objKey: any) => {
          const objValue = filterObj[objKey];
          if (objValue !== "" && objValue !== undefined && objValue !== null) {
            newFilters.push({
              field: objKey,
              value: objValue.toString(),
            });
          }
        });
      });
    }
    await this.setState({
      filter: newFilters,
      data: {
        ...this.state.data,
        page: 1,
      },
      searchValue: searchValues.searchField,
    });
    if (this.props.remoteDataBound) {
      this.fetchListInfo({
        page: this.state.data.page,
        limit: this.state.data.limit,
        filter: newFilters.length !== 0 ? JSON.stringify(newFilters) : undefined,
        order: this.getSortOrder(),
        search: searchValues.searchField !== "" ? searchValues.searchField : undefined,
      });
      this.updateUrl("filter", newFilters.length === 0 ? "" : btoa(JSON.stringify(newFilters)));
      this.updateUrl("search", btoa(searchValues.searchField || ""));
    }
  }

  onRequestSort(_e: React.MouseEvent<HTMLInputElement>, property: string) {
    this.fetchListInfo({
      page: 1,
      limit: this.state.data.limit,
      order: this.getSortOrder(property),
      filter: this.state.filter.length !== 0 ? JSON.stringify(this.state.filter) : undefined,
      search: this.state.searchValue,
    });
  }

  onChangePage(_e: React.MouseEvent<HTMLButtonElement> | null, pageNumber: number) {
    this.fetchListInfo({
      page: ++pageNumber,
      limit: this.state.data.limit,
      order: this.getSortOrder(),
      filter: this.state.filter.length !== 0 ? JSON.stringify(this.state.filter) : undefined,
      search: this.state.searchValue,
    });
  }

  onChangeRowsPerPage(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.fetchListInfo({
      page: 1,
      limit: parseInt(e.target.value, 10),
      order: this.getSortOrder(),
      filter: this.state.filter.length !== 0 ? JSON.stringify(this.state.filter) : undefined,
      search: this.state.searchValue,
    });
  }

  getSortOrder(property?: string): string {
    if (!property) {
      return `${this.state.sort.order === "asc" ? "" : "-"}${this.state.sort.orderBy}`;
    }
    let order = "asc";

    if (this.state.sort.orderBy === property && this.state.sort.order === "asc") {
      order = "desc";
    }

    this.setState({
      sort: {
        order: order as SortValueType,
        orderBy: property,
      },
    });

    return order === "asc" ? property : `-${property}`;
  }

  onRowClick(e: React.MouseEvent<HTMLTableRowElement>, dataItem: T) {
    if (this.props.onRowClick) {
      this.props.onRowClick(e, dataItem);
    }
  }

  getExportFileName = (apiPath?: string) => {
    switch (apiPath) {
      case ApiPath.api.monitorRunsExport:
        return "testrtc-monitor-history.csv";
      case ApiPath.api.testRunsExport:
        return "testrstc-run-history.csv";
      case ApiPath.api.billingDataExport:
        return "billing-data.csv";
      default:
        return "export.csv";
    }
  };

  onExportClick(_e: React.MouseEvent<HTMLElement>) {
    this.setState({
      dataPending: true,
    });
    const axiosFactory = new AxiosFactory();
    if (this.props.exportUrl) {
      axiosFactory.axios
        .get(this.props.exportUrl, {
          params: {
            page: this.state.data.page,
            limit: this.state.data.limit,
            order: this.getSortOrder(),
            filter: this.state.filter.length !== 0 ? JSON.stringify(this.state.filter) : undefined,
            search: this.state.searchValue,
            url: this.props.exportUrl,
          },
        })
        .then((res: AxiosResponse) => {
          const blob = new Blob([res.data]);
          const fileName = this.getExportFileName(this.props.exportUrl);
          this.setState({
            dataPending: false,
          });
          saveAs(blob, fileName);
        })
        .catch((err) => {
          console.log(err.message);
          this.setState({
            dataPending: false,
          });
        });
    }
  }

  public get getData(): DataDescriptor<T> {
    return this.state.data;
  }

  public setData(data: Array<T>) {
    return this.setState({
      data: {
        ...this.state.data,
        docs: data,
      },
    });
  }

  public reloadData(hideAppSpinner?: boolean) {
    this.fetchDefaultList(hideAppSpinner);
  }

  render() {
    return <View {...this.props} {...this.state} {...this.handlers} />;
  }
}
