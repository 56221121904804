import * as React from "react";
import * as classnames from "classnames";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Chart from "src/components/Chart";

import { DataType, MediaType, dataTypeToTitle } from "./constants";

interface ISingleChart {
  charts: any;
  mediaType: MediaType;
  group: { audio: string[]; video: string[] };
  byProbeActivated?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labels: {
      display: "flex",
      flexDirection: "row",
      marginRight: theme.spacing(1),
      justifyContent: "flex-end",
    },
    label: {
      fontWeight: 400,
      "&:hover": {
        cursor: "pointer",
      },
    },
    selectedLabel: {
      fontWeight: 600,
    },
    spacer: {
      fontWeight: 700,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  })
);

const VSpacer = ({ idx, length }: any) => {
  const classes = useStyles();
  if (idx === length - 1) {
    return null;
  }
  return <span className={classes.spacer}>|</span>;
};

const SingleChart = (props: ISingleChart) => {
  const { charts, mediaType, group, byProbeActivated } = props;
  const groupByMedia = group[mediaType];

  const classes = useStyles();
  const [dataType, setDataType] = React.useState<DataType>(groupByMedia[0] as DataType);

  React.useEffect(() => {
    const groupByMedia = group[mediaType];
    if (groupByMedia.indexOf(dataType) === -1) {
      setDataType(groupByMedia[0] as DataType);
    }
  }, [group, mediaType]);

  const handleDataTypeChange = (type: DataType) => {
    setDataType(type);
  };

  const { dataset, options } = charts[mediaType][dataType] || { dataset: [[0, 0]], options: {} };
  return (
    <div>
      <div className={classes.labels}>
        {groupByMedia.length > 1 &&
          groupByMedia.map((type: DataType, idx: number) => {
            return (
              <div key={type} style={{ display: "flex" }}>
                <Typography
                  key={type}
                  variant="subtitle2"
                  className={classnames(classes.label, type === dataType && classes.selectedLabel)}
                  onClick={() => handleDataTypeChange(type)}
                >
                  {dataTypeToTitle[type].label}
                </Typography>
                <VSpacer key={type + "spacer"} idx={idx} length={groupByMedia.length} />
              </div>
            );
          })}
      </div>
      <Chart
        id={dataType}
        title={dataTypeToTitle[dataType].title.replace("{0}", mediaType)}
        dataset={dataset}
        options={options}
        width="100%"
        byProbeActivated={byProbeActivated}
        showNoDataMessage
        allowZeros
        suppressDirectionSortToLegend
        syncLegends
      />
    </div>
  );
};

export default SingleChart;
