import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { FieldRenderProps } from "react-final-form";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import * as classnames from "classnames";

interface ITextFielControl {
  label?: string;
  multiline?: boolean;
  rows?: string;
  rowsMax?: string;
  min?: number;
  // tslint:disable-next-line: no-any
  variant?: any;
  // tslint:disable-next-line: no-any
  onchange?: (e: any) => any;
  readonly?: boolean;
  resizeY?: boolean;
  autoComplete?: string;
}

class TextFieldControl extends React.Component<
  FieldRenderProps & ITextFielControl & WithStyles<"root" | "input" | "resizeY">
> {
  public render(): JSX.Element {
    const {
      classes,
      label,
      multiline,
      rowsMax,
      rows,
      input: { name, onChange, value, ...restInput },
      meta,
      min,
      variant,
      onchange,
      readonly,
      resizeY,
      autoComplete,
      ...rest
    } = this.props;

    // `InputLabelProps` required since `restInput` override `onFocus` action
    const root = classnames(classes.root, resizeY ? classes.resizeY : "");

    return (
      <TextField
        {...rest}
        classes={{
          root,
        }}
        fullWidth={true}
        label={label}
        value={value}
        name={name}
        helperText={meta.touched ? meta.error || meta.submitError : undefined}
        error={(meta.error || meta.submitError) && meta.touched}
        InputProps={{
          autoComplete: autoComplete,
          readOnly: readonly,
          inputProps: min ? { min } : {},
          classes: label
            ? {}
            : {
                multiline: classes.input,
                inputMarginDense: multiline ? undefined : classes.input,
              },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          ...restInput,
          "data-cy": name,
        }}
        // tslint:disable-next-line: no-any
        onChange={(e: any) => {
          onChange(e);
          if (onchange) {
            onchange(e);
          }
        }}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        margin={multiline ? "normal" : "dense"}
        variant={variant ? variant : "filled"}
      />
    );
  }
}

const styles = createStyles({
  root: {
    width: "100%",
    marginTop: 0,
    marginBottom: 0,
  },
  input: {
    padding: 6,
  },
  resizeY: {
    "& textarea": {
      resize: "vertical",
    },
  },
});

export default withStyles(styles)(TextFieldControl);
