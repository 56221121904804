import * as React from "react";
import { BoxMarkState, BoxMarkProps } from "./index";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Bookmark from "@material-ui/icons/Bookmark";
import Comment from "@material-ui/icons/Comment";
import Share from "@material-ui/icons/Share";
import { Typography } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DialogTitle, Dialog, DialogContent, DialogActions } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

type StyledComponent = WithStyles<
  | "boxMark"
  | "BoxMarkButton"
  | "ShareIcon"
  | "PenIcon"
  | "Dialog"
  | "DialogTitle"
  | "DialogTitleText"
  | "DialogBody"
  | "SaveButton"
>;

type ViewProps = {
  flagTest: () => void;
  shareTest: () => void;
  commentTest: () => void;
  toggleComments: () => void;
  // tslint:disable-next-line:no-any
  onCommentsChange: (e: any) => void;
};

const View = (props: BoxMarkProps & BoxMarkState & StyledComponent & ViewProps) => {
  const {
    absUrl,
    allowToShare,
    firstIterId,
    prevIterId,
    currentIterId,
    testIterations,
    record,
    nextIterId,
    lastIterId,
    classes,
    flagTest,
    shareTest,
    commentTest,
    commentsOpen,
    toggleComments,
    onCommentsChange,
    commentsInput,
    navigateToIteration,
  } = props;

  return (
    <div className={classes.boxMark}>
      <Dialog
        open={!!commentsOpen}
        onClose={toggleComments}
        classes={{
          paper: classes.Dialog,
        }}
        aria-labelledby="comments-dialog-title"
      >
        <DialogTitle className={classes.DialogTitle} id="comments-dialog-title">
          <span className={classes.DialogTitleText}>Comments for Overview</span>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="multiline-flexible"
            label="Test Comments"
            multiline={true}
            rows={14}
            value={commentsInput}
            onChange={onCommentsChange}
            fullWidth={true}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={commentTest}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {testIterations && testIterations.length > 0 && navigateToIteration && (
        <>
          <IconButton
            className={classes.BoxMarkButton}
            onClick={() => navigateToIteration(firstIterId)}
          >
            <FirstPage />
          </IconButton>
          <IconButton
            className={classes.BoxMarkButton}
            onClick={() => navigateToIteration(prevIterId)}
          >
            <ChevronLeft />
          </IconButton>
          <Typography variant="body2">{` ${currentIterId} / ${testIterations.length}`}</Typography>
          <IconButton
            className={classes.BoxMarkButton}
            onClick={() => navigateToIteration(nextIterId)}
          >
            <ChevronRight />
          </IconButton>
          <IconButton
            className={classes.BoxMarkButton}
            onClick={() => navigateToIteration(lastIterId)}
          >
            <LastPage />
          </IconButton>
        </>
      )}
      {allowToShare && (
        // eslint-disable-next-line
        <a>
          <CopyToClipboard text={absUrl && absUrl.toString()}>
            <IconButton
              className={classes.BoxMarkButton}
              style={{
                color: record.shared ? "#3577c1" : undefined,
              }}
              onClick={shareTest}
            >
              <Share className={classes.ShareIcon} />
            </IconButton>
          </CopyToClipboard>
        </a>
      )}
      {
        // eslint-disable-next-line
        <a>
          <IconButton
            className={classes.BoxMarkButton}
            style={{
              color: record && record.comments ? "#3577c1" : undefined,
            }}
            onClick={toggleComments}
          >
            <Comment className={classes.PenIcon} />
          </IconButton>
        </a>
      }
      {
        // eslint-disable-next-line
        <a>
          <IconButton
            onClick={flagTest}
            className={classes.BoxMarkButton}
            style={{
              color: record && record.flag ? "#a22a21" : undefined,
            }}
          >
            <Bookmark />
          </IconButton>
        </a>
      }
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    boxMark: {
      display: "flex",
      alignItems: "center",
    },
    BoxMarkButton: {
      padding: "0px",
      width: 35,
      height: 35,
    },
    ShareIcon: {
      fontSize: 20,
    },
    PenIcon: {
      fontSize: 20,
      color: "#3577c1",
    },
    DialogTitle: {
      backgroundColor: theme.palette.primary.main,
    },
    DialogTitleText: {
      color: "white",
    },
    Dialog: {
      width: 600,
      // height: "90%",
      // paddingBottom: 70,
    },
    DialogBody: {
      padding: 20,
    },
    SaveButton: {
      position: "absolute",
      right: 20,
      bottom: 20,
    },
  });

const decorate = withStyles(styles);

export default decorate(View);
