import * as React from "react";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { ChartsState, ChartsProps } from "../index";
import ExpandChannelDetails from "./ExpandChannelDetails";
import { App as AppRoutes } from "../../../../constants/RoutesNames";

type StyledComponent = WithStyles<
  | "card"
  | "divider"
  | "toolbar"
  | "contentContainer"
  | "advancedButton"
  | "advancedButtonLink"
  | "noWebrtcMessage"
>;

const webrtcInternalsFile = "webrtc_internals_dump.txt";
const getStatFile = "getstat_logs.json";

class View extends React.PureComponent<ChartsState & ChartsProps & StyledComponent> {
  render(): JSX.Element {
    const { test, testRun, classes, channels, isReport, assetsFileNames } = this.props;

    const getAdvancedAnalytics = () => {
      if (assetsFileNames.indexOf(webrtcInternalsFile) !== -1) {
        return webrtcInternalsFile;
      } else if (assetsFileNames.indexOf(getStatFile) !== -1) {
        return getStatFile;
      }
      return "";
    };

    const freeReportBasePath =
      window.location.href.indexOf("report/free") !== -1
        ? AppRoutes.WebrtcInternalsFreeAnalyzeRtc
        : AppRoutes.WebrtcInternalsAnalyzeRtc;

    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Card className={classes.card}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="subtitle1">Channels</Typography>
            </Toolbar>
            <Divider className={classes.divider} />
            <Grid className={classes.contentContainer} container={true} spacing={5}>
              {channels.map((channel, _i) => (
                <ExpandChannelDetails key={channel.guid} channel={channel} />
              ))}

              {!channels.length && (
                <Typography
                  className={classes.noWebrtcMessage}
                  variant="h5"
                  gutterBottom={true}
                  align="center"
                >
                  No WebRTC Data collected for this session
                </Typography>
              )}
              {!isReport && getAdvancedAnalytics() !== "" && (
                <Link
                  className={classes.advancedButtonLink}
                  // to={`${AppRoutes.WebrtcInternals}/${testRun._id}/${test._id}/${test.machine}/${
                  //   test.runIndex
                  // }/${getAdvancedAnalytics()}`}
                  to={`${AppRoutes.WebrtcInternals}/${test._id}/${getAdvancedAnalytics()}/${
                    testRun.runMode
                  }`}
                >
                  <Button className={classes.advancedButton} color={"default"} variant="contained">
                    {"Advanced WebRTC analytics"}
                  </Button>
                </Link>
              )}
              {isReport && test?.blobBaseName && (
                <Link
                  className={classes.advancedButtonLink}
                  to={`${freeReportBasePath}/${test._id}/${test.fileName}`}
                >
                  <Button className={classes.advancedButton} color={"default"} variant="contained">
                    {"Advanced WebRTC analytics"}
                  </Button>
                </Link>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      overflow: "visible",
    },
    divider: {
      margin: "16px -16px",
    },
    contentContainer: {
      padding: 16,
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
    },
    advancedButtonLink: {
      textDecoration: "none",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    advancedButton: {
      margin: "auto",
      marginTop: 10,
      textDecoration: "none",
    },
    noWebrtcMessage: {
      textTransform: "capitalize",
      fontSize: 19,
      textAlign: "center",
      width: "100%",
      marginTop: 20,
    },
  });

const decorate = withStyles(styles);

export default decorate(View);
