import * as _ from "lodash";

import ChartHelper from "src/helpers/chartHelper";
import SeriesBuilder from "src/components/Chart/seriesBuilder";

import { formatDuration } from "src/helpers/testDetails";

import { dataTypesValues, labels, MediaType, DataType, ByType, dataTypeToTitle } from "./constants";

export const processData = (stat: any, events: any[]) => {
  const newData = {} as any;

  newData.events = events;
  newData.dataFrequency = stat.dataFrequency || 1;
  newData.performanceStep = stat.performanceStep || 5000;

  newData.rawFilterOptions = stat.filterData;
  newData.rawFilterOptions_BCNL = stat.filterData_BCNL;

  newData.rawMinMaxBand = stat.maxMinBand;
  newData.rawMinMaxBand_BCNL = stat.maxMinBand_BCNL || [];

  newData.rawProbsOptions = stat.probsData;

  newData.rawPerfData = stat.perfChartData || {};
  newData.rawProbesPerfData = stat.probesPerfChartData || {};

  const fltrDataset = newData.rawFilterOptions || [];
  const fltrDataset_BCNL = newData.rawFilterOptions_BCNL || [];

  const probDataset = ChartHelper.prepareFilterOptions(newData.rawProbsOptions);
  const probDatasetSorted = ChartHelper.prepareFilterOptions(newData.rawProbsOptions);

  const bits = dataTypesValues.bits;
  const loss = dataTypesValues.loss;
  const videoDelay = dataTypesValues.videoDelay;
  const videoFrameRate = dataTypesValues.videoFrameRate;
  // that is because in database we have different naming
  for (const prop of Object.keys(probDataset)) {
    for (const p of Object.keys(probDataset[prop])) {
      for (const type of Object.keys(probDataset[prop][p])) {
        switch (type) {
          case "bytes":
            probDatasetSorted[prop][p][bits] = probDatasetSorted[prop][p][type];
            probDataset[prop][p][bits] = probDataset[prop][p][type];
            newData.rawProbsOptions[prop][p][bits] = newData.rawProbsOptions[prop][p][type];
            break;

          case "packetLoss":
            probDatasetSorted[prop][p][loss] = probDatasetSorted[prop][p][type];
            probDataset[prop][p][loss] = probDataset[prop][p][type];
            newData.rawProbsOptions[prop][p][loss] = newData.rawProbsOptions[prop][p][type];
            break;

          case "rtt":
            probDatasetSorted[prop][p][videoDelay] = probDatasetSorted[prop][p][type];
            probDataset[prop][p][videoDelay] = probDataset[prop][p][type];
            newData.rawProbsOptions[prop][p][videoDelay] = newData.rawProbsOptions[prop][p][type];
            break;
          case "fps":
            probDatasetSorted[prop][p][videoFrameRate] = probDatasetSorted[prop][p][type];
            probDataset[prop][p][videoFrameRate] = probDataset[prop][p][type];
            newData.rawProbsOptions[prop][p][videoFrameRate] =
              newData.rawProbsOptions[prop][p][type];
            break;
          default:
            break;
        }
      }
    }
  }

  // add all prop to filter options
  newData.fltrDataset = ChartHelper.audioVideoMerge(fltrDataset, true);
  newData.fltrDataset_BCNL = ChartHelper.audioVideoMerge(fltrDataset_BCNL, true);
  newData.probDatasetSorted = probDatasetSorted;
  newData.fltrPerf = ChartHelper.prepareFilterPerformance(newData.rawPerfData);
  newData.fltrProbPerf = ChartHelper.prepareFilterProbePerformance(newData.rawProbesPerfData);

  const chartBuilding = stat.chartBuilding;
  if (chartBuilding) {
    // where markers should start working for audio
    newData._channelReadyTime = new Date(chartBuilding.channelReadyTime);
    newData._chartSampleStartTime = new Date(chartBuilding.setupStartTime);
    newData.audioOffset =
      ((newData._channelReadyTime as any) - newData._chartSampleStartTime.getTime()) / 1000;
    newData.firstProbEndsIn = chartBuilding.firstProbEndsIn / 1000;

    // Video and audio
    newData.rawFilterOptions = ChartHelper.audioVideoMerge(newData.rawFilterOptions, false);
    newData.rawFilterOptions_BCNL = ChartHelper.audioVideoMerge(
      newData.rawFilterOptions_BCNL,
      false
    );
  }

  return newData;
};

export const buildAllCharts = (data: any, mediaType: MediaType, byType: ByType) => {
  data = _.cloneDeep(data);
  const charts: any = {};
  if (mediaType !== "performance") {
    charts.bits = buildChart(data, mediaType, "bits", byType);
    charts.jitter = buildChart(data, mediaType, "jitter", byType);
    charts.packets = buildChart(data, mediaType, "packets", byType);
    charts.videoDelay = buildChart(data, mediaType, "videoDelay", byType);
    charts.videoFrameRate = buildChart(data, mediaType, "videoFrameRate", byType);
    charts.loss = buildChart(data, mediaType, "loss", byType);
    charts.lossPCT = buildChart(data, mediaType, "lossPCT", byType);

    // Ugly but until I have a better idea
    for (let i = 0; i < charts.lossPCT.dataset.length; i++) {
      convertSeriesPCT(charts, i);
    }
  } else {
    charts.browserCpu = buildChart(data, "performance", "browserCpu", byType);
    charts.browserMemory = buildChart(data, "performance", "browserMemory", byType);
  }
  return {
    ...charts,
  };
};

const buildChart = (data: any, mediaType: MediaType, chartType: DataType, byType: ByType) => {
  switch (byType) {
    case "time/probe":
    case "time/channel":
      if (mediaType !== "performance") {
        return buildByTimeChart(data, mediaType, chartType, byType);
      } else {
        return buildByTimePerfChart(data, chartType);
      }
    case "probe":
      if (mediaType !== "performance") {
        return buildByProbeChart(data, mediaType, chartType);
      } else {
        return buildByProbePerfChart(data, chartType);
      }
    default:
      throw Error("Unknown chart by-type.");
  }
};

const buildByTimeChart = (data: any, mediaType: MediaType, dataType: DataType, byType: ByType) => {
  let isPCT = false;
  if (dataType === "lossPCT") {
    isPCT = true;
    dataType = "loss";
  }
  const sb = new SeriesBuilder(true);

  // incoming/outgoing
  let sbConfig = {};
  if (byType !== "probe" && dataType === "loss") {
    sbConfig = {
      bars: { show: true, fill: true, horizontal: false },
      lines: { show: false },
    };
  }

  const src = byType === "time/probe" ? data.fltrDataset : data.fltrDataset_BCNL;
  const sendSeries = (src[mediaType] && src[mediaType].send[dataType]) || [[0, 0]];
  const recvSeries = (src[mediaType] && src[mediaType].recv[dataType]) || [[0, 0]];
  sb.addConfigs([sbConfig, sbConfig]).addSeries(
    [recvSeries, sendSeries],
    labels,
    data.dataFrequency
  );
  // from bits to kbits for first two series
  if (dataType === "bits") {
    for (let sIdx = 0; sIdx < 2; sIdx++) {
      for (const pair of sb.series[sIdx]) {
        pair[1] = pair[1] / 1000;
      }
    }
  }

  // min/max bands
  if (byType !== "probe" && dataType !== "loss") {
    const sbConfigBand = {
      ...sbConfig,
      lines: { lineWidth: 0, fill: true, show: true },
    };
    const srcBand = byType === "time/probe" ? data.rawMinMaxBand : data.rawMinMaxBand_BCNL;
    const sendBandSeries = (srcBand[mediaType] &&
      srcBand[mediaType].send &&
      srcBand[mediaType].send[dataType]) || [[0, 0]];
    const recvBandSeries = (srcBand[mediaType] &&
      srcBand[mediaType].recv &&
      srcBand[mediaType].recv[dataType]) || [[0, 0]];
    const bandSend = prepareBand(sendBandSeries.min, sendBandSeries.max, data.dataFrequency);
    const bandRecv = prepareBand(recvBandSeries.min, recvBandSeries.max, data.dataFrequency);
    sb.addCustomSeries(bandSend)
      .addCustomSeries(bandRecv)
      // .setOffsetToSeries(events.length ? 4 : 3, events.length ? 6 : 5)
      .addConfigs([sbConfigBand, sbConfigBand])
      .addLabel("Outgoing min/max band")
      .addLabel("Incoming min/max band");
    // from bits to kbits for min max bands
    if (dataType === "bits") {
      const sLen = sb.series.length - 1;
      for (let sIdx = sLen; sIdx > sLen - 2; sIdx--) {
        for (const pair of sb.series[sIdx]) {
          pair[1] = pair[1] / 1000;
          if (pair[2]) {
            pair[2] = pair[2] / 1000;
          } else {
            pair[2] = 0;
          }
        }
      }
    }
  }

  //call end
  sb.addLabel("Call end")
    .addCustomSeries([[data.firstProbEndsIn, 0]])
    .setOffset(0);
  // if (newState.chartType === chartTypesValues.loss) {
  sb.addConfig({ bars: { show: true, fill: true, horizontal: false } });
  // }

  // add events series label and series
  const events = getEvents(data) || [];
  if (events.length) {
    // if watchRTC
    // then add
    // const joinEvents = events.filter((evt: any) => evt[2]?.toLowerCase() === "join");
    // const leftEvents = events.filter((evt: any) => evt[2]?.toLowerCase() === "leave");

    const numberOfEvents = events.filter((evt: any) => evt[1] === -1).length;
    sb.addLabel(`Global events - ${numberOfEvents}`);

    sb.addCustomSeries(events);
    sb.addHelperData({ tooltip: events }, 2);

    sb.addConfig({
      bars: { lineWidth: 0, align: "top", barWidth: 1, fill: true, show: true },
    });
  }

  let dataset = sb.build();
  if (_.isArray(dataset)) {
    dataset = getSortedLegend(dataset);
    dataset = setSeriesColors(dataset);
  }

  const options = {
    ...getDefaultOptions(dataType, isPCT),
    xAxis: {
      type: "datetime",
      axisLabel: "Time",
      labels: {
        formatter: function (context: any) {
          const value = context.value * 1000;
          const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
          const format = range < 3600 ? "%M:%S" : "%H:%M:%S";
          if (value >= 0) {
            return (window as any).Highcharts.dateFormat(format, value);
          } else {
            return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
          }
        },
      },
    },
    tooltip: {
      show: true,
      formatter: function () {
        const _this = this as any;
        if (
          _this.series.name.toLowerCase().indexOf("global") !== -1 ||
          _this.series.name.toLowerCase().indexOf("local") !== -1 ||
          _this.series.name.toLowerCase().indexOf("call end") !== -1
        ) {
          const date = new Date(0);
          date.setSeconds(_this.x);

          const event = events.find((x: any) => x[0] === _this.x);
          if (event) {
            const eventName = event[2];
            const probeNumber = event[4];
            return `<strong>Probe #${probeNumber}</strong><br /><span>${eventName}</span><br/ ><span>${formatDuration(
              date.getTime(),
              "DHMS"
            )}</span>`;
          } else {
            return `<strong>${_this.series.name}</strong><br /><span>${formatDuration(
              date.getTime(),
              "DHMS"
            )}</span>`;
          }
        }
        if (
          _this.series.name.toLowerCase().indexOf("join") !== -1 ||
          _this.series.name.toLowerCase().indexOf("leave") !== -1
        ) {
          const date = new Date(0);
          date.setSeconds(_this.x);

          const event = events.find((x: any) => x[0] === _this.x);
          if (event) {
            const eventName = event[2];
            const peerName = event[3];
            return `<strong>${peerName}</strong><br /><span>${eventName}</span><br/ ><span>${formatDuration(
              date.getTime(),
              "DHMS"
            )}</span>`;
          } else {
            return `<strong>${_this.series.name}</strong><br /><span>${formatDuration(
              date.getTime(),
              "DHMS"
            )}</span>`;
          }
        }
        return false;
      },
    },
  };
  return {
    options,
    dataset,
  };
};

const buildByProbeChart = (data: any, mediaType: MediaType, dataType: DataType) => {
  let isPCT = false;
  if (dataType === "lossPCT") {
    isPCT = true;
    dataType = "loss";
  }

  const sb = new SeriesBuilder(true);

  // incoming / outgoing;
  const sbConfig = {
    bars: { show: true, fill: true, horizontal: false },
    lines: { show: false },
  };
  const src = data.probDatasetSorted;

  const sendSeries = (src[mediaType] && src[mediaType].send[dataType]) || [[0, 0]];
  const recvSeries = (src[mediaType] && src[mediaType].recv[dataType]) || [[0, 0]];

  const optRef = data.rawProbsOptions ? data.rawProbsOptions[mediaType] : null;
  const sendProbeInfo = (optRef.send && optRef.send[dataType]) || [[0, 0]];
  const recvProbeInfo = (optRef.recv && optRef.recv[dataType]) || [[0, 0]];

  sb.addConfigs([sbConfig, sbConfig]).addSeries([recvSeries, sendSeries], labels, 1);

  sb.addHelperData({ tooltip: recvProbeInfo }, 0).addHelperData({ tooltip: sendProbeInfo }, 1);

  let dataset = sb.build();

  if (_.isArray(dataset)) {
    dataset = getSortedLegend(dataset);
    dataset = setSeriesColors(dataset);
  }

  const options = {
    ...getDefaultOptions(dataType, isPCT),
  };

  return {
    options,
    dataset,
  };
};

export const buildByTimePerfChart = (data: any, chartType: DataType) => {
  const src = data.fltrPerf;
  const sb = new SeriesBuilder(true);

  const avgSeries = (src[chartType] && src[chartType].avg) || [[0, 0]];
  const maxSeries = (src[chartType] && src[chartType].max) || [[0, 0]];
  const minSeries = (src[chartType] && src[chartType].min) || [[0, 0]];

  sb.addConfigs([{}]).addSeries([avgSeries], [`Average Load`], data.performanceStep);

  // min/max bands
  const sbConfigBand = {
    lines: { lineWidth: 0, fill: true, show: true },
  };

  const bandSeries = prepareBand(maxSeries, minSeries, data.performanceStep);

  sb.addCustomSeries(bandSeries).addConfigs([sbConfigBand]).addLabel(`Min/max load band`);

  //call end
  sb.addLabel("Call end")
    .addCustomSeries([[data.firstProbEndsIn * 1000, 0]])
    .setOffset(0)
    .addConfig({ bars: { show: true, fill: true, horizontal: false } });

  // add events series label and series
  const events = getEvents(data, true) || [];
  if (events.length) {
    const numberOfEvents = events.filter((evt: any) => evt[1] === -1).length;
    sb.addLabel(`Global events - ${numberOfEvents}`);

    sb.addCustomSeries(events);
    sb.addHelperData({ tooltip: events }, 2);

    sb.addConfig({
      bars: { lineWidth: 0, align: "top", barWidth: 1, fill: true, show: true },
    });
  }

  let dataset = sb.build();
  if (_.isArray(dataset)) {
    dataset = getSortedLegend(dataset);
    dataset = setSeriesColors(dataset);
  }

  const options = {
    ...getDefaultOptions(chartType),
    xAxis: {
      type: "datetime",
      axisLabel: "Time",
      labels: {
        formatter: function (context: any) {
          const value = context.value;
          const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
          const format = range < 3600 ? "%M:%S" : "%H:%M:%S";
          if (value >= 0) {
            return (window as any).Highcharts.dateFormat(format, value);
          } else {
            return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
          }
        },
      },
    },
    tooltip: {
      show: true,
      formatter: function () {
        const _this = this as any;
        if (
          _this.series.name.toLowerCase().indexOf("global") !== -1 ||
          _this.series.name.toLowerCase().indexOf("local") !== -1 ||
          _this.series.name.toLowerCase().indexOf("call end") !== -1
        ) {
          const date = new Date(0);
          date.setSeconds(_this.x / 1000);
          const event = events.find((x: any) => x[0] === _this.x);
          if (event) {
            const eventName = event[2];
            const probeNumber = event[4];
            return `<strong>Probe #${probeNumber}</strong><br /><span>${eventName}</span><br/ ><span>${formatDuration(
              date.getTime(),
              "DHMS"
            )}</span>`;
          } else {
            return `<strong>${_this.series.name}</strong><br /><span>${formatDuration(
              date.getTime(),
              "DHMS"
            )}</span>`;
          }
        }
        return false;
      },
    },
  };
  return {
    options,
    dataset,
  };
};

export const buildByProbePerfChart = (data: any, chartType: DataType) => {
  const src = data.fltrProbPerf;
  const sb = new SeriesBuilder(true);

  const sbConfig = {
    bars: { show: true, fill: true, horizontal: false },
    lines: { show: false },
  };
  const avgSeries = (src[chartType] && src[chartType].avg) || [[0, 0]];
  const maxSeries = (src[chartType] && src[chartType].max) || [[0, 0]];
  const minSeries = (src[chartType] && src[chartType].min) || [[0, 0]];

  const optRef = data.rawProbesPerfData ? data.rawProbesPerfData[chartType] : [[0, 0]];

  sb.addConfigs([sbConfig, sbConfig, sbConfig]).addSeries(
    [maxSeries, avgSeries, minSeries],
    ["Max", "Avg", "Min"],
    1
  );

  sb.addHelperData({ tooltip: optRef }, 0)
    .addHelperData({ tooltip: optRef }, 1)
    .addHelperData({ tooltip: optRef }, 2);

  let dataset = sb.build();

  if (_.isArray(dataset)) {
    dataset = getSortedLegend(dataset);
    dataset = setSeriesColors(dataset);
  }

  const options = {
    ...getDefaultOptions(chartType),
  };

  return {
    options,
    dataset,
  };
};

// gets min and max arrays of numbers and
// prepare to be series for chart
const prepareBand = (min: any, max: any, dataFrequency: number) => {
  if (!min || !max) {
    return [];
  }

  // it works only for arrays which is equal
  // in case one larger than another - drop it on that point
  const len = min.length > max.length ? max.length : min.length;

  // prepare arrays to be flot-like series
  for (let i = 0; i < len; i++) {
    const tmpMin = min[i];
    const tmpMax = max[i];

    // set seconds
    min[i] = [i * dataFrequency, tmpMin];
    max[i] = [i * dataFrequency, tmpMax];

    // set bottom border
    max[i][2] = min[i][1];
  }

  return max;
};

const getSortedLegend = (dataset: Array<any>) => {
  const copy = _.cloneDeep(dataset);
  copy.sort((a, b) => {
    const aCopy = _.clone(a);
    const bCopy = _.clone(b);

    if (aCopy.label.indexOf("Global events") !== -1) {
      aCopy.label = "Global events";
    }
    if (bCopy.label.indexOf("Global events") !== -1) {
      bCopy.label = "Global events";
    }
    const labelOrder = [
      "Incoming",
      "Outgoing",
      "Average Load",
      "Min/max load band",
      "Max",
      "Avg",
      "Min",
      "Incoming min/max band",
      "Outgoing min/max band",
      "Global events",
      "Call end",
    ];
    const aIndex = labelOrder.indexOf(aCopy.label);
    const bIndex = labelOrder.indexOf(bCopy.label);

    return aIndex - bIndex;
  });
  return copy;
};

const setSeriesColors = (dataset: Array<any>) => {
  const copy = _.cloneDeep(dataset);
  const map = {
    Incoming: "rgba(70, 130, 192, 1)",
    Outgoing: "rgba(162, 42, 33, 1)",
    "Incoming min/max band": "rgba(70, 130, 192, .7)",
    "Outgoing min/max band": "rgba(162, 42, 33, .7)",
    "Call end": "#f1cd2b",
    Avg: "rgb(192,127,70)",
    Min: "rgba(70, 130, 192, 1)",
    Max: "rgba(162, 42, 33, 1)",
  };

  copy.forEach((d) => {
    if (d.label.indexOf("Global events") !== -1) {
      d.color = "#0EEB1A";
    } else {
      if (map[d.label]) {
        d.color = map[d.label];
      }
    }
  });
  return copy;
};

const getEvents = (data: any, performance = false) => {
  const events: any[] = data.events || [];
  const isThereActualEvents =
    events?.filter((evt) => {
      return (
        evt.events &&
        evt.events.length > 0 &&
        evt.events.filter((e: any) => {
          return e.type === "global";
        }).length > 0
      );
    }).length > 0;

  const eventsSeries: any = [];
  if (Array.isArray(events) && isThereActualEvents) {
    const evts = ChartHelper.prepareGlobalEvents(events);
    ChartHelper.sortEventsAsc(evts);

    if (data._chartSampleStartTime) {
      const initialPoint = new Date(data._chartSampleStartTime).getTime();
      let offset = 0;
      for (const event of evts) {
        if (!event) {
          continue;
        }
        const divider = performance ? 1 : 1000;
        offset = Math.abs(event.timestamp - initialPoint) / divider;
        let inSessionIdx = event.inSessionIdx;
        const splittedMachineName = event.machine?.split("-");
        if (splittedMachineName?.length > 0) {
          const sessionIndex = Number(splittedMachineName[splittedMachineName.length - 1]);
          if (typeof sessionIndex === "number") {
            inSessionIdx = sessionIndex;
          }
        }
        eventsSeries[eventsSeries.length] = (data.audioOffset || data.audioOffset === 0) && [
          offset,
          -1,
          event.event,
          "todo: use runName",
          // this.props.test.runName,
          inSessionIdx,
        ];
      }
    }
  }

  return eventsSeries;
};

const convertSeriesPCT = (charts: any, j: number) => {
  const len = charts.lossPCT.dataset[j].data.length;
  for (let i = 0; i < len; i++) {
    if (
      charts.packets.dataset[j].data[i] &&
      charts.packets.dataset[j].data[i][1] &&
      charts.lossPCT.dataset[j].data[i][1]
    ) {
      const isRecv = charts.lossPCT.dataset[j]?.label?.indexOf("Incoming") !== -1;
      const packetsLoss = charts.lossPCT.dataset[j].data[i][1];
      const totalPackets = charts.packets.dataset[j].data[i][1];
      // https://redmine.testrtc.com/issues/4235
      // recalculate % only for recv
      const res = isRecv
        ? (packetsLoss / (totalPackets + packetsLoss)) * 100
        : (packetsLoss / totalPackets) * 100;

      charts.lossPCT.dataset[j].data[i][1] = res;
    } else {
      charts.lossPCT.dataset[j].data[i][1] = 0;
    }
  }
};

const getDefaultOptions = (dataType: DataType, isPCT = false) => {
  if (isPCT && dataType == "loss") {
    dataType = "lossPCT";
  }
  return {
    chart: {
      zoomType: "x",
    },
    yAxis: [
      {
        axisLabel: dataTypeToTitle[isPCT ? dataTypesValues.lossPCT : dataType].yAxisLabel,
        title: {
          text: dataTypeToTitle[dataType].yAxisLabel,
        },
      },
      {
        linkedTo: 0,
        visible: false,
      },
    ],
  };
};

export const checkIfHasData = (data: any, type: "audio" | "video") => {
  try {
    const srcs = ["fltrDataset", "fltrDataset_BCNL", "probDatasetSorted"];
    const directions = ["recv", "send"];

    let hasData = false;

    srcs.forEach((src) => {
      if (!data[src]) {
        return;
      }

      directions.forEach((dir) => {
        if (data[src][type] && data[src][type][dir]) {
          const dataKeys = Object.keys(data[src][type][dir]);
          dataKeys.forEach((dk) => {
            const values = data[src][type][dir][dk];

            if (values?.length) {
              const valuesAreNullOrZeroOrNan = values.every((x: any) => {
                if (!x) {
                  return true;
                }
                if (isNaN(x) || x === null || x === 0) {
                  return true;
                }
                return false;
              });
              if (!valuesAreNullOrZeroOrNan) {
                hasData = true;
              }
            }
          });
        }
      });
    });

    return hasData;
  } catch (err) {
    console.error("checkIfHasData", err.message);
    return true;
  }
};

export const checkIfHasPerfData = (data: any) => {
  try {
    let hasData = false;
    const sources = ["fltrPerf", "fltrProbPerf"]; // processedData keys
    const usedKeys = ["browserCpu", "browserMemory"];
    const metrics = ["avg", "min", "max"];
    sources.forEach((source) => {
      if (data.hasOwnProperty(source) && data[source]) {
        if (source === "fltrPerf") {
          usedKeys.forEach((key) => {
            if (data[source].hasOwnProperty(key) && data[source][key]) {
              metrics.forEach((metric) => {
                if (data[source][key].hasOwnProperty(metric)) {
                  const values = data[source][key][metric];
                  const isAllNull = values.every((x: any) => x === 0 || x === null || isNaN(x));
                  if (!isAllNull) {
                    hasData = true;
                  }
                }
              });
            }
          });
        }
        if (source === "fltrProbPerf") {
          usedKeys.forEach((key) => {
            if (data[source].hasOwnProperty(key)) {
              const probesPerformance = data[source][key];
              if (probesPerformance?.length) {
                probesPerformance.forEach((probeData: any) => {
                  metrics.forEach((metric) => {
                    const value = probeData.values[metric];
                    if (value === null || isNaN(value)) {
                      hasData = false;
                    }
                  });
                });
              }
            }
          });
        }
      } else {
        return;
      }
    });
    return hasData;
  } catch (err) {
    console.error("checkIfHasPerfData", err.message);
    return false;
  }
};
