import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Theme, withStyles, WithStyles, Typography, createStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Email from "@material-ui/icons/EmailOutlined";
import { App as RoutesName } from "../../../constants/RoutesNames";
import { Link } from "react-router-dom";

type StyledComponent = WithStyles<"root" | "mailIcon" | "text" | "link">;

// tslint:disable-next-line:no-any
// const SupportLink = (props: any) => <Link to={RoutesName.Support} {...props} />;

class Support extends React.Component<RouteComponentProps<{}> & StyledComponent> {
  public render(): JSX.Element {
    return (
      <Link to={RoutesName.Support} className={this.props.classes.link}>
        <Button color="primary" className={this.props.classes.root}>
          <Email className={this.props.classes.mailIcon} />
          <Typography variant="body2" color="primary" className={this.props.classes.text}>
            {"Support"}
          </Typography>
        </Button>
      </Link>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
    },
    root: {
      [_theme.breakpoints.down("xs")]: {
        minWidth: 40,
        padding: 0,
      },
    },
    mailIcon: {
      [_theme.breakpoints.up("xs")]: {
        marginRight: 5,
      },
    },
    text: {
      fontWeight: 500,
      letterSpacing: "0.02857em",
      [_theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}>>(decorate(Support));
