import * as React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { FormApi } from "final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import MuiTooltip from "@material-ui/core/Tooltip";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import Icon from "@material-ui/core/Icon";
import OpenInNew from "@material-ui/icons/OpenInNew";
import TextFieldControl from "../../components/form-elements/TextFieldControl";
// import ConfirmDialog from "../../components/Dialog/Confirm";
import FormValidators from "../../helpers/form-validators";
import { ITestPropertyForm } from "./index";
// import Tooltip from "../../components/Tooltip";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import ScriptUploadButton from "./ScriptUploadButton";
import { RouteComponentProps, withRouter, Prompt } from "react-router";
import { Support } from "../../constants/RoutesNames";
import { TestPropertyState } from "./index";
import AxiosFactory from "../../services/AxiosFactory";
import { AxiosResponse } from "axios";
import ApiPath from "../../constants/ApiPath";
import { requireAceAutocompleter, commandsAceAutocompleter } from "../../helpers/aceHelper";
import AceEditorCommands from "../../constants/EditorCompleteCommands";
import { Hidden, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import ActionButtons from "./ActionButtons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LastTests from "./LastTests";

interface TestPropertyFormProps extends RouteComponentProps<{}> {
  // TODO: remove any
  userConfig: any;
  // TODO: remove any
  initialValues?: any;
  newMode: boolean;
  onSubmit(values: ITestPropertyForm, formApi: FormApi): void;
  onDuplicateButtonClick(): void;
  exportHandler(): void;
  deleteHandler(): void;
  onDelete(): void;
  onRunButtonClick(
    e: React.MouseEvent<HTMLElement>,
    values: ITestPropertyForm,
    dirty: boolean,
    invalid: boolean
  ): void;
}

interface TestPropertyFormState {
  showEdit: boolean;
  dirty: boolean;
}

type StyledComponent = WithStyles<
  | "root"
  | "formRoot"
  | "addProfileBtnContainer"
  | "addProfileBtn"
  | "removeBtnContainer"
  | "removeBtn"
  | "greenBtn"
  | "fieldLabel"
  | "indexLabel"
  | "formFieldGridItem"
  | "runBtn"
  | "saveBtnContainer"
  | "bottomActionsContainer"
  | "tooltipLabel"
  | "editBtn"
  | "iconSmall"
  | "mediaMenuItem"
  | "openInNewIconContainer"
  | "openInNewIcon"
  | "tooltip"
>;

const FORM_LABEL_WIDTH = 200;

const tooltipTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: ".85em",
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      },
    },
  },
});

class TestPropertyForm extends React.Component<
  TestPropertyFormProps & StyledComponent & TestPropertyState,
  TestPropertyFormState
> {
  constructor(props: TestPropertyFormProps & StyledComponent & TestPropertyState) {
    super(props);

    this.state = {
      showEdit: false,
      dirty: false,
    };
  }

  configureAceEditor = async (editor: any) => {
    // fetch assets names for the ace editor autocomplete
    const names = await this.fetchAssetsNames();
    // set autocompleters
    editor.completers = [
      requireAceAutocompleter(names),
      commandsAceAutocompleter(AceEditorCommands),
    ];
  };

  fetchAssetsNames = async () => {
    try {
      const axiosFactory = new AxiosFactory();

      const result: AxiosResponse<any> = await axiosFactory.axios.get(ApiPath.api.assets, {
        params: {
          limit: 500,
        },
      });
      return result.data.docs ? result.data.docs.map((doc: any) => doc.name) : [];
    } catch {
      return [];
    }
  };

  onLeave = (): boolean => {
    return this.state.dirty;
  };

  calculateNumberOfSessions = (
    currentProbes: number,
    sessionSize: number,
    setNumberOfSessions: any
  ) => {
    try {
      const result = Math.ceil(currentProbes / sessionSize);
      setNumberOfSessions(result);
    } catch {
      setNumberOfSessions(1);
    }
  };

  onBeautifyClick = async (func: any, value: any) => {
    try {
      const beautify = await import("js-beautify");
      func(beautify.js(value));
    } catch (err) {
      console.error("onBeautifyClick", err.message, err.stack);
    }
  };

  render() {
    const {
      classes,
      userConfig,
      initialValues,
      onSubmit,
      newMode,
      onDuplicateButtonClick,
      exportHandler,
      deleteHandler,
      onDelete,
      deleteDialog,
      onRunButtonClick,
      isRunning,
    } = this.props;
    return (
      <Grid container={true} spacing={2} data-cy="tests-property-form">
        <Prompt
          when={this.onLeave()}
          message="Are you sure you want to leave the page? You have unsaved changes."
        />
        <Grid item={true} xs={12}>
          <Paper className={classes.root}>
            <Form
              onSubmit={(values, formApi) => {
                onSubmit(values as ITestPropertyForm, formApi);
              }}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators,
                setTestScriptValue: (values, state, { changeValue }) => {
                  changeValue(state, "testScript", () => values[0]);
                },
                setCurrentProbes: (values, state, { changeValue }) => {
                  changeValue(state, "parameters.concurrentUsers", () => values[0]);
                },
                setSessionSize: (values, state, { changeValue }) => {
                  changeValue(state, "sessionSize", () => values[0]);
                },
                setNumberOfSessions: (values, state, { changeValue }) => {
                  changeValue(state, "numberOfSessions", () => values[0]);
                },
              }}
              render={({ handleSubmit, form: { mutators }, values, invalid, dirty }) => (
                <form className={classes.formRoot} onSubmit={handleSubmit}>
                  <FormSpy
                    subscription={{ dirty: true }}
                    onChange={(props) => {
                      this.setState({
                        dirty: props.dirty,
                      });
                    }}
                  />
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} className={classes.saveBtnContainer}>
                      <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        className={classes.greenBtn}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        className={classes.runBtn}
                        disabled={isRunning}
                        onClick={(e) => {
                          this.setState({
                            dirty: false,
                          });
                          onRunButtonClick(e, values as ITestPropertyForm, dirty, invalid);
                        }}
                      >
                        Run
                      </Button>
                    </Grid>
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Test Name</FormLabel>
                      </Grid>
                      <Grid className={classes.formFieldGridItem} item={true}>
                        <Field
                          component={TextFieldControl}
                          type="text"
                          name="name"
                          validate={FormValidators.composeValidators(
                            FormValidators.required(),
                            FormValidators.maxValue(512)
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Description</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          component={TextFieldControl}
                          type="text"
                          multiline={true}
                          rows="5"
                          name="info"
                          validate={FormValidators.maxValue(512)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} lg={4} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Concurrent probes</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem} xs={2}>
                        <Field
                          component={TextFieldControl}
                          type="number"
                          name="parameters.concurrentUsers"
                          min={1}
                          onchange={(val: any) => {
                            const value = val.target.valueAsNumber || Number(val.target.value);
                            mutators.setCurrentProbes(value);
                            this.calculateNumberOfSessions(
                              value,
                              values.sessionSize,
                              mutators.setNumberOfSessions
                            );
                            return true;
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} lg={4} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Session size</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem} xs={2}>
                        <Field
                          component={TextFieldControl}
                          type="number"
                          name="sessionSize"
                          min={1}
                          validate={FormValidators.required()}
                          onchange={(val: any) => {
                            const value = val.target.valueAsNumber || Number(val.target.value);
                            mutators.setSessionSize(value);
                            this.calculateNumberOfSessions(
                              values.parameters.concurrentUsers,
                              val.target.value,
                              mutators.setNumberOfSessions
                            );
                            return true;
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} lg={4} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Number of sessions</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem} xs={2}>
                        <Field
                          component={TextFieldControl}
                          type="number"
                          name="numberOfSessions"
                          min={1}
                          validate={FormValidators.required()}
                          readonly={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Webhook</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          component={TextFieldControl}
                          type="text"
                          multiline={true}
                          rows="5"
                          name="webhook"
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Run Options</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          component={TextFieldControl}
                          type="text"
                          multiline={true}
                          rows="5"
                          name="runOptions"
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={10} md={8} lg={9} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Service URL</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          component={TextFieldControl}
                          type="text"
                          name="serviceUrl"
                          placeholder="https://...."
                          validate={FormValidators.isURL}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item={true}
                      xs={2}
                      md={4}
                      lg={3}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <Button
                        type="button"
                        color="primary"
                        className={classes.greenBtn}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          window.open(values.serviceUrl, "serviceurl");
                          e.stopPropagation();
                        }}
                      >
                        <Hidden xsDown={true}>Open</Hidden>
                        <OpenInNew className={classes.openInNewIcon} />
                      </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={2}>
                      <ScriptUploadButton
                        onUploaded={(file: string) => {
                          this.setState({ showEdit: true });
                          mutators.setTestScriptValue(file);
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={12} sm={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.editBtn}
                        onClick={() => this.setState({ showEdit: !this.state.showEdit })}
                        data-cy="edit-script-btn"
                      >
                        {!this.state.showEdit && (
                          <Icon className={classes.iconSmall}>keyboard_arrow_down</Icon>
                        )}
                        {this.state.showEdit && (
                          <Icon className={classes.iconSmall}>keyboard_arrow_up</Icon>
                        )}
                        Edit
                      </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={3}>
                      <MuiThemeProvider theme={tooltipTheme}>
                        <MuiTooltip
                          title="Now FREE, We will love to write or debug the script for you!"
                          // className={classes.tooltip}
                        >
                          <Button
                            variant="contained"
                            className={classes.addProfileBtn}
                            onClick={() =>
                              this.props.history.push({
                                pathname: Support,
                                state: {
                                  subject: `I need help with my test named ${values.name}`,
                                },
                              })
                            }
                          >
                            Request Help
                          </Button>
                        </MuiTooltip>
                      </MuiThemeProvider>
                    </Grid>
                    <Field component="input" type="hidden" name="testScript" label="testScript" />
                    {this.state.showEdit && (
                      <Grid item={true} xs={12} data-cy="script-editor">
                        <AceEditor
                          mode="javascript"
                          theme="github"
                          name="testScript"
                          width={"100%"}
                          onChange={(file: string) => mutators.setTestScriptValue(file)}
                          value={values.testScript}
                          editorProps={{ $blockScrolling: Infinity }}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                          }}
                          onLoad={(editor) => this.configureAceEditor(editor)}
                        />
                        <Button
                          color="default"
                          variant="contained"
                          style={{ marginTop: 5 }}
                          onClick={() => {
                            this.onBeautifyClick(mutators.setTestScriptValue, values.testScript);
                          }}
                        >
                          {"beautify"}
                        </Button>
                      </Grid>
                    )}
                    <FieldArray name="testProfiles">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <React.Fragment key={index}>
                            <Grid className={classes.root} item={true} xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item={true} lg={6} xs={12} container={true}>
                              <Grid item={true}>
                                <FormLabel className={classes.fieldLabel}>Browser</FormLabel>
                              </Grid>
                              <Grid item={true} className={classes.formFieldGridItem}>
                                <Field
                                  component={TextFieldControl}
                                  select={true}
                                  SelectProps={{ autoWidth: true }}
                                  name={`${name}.browser`}
                                  validate={FormValidators.required()}
                                >
                                  {userConfig.data["docker-machines"].map(
                                    (option: any, i: number) => (
                                      <MenuItem key={i} value={option.id}>
                                        {option.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </Grid>
                            </Grid>
                            <Grid item={true} lg={6} xs={12} container={true}>
                              <Grid item={true}>
                                <FormLabel className={classes.fieldLabel}>
                                  Network Profile
                                </FormLabel>
                              </Grid>
                              <Grid item={true} className={classes.formFieldGridItem}>
                                <Field
                                  component={TextFieldControl}
                                  select={true}
                                  SelectProps={{
                                    autoWidth: true,
                                    renderValue: (value: string) => (
                                      <div>
                                        {
                                          userConfig.data["network-profiles"].find(
                                            (f: any) => f.id === value
                                          ).name
                                        }
                                      </div>
                                    ),
                                  }}
                                  name={`${name}.network`}
                                  validate={FormValidators.required()}
                                >
                                  {userConfig.data["network-profiles"].map(
                                    (option: any, i: number) => (
                                      <MenuItem
                                        key={i}
                                        value={option.id}
                                        className={classes.mediaMenuItem}
                                      >
                                        <div>
                                          <strong>{option.name}</strong>
                                        </div>
                                        <div>{option.description}</div>
                                        <CopyToClipboard text={option.name}>
                                          <div>Click to Copy</div>
                                        </CopyToClipboard>
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </Grid>
                            </Grid>
                            <Grid item={true} lg={6} xs={12} container={true}>
                              <Grid item={true}>
                                <FormLabel className={classes.fieldLabel}>Location</FormLabel>
                              </Grid>
                              <Grid item={true} className={classes.formFieldGridItem}>
                                <Field
                                  component={TextFieldControl}
                                  select={true}
                                  SelectProps={{ autoWidth: true }}
                                  name={`${name}.location`}
                                  validate={FormValidators.required()}
                                >
                                  {userConfig.data["agent-locations"].map(
                                    (option: any, i: number) => (
                                      <MenuItem key={i} value={option.id}>
                                        {option.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </Grid>
                            </Grid>
                            <Grid item={true} lg={6} xs={12} container={true}>
                              <Grid item={true}>
                                <FormLabel className={classes.fieldLabel}>
                                  Firewall Profile
                                </FormLabel>
                              </Grid>
                              <Grid item={true} className={classes.formFieldGridItem}>
                                <Field
                                  component={TextFieldControl}
                                  select={true}
                                  SelectProps={{
                                    autoWidth: true,
                                    renderValue: (value: string) => (
                                      <div>
                                        {
                                          userConfig.data["firewall-profiles"].find(
                                            (f: any) => f.id === value
                                          ).name
                                        }
                                      </div>
                                    ),
                                  }}
                                  name={`${name}.firewall`}
                                  validate={FormValidators.required()}
                                >
                                  {userConfig.data["firewall-profiles"].map(
                                    // TODO: remove any
                                    (option: any, i: number) => (
                                      <MenuItem
                                        key={i}
                                        value={option.id}
                                        className={classes.mediaMenuItem}
                                      >
                                        <div>
                                          <strong>{option.name}</strong>
                                        </div>
                                        <div>{option.description}</div>
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </Grid>
                            </Grid>
                            <Grid item={true} lg={6} xs={12} container={true}>
                              <Grid item={true}>
                                <FormLabel className={classes.fieldLabel}>Media</FormLabel>
                              </Grid>
                              <Grid item={true} className={classes.formFieldGridItem}>
                                <Field
                                  component={TextFieldControl}
                                  select={true}
                                  SelectProps={{
                                    autoWidth: true,
                                    renderValue: (value: string) => (
                                      <div>
                                        {
                                          (
                                            userConfig.data["media-list"].find(
                                              (f: any) => f.id === value
                                            ) || {}
                                          ).displayName
                                        }
                                      </div>
                                    ),
                                  }}
                                  name={`${name}.media`}
                                  validate={FormValidators.required()}
                                >
                                  {userConfig.data["media-list"].map(
                                    // TODO: remove any
                                    (option: any, i: number) => (
                                      <MenuItem
                                        key={i}
                                        value={option.id}
                                        className={classes.mediaMenuItem}
                                      >
                                        <div>{option.displayName}</div>
                                        {option.video && (
                                          <div>
                                            <span>
                                              <strong>Video:</strong>
                                              {`${option.video.resolution.w}x${option.video.resolution.h}, 
                                              ${option.video.bitrate} Kbps`}
                                            </span>
                                          </div>
                                        )}
                                        {option.audio && (
                                          <div>
                                            <span>
                                              <strong>Audio:</strong>
                                              {`${option.audio.bitrate || "N/A"} Kbps`}
                                            </span>
                                          </div>
                                        )}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </Grid>
                            </Grid>
                            <Grid className={classes.removeBtnContainer} item={true} lg={6} xs={12}>
                              {fields && fields.length && fields.length > 1 ? (
                                <React.Fragment>
                                  <FormLabel className={classes.indexLabel}>
                                    {`${index + 1} of ${fields.length}`}
                                  </FormLabel>
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                    color="primary"
                                    className={classes.removeBtn}
                                    data-cy="remove-profile-btn"
                                  >
                                    <RemoveCircleOutline />
                                  </IconButton>
                                </React.Fragment>
                              ) : undefined}
                            </Grid>
                            {index === (fields.length ? fields.length - 1 : -1) && (
                              <Grid
                                className={classes.addProfileBtnContainer}
                                item={true}
                                md={6}
                                xs={12}
                              >
                                <Button
                                  variant="contained"
                                  className={classes.addProfileBtn}
                                  onClick={() =>
                                    fields.push({
                                      ...initialValues.testProfiles[0],
                                    })
                                  }
                                  data-cy="add-profile-btn"
                                >
                                  Add Profile
                                </Button>
                              </Grid>
                            )}
                          </React.Fragment>
                        ))
                      }
                    </FieldArray>
                    <Hidden smUp={true}>
                      <Grid item={true} xs={12}>
                        <Divider />
                      </Grid>
                    </Hidden>
                    <Grid item={true} xs={12} className={classes.bottomActionsContainer}>
                      <ActionButtons
                        {...this.props}
                        newMode={newMode}
                        toggleDeleteDialog={deleteHandler}
                        deleteDialogOpen={deleteDialog}
                        isRunning={isRunning}
                        onRun={(e: React.MouseEvent<HTMLElement>) => {
                          this.setState({
                            dirty: false,
                          });
                          onRunButtonClick(e, values as ITestPropertyForm, dirty, invalid);
                        }}
                        onDuplicate={onDuplicateButtonClick}
                        onExport={exportHandler}
                        onDelete={onDelete}
                      />
                      {/* {!newMode && (
                        <>
                          <Hidden xsDown={true}>
                            <Button
                              type="button"
                              color="primary"
                              className={classes.greenBtn}
                              onClick={deleteHandler}
                            >
                              Delete
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              className={classes.greenBtn}
                              onClick={exportHandler}
                            >
                              Export
                            </Button>
                            <Button
                              type="button"
                              color="primary"
                              className={classes.greenBtn}
                              onClick={onDuplicateButtonClick}
                              data-cy="duplicate-test-btn"
                            >
                              Duplicate
                            </Button>
                            <ConfirmDialog
                              open={deleteDialog}
                              title="Are you sure?"
                              content="You will not be able to recover this test!"
                              handleConfirm={onDelete}
                              handleClose={deleteHandler}
                            />
                          </Hidden>
                        </>
                      )} */}
                      {/* <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        className={classes.greenBtn}
                        data-cy="save-test-btn"
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        className={classes.runBtn}
                        disabled={isRunning}
                        onClick={(e) =>
                          onRunButtonClick(e, values as ITestPropertyForm, dirty, invalid)
                        }
                      >
                        Run
                      </Button> */}
                    </Grid>
                    <LastTests testName={values.name} {...this.props} />
                  </Grid>
                </form>
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const decorate = withStyles((theme: Theme): {
  [className: string]: CSSProperties;
} => ({
  root: {
    width: "100%",
    height: "auto",
    padding: theme.spacing(3),
  },
  addProfileBtnContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  addProfileBtn: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: "#3e76b2",
      color: "white",
    },
    "&:not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  fieldLabel: {
    display: "inline-block",
    fontSize: 13,
    padding: "10px 12px 0 0",
    textAlign: "right",
    width: FORM_LABEL_WIDTH,
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  indexLabel: {
    marginRight: theme.spacing(2),
  },
  formFieldGridItem: {
    flexGrow: 1,
  },
  removeBtnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  removeBtn: {
    backgroundColor: "#d2d2d2",
    borderRadius: 3,
    color: "#a22a21",
  },
  greenBtn: {
    "&:not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
  },
  runBtn: {
    marginLeft: theme.spacing(2),
  },
  editBtn: {
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
  },
  saveBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  bottomActionsContainer: {
    flexBasis: "unset",
    maxWidth: "unset",
    width: "calc(100% + 30px)",
    margin: "0 -15px",
    zIndex: 100,
    marginBottom: "10px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "sticky",
    top: 0,
    bottom: 0,
    paddingRight: "50px !important",
    backgroundColor: "#f4f4f4",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  actionButtonsLeftContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "& button": {
        margin: "0 !important",
      },
    },
  },
  actionButtonsRightContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      height: "100%",
      justifyContent: "space-evenly",
      "& button": {
        margin: "0 !important",
      },
    },
  },
  tooltipLabel: {
    color: theme.palette.primary.main,
  },
  iconSmall: {
    fontSize: 20.5,
  },
  mediaMenuItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    fontSize: 11,
  },
  openInNewIconContainer: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    verticalAlign: "middle",
  },
  openInNewIcon: {
    fontSize: 19,
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  tooltip: {
    // background: "rgba(0, 0, 0, 0.7)",
    // fontSize: ".85em",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}));

export default withRouter(decorate(TestPropertyForm));
