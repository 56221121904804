import { PUSH_BREADCRUMB_NODE, RESET_BREADCRUMB } from "../constants/ActionTypes";

export interface ISetBreadcrumbAction {
  type: string;
  payload: BreadcrumbNode;
}

export const PushNode = (node: BreadcrumbNode) => {
  return {
    type: PUSH_BREADCRUMB_NODE,
    payload: node,
  };
};

export const PopNode = (node: BreadcrumbNode) => {
  return {
    type: PUSH_BREADCRUMB_NODE,
    payload: node,
  };
};

export const ResetBreadcrumb = () => {
  return {
    type: RESET_BREADCRUMB,
  };
};
