import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Theme, WithStyles, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getLocationTitle } from "../../../../constants/PageStateDictionary";

import * as moment from "moment";
import AdvancedToolbar from "./AdvancedToolbar";
import { AppToolbarContext } from "./Context";

type AppToolbarProps = {
  selectedProject?: Project | null;
};

const getAlertMessage = (selectedProject: Project): string => {
  const project = selectedProject;
  if (!project) {
    return "";
  }

  if (project.accountType === "Evaluation") {
    const left = (project.max_EvaluationTestRunCount || 100) - (project.stat_testRunCount || 0);
    const expired = new Date(project.paymentExpiry).getTime() < Date.now();
    if (expired || isNaN(left) || left <= 0) {
      return "Your account has expired. Please contact support if you wish to renew it";
    } else {
      // tslint:disable-next-line:max-line-length
      return (
        "Evaluation Account valid until " +
        moment(project.paymentExpiry).format("DD MMM YYYY") +
        "; You have " +
        left +
        "  more tests."
      );
    }
  } else if (project.accountType === "Suspended") {
    return "Please contact support";
  }
  return "";
};

const AppToolbar = (
  props: AppToolbarProps &
    WithStyles<"alertText" | "toolbar" | "alertToolbar" | "headText" | "appBar"> &
    RouteComponentProps<{}>
) => {
  const { classes, location, selectedProject } = props;
  const { pageInfo } = React.useContext(AppToolbarContext);
  const headText: string = getLocationTitle(location.pathname);
  const alertMessage = selectedProject ? getAlertMessage(selectedProject) : "";
  const isValidUntilAlertMessage = alertMessage.indexOf("Evaluation Account valid until") !== -1;

  const showToolbar =
    headText.toLowerCase() !== "home" &&
    headText.toLowerCase() !== "network tests" &&
    headText.toLowerCase() !== "billing data" &&
    headText.toLowerCase() !== "analytics events";

  const getHeadText = () => {
    const addDivider = headText.split(" ").join(" > ");
    return `${addDivider} ${pageInfo && pageInfo[headText] ? `: ${pageInfo[headText]}` : ""}`;
  };

  return (
    <AppBar className={classes.appBar} position="static" color="default">
      {selectedProject &&
        (selectedProject.accountType === "Evaluation" ||
          selectedProject.accountType === "Suspended") && (
          <Toolbar
            className={classes.alertToolbar}
            style={{ backgroundColor: isValidUntilAlertMessage ? "#d9edf7" : "#ff665b" }}
          >
            <Typography className={classes.alertText}>{alertMessage}</Typography>
          </Toolbar>
        )}
      {showToolbar ? <AdvancedToolbar {...props} headText={getHeadText()} /> : null}
    </AppBar>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: "none",
      color: "#83807D",
    },
    toolbar: {
      ...theme.mixins.toolbar,
      padding: theme.spacing(2),
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    tabsWrapper: {
      padding: theme.spacing(2),
    },
    alertToolbar: {
      ...theme.mixins.toolbar,
    },
    alertText: {
      fontWeight: 500,
    },
    headText: {
      fontWeight: 400,
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}> & AppToolbarProps>(decorate(AppToolbar));
