import * as React from "react";
import Button from "@material-ui/core/Button";

interface ScriptUploadButtonProps {
  onUploaded: (file: string) => void;
}

class ScriptUploadButton extends React.Component<ScriptUploadButtonProps> {
  upload: HTMLInputElement | null = null;

  constructor(props: ScriptUploadButtonProps) {
    super(props);

    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(event: React.FormEvent<HTMLInputElement>) {
    const file = event.target["files"][0]; // tslint:disable-line

    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (evt: Event) => {
      this.props.onUploaded(evt.target ? evt.target["result"] : {}); // tslint:disable-line
    };
  }

  render() {
    return (
      <React.Fragment>
        <input
          type="file"
          ref={(ref) => (this.upload = ref)}
          style={{ display: "none" }}
          accept="application/javascript"
          onChange={this.uploadFile}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => this.upload !== null && this.upload.click()}
        >
          Upload
        </Button>
      </React.Fragment>
    );
  }
}

export default ScriptUploadButton;
