// tslint:disable:no-any

import * as React from "react";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Warning from "@material-ui/icons/Warning";
import Error from "@material-ui/icons/Error";
import { Tabs, Tab } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { LogToolbarProps, LogToolbarState } from "./index";
import MediaPreview from "./MediaPreview";
import LogList from "./LogList";
import { TypesMap, NotificationTypes, NotificationType } from "./index";
import PerformanceLogs from "../../../../LogsContainer/PerformanceLogs";

interface LogToolbarViewProps {
  handleTabChange: (e: any, tab: number) => void;
  changeLimit: (selectedTypevalue: string) => void;
  selectType: (type: NotificationType) => void;
  getAllWarnings: () => Array<any>;
}

type StyledComponent = WithStyles<
  | "toolbar"
  | "button"
  | "headContainer"
  | "chartTypeControlsContainer"
  | "selectField"
  | "contentContainer"
  | "messageColumn"
  | "typeColumn"
  | "notifColumn"
  | "limitButton"
  | "errorTabIcon"
  | "warningTabIcon"
  | "logsWarningIcon"
  | "gridList"
  | "imageTile"
  | "listTileBar"
  | "fullScreenIcon"
  | "filterButtonsContainer"
  | "tabsRoot"
  | "labelContainer"
  | "tabLabel"
>;

const View = (props: StyledComponent & LogToolbarProps & LogToolbarState & LogToolbarViewProps) => {
  const {
    classes,
    currentTab,
    handleTabChange,
    changeLimit,
    warningsCount,
    warnings,
    warningErr,
    showWarningIcon,
    limitMap,
    notificationList,
    mediaList,
    hasLogs,
    resourceList,
    stdout,
    selectedType,
    standOutUrl,
    selectType,
    logHasErrors,
    assetsFileNames,
    testIteration,
    testRun,
    getAllWarnings,
    performance,
  } = props;

  const voiceStartTime = testIteration.stat ? testIteration.stat.voiceStartTime : 0;
  return (
    <Grid container={true} spacing={5}>
      <div className={classes.headContainer}>
        <AppBar className={classes.toolbar}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab
              // classes={{ root: classes.labelContainer, label: classes.tabLabel }}
              label={
                <span>
                  {warningsCount > 0 && warningErr && <Error className={classes.errorTabIcon} />}
                  {warningsCount > 0 && showWarningIcon && (
                    <Warning className={classes.warningTabIcon} />
                  )}
                  <span>
                    {`Notifications ${warningsCount > 0 ? "(" + warningsCount + ")" : ""}`}
                  </span>
                </span>
              }
            />
            <Tab
              label="Media"
              // classes={{ root: classes.labelContainer, label: classes.tabLabel }}
            />
            <Tab
              label="Performance"
              // classes={{ root: classes.labelContainer, label: classes.tabLabel }}
            />
            <Tab
              // classes={{ root: classes.labelContainer, label: classes.tabLabel }}
              label={
                <span>
                  {logHasErrors && <Typography className={classes.logsWarningIcon}>!</Typography>}
                  <span>Logs</span>
                </span>
              }
            />
          </Tabs>
        </AppBar>
        <div className={classes.contentContainer}>
          {currentTab === 0 &&
            (warningsCount > 0 ? (
              <>
                {warningsCount >= 7 && (
                  <div className={classes.filterButtonsContainer}>
                    {NotificationTypes.map((type) => (
                      <Button
                        key={type.value}
                        color="primary"
                        onClick={() => selectType(type)}
                        variant={type.value === selectedType.value ? "contained" : undefined}
                      >
                        {type.label}
                      </Button>
                    ))}
                  </div>
                )}
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.notifColumn}>Channel</TableCell>
                      <TableCell className={classes.typeColumn}>Type</TableCell>
                      <TableCell className={classes.messageColumn}>Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedType.value !== "all" &&
                      Object.keys(notificationList).map((key, _i) => {
                        const notificationKey = notificationList[key];
                        return typeof notificationKey === "string"
                          ? warnings[0][notificationKey].map((log: any, j: number) =>
                              // filter apply if more than 7
                              log.alertType === selectedType.value &&
                              (limitMap[notificationKey] || j < 2) ? (
                                <TableRow key={j}>
                                  <TableCell>{log.channel}</TableCell>
                                  <TableCell>{TypesMap[log.alertType]}</TableCell>
                                  <TableCell>{log.message}</TableCell>
                                </TableRow>
                              ) : null
                            )
                          : null;
                      })}
                    {selectedType.value === "all" &&
                      getAllWarnings().map((log, i) => {
                        return limitMap.all || i < 2 ? (
                          <TableRow key={i}>
                            <TableCell style={{ width: "12%" }}>{log.channel}</TableCell>
                            <TableCell style={{ width: "12%" }}>
                              {TypesMap[log.alertType]}
                            </TableCell>
                            <TableCell style={{ width: "76%", wordBreak: "break-word" }}>
                              {log.message}
                            </TableCell>
                          </TableRow>
                        ) : null;
                      })}
                    {selectedType.value !== "all" &&
                      warnings[0][selectedType.value] &&
                      warnings[0][selectedType.value].length &&
                      warnings[0][selectedType.value].length > 2 && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Button
                              onClick={() => changeLimit(selectedType.value)}
                              className={classes.limitButton}
                            >
                              {`Show ${limitMap[selectedType.value] ? "less" : "all"}`}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    {selectedType.value === "all" && getAllWarnings().length > 2 && (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Button
                            onClick={() => changeLimit("all")}
                            className={classes.limitButton}
                          >
                            {`Show ${limitMap.all ? "less" : "all"}`}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </>
            ) : (
              <Typography variant="subtitle1" align="center">
                No Errors or Warnings
              </Typography>
            ))}
          {currentTab === 1 && <MediaPreview mediaList={mediaList} />}
          {currentTab === 2 && (
            <PerformanceLogs data={performance} voiceStartTime={voiceStartTime} />
          )}
          {currentTab === 3 && (
            <LogList
              assetsFileNames={assetsFileNames}
              stdout={stdout}
              standOutUrl={standOutUrl}
              hasLogs={hasLogs}
              resourceList={resourceList}
              test={testIteration}
              testRun={testRun}
            />
          )}
        </div>
      </div>
    </Grid>
  );
};

const TableHeadStyles = {
  backgroundColor: "#F5F5F6",
  fontWeight: 600,
  fontSize: "0.9rem",
} as React.CSSProperties;

const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: `calc(100% - ${theme.spacing(6)}px)`,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      margin: theme.spacing(3),
      position: "relative",
      backgroundColor: "white",
      color: "black",
      boxShadow: "none",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        width: "100%",
        margin: 0,
      },
    },
    tabsRoot: {
      [theme.breakpoints.down("xs")]: {},
    },
    labelContainer: {
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    tabLabel: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        letterSpacing: "0.01px",
      },
    },
    button: {
      margin: theme.spacing(1) / 2,
    },
    headContainer: {
      width: "100%",
      display: "block",
    },
    chartTypeControlsContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: `0 ${theme.spacing(3) + theme.spacing(1) / 2}px`,
      paddingBottom: theme.spacing(3) + theme.spacing(1) / 2,
    },
    selectField: {
      margin: theme.spacing(1) / 2,
    },
    contentContainer: {
      padding: "0px 24px 24px 24px",
      [theme.breakpoints.down("xs")]: {
        overflowX: "scroll",
      },
    },
    messageColumn: {
      ...TableHeadStyles,
    },
    typeColumn: {
      ...TableHeadStyles,
      width: "12%",
    },
    notifColumn: {
      ...TableHeadStyles,
      width: "12%",
    },
    limitButton: {
      margin: "auto",
      width: "auto",
      display: "block",
      color: theme.palette.primary.main,
    },
    warningTabIcon: {
      display: "inline",
      verticalAlign: "middle",
      color: "#F1CD2B",
      marginRight: 10,
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    errorTabIcon: {
      display: "inline",
      verticalAlign: "middle",
      color: "#A22A21",
      marginRight: 10,
    },
    logsWarningIcon: {
      fontWeight: 600,
      fontSize: "1.2rem",
      color: "#A22A21",
      marginRight: 10,
      verticalAlign: "middle",
      display: "inline",
    },
    gridList: {
      width: "100%",
      height: "auto",
      maxHeight: 450,
    },
    imageTile: {
      width: "30%",
      height: 120,
    },
    listTileBar: {
      backgroundColor: "rgba(0, 0, 0, 0.67)",
    },
    fullScreenIcon: {
      color: "white",
    },
    filterButtonsContainer: {
      marginBottom: 20,
    },
  });

const decorate = withStyles(styles);

export default decorate(View);
