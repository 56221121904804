import * as React from "react";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { RouteComponentProps, withRouter } from "react-router";
import { NewAssetProperty } from "../../constants/RoutesNames";

type StyledComponent = WithStyles<"root" | "newAssetButton">;

type GridControlProps = {
  // tslint:disable-next-line:no-any
  onAssetImport: (file: any) => void;
};

export class GridControl extends React.Component<
  RouteComponentProps<{}> & GridControlProps & StyledComponent
> {
  upload: HTMLInputElement | null;
  constructor(props: RouteComponentProps<{}> & GridControlProps & StyledComponent) {
    super(props);

    this.toNewAsset = this.toNewAsset.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(event: React.FormEvent<HTMLInputElement>) {
    const file = event.target["files"][0]; // tslint:disable-line
    this.props.onAssetImport(file);
  }

  toNewAsset() {
    this.props.history.push(NewAssetProperty);
  }

  render() {
    const { classes } = this.props;

    return (
      <Toolbar className={classes.root}>
        <input
          type="file"
          ref={(ref) => (this.upload = ref)}
          style={{ display: "none" }}
          accept="application/json"
          onChange={this.uploadFile}
        />
        <Button
          variant="contained"
          color="default"
          onClick={() => this.upload !== null && this.upload.click()}
        >
          Import
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.newAssetButton}
          onClick={this.toNewAsset}
        >
          Create New Asset
        </Button>
      </Toolbar>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "flex-end",
    },
    newAssetButton: {
      marginLeft: theme.spacing(2),
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}> & GridControlProps>(decorate(GridControl));
