// tslint:disable:no-any
import * as React from "react";
import { connect } from "react-redux";
import { PushNode, ResetBreadcrumb } from "../../actions/breadcrumbActions";
import { getLocationTitle } from "../../constants/PageStateDictionary";

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any) => ({
  PushBreadcrumbNode: (link: string, text?: string) =>
    dispatch(
      PushNode({
        link,
        text: text || getLocationTitle(link),
      })
    ),
  ResetBreadcrumb: () => dispatch(ResetBreadcrumb()),
});

export type WithBreadcrumb = {
  pushBreadcrumbNode: (link: string, text?: string) => void;
  resetBreadcrumb: () => void;
};

function withBreadcrumb(WrappedComponent: any, prependNodes?: Array<BreadcrumbNode>) {
  return connect(
    null,
    mapDispatchToProps
  )(
    class extends React.Component<any> {
      UNSAFE_componentWillMount() {
        // const { location } = this.props;

        this.props.ResetBreadcrumb();

        if (prependNodes && prependNodes.length) {
          prependNodes.forEach((p: BreadcrumbNode) => {
            this.props.PushBreadcrumbNode(p.link, p.text);
          });
        }
        // else {
        //   this.props.PushBreadcrumbNode(location.pathname);
        // }
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            pushBreadcrumbNode={this.props.PushBreadcrumbNode}
            resetBreadcrumb={this.props.ResetBreadcrumb}
          />
        );
      }
    }
  );
}

export default withBreadcrumb;
