export const AssignToTest = "/app/test-run/assign";
export const Main = "/app/main";
export const Tests = "/app/testDefinition";
export const TestRun = "/app/testRun";
export const TestProperty = "/app/testProperties";
export const TestPropertyId = "/app/testProperties/:objectId";
export const MonitorProperty = "/app/monitorProperties";
export const MonitorPropertyId = "/app/monitorProperties/:objectId";
export const TestRunDetails = "/app/testRunDetails";
export const TestRunDetailsId = "/app/testRunDetails/:objectId";
export const TestIteration = "/app/testIteration";
export const TestIterationId = "/app/testIteration/:objectId";
export const TestLogs = "/app/testLogs";
export const TestLogsId = "/app/testLogs/:objectId/:machine?/:manual?";
export const NewTestProperty = "/app/new-testProperties";
export const NewMonitoring = "/app/new-monitorProperties";
export const Monitoring = "/app/monitoring";
export const MonitorRun = "/app/monitor-run";
export const QualityRTC = "/app/qualityrtc";
export const QualityRTCInvites = "/app/qualityrtc-invite";
export const QualityRTCInviteNew = "/app/qualityrtc-invite-new";
export const QualityRTCInviteId = "/app/qualityrtc-invite/:objectId";
export const NetworkTestCharts = "/app/network-test-charts";
export const OnPremiseProbe = "/app/probertc-overview";
export const OnPremiseProbeHistory = "/app/probertc-history";
export const OnPremiseProbeHistoryId = "/app/probertc-history/:probeId";
export const NewOnPremiseProbeProperty = "/app/probertc-new";
export const OnPremiseProbeProperty = "/app/probertc-edit";
export const OnPremiseProbesCompareId = "/app/probertc-compare/:secondId/:firstId";
export const OnPremiseProbesCompare = "/app/probertc-compare";
export const OnPremiseProbePropertyId = "/app/probertc-edit/:objectId";
export const OnPremiseProbeCharts = "/app/probertc-view";
export const OnPremiseProbeChartsId = "/app/probertc-view/:objectId";
export const OnPremiseProbeResults = "/app/probertc-view/results";
export const OnPremiseProbeResultsId = "/app/probertc-view/results/:probeId/:objectId";
export const AnalyzeDump = "/app/analyzeDump";
export const Assets = "/app/assets";
export const NewAssetProperty = "/app/new-asset";
export const AssetPropertyId = "/app/assets/:objectId";
export const Logs = "/app/logs";
export const WebrtcInternals = "/app/logs/webrtc-internals";
// export const WebrtcInternalsId =
//   "/app/logs/webrtc-internals/:testRunId/:testIterationId/:iterationMachine/:runIndex/:fileName/:dumpUrl?/:isAnalyzeDump?";
export const WebrtcInternalsId =
  "/app/logs/webrtc-internals/:testIterationId/:fileName/:testVariant?";
export const WebrtcInternalsAnalyzeRtc = "/app/logs/webrtc-internals-analyze-rtc";
export const WebrtcInternalsAnalyzeRtcId =
  "/app/logs/webrtc-internals-analyze-rtc/:testIterationId/:fileName";
export const WebrtcInternalsFreeAnalyzeRtc = "/report/free/webrtc-internals-analyze-rtc";
export const WebrtcInternalsFreeAnalyzeRtcId =
  "/report/free/webrtc-internals-analyze-rtc/:testIterationId/:fileName";
export const WebrtcInternalsWatchRtc = "/app/logs/webrtc-internals-watch-rtc";
export const WebrtcInternalsWatchRtcId =
  "/app/logs/webrtc-internals-watch-rtc/:testIterationId/:fileName/:dumpUrl";
export const NightWatch = "/app/logs/nightwatch";
export const NightWatchId =
  "/app/logs/nightwatch/:testRunId/:testIterationId/:iterationMachine/:runIndex";
export const TestScript = "/app/logs/test-script";
export const TestScriptId =
  "/app/logs/test-script/:testRunId/:testIterationId/:iterationMachine/:runIndex";
export const Browser = "/app/logs/browser";
export const BrowserId =
  "/app/logs/browser/:testRunId/:testIterationId/:iterationMachine/:inSessionIdx";
export const Performance = "/app/logs/performance";
export const PerformanceId =
  "/app/logs/performance/:testRunId?/:testIterationId?/:iterationMachine?/:runIndex?";
export const Support = "/app/support-ticket";

export const TestRunGroup = "/app/testRunGroup";
export const TestRunGroupId = "/app/testRunGroup/:objectId/:group/:groupValue";

// Admin Routes
export const UserAccounts = "/app/users";
export const UserDetails = "/app/user-details";
export const UserDetailsId = "/app/user-details/:objectId";
export const Projects = "/app/projects";
export const ProjectProperties = "/app/projectProperties";
export const ProjectPropertiesId = "/app/projectProperties/:objectId";
export const Probes = "/app/agents";
export const ProbesSetup = "/app/agentSetup";
export const ProbeSetupDetails = "/app/agent-setup-details";
export const ProbeSetupDetailsId = "/app/agent-setup-details/:objectId";
export const NewProbeSetupDetails = "/app/new-agent-setup-details";
export const DynamicProbe = "/app/dynamic-agent";
export const DynamicProbeDetails = "/app/dynamic-agent-details";
export const DynamicProbeDetailsId = "/app/dynamic-agent-details/:objectId";
export const NewDynamicProbeDetails = "/app/new-dynamic-agent-details";
export const UsageGraphs = "/app/usage-graph";
export const AdminUtils = "/app/admin-utils";
export const UserSettings = "/app/user-settings";
export const BillingData = "/app/billing-data";
export const BillingDataId = "/app/billing-data/:objectId";
export const NetworkTestAdmin = "/app/network-test-admin";
export const WatchRTCRoom = "/app/watchrtc-room";
export const WatchRTCRoomId = "/app/watchrtc-room/:objectId";
export const WatchRTCPeer = "/app/watchrtc-peer";
export const WatchRTCPeerId = "/app/watchrtc-peer/:objectId/:isLive?";
export const WatchRTCHighlights = "/app/watchrtc-highlights";
export const WatchRTCTrends = "/app/watchrtc-trends";
export const WatchRTCLive = "/app/watchrtc-live";
export const AnalyticsEvents = "/app/analytics-events";
export const Forbidden = "/app/403";
export const QualityRTCServer = "/app/qualityrtc-server";
export const QualityRTCServerPropertyId = "/app/qualityrtc-server/:objectId";
export const QualityRTCServerPropertyNew = "/app/qualityrtc-server/new";
export const Settings = "/app/settings";
export const MyProfile = "/app/my-profile";
export const AccountBilling = "/app/billing";
export const DrillDownToTest = "/app/drilldowntotest";
export const DrillDownToTestId = "/app/drilldowntotest/:testId/:testType";

export const App = {
  AssignToTest,
  Main,
  Tests,
  TestRun,
  TestRunDetails,
  TestRunDetailsId,
  TestIteration,
  TestIterationId,
  TestLogs,
  TestLogsId,
  Monitoring,
  MonitorRun,
  QualityRTC,
  QualityRTCInvites,
  QualityRTCInviteNew,
  QualityRTCInviteId,
  NetworkTestCharts,
  OnPremiseProbe,
  OnPremiseProbeHistory,
  OnPremiseProbeHistoryId,
  NewOnPremiseProbeProperty,
  OnPremiseProbeProperty,
  OnPremiseProbePropertyId,
  OnPremiseProbeCharts,
  OnPremiseProbeChartsId,
  OnPremiseProbeResults,
  OnPremiseProbeResultsId,
  OnPremiseProbesCompare,
  OnPremiseProbesCompareId,
  TestProperty,
  TestPropertyId,
  MonitorProperty,
  MonitorPropertyId,
  NewTestProperty,
  NewMonitoring,
  AnalyzeDump,
  Assets,
  NewAssetProperty,
  AssetPropertyId,
  TestRunGroup,
  TestRunGroupId,
  Logs,
  WebrtcInternals,
  WebrtcInternalsId,
  WebrtcInternalsAnalyzeRtc,
  WebrtcInternalsAnalyzeRtcId,
  WebrtcInternalsFreeAnalyzeRtc,
  WebrtcInternalsWatchRtc,
  WebrtcInternalsWatchRtcId,
  NightWatch,
  NightWatchId,
  TestScript,
  TestScriptId,
  Browser,
  BrowserId,
  Performance,
  PerformanceId,
  Support,
  UserAccounts,
  UserDetails,
  UserDetailsId,
  Projects,
  ProjectProperties,
  ProjectPropertiesId,
  Probes,
  ProbesSetup,
  ProbeSetupDetails,
  NewProbeSetupDetails,
  ProbeSetupDetailsId,
  DynamicProbe,
  DynamicProbeDetails,
  DynamicProbeDetailsId,
  NewDynamicProbeDetails,
  UsageGraphs,
  AdminUtils,
  UserSettings,
  BillingData,
  BillingDataId,
  NetworkTestAdmin,
  WatchRTCRoom,
  WatchRTCRoomId,
  WatchRTCPeer,
  WatchRTCPeerId,
  WatchRTCHighlights,
  WatchRTCTrends,
  WatchRTCLive,
  AnalyticsEvents,
  Forbidden,
  QualityRTCServer,
  QualityRTCServerPropertyId,
  QualityRTCServerPropertyNew,
  Settings,
  MyProfile,
  AccountBilling,
  DrillDownToTest,
  DrillDownToTestId,
};

export const SignIn = "/signin";
export const SignUp = "/signup";
export const SignUpConfirm = "/signup-confirm";
export const ConfirmAccount = "/confirm-account";
export const ConfirmAccountId = "/confirm-account/:objectId";
export const ForgotPassword = "/forgot-password";
export const ResetPassword = "/reset";
export const ResetPasswordId = "/reset/:token";
export const Subscribe = "/newsletter-subscribe";
export const Analyze = "/analyze";
export const ReportFree = "/app/report/free";
export const ReportFreeId = "/report/free/:objectId";

export const Login = {
  SignIn,
  SignUp,
  SignUpConfirm,
  ConfirmAccount,
  ConfirmAccountId,
  ForgotPassword,
  ResetPassword,
  ResetPasswordId,
  Subscribe,
  Analyze,
  ReportFree,
  ReportFreeId,
};

export const Shared = {
  ProbeRtcView: "/shared/probertc-view",
  ProbeRtcViewId: "/shared/probertc-view/:objectId",
  ProbeRtcResults: "/shared/probertc-view/results",
  ProbeRtcResultsId: "/shared/probertc-view/results/:probeId/:objectId",

  QualityRTCInviteTests: "/shared/quality-rtc-invite-tests/:inviteId/:apiKey",
};

export const AppRoute = "/app";
export const SharedRoute = "/shared";
export const NotFound = "/404";
