import * as React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";

import FileCopy from "@material-ui/icons/FileCopy";
import Renew from "@material-ui/icons/Autorenew";

import { CopyToClipboard } from "react-copy-to-clipboard";

import ApiPath from "src/constants/ApiPath";
import AxiosFactory from "src/services/AxiosFactory";
import { AxiosResponse } from "axios";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingBottom: 19,
    },
    container: {
      justifyContent: "flex-end",
    },
    row: {
      marginBottom: theme.spacing(3),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    divider: {
      margin: "16px -16px",
      width: "90%",
    },
    showButton: {
      marginRight: 5,
    },
  })
);

interface State {
  userRef: string;
  apiKey: string;
}

interface IAnalyzeRTCSection {
  isHistoryVisible: boolean;
  toggleTable: () => void;
  setNotification: (message: string) => void;
}

const AnalyzeRTCSection = ({
  isHistoryVisible,
  toggleTable,
  setNotification,
}: IAnalyzeRTCSection) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<State>({
    userRef: "",
    apiKey: "",
  });
  const [errors, setErrors] = React.useState({
    userRef: false,
    apiKey: false,
  });

  React.useEffect(() => {
    generateApiKey();
    // eslint-disable-next-line
  }, []);

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrors({
      userRef: false,
      apiKey: false,
    });
  };

  const generateApiKey = async (updateState = true) => {
    try {
      const axiosFactory = new AxiosFactory();

      const response: AxiosResponse<string> = await axiosFactory.axios.get(
        `${ApiPath.api.analyzeRTC}/generate-key`
      );
      const apiKey = response.data;

      if (updateState) {
        setValues({
          ...values,
          apiKey,
        });
      }
      return apiKey;
    } catch {
      return "";
    }
  };

  const create = async () => {
    if (!values.userRef) {
      setErrors({
        ...errors,
        userRef: true,
      });
      return;
    }
    try {
      const axiosFactory = new AxiosFactory();
      await axiosFactory.axios.post(ApiPath.api.analyzeRTC, {
        ...values,
      });
      const apiKey = await generateApiKey(false);
      setValues({
        userRef: "",
        apiKey,
      });
      if (isHistoryVisible) {
        await toggleTable();
        await toggleTable();
      }
      setNotification("API Key is copied");
    } catch {
      setValues({
        ...values,
        userRef: "",
      });
    }
  };

  return (
    <Grid item={true} xs={4}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="subtitle1">{"analyzeRTC"}</Typography>
      </Toolbar>
      <Grid item={true} container={true} className={classes.container}>
        <Grid item={true} xs={12} className={classes.row}>
          <TextField
            value={values.userRef}
            variant="outlined"
            label="User Ref"
            fullWidth={true}
            onChange={handleChange("userRef")}
            error={errors.userRef}
            helperText={errors.userRef ? "The field is required" : ""}
          />
        </Grid>
        <Grid item={true} xs={12} className={classes.row}>
          <TextField
            value={values.apiKey}
            variant="outlined"
            label="API Key"
            fullWidth={true}
            onChange={handleChange("apiKey")}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Copy" placement="top-start">
                    <IconButton edge="end" aria-label="Copy">
                      <CopyToClipboard text={values.apiKey}>
                        <FileCopy onClick={() => setNotification("API Key is copied")} />
                      </CopyToClipboard>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Generate" placement="top-start">
                    <IconButton
                      edge="end"
                      aria-label="Re-Generate"
                      onClick={() => generateApiKey()}
                    >
                      <CopyToClipboard text={values.userRef}>
                        <Renew />
                      </CopyToClipboard>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item={true} container={true} xs={12} className={classes.row} justify="flex-end">
          <Button color="secondary" className={classes.showButton} onClick={toggleTable}>
            Show
          </Button>
          <CopyToClipboard text={values.apiKey}>
            <Button color="primary" onClick={create}>
              Create
            </Button>
          </CopyToClipboard>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnalyzeRTCSection;
