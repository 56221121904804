import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { Field, Form } from "react-final-form";
import Button from "@material-ui/core/Button";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import TextFieldControl from "../../../components/form-elements/TextFieldControl";
import FormValidators from "../../../helpers/form-validators";
import { Login as LoginRoutes, Login } from "../../../constants/RoutesNames";
import { ILoginForm } from "./index";

type SigninFormProps = {
  onSubmit: (values: ILoginForm) => void;
  isLoading: boolean;
};

type StyledComponent = WithStyles<
  | "signInButton"
  | "buttonContainer"
  | "formContainer"
  | "forgotPasswordContainer"
  | "link"
  | "linkText"
  | "fieldIconContainer"
  | "formSection"
>;

const SigninForm = (props: SigninFormProps & StyledComponent) => {
  const { classes, onSubmit, isLoading } = props;

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form className={classes.formContainer} onSubmit={handleSubmit}>
          <Grid container={true} spacing={2}>
            <Grid className={classes.fieldIconContainer} item={true} xs={1}>
              <Email />
            </Grid>
            <Grid item={true} xs={11}>
              <Field
                component={TextFieldControl}
                type="text"
                name="email"
                label="Email Address"
                validate={FormValidators.composeValidators(
                  FormValidators.required(),
                  FormValidators.isEmail
                )}
              />
            </Grid>
            <Grid className={classes.fieldIconContainer} item={true} xs={1}>
              <Lock />
            </Grid>
            <Grid item={true} xs={11}>
              <Field
                component={TextFieldControl}
                type="password"
                name="password"
                label="Password"
                validate={FormValidators.required()}
              />
            </Grid>
            <Grid item={true} xs={12} className={classes.formSection}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6} className={classes.forgotPasswordContainer}>
                  <Link className={classes.link} to={LoginRoutes.ForgotPassword}>
                    <Typography variant="body2" align="right" className={classes.linkText}>
                      Forgot password?
                    </Typography>
                  </Link>
                </Grid>
                <Grid item={true} xs={6} className={classes.buttonContainer}>
                  <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.signInButton}
                  >
                    {isLoading ? "Loading" : "Sign in"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} className={classes.formSection}>
              <Grid container={true} spacing={2} className={classes.formSection}>
                <Grid item={true} xs={6}>
                  <Link className={classes.link} to={Login.SignUp}>
                    <Typography variant="body2" color="textSecondary" className={classes.linkText}>
                      Create an account
                    </Typography>
                  </Link>
                </Grid>
                <Grid item={true} xs={6}>
                  <div>
                    <Link className={classes.link} to={Login.Subscribe}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="right"
                        className={classes.linkText}
                      >
                        Subscribe for updates
                      </Typography>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const decorate = withStyles((theme) => {
  return createStyles({
    formSection: {
      padding: `${theme.spacing(1)}px 0`,
    },
    fieldIconContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: 16,
      opacity: 0.4,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    forgotPasswordContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    signInButton: {
      width: "auto",
    },
    formContainer: {
      paddingTop: theme.spacing(10),
    },
    link: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:active": {
        textDecoration: "none",
      },
    },
    linkText: {
      color: "#559542",
    },
  });
});

export default decorate(SigninForm);
