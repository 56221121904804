export const warnings = "warnings";
export const error = "error";
export const failure = "failure";
export const timeout = "timeout";
export const finished = "finished";
export const completed = "completed";
export const serviceFailure = "service-failure";
export const terminated = "terminated";
export const started = "started";
export const retry = "retry";
export const dismissed = "dismissed";

export const Statuses = {
  warnings,
  error,
  failure,
  timeout,
  finished,
  completed,
  serviceFailure,
  terminated,
  started,
  retry,
  dismissed,
};
