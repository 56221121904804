// tslint:disable:no-any
import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { AxiosError, AxiosResponse } from "axios";
import * as moment from "moment";
import { withStyles, WithStyles, createStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Switch from "@material-ui/core/Switch";
import RTCGrid, { FilterValue, GridFilter } from "../../components/Grid";
import ApiPath from "../../constants/ApiPath";
import { TestProperty, MonitorProperty, MonitorRun } from "../../constants/RoutesNames";
import Tooltip from "../../components/Tooltip";
import { FetchMonitorsSort } from "../../actions/dictionaryAction";
import { SetNotification } from "../../actions/notificationAction";
import GridToolbar from "./GridToolbar";
import { Statuses } from "../../constants/TestStatus";
import StatusIcon from "../../components/Test/StatusIcon";
import MonitorRibbon from "../Main/Widgets/Components/MonitorRibbon";
import AxiosFactory from "../../services/AxiosFactory";

interface IMonitorHistoryState {
  filters: Array<GridFilter>;
  testRuns: Array<FilterValue>;
}

interface ITestsHistoryDispatch {
  fetchMonitorsSort(): void;

  setNotification(message: string): void;
}

interface IMonitorHistoryProps {
  testRuns: Array<string>;
}

const tooltipHelper = (
  title: string,
  icon: React.ReactElement<React.ReactNode>
): React.ReactNode => {
  return <Tooltip content={title}>{icon}</Tooltip>;
};

type StyledComponent = WithStyles<
  "root" | "tableRowItemHover" | "hoverActionBtnStyle" | "monitorNameActionBtn"
>;

export class GridControl extends React.Component<
  IMonitorHistoryProps & ITestsHistoryDispatch & RouteComponentProps<{}> & StyledComponent,
  IMonitorHistoryState
> {
  // tslint:disable-next-line:no-any
  grid: any = null;

  columnSchema: Array<ColumnSchema> = [
    {
      id: "active",
      numeric: false,
      disablePadding: true,
      label: "Active",
      labelRender: () => (
        <div
          style={{
            paddingLeft: 15,
          }}
        >
          Active
        </div>
      ),
      // tslint:disable-next-line:no-any
      render: (dataItem: any) => {
        return (
          <Switch
            onClick={GridControl.switchClickHandler}
            checked={dataItem.active}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, _state: boolean) =>
              this.activateHandler(e, dataItem)
            }
            value="foo"
            color="primary"
          />
        );
      },
      style: { maxWidth: "10%" },
    },
    {
      id: "testName",
      numeric: false,
      disablePadding: true,
      label: "Test Name",
      // style: { maxWidth: 200 },
      render: (
        dataItem: any // tslint:disable-line
      ) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pointerEvents: "none",
          }}
        >
          <span>{dataItem.executeTest ? dataItem.executeTest.name : ""}</span>
          <div>
            {tooltipHelper(
              "Open link in new tab",
              <IconButton
                className={this.props.classes.hoverActionBtnStyle}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  window.open(`${TestProperty}/${dataItem.executeTest._id}`, "_blank");
                  e.stopPropagation();
                }}
              >
                <OpenInNew />
              </IconButton>
            )}
          </div>
        </div>
      ),
      hideDown: "xs",
    },
    {
      id: "lastRunDate",
      numeric: false,
      disablePadding: true,
      label: "Last Run",
      render: (dataItem: any) =>
        dataItem.lastRunDate
          ? moment(dataItem.lastRunDate).format("YYYY") === "1970"
            ? "Never"
            : moment(dataItem.lastRunDate).format("MMM DD, YYYY - HH:mm")
          : "Never",
      style: {
        whiteSpace: "nowrap",
        maxWidth: "10%",
        padding: "0px 10px",
      },
      hideDown: "sm",
    },
    {
      id: "runCount",
      numeric: true,
      disablePadding: true,
      sortable: true,
      label: "Run Count",
      style: { maxWidth: "25%", wordWrap: "break-word", textAlign: "center", width: "60px" },
      hideDown: "sm",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      // tslint:disable-next-line:no-any
      render: (dataItem: any, _index: number) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            const filterSchema: Array<IFilterServer> = [
              { field: "monitorName", value: dataItem.name },
            ];
            const filter = JSON.stringify(filterSchema);
            this.props.history.push(`${MonitorRun}?filter=${btoa(filter)}`);
          }}
        >
          <StatusIcon status={dataItem.lastStatus} />
          <MonitorRibbon monitor={dataItem} />
        </div>
      ),
      style: { width: 200 },
      hideDown: "xs",
    },
  ];

  constructor(
    props: IMonitorHistoryProps & ITestsHistoryDispatch & RouteComponentProps<{}> & StyledComponent
  ) {
    super(props);

    this.state = {
      filters: [],
      testRuns: [],
    };

    GridControl.switchClickHandler = GridControl.switchClickHandler.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchMonitorsSort();
  }

  UNSAFE_componentWillReceiveProps(nextProps: IMonitorHistoryProps) {
    if (nextProps.testRuns.length !== 0) {
      const testRunsFilter: Array<FilterValue> = [{ value: "", label: "Any monitor" }];

      // eslint-disable-next-line
      nextProps.testRuns.map((tr: any) => {
        const testRunFilterSingle: FilterValue = {
          value: tr._id,
          label: tr.name,
        };
        testRunsFilter.push(testRunFilterSingle);
      });
      this.setState({
        testRuns: testRunsFilter,
      });
    }
  }

  onRowClick(_e: React.MouseEvent<HTMLTableRowElement>, dataItem: GridModel) {
    this.props.history.push(`${MonitorProperty}/${dataItem._id}`);
  }

  render() {
    const { classes, location } = this.props;

    const statusFilterValues = [
      { value: "", label: "Any Result" },
      { value: Statuses.completed, label: "Completed" },
      { value: Statuses.warnings, label: "Warnings" },
      { value: Statuses.failure, label: "Failure" },
      { value: Statuses.timeout, label: "Timeout" },
    ];

    const createDateFilterValues = [
      { value: "", label: "Any date" },
      { value: "0", label: "Today" },
      { value: "7", label: "Last 7 days" },
      { value: "30", label: "Last 30 days" },
    ];

    return (
      <Paper className={classes.root}>
        <GridToolbar />
        <RTCGrid
          // tslint:disable-next-line:no-any
          ref={(element: any) => (this.grid = element)}
          onRowClick={this.onRowClick}
          search={true}
          remoteDataBound={true}
          searchByLabel={"Test Name"}
          apiRoute={ApiPath.api.monitors}
          columnSchema={this.columnSchema}
          defaultSort={{
            order: "desc",
            orderBy: "active -lastRunDate",
          }}
          filters={[
            {
              fieldName: "lastStatus",
              filterLabel: "Result",
              filterValues: statusFilterValues,
              value: "",
            },
            /*
            {
              fieldName: "_id",
              filterLabel: "Monitor",
              filterValues: this.state.testRuns,
              value: "",
            },
             */
            {
              fieldName: "lastRunDate",
              filterLabel: "Date",
              filterValues: createDateFilterValues,
              value: "",
            },
          ]}
          rowProps={{
            className: classes.tableRowItemHover,
          }}
          pagination={true}
          enableUrlPaging={true}
          location={location}
          bottomNavigation
        />
      </Paper>
    );
  }

  private async activateHandler(e: React.ChangeEvent<HTMLInputElement>, dataItem: any) {
    const axiosFactory = new AxiosFactory();
    const checkedState = e.target.checked;
    e.preventDefault();

    const result = await axiosFactory.axios
      .put(`${ApiPath.api.monitors}/${dataItem._id}/${e.target.checked}`, {
        ...dataItem,
        active: checkedState,
      })
      .then((res: AxiosResponse<any>) => res)
      .catch((error: AxiosError) => error.response);

    if (result && result.status !== 500) {
      if (result.status === 303) {
        this.props.setNotification(result.data.message);
      }
      this.grid.setData(
        this.grid.getData.docs.map((d: any) =>
          d._id === dataItem._id
            ? {
                ...d,
                active: result.data.active,
              }
            : d
        )
      );
    } else {
      if (!result) {
        this.props.setNotification("Network error!");
      } else {
        this.props.setNotification(
          "Maximum number of active monitors is reached, please contact support if you need more"
        );
      }
    }

    return false;
  }

  private static switchClickHandler(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
}

const styles = () =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    tableRowItemHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
        "& button,a": {
          visibility: "visible",
          pointerEvents: "all",
        },
      },
    },
    hoverActionBtnStyle: {
      padding: "0px",
      width: 35,
      minWidth: 35,
      height: 35,
      minHeight: 35,
      visibility: "hidden",
      pointerEvents: "none",
    },
    monitorNameActionBtn: {
      "&:hover": {
        color: "#23527c",
        textDecoration: "none",
      },
      visibility: "hidden",
      pointerEvents: "none",
      cursor: "pointer",
      color: "#337ab7",
      textDecoration: "none",
      paddingRight: 20,
    },
  });

const decorate = withStyles(styles);

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): ITestsHistoryDispatch => ({
  fetchMonitorsSort: () => dispatch(FetchMonitorsSort()),
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

const mapStateToProps = (state: IStore) => ({
  testRuns: state.dictionary.monitorSort,
});

// tslint:disable-next-line:no-any
export default connect<IMonitorHistoryProps, ITestsHistoryDispatch, any>(
  mapStateToProps,
  mapDispatchToProps
)(decorate(GridControl));
