export const environmentVariables = [
  "RTC_TEST_SCRIPT_NAME",
  "RTC_SERVICE_URL",
  "RTC_TEST_NAME",
  "RTC_AGENT_COUNT",
  "RTC_AGENT_NAME",
  "RTC_AGENT_NUM",
  "RTC_PROBE_COUNT",
  "RTC_PROBE_NAME",
  "RTC_PROBE_NUM",
  "RTC_ITERATION_COUNT",
  "RTC_ITERATION_NUM",
  "RTC_SESSION_NAME",
  "RTC_SESSION_IDX",
  "RTC_SESSION_SIZE",
  "RTC_SESSION_COUNT",
  "RTC_IN_SESSION_ID",
  "RTC_TIMEOUT",
  "RTC_OS",
  "RTC_LOCATION",
  "RTC_BROWSER",
  "RTC_NETWORK_PROFILE",
  "RTC_FIREWALL_PROFILE",
  "RTC_ITERATION_MACHINE_ID",
  "RTC_TEST_RUN_ID",
  "RTC_RUN_TYPE",
  "RTC_EXTRA_TABS",
  "API_URL",
];

export default [
  "rtcActivateWebhook",
  "rtcCaptureCallData",
  "rtcCallWhenDone",
  "rtcSetNetwork",
  "rtcSetMetricFromThresholdTime",
  "rtcSetCustomEventsExpectation",
  "rtcSetEventsExepctation",
  "rtcSetCustomExpectation",
  "rtcEvent",
  "rtcGetSessionValue",
  "rtcGetTimer",
  "rtcInfo",
  "rtcProgress",
  "rtcScreenshot",
  "rtcSetAdditionalInfo",
  "rtcSetMetric",
  "rtcSetMetricFromTimer",
  "rtcSetNetworkProfile",
  "rtcSetSessionValue",
  "rtcSetTestExpectation",
  "rtcSetTestValue",
  "rtcStartTimer",
  "rtcWaitForSessionValue",
  "rtcWaitForTestValue",
  "waitForAttribute",
  "waitForClientConnected",
  "waitForText",
  "waitForVideoHasLoaded",
  "waitForElementVisible",
  "waitForElementNotVisible",
  "waitForElementPresent",
  "waitForElementNotPresent",
  "element",
  "elements",
  "elementIdEquals",
  "elementIdElement",
  "elementIdElements",
  "click",
  "clearValue",
  "moveToElement",
  "setValue",
  "submitForm",
  "keys",
  "getAttribute",
  "getCssProperty",
  "getElementSize",
  "getTagName",
  "getText",
  "getValue",
  "isVisible",
  "elementActive",
  "Element Location",
  "getLocation",
  "getLocationInView",
  "elementIdAttribute",
  "elementIdClear",
  "elementIdClick",
  "elementIdCssProperty",
  "elementIdDisplayed",
  "elementIdEnabled",
  "elementIdLocationInView",
  "elementIdLocation",
  "elementIdName",
  "elementIdSelected",
  "elementIdSize",
  "elementIdText",
  "elementIdValue",
  "submit",
  "injectScript",
  "execute",
  "executeAsync",
  "source",
  "session",
  "sessions",
  "timeouts",
  "timeoutsAsyncScript",
  "timeoutsImplicitWait",
  "status",
  "sessionLog",
  "sessionLogTypes",
  "Navigation",
  "getTitle",
  "init",
  "urlHash",
  "url",
  "back",
  "forward",
  "refresh",
  "title",
  "switchWindow",
  "resizeWindow",
  "setWindowPosition",
  "maximizeWindow",
  "closeWindow",
  "window",
  "windowHandle",
  "windowHandles",
  "windowMaximize",
  "windowPosition",
  "windowSize",
  "frame",
  "frameParent",
  "getCookies",
  "getCookie",
  "setCookie",
  "deleteCookie",
  "deleteCookies",
  "cookie",
  "doubleClick",
  "mouseButtonClick",
  "mouseButtonDown",
  "mouseButtonUp",
  "moveTo",
  "acceptAlert",
  "dismissAlert",
  "getAlertText",
  "setAlertText",
  "useCss",
  "useXpath",
  "include",
  "pause",
];
