// tslint:disable:no-any
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import LayoutGrid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core";

import RTCGrid from "src/components/Grid";
import StatusIcon from "src/components/Test/StatusIcon";
import MonitorRibbon from "./Components/MonitorRibbon";
import { MonitorRun } from "src/constants/RoutesNames";
import { formatAsTimeAgo } from "src/helpers/timeHelper";
import { IActiveMonitorList } from "../indexNew";

interface IActiveMonitorsProps {
  monitors: IActiveMonitorList;
}

const ActiveMonitors = ({ history, monitors }: IActiveMonitorsProps & RouteComponentProps<{}>) => {
  const classes = useStyles();
  const columnSchema: ColumnSchema[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "#",
      sortable: false,
      render: (_dataItem: any, index: number) => (
        <Typography variant="body2">{index + 1}</Typography>
      ),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "Monitor",
      render: (dataItem: any) => (
        <>
          <span>{dataItem.name}</span>
          <hr />
          <span style={{ whiteSpace: "nowrap" }}>
            Last Run: {formatAsTimeAgo(dataItem.lastRunDate)}
          </span>
        </>
      ),
      style: { minWidth: 120, paddingRight: 10 },
    },
    // {
    //   id: "lastRunDate",
    //   numeric: false,
    //   disablePadding: true,
    //   sortable: false,
    //   label: "Time",
    //   render: (dataItem: any, _index: number) => (
    //     <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
    //       {formatAsTimeAgo(dataItem.lastRunDate)}
    //     </Typography>
    //   ),
    //   style: { minWidth: "30%" },
    // },
    {
      id: "lastStatus",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "Status",
      render: (dataItem: any, _index: number) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <StatusIcon status={dataItem.lastStatus} style={{ width: "10%" }} />
          <MonitorRibbon monitor={dataItem} width={"90%"} classes={classes} />
        </div>
      ),
      style: { paddingLeft: 5 },
    },
  ];

  const selectMonitor = (_e: any, dataItem: any) => {
    const filterSchema: Array<IFilterServer> = [{ field: "monitorName", value: dataItem.name }];
    const filter = JSON.stringify(filterSchema);
    history.push(`${MonitorRun}?filter=${btoa(filter)}`);
  };

  return (
    <LayoutGrid container={true} spacing={2} className={classes.tableContainer}>
      <RTCGrid
        onRowClick={selectMonitor}
        localData={monitors?.docs}
        columnSchema={columnSchema}
        defaultSort={{
          order: "desc",
          orderBy: "lastRunDate",
        }}
        rowProps={{
          className: classes.tableRow,
        }}
      />
    </LayoutGrid>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      paddingBottom: theme.spacing(3),
      overflowY: "hidden",
      overflowX: "auto",
    },
    tableRow: {
      cursor: "pointer",
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
    },
    ribbonContainer: {
      display: "flex",
      height: 60,
      overflow: "hidden",
      position: "relative",
      "&>div": {
        width: "100%",
      },
    },
  })
);

export default withRouter<IActiveMonitorsProps & RouteComponentProps<{}>>(ActiveMonitors);
