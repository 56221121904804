// tslint:disable:no-any
import * as React from "react";

import { INetworkTests } from "../indexNew";
import Widget from "./Components/Widget";
import Line from "./Components/Line";
import LinearProgress from "./Components/LinearProgress";

import * as moment from "moment";

interface IQualityRTC {
  networkTests?: INetworkTests;
}

const QualityRTC = ({ networkTests }: IQualityRTC) => {
  if (!networkTests) {
    return null;
  }

  const getMonthlyEstimate = () => {
    const daysPassedFromCycleStart = moment().date();
    const daysInMonth = moment().daysInMonth();

    return Math.ceil((networkTests.count / daysPassedFromCycleStart) * daysInMonth);
  };

  const networkTestsOveruse = networkTests.count > networkTests?.limit;
  const networkTestsConductedLinearValue = networkTestsOveruse
    ? 100
    : (networkTests.count / networkTests.limit) * 100;
  return (
    <Widget title={"qualityRTC"} marginBottom={8}>
      <Line
        label="Monthly cycle date"
        value={{
          value: moment().startOf("month").format("Do MMM"),
        }}
      />

      <Line
        label="Tests conducted"
        value={[
          {
            value: networkTests.count.toLocaleString("en", {
              maximumFractionDigits: 0,
            }),
            tooltip: "Conducted",
          },
          {
            value: (networkTests.limit || 0).toLocaleString("en", {
              maximumFractionDigits: 0,
            }),
            tooltip: "Limit",
          },
        ]}
      />
      <LinearProgress
        value={networkTestsConductedLinearValue || 0}
        hasError={networkTestsOveruse}
      />
      <Line
        label="Estimated for this cycle"
        value={{
          value: getMonthlyEstimate(),
        }}
      />
    </Widget>
  );
};

export default QualityRTC;
