import { AxiosResponse } from "axios";
import AxiosFactory from "./AxiosFactory";
import ApiPath from "../constants/ApiPath";
import * as Cookies from "es-cookie";
import * as moment from "moment";

export default class AuthService {
  public static login(email: string, password: string) {
    const axiosFactory = new AxiosFactory();
    return axiosFactory.axios
      .post(
        `${ApiPath.auth.loginLocal}`,
        { email: email, password: password },
        { validateStatus: (status) => status < 500 }
      )
      .then((res: AxiosResponse<LoginResponse>) => {
        if (res.status === 200) {
          this.setToken(res.data.token);
          this.setExpires(res.data.expires);
          return Promise.resolve(res.data);
        } else {
          return Promise.reject(res.data);
        }
      });
  }

  public static collectDocumentCookies() {
    const token = Cookies.get("token");

    // Muly: For now, need to fix
    const expires = moment(new Date()).add(1, "d").toString(); // Cookies.get("expires");

    if (token) {
      this.setToken(token);
      this.setExpires(expires || "");
    }
  }

  public static logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("expires");

    Cookies.remove("token", { domain: "testrtc.com" });
    // for local dev
    Cookies.remove("token");
  }

  public static loggedIn(): boolean {
    const token = this.getToken();
    const expires = this.getExpires();
    const isLoggedIn = !!token && !this.isTokenExpired(expires);

    return isLoggedIn;
  }

  public static setExpires(expires: string) {
    localStorage.setItem("expires", expires);
  }

  public static getExpires(): number {
    const expires = localStorage.getItem("expires");
    const parsedExpiresDate = Date.parse(expires !== null ? expires : "");
    if (isNaN(parsedExpiresDate)) {
      return 0;
    }
    return parsedExpiresDate;
  }

  public static getToken(): string {
    const token = localStorage.getItem("token");
    return token !== null ? token : "";
  }

  public static setToken(token: string) {
    localStorage.setItem("token", token);
  }

  public static isTokenExpired(expires: number): boolean {
    try {
      return expires < Date.now();
    } catch (err) {
      return false;
    }
  }

  public static setRedirectUrl(url: string) {
    localStorage.setItem("redirectUrl", url);
  }

  public static getRedirectUrl(): string {
    const redirectUrl = localStorage.getItem("redirectUrl");
    return redirectUrl !== null ? redirectUrl : "";
  }
}
