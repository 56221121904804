import AxiosFactory from "./AxiosFactory";
import ApiPath from "../constants/ApiPath";
import { AxiosError } from "axios";
// const axiosFactory = new AxiosFactory();

export default class TestService {
  static getTestRun(testId: string) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios
      .get(`${ApiPath.api.testRuns}/show/${testId}`)
      .then((response: any) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }
  static getTestStats(testId: string) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios
      .get(`${ApiPath.api.testRuns}/getStats/${testId}`)
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error;
      });
  }
  static getLatestTestRun(selectField: string, limit?: number) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.get(
      `${ApiPath.api.testRuns}/latest/${selectField}` + (limit ? `?limit=${limit}` : "")
    );
  }
  static getAllTestIterations(testId: string) {
    const axiosFactory = new AxiosFactory();

    const tmp = axiosFactory.axios.get(`${ApiPath.api.testIterations}/${testId}/all`);
    return tmp;
  }
  static getDistinctUnassignedTestIteration(selectField: string) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.get(
      `${ApiPath.api.testIterations}/unassigned/distinct/${selectField}`
    );
  }
  static generateNewRunName() {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.get(`${ApiPath.api.testIterations}/generate-name`);
  }
  static getAllTestDefinitions() {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.get(`${ApiPath.api.testDefinitions}/all`);
  }
  static getTestDefinition(testId: string) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.get(`${ApiPath.api.testDefinitions}/${testId}`);
  }
  static getTestIteration(iterationId: string) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.get(`${ApiPath.api.testIterations}/${iterationId}`);
  }
  static getTestIterationChartData(iterationId: string, freeReport?: boolean) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.get(
      `${ApiPath.api.testIterations}/chartData/${iterationId}/${freeReport ? "free" : ""}`
    );
  }
  static getTestIterationEvents(testIterationId: string) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.get(`${ApiPath.api.testIterations}/${testIterationId}/events`);
  }
  static getMinimalTestIterations(testRunId: string) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.get(`${ApiPath.api.testIterations}/${testRunId}/minimal`);
  }

  static getUnassignedTestIterationCount() {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.get(`${ApiPath.api.testIterations}/unassigned/count`);
  }
  // tslint:disable-next-line:no-any
  static saveTestIteration(testIteration: any) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.put(
      `${ApiPath.api.testIterations}/${testIteration._id}`,
      testIteration
    );
  }
  // tslint:disable-next-line:no-any
  static updateTestIterations(data: any) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.put(`${ApiPath.api.testIterations}/assign`, data);
  }

  // tslint:disable-next-line:no-any
  static saveTestRun(testRun: any) {
    const axiosFactory = new AxiosFactory();

    return axiosFactory.axios.put(`${ApiPath.api.testRuns}/${testRun._id}`, testRun);
  }
}
