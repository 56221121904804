// tslint:disable:no-any
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { Statuses } from "../../../constants/TestStatus";
import View from "./View";
import * as _ from "lodash";
import TestService from "../../../services/TestService";
import { SetNotification, HideNotification } from "../../../actions/notificationAction";

export const BoxMarkTypes = {
  iteration: "iteration",
  details: "details",
};

type AppDispatch = {
  setNotification(message: string): void;
  hideNotification(): void;
};

export type BoxMarkProps = {
  type: string;
  record: any;
  testIterations?: Array<any>;
  updateRecord?: (record: any) => void;
  hiddenShare?: boolean;
  navigateToIteration?: (id?: string) => void;
};

export type BoxMarkState = {
  dismissToggled?: boolean;
  dBtnText?: string;
  currPageId?: string;
  currNumber?: string;
  currentIterId?: string;
  lastIterId?: string;
  firstIterId?: string;
  iters?: Array<any>;
  prevIterId?: string;
  nextIterId?: string;
  absUrl?: string;
  allowToShare?: boolean;
  commentsOpen?: boolean;
  commentsInput?: string;
};

export type BoxMarkHandlers = {
  showDismissButton: () => void;
};

class BoxMark extends React.Component<
  AppDispatch & RouteComponentProps<any> & BoxMarkProps,
  BoxMarkState
> {
  state: BoxMarkState = {};

  constructor(props: AppDispatch & RouteComponentProps<any> & BoxMarkProps) {
    super(props);

    this.flagTest = this.flagTest.bind(this);
    this.shareTest = this.shareTest.bind(this);
    this.commentTest = this.commentTest.bind(this);
    this.toggleComments = this.toggleComments.bind(this);
    this.onCommentsChange = this.onCommentsChange.bind(this);
    this.allowedToShare = this.allowedToShare.bind(this);
  }

  componentDidMount() {
    this.setUp();
  }

  allowedToShare() {
    if (this.props.hiddenShare) {
      return false;
    }
    const { record } = this.props;

    const finalStatusOptions = [
      Statuses.completed,
      Statuses.finished,
      Statuses.warnings,
      Statuses.failure,
      Statuses.serviceFailure,
      Statuses.terminated,
      Statuses.timeout,
    ];

    return record && !!finalStatusOptions.find((st) => st === record.status);
  }

  setUp() {
    const { testIterations, record } = this.props;

    const newState: BoxMarkState = {};

    if (testIterations) {
      this.prepairPages(newState);
    }

    newState.dBtnText = newState.dismissToggled ? "Count test" : "Dismiss test";

    newState.allowToShare = this.allowedToShare();
    if (record) {
      newState.dismissToggled = record.status === Statuses.dismissed;
      newState.absUrl = `${window.location.host}/${this.getShareUri()}/${record._id
        .split("")
        .reverse()
        .join("")}`;
      newState.commentsInput = record.comments;
    }

    this.setState(newState);
  }

  getShareUri() {
    switch (this.props.type) {
      case BoxMarkTypes.details:
        return "shared-report";
      case BoxMarkTypes.iteration:
        return "report/shared-test-details";
      default: {
        throw new Error("[type] property passed is incorrect");
      }
    }
  }

  prepairPages(newState: BoxMarkState) {
    const { match, testIterations } = this.props;

    if (!testIterations || testIterations.length < 1) {
      return;
    }

    // get current id to have number of page
    const currPageId = match.params.objectId;

    // before processing sort iters by asc
    const iters = _.sortBy(testIterations, (iter: any) => iter.idx) as any;
    newState.currentIterId = iters.find((i: any) => i._id === currPageId).idx;

    newState.lastIterId = iters[testIterations.length - 1]._id;
    newState.firstIterId = iters[0]._id;

    let nextPrevPair;
    for (let i = 0; i < iters.length; i++) {
      if (currPageId === iters[i]._id) {
        let prev;
        let next;
        if (i !== 0) {
          prev = iters[i - 1]._id;
        } else {
          prev = iters[i]._id;
        }

        if (i !== iters.length - 1) {
          next = iters[i + 1]._id;
        } else {
          next = iters[i]._id;
        }

        nextPrevPair = [prev, next];
        break;
      }
    }
    newState.prevIterId = nextPrevPair && nextPrevPair[0];
    newState.nextIterId = nextPrevPair && nextPrevPair[1];
  }

  async flagTest() {
    const { record, updateRecord } = this.props;

    const newRecord = {
      ...record,
    };

    newRecord.flag = !record.flag;

    if (updateRecord) {
      updateRecord(newRecord);
    }

    await this.saveRecord(newRecord);
  }

  async shareTest() {
    const { record, updateRecord, setNotification } = this.props;

    const newRecord = {
      ...record,
    };

    newRecord.shared = !newRecord.shared;

    if (updateRecord) {
      updateRecord(newRecord);
    }

    await this.saveRecord(newRecord);
    if (newRecord.shared) {
      setNotification("Report URL shared and copied to clipboard.");
      setTimeout(() => {
        this.props.hideNotification();
      }, 5000);
    }
  }

  async commentTest() {
    const { record, updateRecord } = this.props;

    const newRecord = {
      ...record,
    };

    newRecord.comments = this.state.commentsInput;

    if (updateRecord) {
      updateRecord(newRecord);
    }

    this.setState({
      commentsOpen: false,
    });

    await this.saveRecord({ _id: newRecord._id, comments: this.state.commentsInput });
  }

  saveRecord(record: any) {
    switch (this.props.type) {
      case BoxMarkTypes.details:
        return TestService.saveTestRun(record);
      case BoxMarkTypes.iteration:
        return TestService.saveTestIteration(record);
      default: {
        throw new Error("[type] property passed is incorrect");
      }
    }
  }

  toggleComments() {
    this.setState({
      commentsOpen: !this.state.commentsOpen,
    });
  }

  onCommentsChange(e: any) {
    this.setState({
      commentsInput: e.target.value,
    });
  }

  render() {
    return (
      <View
        {...this.props}
        {...this.state}
        flagTest={this.flagTest}
        shareTest={this.shareTest}
        commentTest={this.commentTest}
        toggleComments={this.toggleComments}
        onCommentsChange={this.onCommentsChange}
      />
    );
  }
}

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): AppDispatch => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
  hideNotification: () => dispatch(HideNotification()),
});

// tslint:disable-next-line:no-any
export default withRouter<BoxMarkProps & RouteComponentProps<{}>>(
  connect<any, AppDispatch, BoxMarkProps & RouteComponentProps<{}>>(
    null,
    mapDispatchToProps
  )(BoxMark)
);
