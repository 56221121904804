// tslint:disable:no-any
import * as React from "react";
import TestService from "../../services/TestService";
import GridControl from "./GridControl";
import { RouteComponentProps } from "react-router";
import { AxiosResponse } from "axios";
import withBreadcrumb, { WithBreadcrumb } from "../../components/withBreadcrumb";

export type RunObject = {
  runName: string;
  _id: string;
};

type TestRunHistoryState = {
  unassigned: number;
  runNames: Array<RunObject>;
  selectedRunName?: RunObject | null;
  allSelected: boolean;
  anySelected: boolean;
  filterValues: Array<any>;
  validated: boolean;
  assigning: boolean;
};

class TestRunHistory extends React.Component<
  RouteComponentProps<{}> & WithBreadcrumb,
  TestRunHistoryState
> {
  grid: any = null;

  constructor(props: RouteComponentProps<{}> & WithBreadcrumb) {
    super(props);

    this.state = {
      unassigned: 0,
      runNames: [],
      allSelected: false,
      anySelected: false,
      filterValues: [],
      validated: false,
      assigning: false,
    };

    this.generateNewRunName = this.generateNewRunName.bind(this);
    this.onSelectedRunNameChange = this.onSelectedRunNameChange.bind(this);
    this.selectTest = this.selectTest.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.assignToHandler = this.assignToHandler.bind(this);
  }

  async componentDidMount() {
    await this.getListofRunNames();
    await this.getUnassignedTestIterations();
    await this.getDistinctUnassignedTestIteration();
  }

  async getListofRunNames() {
    const result: AxiosResponse<Array<RunObject>> = await TestService.getLatestTestRun(
      "runName",
      10
    );
    this.setState({
      runNames: result.data,
    });
  }

  async getUnassignedTestIterations() {
    const result: AxiosResponse<number> = await TestService.getUnassignedTestIterationCount();
    this.setState({
      unassigned: result.data,
    });
  }

  async getDistinctUnassignedTestIteration() {
    const result: AxiosResponse<Array<any>> = await TestService.getDistinctUnassignedTestIteration(
      "comments"
    );
    const filters = result.data
      .filter((test: any) => !!test.comments)
      .map((test: any) => ({ value: test.comments, label: test.comments }));
    filters.unshift({ value: "", label: "Any Source" });

    this.setState({
      filterValues: filters,
    });
  }

  async generateNewRunName() {
    const result: AxiosResponse<string> = await TestService.generateNewRunName();
    const newRunName: RunObject = {
      _id: "",
      runName: result.data,
    };

    const newRunNames = [...this.state.runNames];
    newRunNames.unshift(newRunName);

    this.setState({
      runNames: newRunNames,
      selectedRunName: newRunNames[0],
    });
  }

  onSelectedRunNameChange(e: any) {
    this.setState({
      selectedRunName: this.state.runNames.find((r) => r.runName === e.target.value),
    });
  }

  async selectTest(e: any, dataItem: any) {
    await this.grid.setData(
      this.grid.getData.docs.map((d: any) =>
        d._id === dataItem._id
          ? {
              ...d,
              selected: e.target.checked,
            }
          : d
      )
    );
    await this.setState({
      allSelected: this.grid.getData.docs.every((d: any) => !!d.selected),
      anySelected: this.grid.getData.docs.some((d: any) => !!d.selected),
    });
  }

  async selectAll(e: any) {
    await this.grid.setData(
      this.grid.getData.docs.map((d: any) => ({
        ...d,
        selected: e.target.checked,
      }))
    );
    await this.setState({
      allSelected: this.grid.getData.docs.every((d: any) => !!d.selected),
      anySelected: this.grid.getData.docs.some((d: any) => !!d.selected),
    });
  }

  async assignToHandler() {
    if (!this.state.selectedRunName || !this.grid.getData.docs.some((d: any) => !!d.selected)) {
      return this.setState({
        validated: true,
      });
    } else {
      await this.setState({
        assigning: true,
      });

      const data = this.grid.getData.docs
        .filter((d: any) => !!d.selected)
        .map((d: any) => ({
          _id: d._id,
          runName: this.state.selectedRunName && this.state.selectedRunName.runName,
        }));

      await TestService.updateTestIterations(data);

      await this.setState({
        assigning: false,
      });

      this.grid.fetchDefaultList();
    }
  }

  render() {
    return (
      <GridControl
        ref={(element: any) => (this.grid = element)}
        {...this.props}
        {...this.state}
        onSelectedRunNameChange={this.onSelectedRunNameChange}
        generateNewRunName={this.generateNewRunName}
        assignToHandler={this.assignToHandler}
        selectTest={this.selectTest}
        selectAll={this.selectAll}
      />
    );
  }
}

export default withBreadcrumb(TestRunHistory);
