// tslint:disable:no-any

import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles, WithStyles, createStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import RTCGrid, { FilterValue, GridFilter } from "../../components/Grid";
import ApiPath from "../../constants/ApiPath";
import { RouteComponentProps } from "react-router";
import { TestIteration } from "../../constants/RoutesNames";
import * as moment from "moment";
import GridToolbar from "./GridToolbar";
import { RunObject } from "./index";

interface ITestHistoryState {
  filters: Array<GridFilter>;
  testRuns: Array<FilterValue>;
}

interface ITestHistoryProps {
  unassigned: number;
  runNames: Array<RunObject>;
  selectedRunName?: RunObject | null;
  generateNewRunName: () => void;
  assignToHandler: () => void;
  onSelectedRunNameChange: (event: any) => void;
  selectTest: (event: any, test: any) => void;
  selectAll: (event: any) => void;
  gridRef?: any;
  allSelected: boolean;
  anySelected: boolean;
  validated: boolean;
  assigning: boolean;
  filterValues: Array<any>;
}

export class GridControl extends React.Component<
  ITestHistoryProps & RouteComponentProps<{}> & WithStyles,
  ITestHistoryState
> {
  public grid: any = null;

  columnSchema: Array<ColumnSchema> = [
    {
      id: "selected",
      numeric: false,
      disablePadding: false,
      label: "",
      sortable: false,
      render: (dataItem: any) => {
        return (
          <div className={this.props.classes.checkboxContainer}>
            <Checkbox
              checked={!!dataItem.selected}
              onClick={this.checkboxClickHanlder}
              onChange={(e) => this.props.selectTest(e, dataItem)}
              color="primary"
            />
          </div>
        );
      },
      labelRender: () => (
        <div className={this.props.classes.checkboxContainer}>
          <Checkbox
            checked={this.props.allSelected}
            onClick={this.checkboxClickHanlder}
            onChange={(e) => this.props.selectAll(e)}
            color="primary"
          />
        </div>
      ),
    },
    {
      id: "fileName",
      numeric: false,
      disablePadding: false,
      label: "Source",
    },
    {
      id: "fileName",
      numeric: false,
      disablePadding: false,
      label: "Session Length",
      render: (dataItem: any) => (
        <Typography variant="body2">
          {dataItem.stat ? moment(dataItem.stat.voiceDuration).format("H:mm:ss") : ""}
        </Typography>
      ),
    },
    {
      id: "createDate",
      numeric: false,
      disablePadding: false,
      label: "Session Length",
      render: (dataItem: any) => (
        <Typography variant="body2">
          {moment(dataItem.createDate).format("MMM d YYYY - H:mm")}
        </Typography>
      ),
    },
  ];

  constructor(props: ITestHistoryProps & RouteComponentProps<{}> & WithStyles) {
    super(props);

    this.state = {
      filters: [],
      testRuns: [],
    };

    this.onRowClick = this.onRowClick.bind(this);
    this.checkboxClickHanlder = this.checkboxClickHanlder.bind(this);
  }

  onRowClick(_e: React.MouseEvent<HTMLTableRowElement>, dataItem: GridModel) {
    this.props.history.push(`${TestIteration}/${dataItem._id}`);
  }

  checkboxClickHanlder(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    return false;
  }

  render() {
    const {
      classes,
      runNames,
      generateNewRunName,
      assignToHandler,
      selectedRunName,
      onSelectedRunNameChange,
      gridRef,
      filterValues,
      validated,
      anySelected,
      assigning,
      location,
    } = this.props;

    return (
      <Paper className={classes.root}>
        <GridToolbar
          assigning={assigning}
          validated={validated}
          anySelected={anySelected}
          runNames={runNames}
          selectedRunName={selectedRunName}
          generateNewRunName={generateNewRunName}
          assignToHandler={assignToHandler}
          onSelectedRunNameChange={onSelectedRunNameChange}
        />
        <RTCGrid
          wrappedComponentRef={gridRef}
          onRowClick={this.onRowClick}
          remoteDataBound={true}
          apiRoute={ApiPath.api.testIterationsUnassigned}
          columnSchema={this.columnSchema}
          defaultSort={{
            order: "desc",
            orderBy: "createDate",
          }}
          filters={[
            {
              fieldName: "comments",
              filterLabel: "Source",
              filterValues: filterValues,
              value: "",
            },
          ]}
          rowProps={{
            className: classes.tableRowItemHover,
          }}
          pagination={true}
          location={location}
        />
      </Paper>
    );
  }
}

const styles = () =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    tableRowItemHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
        "& button": {
          visibility: "visible",
          pointerEvents: "all",
        },
      },
    },
    hoverActionBtnStyle: {
      padding: "0px",
      width: 35,
      minWidth: 35,
      height: 35,
      minHeight: 35,
      visibility: "hidden",
      pointerEvents: "none",
    },
    checkboxContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });

const decorate = withStyles(styles);

const DecoratedComponent = decorate(GridControl);

export default React.forwardRef((props: ITestHistoryProps & RouteComponentProps<{}>, ref) => (
  <DecoratedComponent {...props} gridRef={ref} />
));
