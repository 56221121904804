import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { Field, Form } from "react-final-form";
import TextFieldControl from "../../../components/form-elements/TextFieldControl";
import Button from "@material-ui/core/Button";
import { WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { RouteComponentProps, withRouter } from "react-router";
import FormValidators from "../../../helpers/form-validators";
import AxiosFactory from "../../../services/AxiosFactory";
import ApiPath from "../../../constants/ApiPath";
import { Login as LoginRoutes, Login } from "../../../constants/RoutesNames";
import CheckboxControl from "../../../components/form-elements/CheckboxControl";
import { SetNotification } from "../../../actions/notificationAction";
import store from "../../../store";
import { ReactSVG } from "react-svg";
import LoaderIcon from "../../../assets/images/loading-progress.svg";

interface ISignupForm {
  email: string;
  password: string;
  phone: string;
  company: string;
  acceptTerms: boolean;
  termsDate?: Date;
}

class SignupForm extends React.Component<
  RouteComponentProps<{}> &
    WithStyles<
      | "signInButton"
      | "buttonContainer"
      | "formContainer"
      | "forgotPasswordContainer"
      | "link"
      | "linkText"
      | "progress"
    >
> {
  axiosFactory: AxiosFactory;

  constructor(
    props: RouteComponentProps<{}> &
      WithStyles<
        | "signInButton"
        | "buttonContainer"
        | "formContainer"
        | "forgotPasswordContainer"
        | "link"
        | "linkText"
        | "progress"
      >
  ) {
    super(props);

    this.axiosFactory = new AxiosFactory();

    this.onSubmit = this.onSubmit.bind(this);
  }

  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form className={classes.formContainer} onSubmit={handleSubmit}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Field
                  component={TextFieldControl}
                  type="email"
                  name="email"
                  label="Email Address"
                  validate={FormValidators.composeValidators(
                    FormValidators.required(),
                    FormValidators.isEmail
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  component={TextFieldControl}
                  type="password"
                  name="password"
                  label="Password"
                  validate={FormValidators.composeValidators(
                    FormValidators.required(),
                    FormValidators.minValue(6, "Password must be at least 6 characters.")
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="phone"
                  label="Phone Number"
                  validate={FormValidators.composeValidators(
                    FormValidators.required(),
                    FormValidators.minValue(7, "Phone must be at least 7 characters.")
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="company"
                  label="Company"
                  validate={FormValidators.required()}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  name="acceptTerms"
                  component={CheckboxControl}
                  type="checkbox"
                  label={
                    <span>
                      I agree to the{" "}
                      <a
                        target="_blank"
                        href="https://testrtc.com/app-terms/"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </a>
                    </span>
                  }
                  validate={FormValidators.required(`To open an account on testRTC you
                                                                       must agree to our terms of service.`)}
                />
              </Grid>
              <Grid className={classes.buttonContainer} item={true} xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.signInButton}
                  disabled={submitting}
                >
                  {!submitting ? (
                    <React.Fragment>Sign Up</React.Fragment>
                  ) : (
                    <ReactSVG src={LoaderIcon} className={classes.progress} />
                  )}
                </Button>
              </Grid>
              <Grid item={true} xs={6}>
                <Link className={classes.link} to={Login.SignIn}>
                  <Typography variant="body2" color="textSecondary" className={classes.linkText}>
                    Already have an account?
                  </Typography>
                </Link>
              </Grid>
              <Grid item={true} xs={6}>
                <Link className={classes.link} to={Login.Subscribe}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    className={classes.linkText}
                  >
                    Subscribe for updates
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }

  private onSubmit(values: ISignupForm): Promise<void | { [x: string]: string }> {
    const signUpValues = values;
    signUpValues.termsDate = new Date();

    return this.axiosFactory.axios
      .post(ApiPath.api.signup, signUpValues)
      .then(() => {
        this.props.history.push(`${LoginRoutes.SignUpConfirm}/${signUpValues.email}`);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          store.dispatch(SetNotification(err.response.data.message));
        } else {
          console.error(err);
        }
      });
  }
}

const decorate = withStyles((_theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    forgotPasswordContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    signInButton: {
      width: "auto",
    },
    formContainer: {
      paddingTop: 0,
    },
    link: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:active": {
        textDecoration: "none",
      },
    },
    linkText: {
      color: "#559542",
    },
    progress: {
      display: "flex",
      justifyContent: "center",
      "& svg": {
        height: 20,
        width: 20,
      },
    },
  })
);

export default withRouter<RouteComponentProps<{}>>(decorate(SignupForm));
