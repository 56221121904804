import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import AsyncComponent from "../../components/asyncComponent";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import AppContent from "./components/AppContent";
import Main from "../Main/indexNew";
import Tests from "../Tests";
import TestProperty from "../TestProperty";
import MonitoringProperty from "../MonitoringProperty";
import AssignToTest from "../AssignToTest";
import TestRunHistory from "../TestRunHistory";
import TestRunGroup from "../TestRunGroup";
import AnalyzeDump from "../AnalyzeDump";
import Assets from "../Assets";
import AssetProperty from "../AssetProperty";
import Monitoring from "../Monitoring";
import MonitorRunHistory from "../MonitorRunHistory";
import TestIteration from "../TestIteration";
import TestLogs from "../TestLogs";
import { App as AppRoutes, NotFound } from "../../constants/RoutesNames";
import Support from "../Support/index";
import { AppLayoutState } from "./index";
import AppRoot from "./components/AppRoot";
import AppContentSpinner from "./components/AppContentSpinner";
import AppController, { AppStoreProps } from "../AppController";
import { AppContext, AppState } from "../../App";
import { ProjectsContext, AppControllerState } from "../AppController";
import { AppToolbarProvider } from "./components/AppToolbar/Context";
import UserSettings from "../UserSettings";
import ForbiddenPage from "../ForbiddenPage";
import { WatchRTCProvider } from "../WatchRTC/Context";

type ViewProps = {
  handleDrawerToggle: () => void;
};

const TestRunDetails = AsyncComponent(() => import("../TestRunDetails"));
const Logs = AsyncComponent(() => import("../LogsContainer"));

const AsyncQualityRTC = AsyncComponent(() => import("../QualityRTC"));
const AsyncQualityRTCInvites = AsyncComponent(() => import("../QualityRTCInvites"));
const AsyncQualityRTCInvite = AsyncComponent(() => import("../QualityRTCInvite"));
const AsyncNetworkTestCharts = AsyncComponent(() => import("../NetworkTestCharts"));
const AsyncOnPremiseProbes = AsyncComponent(() => import("../OnPremiseProbes"));
const AsyncOnPremiseProbesHistory = AsyncComponent(() => import("../OnPremiseProbesHistory"));
const AsyncOnPremiseProbeProperty = AsyncComponent(() => import("../OnPremiseProbeProperty"));
const AsyncOnPremiseProbeCharts = AsyncComponent(() => import("../OnPremiseProbeCharts"));
const AsyncOnPremiseProbesCompare = AsyncComponent(() => import("../OnPremiseProbesCompare"));
const AsyncOnPremiseProbeResults = AsyncComponent(() => import("../OnPremiseProbeResults"));
const UserAccounts = AsyncComponent(() => import("../Admin/UserAccounts"));
const UserDetails = AsyncComponent(() => import("../Admin/UserDetails"));
const Projects = AsyncComponent(() => import("../Admin/Projects"));
const ProjectProperties = AsyncComponent(() => import("../Admin/ProjectProperties"));
const Probes = AsyncComponent(() => import("../Admin/Probes"));
const ProbesSetup = AsyncComponent(() => import("../Admin/ProbesSetup"));
const ProbeSetupDetails = AsyncComponent(() => import("../Admin/ProbeSetupDetails"));
const DynamicProbe = AsyncComponent(() => import("../Admin/DynamicProbe"));
const DynamicProbeDetails = AsyncComponent(() => import("../Admin/DynamicProbeDetails"));
const UsageGraph = AsyncComponent(() => import("../Admin/UsageGraph"));
const AdminUtils = AsyncComponent(() => import("../Admin/AdminUtils"));
const BillingData = AsyncComponent(() => import("../Admin/BillingData"));
const BillingDataDetails = AsyncComponent(() => import("../Admin/BillingData/Details"));
const WatchRTCRoomHistory = AsyncComponent(() => import("../WatchRTCRoomHistory"));
const WatchRTCRoom = AsyncComponent(() => import("../WatchRTCRoom"));
const WatchRTCPeer = AsyncComponent(() => import("../WatchRTCPeer"));
const WatchRTCHighlights = AsyncComponent(() => import("../WatchRTCHighlights"));
const WatchRTCTrends = AsyncComponent(() => import("../WatchRTCTrends"));
const WatchRTCLive = AsyncComponent(() => import("../WatchRTCLive"));
const AnalyticsEvents = AsyncComponent(() => import("../Admin/AnalyticsEvents"));
const QualityRTCServer = AsyncComponent(() => import("../Admin/QualityRTCServer"));
const QualityRTCServerProperty = AsyncComponent(() => import("../Admin/QualityRTCServerProperty"));
const Settings = AsyncComponent(() => import("../Options/Settings"));
const DrillDownToTest = AsyncComponent(() => import("../DrillDownToTest"));
// const MyProfile = AsyncComponent(() => import("../Options/MyProfile"));
// const AccountBilling = AsyncComponent(() => import("../Options/AccountBilling"));

export default (props: AppLayoutState & ViewProps) => (
  <AppController>
    <AppContext.Consumer>
      {(appProps: AppState) => (
        <ProjectsContext.Consumer>
          {(projectProps: AppControllerState & AppStoreProps) => (
            <AppToolbarProvider>
              <WatchRTCProvider>
                <AppRoot>
                  <AppContentSpinner />
                  <Navbar handleDrawerToggle={props.handleDrawerToggle} {...projectProps} />
                  <Sidebar
                    {...projectProps}
                    mobileOpen={props.mobileOpen}
                    handleDrawerToggle={props.handleDrawerToggle}
                  />
                  <AppContent error={appProps.error}>
                    <Switch>
                      <Redirect exact={true} from="/" to={AppRoutes.Main} />
                      <Route exact={true} path={AppRoutes.AssignToTest} component={AssignToTest} />
                      <Route exact={true} path={AppRoutes.Main} component={Main} />
                      <Route exact={true} path={AppRoutes.Tests} component={Tests} />
                      <Route exact={true} path={AppRoutes.TestRun} component={TestRunHistory} />
                      <Route exact={true} path={AppRoutes.Monitoring} component={Monitoring} />
                      <Route
                        exact={true}
                        path={AppRoutes.MonitorRun}
                        component={MonitorRunHistory}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.NewTestProperty}
                        component={TestProperty}
                      />
                      <Route exact={true} path={AppRoutes.Forbidden} component={ForbiddenPage} />
                      <Route
                        exact={true}
                        path={AppRoutes.NewMonitoring}
                        component={MonitoringProperty}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.TestPropertyId}
                        component={TestProperty}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.MonitorPropertyId}
                        component={MonitoringProperty}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.TestRunDetailsId}
                        component={TestRunDetails}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.TestRunGroupId}
                        component={TestRunGroup}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.TestIterationId}
                        component={TestIteration}
                      />
                      <Route exact={true} path={AppRoutes.TestLogsId} component={TestLogs} />
                      <Route exact={true} path={AppRoutes.QualityRTC} component={AsyncQualityRTC} />
                      <Route
                        exact={true}
                        path={AppRoutes.NetworkTestCharts}
                        component={AsyncNetworkTestCharts}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.QualityRTCInvites}
                        component={AsyncQualityRTCInvites}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.QualityRTCInviteId}
                        component={AsyncQualityRTCInvite}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.QualityRTCInviteNew}
                        component={AsyncQualityRTCInvite}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.OnPremiseProbe}
                        component={AsyncOnPremiseProbes}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.OnPremiseProbeHistoryId}
                        component={AsyncOnPremiseProbesHistory}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.NewOnPremiseProbeProperty}
                        component={AsyncOnPremiseProbeProperty}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.OnPremiseProbeChartsId}
                        component={AsyncOnPremiseProbeCharts}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.OnPremiseProbesCompareId}
                        component={AsyncOnPremiseProbesCompare}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.TestPropertyId}
                        component={TestProperty}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.OnPremiseProbePropertyId}
                        component={AsyncOnPremiseProbeProperty}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.OnPremiseProbeResultsId}
                        component={AsyncOnPremiseProbeResults}
                      />
                      <Route exact={true} path={AppRoutes.Assets} component={Assets} />
                      <Route
                        exact={true}
                        path={AppRoutes.NewAssetProperty}
                        component={AssetProperty}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.AssetPropertyId}
                        component={AssetProperty}
                      />

                      <Route
                        exact={true}
                        path={AppRoutes.WatchRTCRoom}
                        component={WatchRTCRoomHistory}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.WatchRTCHighlights}
                        component={WatchRTCHighlights}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.WatchRTCTrends}
                        component={WatchRTCTrends}
                      />
                      <Route exact={true} path={AppRoutes.WatchRTCLive} component={WatchRTCLive} />

                      <Route exact={true} path={AppRoutes.AnalyzeDump} component={AnalyzeDump} />
                      <Route path={AppRoutes.Logs} component={Logs} />
                      <Route exact={true} path={AppRoutes.Support} component={Support} />

                      <Route exact={true} path={AppRoutes.UserAccounts} component={UserAccounts} />
                      <Route exact={true} path={AppRoutes.UserDetailsId} component={UserDetails} />
                      <Route exact={true} path={AppRoutes.Projects} component={Projects} />
                      <Route
                        exact={true}
                        path={AppRoutes.ProjectPropertiesId}
                        component={ProjectProperties}
                      />
                      <Route exact={true} path={AppRoutes.Probes} component={Probes} />
                      <Route exact={true} path={AppRoutes.ProbesSetup} component={ProbesSetup} />
                      <Route
                        exact={true}
                        path={AppRoutes.ProbeSetupDetailsId}
                        component={ProbeSetupDetails}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.NewProbeSetupDetails}
                        component={ProbeSetupDetails}
                      />
                      <Route exact={true} path={AppRoutes.DynamicProbe} component={DynamicProbe} />
                      <Route
                        exact={true}
                        path={AppRoutes.DynamicProbeDetailsId}
                        component={DynamicProbeDetails}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.NewDynamicProbeDetails}
                        component={DynamicProbeDetails}
                      />
                      <Route exact={true} path={AppRoutes.AdminUtils} component={AdminUtils} />
                      <Route exact={true} path={AppRoutes.UsageGraphs} component={UsageGraph} />
                      <Route exact={true} path={AppRoutes.BillingData} component={BillingData} />
                      <Route
                        exact={true}
                        path={AppRoutes.BillingDataId}
                        component={BillingDataDetails}
                      />
                      <Route exact={true} path={AppRoutes.UserSettings} component={UserSettings} />

                      <Route
                        exact={true}
                        path={AppRoutes.WatchRTCRoomId}
                        component={WatchRTCRoom}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.WatchRTCPeerId}
                        component={WatchRTCPeer}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.AnalyticsEvents}
                        component={AnalyticsEvents}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.QualityRTCServer}
                        component={QualityRTCServer}
                      />
                      <Route
                        exact={true}
                        path={AppRoutes.QualityRTCServerPropertyId}
                        component={QualityRTCServerProperty}
                      />
                      <Route exact={true} path={AppRoutes.Settings} component={Settings} />
                      <Route exact={true} path={AppRoutes.MyProfile} component={Settings} />
                      <Route exact={true} path={AppRoutes.AccountBilling} component={Settings} />

                      <Route
                        exact={true}
                        path={AppRoutes.DrillDownToTestId}
                        component={DrillDownToTest}
                      />
                      <Redirect from="*" to={NotFound} />
                    </Switch>
                  </AppContent>
                </AppRoot>
              </WatchRTCProvider>
            </AppToolbarProvider>
          )}
        </ProjectsContext.Consumer>
      )}
    </AppContext.Consumer>
  </AppController>
);
