// tslint:disable:no-any

import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import StatusIcon from "../../../../components/Test/StatusIcon";
import ResultLine from "../../../../components/Test/ResultLine";
import { TestProperty, TestLogs } from "../../../../constants/RoutesNames";
import { formatDuration } from "../../../../helpers/testDetails";
import ErrorLine from "../../../../components/Test/ErrorLine";
// import ChartOverview from "../ChartOverview";
import ChartOverviewNew from "../ChartOverviewNew";
import { TestRunDetailsState, TestRunDetailsProps } from "../../index";
import BoxMark, { BoxMarkTypes } from "../../../../components/Test/BoxMark";
import * as moment from "moment";
import Tooltip from "../../../../components/Tooltip";

export interface TestOverviewStoreProps {
  user: User;
}

export interface TestOverviewProps {
  // tslint:disable-next-line:no-any
  updateTest: (test: any) => void;
  boxMark?: boolean;
  // tslint:disable-next-line: no-any
  history: any;
}

type StyledComponent = WithStyles<
  | "card"
  | "overviewLine"
  | "link"
  | "lineValue"
  | "lineIcon"
  | "lineTitle"
  | "divider"
  | "statusValue"
  | "toolbar"
  | "redText"
>;

type Props = TestOverviewStoreProps &
  TestOverviewProps &
  TestRunDetailsProps &
  TestRunDetailsState &
  StyledComponent;

class TestOverview extends React.Component<Props> {
  // tslint:disable-next-line:no-any
  validToShowChart(stat: any, events: Array<any>): boolean {
    // check usual conditions as before
    const evtCond = !!events && Array.isArray(events) && !!events.length;
    const iterMoreThanOne = stat.count >= 1;
    const hasEvents: boolean = evtCond && events.some((evt) => evt.length);
    // let hasChartData = !!stat.chartData;

    const hasProbsData = !!stat.probsData;
    const hasFilterData = !!stat.filterData;
    // decide whether it has what to show
    const res = (iterMoreThanOne && hasProbsData && hasFilterData) || hasEvents;
    return res;
  }

  // tslint:disable-next-line:no-any
  calculateTestDuration(stat: any) {
    const browserDuration = stat && stat.browserDuration ? stat.browserDuration : 0;
    return formatDuration(browserDuration, "DHMS");
  }

  // tslint:disable-next-line:no-any
  calculateTestOverhead(stat: any) {
    const browserDuration = stat && stat.browserDuration ? stat.browserDuration : 0;
    return formatDuration(
      this.props.calc.testDuration ? Math.abs(this.props.calc.testDuration - browserDuration) : 0,
      "DHMS"
    );
  }

  getCallSetupTime() {
    return formatDuration(this.props.calc.callSetupTime as number, "DHMSms");
  }

  onStatusClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.props.test || !this.props.history) {
      return true;
    }
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      this.props.history.push(`${TestLogs}/${this.props.test._id}/undefined/manual`);
      return false;
    }
    return true;
  };

  printCustomMetrics = (metrics: StatObj["customTestMetric"] | undefined) => {
    if (metrics) {
      if (Object.keys(metrics).length > 0) {
        const metricsRender = [];
        const keys = Object.keys(metrics);
        for (const key of keys) {
          const value: any = metrics[key]?.varValue ? metrics[key]?.varValue : "Null";
          const result = (
            <ResultLine
              key={key}
              title={key}
              tooltipTitle={"Aggregation type - " + metrics[key]?.aggType}
              value={
                value && value !== 0 && isFinite(value)
                  ? value.toLocaleString("en", {
                      maximumFractionDigits: 2,
                    })
                  : "Null"
              }
            />
          );
          metricsRender.push(result);
        }
        return metricsRender;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  renderConcurrentProbesResult = (test: any, iterationsStats?: any) => {
    let tooltipTitle = "Number of browsers used simultaneously";
    let value = test.parameters && test.parameters.concurrentUsers;
    let error = false;
    if (test?.parameters?.concurrentUsers && iterationsStats?.total) {
      if (test.parameters.concurrentUsers > iterationsStats.total) {
        tooltipTitle = `Executed only ${iterationsStats.total} probes out of the expected ${test.parameters.concurrentUsers}`;
        value = `${iterationsStats.total} (${test.parameters.concurrentUsers})`;
        error = true;
      }
    }
    return (
      <ResultLine
        title="Concurrent probes"
        icon="group"
        tooltipTitle={tooltipTitle}
        value={value}
        color={error ? "#a22a21" : "inherit"}
      />
    );
  };

  render() {
    const {
      test,
      events,
      testDefinition,
      user,
      audioMosPanelColor,
      classes,
      successRate,
      iterationsStats,
      boxMark,
      updateTest,
    } = this.props;

    const stat: StatObj | null | undefined = test && (test.stat as StatObj);
    const showChart: boolean = test
      ? !!test.stat && this.validToShowChart(test.stat, events ? events : [])
      : false;

    const isCustomMetrics =
      stat && stat.customTestMetric && Object.keys(stat?.customTestMetric).length > 0;

    const hasNotification =
      (test as any)?.comments || (test as any)?.textError || (test as any)?.rtcSetAdditionalInfo;

    return test ? (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Card className={classes.card}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="subtitle1">
                {this.props.title ? this.props.title : "Test Result Overview"}
              </Typography>
              {boxMark && (
                <BoxMark
                  updateRecord={updateTest}
                  type={BoxMarkTypes.details}
                  record={test}
                  hiddenShare={true}
                />
              )}
            </Toolbar>
            <Divider className={classes.divider} />
            <Grid container={true} spacing={5}>
              <Grid
                item={true}
                sm={6}
                xs={12}
                style={{
                  paddingTop: 0,
                  marginTop: 20,
                  paddingBottom: 0,
                  marginBottom: 20,
                  borderRight: "1px solid rgba(0, 0, 0, .2)",
                }}
              >
                <ResultLine
                  title="Test name"
                  pure={false}
                  tooltipTitle={testDefinition ? "Click to open test script" : undefined}
                  value={
                    !!testDefinition ? (
                      <Link className={classes.link} to={`${TestProperty}/${test.testId}`}>
                        {test.name}
                      </Link>
                    ) : (
                      <>
                        <span>{test.name}</span>
                        {testDefinition === false && (
                          <span className={classes.redText}>(Deleted)</span>
                        )}
                      </>
                    )
                  }
                />
                {this.renderConcurrentProbesResult(test, iterationsStats)}
                <ResultLine
                  title="Session size"
                  icon="device_hub"
                  tooltipTitle="Session size"
                  value={test.sessionSize}
                />
                {test.usage_report && (
                  <ResultLine
                    title="Probes type"
                    icon="storage"
                    value={test.usage_report.loadFactor * 2}
                    tooltipTitle="Number of vCPUs used by the probes"
                  />
                )}
                {successRate && (
                  <ResultLine
                    title="Success rate"
                    icon="done"
                    tooltipTitle="Success rate of probes"
                    value={successRate}
                  />
                )}
                {test.manual > 0 && (
                  <ResultLine
                    title="Manually attached browsers"
                    tooltipTitle="Number of manually attached browsers"
                    value={test.manual}
                  />
                )}
                <ResultLine title="User" icon="person" value={test.userName} />
                {test.systemInUse && user && user.role === "admin" && (
                  <ResultLine
                    title="System"
                    icon="storage"
                    color={audioMosPanelColor}
                    value={test.systemInUse}
                  />
                )}
              </Grid>
              <Grid
                item={true}
                sm={6}
                xs={12}
                style={{
                  paddingTop: 0,
                  marginTop: 20,
                  paddingBottom: 0,
                  marginBottom: 20,
                }}
              >
                <ResultLine
                  title="Status"
                  tooltipTitle="Test completion status"
                  value={
                    <span className={this.props.classes.statusValue}>
                      <StatusIcon status={test.status} />
                      {test.logUrls && user && user.role === "admin" ? (
                        <Link
                          className={classes.link}
                          to={`${TestLogs}/${test._id}`}
                          onClick={this.onStatusClick as any}
                        >
                          {test.status}
                        </Link>
                      ) : (
                        test.status
                      )}
                    </span>
                  }
                />
                <ResultLine
                  title="Test start time"
                  icon="play_arrow"
                  tooltipTitle="Time the test started"
                  value={moment(test.createDate).format("MM/DD/YYYY @ h:mm:ssA")}
                />
                <ResultLine
                  title="Test end time"
                  icon="stop"
                  tooltipTitle="Time the test finished"
                  value={
                    moment(test.endDate).isValid()
                      ? moment(test.endDate).format("MM/DD/YYYY @ h:mm:ssA")
                      : ""
                  }
                />
                <ResultLine
                  title="Test Duration"
                  icon="access_time"
                  tooltipTitle="Total time of a test from the moment the browser was spawned and until all logs were collected"
                  value={this.calculateTestDuration(stat)}
                />
                <ResultLine
                  title="Test Overhead"
                  icon="access_time"
                  tooltipTitle="The time from the moment a test is executed until the probe spawns the browser"
                  value={this.calculateTestOverhead(stat)}
                />
                {/* <ResultLine
                  title="Average call setup time"
                  icon="av_timer"
                  tooltipTitle="The average time it took to connect the sessions"
                  value={formatDuration((test.stat as StatObj).voiceSetupTime * 1000, 'DHMSms')}
                /> */}
                {test.audioMOS && (
                  <ResultLine title="Audio MOS" color={audioMosPanelColor} value={test.audioMOS} />
                )}
                <ResultLine
                  title="Call Setup Time"
                  icon="access_time"
                  tooltipTitle="The total time before first communication channels were opened across all browser agents"
                  value={this.getCallSetupTime()}
                />
              </Grid>
            </Grid>
            {isCustomMetrics && (
              <>
                <Divider className={classes.divider} />
                <Grid item={true} lg={4} sm={6} xs={12}>
                  <Tooltip
                    content={"Custom Metrics sect by rtcSetMetric()"}
                    display={true}
                    displayType="block"
                  >
                    <Typography variant="subtitle1">Custom Metrics</Typography>
                  </Tooltip>
                </Grid>
                <Grid container={true}>
                  <Grid
                    item={true}
                    sm={6}
                    xs={12}
                    style={{
                      paddingTop: 0,
                      marginTop: 20,
                      paddingBottom: 0,
                      marginBottom: 20,
                    }}
                  >
                    {this.printCustomMetrics(stat?.customTestMetric)}
                  </Grid>
                </Grid>
              </>
            )}
            {hasNotification && (
              <Grid item={true}>
                <Divider className={classes.divider} />
                <ErrorLine data={test} />
              </Grid>
            )}
            <Divider className={classes.divider} />

            {/* {showChart ? <ChartOverview test={test} events={events} /> : null} */}
            {showChart ? <ChartOverviewNew test={test} events={events} /> : null}
          </Card>
        </Grid>
      </Grid>
    ) : null;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    divider: {
      margin: "16px -16px",
    },
    statusValue: {
      display: "flex",
      alignItems: "center",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    redText: {
      color: "#a22a21",
      fontWeight: 500,
    },
  });

const decorate = withStyles(styles);

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

export default connect<TestOverviewStoreProps, {}, TestRunDetailsState & TestOverviewProps>(
  mapStateToProps
)(decorate(TestOverview));
