// tslint:disable:no-any
import * as React from "react";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import { GridListTileBar, Grid, Theme } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import { pure } from "recompose";

type StyledComponent = WithStyles<
  | "gridList"
  | "gridItem"
  | "imageTile"
  | "listTileBar"
  | "fullScreenIcon"
  | "previewImage"
  | "previewDialog"
  | "navigationContainer"
  | "imageHolder"
  | "imageStats"
>;

type MediaPreviewProps = {
  mediaList: Array<any>;
};

type MediaPreviewState = {
  previewOpen: boolean;
  currentPreviewIndex: number;
};

class MediaPreview extends React.Component<StyledComponent & MediaPreviewProps, MediaPreviewState> {
  ImageList = pure(() => (
    <Grid container={true} className={this.props.classes.gridList}>
      {this.props.mediaList.map((screen, i) => (
        <Tooltip key={i} title={screen.caption.replace(/\.[^/.]+$/, "")} placement="top">
          <Grid
            onClick={() => this.openDialog(i)}
            item={true}
            className={this.props.classes.gridItem}
          >
            <img
              src={screen.thumbUrl}
              alt={screen.caption}
              style={{ height: "inherit", border: "1px solid silver" }}
            />
            <GridListTileBar
              className={this.props.classes.listTileBar}
              title={screen.caption.replace(/\.[^/.]+$/, "")}
            />
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  ));

  constructor(props: StyledComponent & MediaPreviewProps) {
    super(props);

    this.state = {
      previewOpen: false,
      currentPreviewIndex: 0,
    };

    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.navigateNext = this.navigateNext.bind(this);
    this.navigateBack = this.navigateBack.bind(this);
  }

  openDialog(currentPreviewIndex: number) {
    const newState = {
      ...this.state,
    };
    newState.previewOpen = true;
    newState.currentPreviewIndex = currentPreviewIndex;
    // if (index !== undefined) {
    //   newState.currentPreviewIndex = index;
    // }
    this.setState(newState);
  }

  closeDialog() {
    this.setState({
      previewOpen: false,
    });
  }

  async navigateBack() {
    if (this.state.currentPreviewIndex === 0) {
      return await this.setState({
        currentPreviewIndex: this.props.mediaList.length - 1,
      });
    }
    return await this.setState({
      currentPreviewIndex: this.state.currentPreviewIndex - 1,
    });
  }

  async navigateNext() {
    if (this.state.currentPreviewIndex === this.props.mediaList.length - 1) {
      return await this.setState({
        currentPreviewIndex: 0,
      });
    }
    await this.setState({
      currentPreviewIndex: this.state.currentPreviewIndex + 1,
    });
  }

  render() {
    const { mediaList, classes } = this.props;
    const { previewOpen, currentPreviewIndex } = this.state;

    return (
      <>
        {mediaList.length > 0 ? (
          <this.ImageList />
        ) : (
          <Typography variant="subtitle1" align="center">
            No Media
          </Typography>
        )}
        {mediaList.length > 0 && (
          <Dialog
            classes={{
              paper: classes.previewDialog,
            }}
            open={previewOpen}
            onClose={this.closeDialog}
          >
            {mediaList.length > 1 && (
              <div className={classes.navigationContainer}>
                <Button onClick={this.navigateBack}>
                  <KeyboardArrowLeft />
                  <span>Previous</span>
                </Button>
                <Button
                  href={mediaList[currentPreviewIndex].thumbUrl}
                  download={true}
                  target={"_blank"}
                >
                  <span>Open image in new tab</span>
                </Button>
                <Button onClick={this.navigateNext}>
                  <span>Next</span>
                  <KeyboardArrowRight />
                </Button>
              </div>
            )}
            <div className={classes.imageHolder}>
              <div className={classes.imageStats}>
                <Typography variant="body2">
                  {mediaList[currentPreviewIndex].caption.replace(/\.[^/.]+$/, "")}
                </Typography>
                <Typography variant="body2">{mediaList[currentPreviewIndex].urlCaption}</Typography>
                <Typography variant="body2">
                  {moment(mediaList[currentPreviewIndex].timestamp).format("MM/DD/YYYY @ h:mm:ssA")}
                </Typography>
              </div>
              <img
                className={classes.previewImage}
                src={mediaList[currentPreviewIndex].thumbUrl}
                alt="thumbUrl"
              />
            </div>
          </Dialog>
        )}
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    gridList: {
      width: "100%",
      height: "auto",
      maxHeight: 450,
      overflowY: "auto",
      justifyContent: "center",
    },
    gridItem: {
      height: 150,
      paddingLeft: 15,
      paddingRight: 15,
      marginBottom: 15,
      "&:hover": {
        cursor: "pointer",
      },
      "&:hover $listTileBar": {
        display: "block",
      },
      position: "relative",
    },
    imageTile: {
      width: "30%",
      height: 120,
    },
    listTileBar: {
      backgroundColor: "rgba(0, 0, 0, 0.67)",
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: 48,
      marginLeft: 15,
      marginRight: 15,
      display: "none",
    },
    fullScreenIcon: {
      color: "white",
    },
    previewImage: {
      maxWidth: "calc(90vw - 40px)",
      maxHeight: "calc(90vh - 60px)",
      margin: "auto",
      display: "block",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "calc(90vw - 100px)",
      },
    },
    previewDialog: {
      padding: "40px 20px 20px 20px",
      height: "auto",
      width: "auto",
      maxWidth: "90vw",
      maxHeight: "90vh",
      overflow: "hidden",
    },
    imageHolder: {
      "&:hover": {
        "&>div": {
          visibility: "visible",
        },
      },
      position: "relative",
      height: "auto",
      textAlign: "left",
    },
    imageStats: {
      position: "absolute",
      top: 0,
      left: 0,
      visibility: "hidden",
      padding: 15,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      maxWidth: "40vw",
      wordBreak: "break-all",
      marginLeft: 0,
      marginTop: 15,
      fontWeight: 600,
    },
    navigationContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: -40,
      "& span": {
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.675rem",
        },
      },
    },
  });

const decorate = withStyles(styles);

export default decorate(MediaPreview);
