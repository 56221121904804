import * as React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import { formatNumber, formatScore } from "../../../../helpers/testDetails";
import { numberWithCommas } from "../../../../helpers/numbers";
import Grow from "@material-ui/core/Grow";
import { ChartsState } from "../index";

type StyledComponent = WithStyles<
  | "durationContainer"
  | "cardWrapper"
  | "freeReportCardWrapper"
  | "fiveCardWrapper"
  | "greenCard"
  | "blueCard"
  | "cardLabel"
  | "greenText"
  | "blueText"
  | "greyText"
  | "alignRight"
>;

type TestStatsProps = {
  isReport: boolean;
  freeReport: boolean;
  manualUpload: boolean;
};

class TestStats extends React.Component<ChartsState & TestStatsProps & StyledComponent> {
  render() {
    const { calc, classes, session, total, performance, freeReport, manualUpload } = this.props;

    if (!total) {
      return null;
    }

    const withSession =
      // tslint:disable-next-line: no-any
      Object.keys(session).length > 0 && Object.keys(session).every((x: any) => session[x]);

    let cardClassName = null;

    if (freeReport) {
      cardClassName = classes.freeReportCardWrapper;
    } else if (!withSession) {
      cardClassName = classes.fiveCardWrapper;
    } else {
      cardClassName = classes.cardWrapper;
    }

    const _e = (key: string, suffix: string): string => {
      if (performance) {
        const val = performance[key];
        return val ? `${numberWithCommas(formatNumber(val, 0))} ${suffix}` : "-";
      } else {
        return "-";
      }
    };

    return (
      <Grid container={true} spacing={2}>
        {!freeReport && (
          /*Score*/
          <Grid item={true} className={cardClassName}>
            <Grow in={true}>
              <Card className={classes.greenCard}>
                <Grid container={true} spacing={2} alignItems="center" direction="column">
                  <Grid item={true} xs={12}>
                    <Typography className={classes.cardLabel}>Score</Typography>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Typography className={classes.greenText}>{formatScore(calc)}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grow>
          </Grid>
        )}

        {/*Performance*/}
        {!manualUpload && (
          <Grid item={true} className={cardClassName}>
            <Grow in={true}>
              <Card className={classes.blueCard}>
                <Grid container={true} spacing={2} alignItems="center" direction="column">
                  <Grid item={true} xs={12}>
                    <Typography className={classes.cardLabel}>Performance</Typography>
                  </Grid>
                  <Grid item={true} container={true} justify="space-around">
                    <Grid item={true}>
                      <Typography className={classes.greyText}>CPU</Typography>
                      <Typography className={classes.blueText}>{_e("browserCpu", "%")}</Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography className={classes.greyText}>Memory</Typography>
                      <Typography className={classes.blueText}>
                        {_e("browserMemory", "MB")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grow>
          </Grid>
        )}

        {/*Bitrate*/}
        <Grid item={true} className={cardClassName}>
          <Grow in={true}>
            <Card className={classes.blueCard}>
              <Grid container={true} spacing={2} alignItems="center" direction="column">
                <Grid item={true} xs={12}>
                  <Typography className={classes.cardLabel}>Bitrate (Kbits)</Typography>
                </Grid>
                <Grid item={true} container={true} xs={12}>
                  <Grid item={true} xs={4} />
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"in"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"out"}</Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"A"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.audio?.recv?.bitRate, 0))}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.audio?.send?.bitRate, 0))}
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"V"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.video?.recv?.bitRate, 0))}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.video?.send?.bitRate, 0))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grow>
        </Grid>

        {/*Packet loss*/}
        <Grid item={true} className={cardClassName}>
          <Grow in={true}>
            <Card className={classes.blueCard}>
              <Grid container={true} spacing={2} alignItems="center" direction="column">
                <Grid item={true} xs={12}>
                  <Typography className={classes.cardLabel}>Packet Loss</Typography>
                </Grid>
                <Grid item={true} container={true} xs={12}>
                  <Grid item={true} xs={4} />
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"in"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"out"}</Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"A"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {`${numberWithCommas(formatNumber(total.audio?.recv?.packetLossPCT, 1))}%`}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {`${numberWithCommas(formatNumber(total.audio?.send?.packetLossPCT, 1))}%`}
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"V"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {`${numberWithCommas(formatNumber(total.video?.recv?.packetLossPCT, 1))}%`}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {`${numberWithCommas(formatNumber(total.video?.send?.packetLossPCT, 1))}%`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grow>
        </Grid>

        {/*Jitter*/}
        <Grid item={true} className={cardClassName}>
          <Grow in={true}>
            <Card className={classes.blueCard}>
              <Grid container={true} spacing={2} alignItems="center" direction="column">
                <Grid item={true} xs={12}>
                  <Typography className={classes.cardLabel}>Jitter (ms)</Typography>
                </Grid>
                <Grid item={true} container={true} xs={12}>
                  <Grid item={true} xs={4} />
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"in"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"out"}</Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"A"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.audio?.recv?.jitter, 0))}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.audio?.send?.jitter, 0))}
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"V"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    {/* <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.video?.recv?.jitter, 0))}
                    </Typography> */}
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.video?.send?.jitter, 0))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grow>
        </Grid>

        {/*RTT*/}
        <Grid item={true} className={cardClassName}>
          <Grow in={true}>
            <Card className={classes.blueCard}>
              <Grid container={true} spacing={2} alignItems="center" direction="column">
                <Grid item={true} xs={12}>
                  <Typography className={classes.cardLabel}>RTT (ms)</Typography>
                </Grid>
                <Grid item={true} container={true}>
                  <Grid item={true} xs={4} />
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"in"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"out"}</Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"A"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.audio?.recv?.rtt, 0))}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.audio?.send?.rtt, 0))}
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={4}>
                    <Typography className={classes.greyText}>{"V"}</Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.video?.recv?.rtt, 0))}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography className={classes.blueText}>
                      {numberWithCommas(formatNumber(total.video?.send?.rtt, 0))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grow>
        </Grid>
      </Grid>
    );
  }
}

const cardStyles = {
  borderRadius: 2.5,
  padding: 8,
  borderStyle: "solid",
  height: "100%",
  boxShadow: "none",
};

const styles = (theme: Theme) =>
  createStyles({
    freeReportCardWrapper: {
      width: "25%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    fiveCardWrapper: {
      width: "20%",
      [theme.breakpoints.down("md")]: {
        width: "33.3%",
        "&:first-child": {
          width: "50%",
        },
        "&:nth-child(2)": {
          width: "50%",
        },
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        "&:first-child": {
          width: "100%",
        },
        "&:nth-child(2)": {
          width: "100%",
        },
      },
    },
    cardWrapper: {
      width: 100 / 6 + "%",
      [theme.breakpoints.down("md")]: {
        width: "33.3%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    cardLabel: {
      fontSize: 16,
      fontWeight: 500,
      color: "#172240",
    },
    greenCard: {
      ...cardStyles,
      border: "solid 1px #0fc5cb",
    },
    blueCard: {
      ...cardStyles,
      border: "solid 1px #1c8cd8",
    },
    greenText: {
      fontSize: 32,
      color: "#0fc5cb",
      fontWeight: 500,
      paddingTop: 13,
    },
    blueText: {
      fontSize: 17,
      color: "#1c8cd8",
      fontWeight: 500,
    },
    greyText: {
      fontSize: 13,
      color: "#c2c2c2",
      fontWeight: 500,
      paddingTop: 4,
    },
    alignRight: {
      textAlign: "right",
    },
  });

const decorate = withStyles(styles);

export default decorate(TestStats);
