import * as React from "react";
import * as _ from "lodash";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ToolbarCore from "@material-ui/core/Toolbar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import SingleChart from "./SingleChart";
import { processData, buildAllCharts, checkIfHasData, checkIfHasPerfData } from "./helpers";
import { MediaType, ByType, medias, mediaIcons, chartGroup, byTypeArray } from "./constants";

import ChartSize, {
  ChartSizeProvider,
  chartSizes,
} from "src/components/Chart/components/ChartSize";

interface IChartOverviewProps {
  test: any;
  events?: Array<any>;
  watchRTC?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    leftButtons: {},
    rightButtons: {
      display: "flex",
      flexDirection: "row",
      marginBottom: theme.spacing(1),
    },
  })
);

const ChartOverview = (props: IChartOverviewProps) => {
  const classes = useStyles();

  const [mediaType, setMediaType] = React.useState<MediaType>("video");
  const [byType, setByType] = React.useState<ByType>(byTypeArray[0] as ByType);

  const [charts, setCharts] = React.useState<any>();
  const [data, setData] = React.useState<any>();
  const [hasData, setHasData] = React.useState({
    audio: false,
    video: false,
    performance: false,
  });

  React.useEffect(() => {
    moveLegends();
  }, []);

  React.useEffect(() => {
    const processedData = processData({ ...props.test.stat }, props.events || []);

    const hasAudio = checkIfHasData(processedData, "audio");
    const hasVideo = checkIfHasData(processedData, "video");
    const hasPerformance = checkIfHasPerfData(processedData);

    if (hasAudio || hasVideo) {
      setData(processedData);
      setHasData({
        audio: hasAudio,
        video: hasVideo,
        performance: hasPerformance,
      });

      if (!hasVideo) {
        setMediaType("audio");
      }
    }
  }, [props.test.stat, props.events]);

  React.useEffect(() => {
    if (data) {
      const audio = buildAllCharts(data, "audio", byType);
      const video = buildAllCharts(data, "video", byType);
      const performance = buildAllCharts(data, "performance", byType);

      setCharts({ audio, video, performance });
    }
  }, [data, byType]);

  const handleMediaChange = (value: MediaType) => {
    if (value === "performance" && byType === "time/channel") {
      setByType(byTypeArray[0] as ByType);
    }
    setMediaType(value);
  };

  const handleTabChange = (_e: any, tab: number) => {
    setByType(byTypeArray[tab] as ByType);
  };

  const moveLegends = () => {
    setTimeout(() => {
      const legends = document.getElementsByClassName("custom-legend");
      try {
        if (legends.length === 2) {
          (legends[1] as any).replaceChildren(legends[0]);
        }
      } catch {
        //
      }
    }, 1000);
  };

  if ((!hasData.audio && !hasData.video) || !charts) {
    return null;
  }

  const probeLabel = props.watchRTC ? "peer" : "probe";

  return (
    <ChartSizeProvider>
      <Grid container spacing={2}>
        <Grid item={true} xs={12}>
          <ToolbarCore className={classes.toolbar}>
            <div className={classes.leftButtons}>
              <Tabs value={byTypeArray.indexOf(byType)} onChange={handleTabChange}>
                <Tab label={`By time/${probeLabel}`} />
                <Tab label="By time/channel"  disabled={mediaType === "performance"} />
                <Tab label={`By ${probeLabel}`} />
              </Tabs>
            </div>
            <div className={classes.rightButtons}>
              <ChartSize defaultSize={chartSizes.Compact} />
              <ButtonGroup color="default">
                {medias.map((value: MediaType) => {
                  const name = _.startCase(value);
                  return (
                    <Button
                      key={value}
                      onClick={() => handleMediaChange(value)}
                      disabled={!hasData[value]}
                      style={{ pointerEvents: "visible" }}
                    >
                      <Tooltip title={name}>
                        <Icon
                          color={
                            !hasData[value]
                              ? "disabled"
                              : value === mediaType
                              ? "secondary"
                              : "action"
                          }
                        >
                          {mediaIcons[value]}
                        </Icon>
                      </Tooltip>
                    </Button>
                  );
                })}
              </ButtonGroup>
            </div>
          </ToolbarCore>

          <div>
            <SingleChart
              charts={charts}
              mediaType={mediaType}
              group={chartGroup[byType].bitrate}
              byProbeActivated={byType === "probe"}
            />
            <SingleChart
              charts={charts}
              mediaType={mediaType}
              group={chartGroup[byType].quality}
              byProbeActivated={byType === "probe"}
            />
          </div>
        </Grid>
      </Grid>
    </ChartSizeProvider>
  );
};

export default ChartOverview;
