import * as React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import { TestExecustionProps, TestExecustionState } from "./index";
import RTCGrid from "../../../../components/Grid";
import StatusIcon from "../../../../components/Test/StatusIcon";

type ViewProps = {
  // tslint:disable-next-line:no-any
  columnSchema: any;
  cIsDisabled: boolean;
  notifSent: boolean;
  handleCancelTest: () => void;
  handleNotify: () => void;
};

type StyledComponent = WithStyles<
  | "card"
  | "overviewLine"
  | "link"
  | "lineValue"
  | "lineIcon"
  | "lineTitle"
  | "divider"
  | "statusValue"
  | "toolbar"
  | "dangerBtn"
  | "notifyBtn"
  | "controlsWrapper"
  | "snackbarContainer"
  | "redText"
>;

class View extends React.Component<
  ViewProps & TestExecustionState & TestExecustionProps & StyledComponent
> {
  render() {
    const {
      classes,
      runStatus,
      columnSchema,
      handleCancelTest,
      handleNotify,
      cIsDisabled,
      notifSent,
      test,
    } = this.props;

    return (
      <>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Card className={classes.card}>
              <Toolbar className={classes.toolbar}>
                <Typography variant="subtitle1">{"Test Execution Status"}</Typography>
                <div className={classes.controlsWrapper}>
                  <Button
                    onClick={handleCancelTest}
                    className={classes.dangerBtn}
                    disabled={cIsDisabled}
                    variant="contained"
                  >
                    Cancel Test
                  </Button>
                  {!notifSent && (
                    <Button
                      onClick={handleNotify}
                      className={classes.notifyBtn}
                      color="primary"
                      variant="contained"
                    >
                      Notify
                    </Button>
                  )}
                  <StatusIcon style={{ marginLeft: 10 }} status={test.status} />
                </div>
              </Toolbar>
              <Divider className={classes.divider} />
              <Grid container={true} spacing={5}>
                <RTCGrid localData={runStatus} columnSchema={columnSchema} />
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(5),
    },
    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    divider: {
      margin: "16px -16px",
    },
    dangerBtn: {
      backgroundColor: "#a22a21",
      color: "white",
      "&:disabled": {
        color: "white",
        backgroundColor: "#e4b6b3",
      },
    },
    notifyBtn: {
      marginLeft: theme.spacing(2),
    },
    statusValue: {
      display: "flex",
      alignItems: "center",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    redText: {
      color: "#a22a21",
      fontWeight: 500,
    },
    controlsWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    snackbarContainer: {
      zIndex: 99998,
    },
  });

const decorate = withStyles(styles);

export default decorate(View);
