// tslint:disable:no-any
import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

type UserMediaTableCellProps = {
  data: Array<any>;
  cellsStyle?: any;
};

type StyledComponent = WithStyles<"cell" | "row">;

export class KeyValueTableCell extends React.Component<UserMediaTableCellProps & StyledComponent> {
  render() {
    const { classes, data, cellsStyle } = this.props;
    return (
      <Table>
        <TableBody>
          {data.map((item: any, index: number) => {
            return (
              <TableRow key={index} className={classes.row}>
                <TableCell className={classes.cell} style={cellsStyle ? cellsStyle[0] : {}}>
                  {item.key}
                </TableCell>
                <TableCell className={classes.cell} style={cellsStyle ? cellsStyle[1] : {}}>
                  {item.value}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

const styles = () =>
  createStyles({
    cell: {
      borderBottom: "none",
      borderRight: "none !important",
      padding: "4px",
    },
    row: {
      height: "auto",
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
    },
  });

const decorate = withStyles(styles);

export default decorate(KeyValueTableCell);
