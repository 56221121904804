import * as React from "react";
import { LegendContext } from "../index";

export const useLegend = () => {
  const { series, suppressDirectionSortToLegend, syncLegends, toggleAll } = React.useContext(
    LegendContext
  );
  const [columnWidth, setColumnWidth] = React.useState(10);
  const [showInOut, setShowInOut] = React.useState(false);

  React.useEffect(() => {
    setColumnWidth(getColumnWidth());
    setShowInOut(shouldShowInOut && !!suppressDirectionSortToLegend);
  }, [series]);

  const getColumnWidth = () => {
    // 7 average char width
    // 25 gap for item symbol and padding
    let columnWidth = 10;
    series.forEach((s) => {
      if (s.name.length > columnWidth) {
        columnWidth = s.name.length;
      }
    });
    return columnWidth * 7 + 25;
  };

  const shouldShowInOut =
    series.length > 2 &&
    series.some(
      (s: any) =>
        (/outgoing/gi.test(s?.name) && !/outgoingBitrate/gi.test(s?.name)) ||
        (/incoming/gi.test(s?.name) && !/incomingBitrate/gi.test(s?.name))
    );

  return { columnWidth, showInOut, syncLegends, toggleAll };
};
