export const VisibilityRules = {
  remove: ["roundTripTime", "timestamp", "remoteTimestamp"],
  hide: {
    RTCVideoSource: ["frames"],
    RTCInboundRTPAudioStream: [
      "totalSamplesReceived",
      "totalSamplesDuration",
      "jitterBufferEmittedCount",
      "jitterBufferDelay",
      "headerBytesReceived",
    ],
    RTCInboundRTPVideoStream: [
      "framesDecoded",
      "qpSum",
      "totalSquaredFrameDelay",
      "headerBytesReceived",
      "framesReceived",
    ],
    RTCMediaStreamTrackReceiver: [
      "totalSamplesReceived",
      "jitterBufferEmittedCount",
      "jitterBufferDelay",
      "concealedSamples",
      "silentConcealedSamples",
    ],
    RTCRemoteInboundRtpAudioStream: [
      "timestamp", // - remove from graphs altogether
      "roundTripTimeMeasurements",
      "roundTripTime", // - remove from graphs altogether
      "totalRoundTripTime",
    ],
  },
  mixed: {
    RTCIceCandidatePair: {
      visible: [
        "[bytesSent_in_bits/s]",
        "[bytesReceived_in_bits/s]",
        "currentRoundTripTime",
        "availableOutgoingBitrate",
        "availableIncomingBitrate",
        "bytesSent",
        "bytesReceived",
        "requestsReceived",
        "requestsSent",
        "responsesReceived",
        "responsesSent",
        "consentRequestsSent",
      ],
      disabled: [
        "bytesReceived",
        "bytesSent",
        "consentRequestsSent",
        "requestsSent",
        "responsesReceived",
      ],
    },
  },
};
