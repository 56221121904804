import * as React from "react";
import { withStyles } from "@material-ui/core";
import LineNavigator from "line-navigator";
import { Virtuoso, VirtuosoMethods } from "react-virtuoso";
import LogViewerRow from "./LogViewerRow";
import styles, { Styles } from "./LogViewerStyles";
import { getLines, getLastLine, getRandomMuiColor } from "./utils";
import * as _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

const DEBOUNCE_DELAY = 10;

export interface Range {
  startIndex: number;
  endIndex: number;
}
export interface FindMatch {
  line: string;
  offset: number;
  length: number;
}

export interface FindAllResult {
  index: number;
  line: string;
  offset: number;
  length: number;
}
export interface Navigator {
  readSomeLines: (
    indexToStartWith: number,
    clb: (
      err: string | null,
      index: number,
      lines: Array<string>,
      isEof: boolean,
      progress: number
    ) => void
  ) => void;
  readLines: (
    indexToStartWith: number,
    count: number,
    clb: (
      err: string | null,
      index: number,
      lines: Array<string>,
      isEof: boolean,
      progress: number
    ) => void
  ) => void;
  find: (
    regex: RegExp,
    indexToStartWith: number,
    clb: (err: string | null, index: number, match: FindMatch) => void
  ) => void;
  findAll: (
    regex: RegExp,
    indexToStartWith: number,
    limit: number,
    clb: (
      err: string | null,
      index: number,
      limitHit: boolean,
      results: Array<FindAllResult>
    ) => void
  ) => void;
}
export interface LogRow {
  fileName: string;
  label: string;
  level: string;
  message: string;
  timestamp: string;
}

export interface ILogViewerProps {
  file?: File;
  chuckSize: number;
  search: string;
  searchLimit: number;
  searchMinLength: number;
  setSearch: (search: string) => void;
}

export interface ListScrollProps {
  index: number;
  align: string;
}
export type List = VirtuosoMethods;

const LogViewer = (props: ILogViewerProps & Styles) => {
  const wrapperDivRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [currentSearchResIndex, setCurrentSearchResIndex] = React.useState(0);
  const [searchResults, setSearchResults] = React.useState<Array<number>>([]);
  const [isLoading, setLoading] = React.useState(false);
  const [dataMap, setDataMap] = React.useState<Map<number, string>>();
  const [expandedLines, setExpandedLines] = React.useState({});
  const navigator = React.useRef<Navigator>();
  const virtuoso = React.useRef<List>(null);
  const labelColorMap = React.useRef<Map<string, string>>(new Map());

  const file = React.useRef(new File([], "default"));

  const getLabelColor = (label: string) => {
    const mapColor = labelColorMap.current.get(label);
    if (mapColor) {
      return mapColor;
    } else {
      const color = getRandomMuiColor();
      labelColorMap.current.set(label, color);
      return color;
    }
  };

  const dropSearch = () => {
    setSearchResults([]);
    props.setSearch("");
  };

  const scrollToNext = () => {
    let newIndex = currentSearchResIndex + 1;
    if (newIndex > searchResults.length - 1) {
      newIndex = searchResults.length - 1;
    }
    const lineNumber = searchResults[newIndex];
    if (virtuoso.current) {
      virtuoso.current.scrollToIndex({
        index: lineNumber,
        align: "start",
      });
      setCurrentSearchResIndex(newIndex);
    }
  };

  const scrollToPrev = () => {
    let newIndex = currentSearchResIndex - 1;
    if (newIndex < 0) {
      newIndex = 0;
    }
    const lineNumber = searchResults[newIndex];
    if (virtuoso.current) {
      virtuoso.current.scrollToIndex({
        index: lineNumber,
        align: "start",
      });
      setCurrentSearchResIndex(newIndex);
    }
  };

  const updateDataMap = (range: Range) => {
    const { startIndex, endIndex } = range;
    if (navigator.current && startIndex !== endIndex) {
      getLines(navigator.current, startIndex, endIndex + 1).then((lines: Array<string>) => {
        const newDataMap = new Map();
        lines.forEach((line: string, i: number) => {
          const index = startIndex + i;
          newDataMap.set(index, line);
        });
        setDataMap(newDataMap);
      });
    }
  };

  const updatedataMapDebounced = _.debounce(updateDataMap, DEBOUNCE_DELAY);

  // tslint:disable-next-line: no-shadowed-variable
  const setNavigator = (file: File) => {
    if (!navigator.current) {
      navigator.current = new LineNavigator(file, {
        encoding: "utf8",
        chunkSize: 3 * 1024 * 1024,
        throwOnLongLines: true,
      });
    }
  };

  const fileLoad = () => {
    if (props.file) {
      setLoading(true);
      setNavigator(props.file);
      if (navigator.current) {
        getLastLine(navigator.current).then((count: number) => {
          setTotal(count);
          setLoading(false);
        });
      }
    }
  };

  const onSearch = () => {
    if (
      navigator.current &&
      props.search.length >= (props.searchMinLength ? props.searchMinLength : 3)
    ) {
      setLoading(true);
      navigator.current.findAll(
        new RegExp(props.search, "i"),
        0,
        props.searchLimit ? props.searchLimit : 100,
        (_err, _index, _limitHit, results) => {
          setSearchResults(results.map((r) => r.index));
          setLoading(false);
        }
      );
    }
  };

  const updateExpandedLines = (index: number) => (isExpanded: boolean) => {
    setExpandedLines({
      ...expandedLines,
      [index]: isExpanded,
    });
  };

  React.useLayoutEffect(() => {
    if (wrapperDivRef && wrapperDivRef.current) {
      const wrapperDiv = wrapperDivRef.current.getBoundingClientRect();
      setHeight(wrapperDiv.height);
    }
  }, [wrapperDivRef]);

  React.useEffect(fileLoad, [props.file]);
  React.useEffect(onSearch, [props.search]);

  const renderItem = (index: number) => {
    let data = "";
    let prevTimeStamp = 0;

    if (dataMap) {
      const mapRowData = dataMap.get(index);
      const mapPrevRowData = dataMap.get(index - 1);
      if (mapRowData) {
        data = mapRowData;
      }
      if (mapPrevRowData) {
        let prevData = null;
        try {
          prevData = JSON.parse(mapPrevRowData);
        } catch (error) {
          //
        }
        if (prevData && prevData.timestamp) {
          prevTimeStamp = new Date(prevData.timestamp).getTime();
        }
      }
    }
    return (
      <LogViewerRow
        key={`LogViewerRow-${index}`}
        data={data}
        index={index}
        prevTimeStamp={prevTimeStamp}
        getLabelColor={getLabelColor}
        isExpanded={expandedLines[index]}
        setExpanded={updateExpandedLines(index)}
      />
    );
  };

  const hasSearchResults = searchResults.length > 0;

  return (
    <div className={props.classes.wrapper} ref={wrapperDivRef}>
      <Virtuoso
        ref={virtuoso}
        key={`Virtuoso-${file.current ? file.current.lastModified : ""}`}
        className={props.classes.virtuoso}
        style={{ height: height }}
        totalCount={total}
        item={renderItem}
        // tslint:disable-next-line: no-any
        ItemContainer={({ children, ...rowprops }: any) => {
          const isInSearch = searchResults.includes(rowprops["data-index"]);
          return (
            <div
              {...rowprops}
              className={`${props.classes.logRow} ${isInSearch ? props.classes.logRowSearch : ""}`}
            >
              {children}
            </div>
          );
        }}
        rangeChanged={updatedataMapDebounced}
      />
      <div
        className={`${props.classes.spinnerWrapper} ${
          isLoading ? props.classes.spinnerWrapperVisible : ""
        }`}
      >
        <CircularProgress />
      </div>
      {hasSearchResults ? (
        <div className={props.classes.searchActions}>
          <div className={props.classes.resultsCount}>
            {`${currentSearchResIndex + 1}/${searchResults.length}`}
          </div>
          <ButtonGroup variant="contained" color="primary">
            <Button startIcon={<UpIcon />} onClick={scrollToPrev}>
              {"Prev"}
            </Button>
            <Button startIcon={<DownIcon />} onClick={scrollToNext}>
              {"Next"}
            </Button>
            <Button startIcon={<CloseIcon />} onClick={dropSearch}>
              {"Clear"}
            </Button>
          </ButtonGroup>
        </div>
      ) : null}
    </div>
  );
};

const decorate = withStyles(styles);

export default decorate(LogViewer);
