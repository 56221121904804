import * as React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

type ConrfirmDialogProps = {
  handleConfirm: () => void;
  handleClose?: () => void;
  open: boolean;
  title?: string;
  content?: string;
};

const ConfirmDialog = (props: ConrfirmDialogProps) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {props.title && <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>}
        {props.content && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{props.content}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={props.handleConfirm} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
