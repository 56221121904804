import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Grid, withStyles, Typography, Button } from "@material-ui/core";
import AxiosFactory from "src/services/AxiosFactory";
import ApiPath from "src/constants/ApiPath";
import { connect } from "react-redux";
import { SetNotification } from "src/actions/notificationAction";
import { Login, Main } from "src/constants/RoutesNames";
import AuthService from "../../../services/AuthService";
import { AxiosResponse } from "axios";
import { FetchUser } from "../../../actions/authAction";

type RouteParams = {
  objectId: string;
};

type Props = {
  // tslint:disable-next-line: no-any
  classes: any;
  fetchUser: () => any;
  setNotification(message: string): void;
};

type State = {
  error: string;
  confirming: boolean;
};

class ConfirmAccount extends React.Component<Props & RouteComponentProps<RouteParams>, State> {
  constructor(props: Props & RouteComponentProps<RouteParams>) {
    super(props);

    this.state = {
      error: "",
      confirming: true,
    };
  }

  async componentDidMount() {
    try {
      const axiosFactory = new AxiosFactory();
      await axiosFactory.axios
        .get(`${ApiPath.api.user.confirmAccount}/${this.props.match.params.objectId}`)
        .then((response: AxiosResponse<LoginResponse>) => {
          if (response.status === 200 && response?.data?.token && response?.data?.expires) {
            AuthService.setToken(response.data.token);
            AuthService.setExpires(response.data.expires);
          }
        });
    } catch (err) {
      this.props.history.push(Login.SignIn);
      this.props.setNotification("Failed to confirm account. Token is invalid");
    } finally {
      this.setState({
        confirming: false,
      });
    }
  }

  continue = async () => {
    const user = await this.props.fetchUser();
    if (user) {
      this.props.history.push(Main);
    } else {
      this.props.history.push(Login.SignIn);
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.confirming) {
      return (
        <Grid
          container={true}
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.root}
        >
          <Grid item={true}>
            <Typography variant="h3">Waiting for confirmation</Typography>
          </Grid>
          <Grid item={true} style={{ marginTop: 30 }}>
            <Typography variant="h6">Please wait...</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid
        container={true}
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.root}
      >
        <Grid item={true}>
          <Typography variant="h3">Thanks for signing up</Typography>
        </Grid>
        <Grid item={true} style={{ marginTop: 30 }}>
          <Typography variant="h6">Your account is ready.</Typography>
        </Grid>
        <Grid item={true} style={{ marginTop: 30 }}>
          <Button color="secondary" variant="contained" onClick={this.continue}>
            Continue
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const decorate = withStyles({
  root: {
    height: "inherit",
    paddingBottom: 20,
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10%",
  },
  logoImage: {
    height: "auto",
    maxHeight: 50,
  },
});

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any) => ({
  fetchUser: () => dispatch(FetchUser()),
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect(null, mapDispatchToProps)(decorate(ConfirmAccount));
