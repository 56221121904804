import * as React from "react";
import AxiosFactory from "src/services/AxiosFactory";
import ApiPath from "src/constants/ApiPath";
import * as moment from "moment";
import { RibbonChart } from "./RibbonChart";

type MonitorRibbonProps = {
  monitor: any;
  width?: any;
  classes?: any;
};

type MonitorRibbonState = {
  series: any[];
};

type MonitorRunData = {
  day: string;
  completed: number;
  warnings: number;
  failure: number;
  serviceFailure: number;
};

export default class MonitorRibbon extends React.Component<MonitorRibbonProps, MonitorRibbonState> {
  constructor(props: MonitorRibbonProps) {
    super(props);

    this.state = {
      series: [],
    };
  }

  async componentDidMount() {
    await this.fetchStatus();
  }

  prepareSeries(data: MonitorRunData[]): any[] {
    const result = {
      completed: [] as number[],
      failure: [] as number[],
      warnings: [] as number[],
      serviceFailure: [] as number[],
      notRun: [] as number[],
    };
    const now = moment(Date.now());
    const dayNames = [];
    let i = 1;
    while (i < 31) {
      const day = now.subtract(1, "day").format("YYYYMMDD");
      dayNames.push(day);
      i++;
    }

    for (const dayName of dayNames) {
      const day_in_data = data.find((item: MonitorRunData) => item.day === dayName);
      if (!day_in_data) {
        result.completed.push(0);
        result.warnings.push(0);
        result.failure.push(0);
        result.serviceFailure.push(0);
        result.notRun.push(1); // to show empty days on chart
      } else {
        result.completed.push(day_in_data.completed);
        result.warnings.push(day_in_data.warnings);
        result.failure.push(day_in_data.failure);
        result.serviceFailure.push(day_in_data.serviceFailure);
        result.notRun.push(0);
      }
    }

    const series = [];
    series.push({ name: "Failure/Timeout", data: result.failure, color: "rgb(162, 42, 33)" });
    series.push({ name: "Warnings", data: result.warnings, color: "rgb(241, 205, 43)" });
    series.push({ name: "Completed", data: result.completed, color: "rgb(81, 142, 69)" });
    series.push({
      name: "Service Failure",
      data: result.serviceFailure,
      color: "rgb(159, 159, 159)",
    });
    series.push({ name: "Not Running", data: result.notRun, color: "rgb(159, 159, 159)" });

    // RED - failed or timeout
    // YELLOW - warning
    // GREEN - success
    // GRAY - not running/infrastructure failures that got bubbled to the monitor (=retried and failed again due to infrastructure)
    return series;
  }

  dayNames = () => {
    let days = 1;
    const res = [];
    while (days <= 30) {
      res.push(`${days === 1 ? days + " day" : days + " days"} ago`);
      days++;
    }
    return res;
  };

  render() {
    const { width, classes } = this.props;
    return (
      <div className={classes?.ribbonContainer} style={{ width: width ? width : 350 }}>
        <RibbonChart dayNames={this.dayNames()} series={this.state.series} />
      </div>
    );
  }

  private async fetchStatus() {
    const monitor = this.props.monitor;

    if (monitor && monitor.name) {
      const axiosFactory = new AxiosFactory();
      const result: any[] = await axiosFactory.axios
        .get(`${ApiPath.api.monitors}/${monitor._id}/status`)
        .then((res) => res.data)
        .catch((err) => err.response);
      const data = result.length ? result : [];
      let dataSeries: any;
      setTimeout(() => {
        dataSeries = this.prepareSeries(data);
        this.setState({
          series: dataSeries,
        });
      }, 300);
    }
  }
}
