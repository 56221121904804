// tslint:disable:no-any
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { Main, Login as LoginRoutes } from "../../../constants/RoutesNames";
import AuthService from "../../../services/AuthService";
import { FetchUser } from "../../../actions/authAction";
import { SetNotification } from "src/actions/notificationAction";
import View from "./View";

export interface ISignInPageState {
  remindOpened: boolean;
  subscribeOpened: boolean;
  emailRemind: string;
  isLoading: boolean;
}

export interface ILoginForm {
  email: string;
  password: string;
}

type SignInPageDispatch = {
  // tslint:disable-next-line: no-any
  fetchUser: () => any;
  setNotification(message: string): void;
};

export class SignInPage extends React.Component<
  SignInPageDispatch & RouteComponentProps<{}>,
  ISignInPageState
> {
  constructor(props: SignInPageDispatch & RouteComponentProps<{}>) {
    super(props);

    this.state = {
      remindOpened: false,
      subscribeOpened: false,
      emailRemind: "",
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.location?.state) {
      const state: any = this.props.location.state;
      if (state.isPasswordRemind) {
        this.setState({
          remindOpened: true,
          emailRemind: state.emailRemind,
        });
      }
      if (state.isSubscribeSent) {
        this.setState({
          subscribeOpened: true,
          emailRemind: state.emailRemind,
        });
      }
      this.props.history.replace({ pathname: LoginRoutes.SignIn, state: {} });
    }
  }

  openSnackbar = (message: string) => {
    this.props.setNotification(message);
  };

  public render(): JSX.Element {
    return <View closeModal={this.closeModal} onSubmit={this.onSubmit} {...this.state} />;
  }

  private async onSubmit(values: ILoginForm): Promise<void | { [x: string]: string }> {
    try {
      this.setState({ isLoading: true });
      await AuthService.login(values.email, values.password);
      const user = await this.props.fetchUser();
      this.setState({ isLoading: false });

      if (user.data && user.data.role === "pending") {
        this.openSnackbar("The user is not confirmed. Please confirm the user.");
        await AuthService.logout();
        return;
      }
      const redirectUrl = AuthService.getRedirectUrl();
      AuthService.setRedirectUrl("");
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        this.props.history.push(Main);
      }
    } catch (err) {
      this.setState({ isLoading: false });

      return { password: err.message };
    }
  }

  private closeModal(): void {
    this.setState({
      remindOpened: false,
      subscribeOpened: false,
      isLoading: false,
    });
  }
}

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): SignInPageDispatch => ({
  fetchUser: () => dispatch(FetchUser()),
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

// tslint:disable-next-line:no-any
export default connect<any, SignInPageDispatch, any>(null, mapDispatchToProps)(SignInPage);
