import * as React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles, createStyles } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LOGO_IMG from "../../../../assets/images/logo_text3_1.png";

class NavBar extends React.Component<
  RouteComponentProps<{}> & WithStyles<"appBar" | "toolbar" | "logoContainer" | "logoImage">
> {
  public render(): JSX.Element {
    const { classes } = this.props;

    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoContainer} onClick={() => this.props.history.push("/")}>
            <img className={classes.logoImage} src={LOGO_IMG} alt="logo-img" />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const styles = {
  toolbar: {
    height: "100%",
  },
  appBar: {
    height: 70,
    backgroundColor: "#FDFDFD",
    boxShadow: "none",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10%",
  },
  logoImage: {
    height: "auto",
    maxHeight: 50,
  },
};

const decorate = withStyles(() => createStyles(styles));

export default withRouter<RouteComponentProps<{}>>(decorate(NavBar));
