// tslint:disable:no-any

import * as React from "react";
import { Field, Form } from "react-final-form";
import Dropzone from "react-dropzone";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import ReCAPTCHA from "react-google-recaptcha";
import TextFieldControl from "../../../components/form-elements/TextFieldControl";
import FormValidators from "../../../helpers/form-validators";
import { Login as LoginRoutes } from "../../../constants/RoutesNames";
import MediaQuality from "../../../components/Test/MediaQuality";
import setFieldTouched from "final-form-set-field-touched";

interface AnalyzeDumpFormProps {
  handleDrop: (acceptedFiles: any, rejectedFiles: any) => void;
  onSubmit: (values: any) => void;
  onRecaptchaChange: (e: any) => void;
  acceptedFiles: Array<any>;
  rejectedFiles: Array<any>;
  recaptchaKey: string;
}

type StyledComponent = WithStyles<
  | "signInButton"
  | "buttonContainer"
  | "formContainer"
  | "forgotPasswordContainer"
  | "link"
  | "mediaQualityContainer"
  | "dropzone"
  | "dropzoneCard"
  | "browseButton"
  | "recaptchaContainer"
  | "errorLabel"
>;

class AnalyzeDumpForm extends React.Component<AnalyzeDumpFormProps & StyledComponent> {
  dropzoneRef: any = null;

  handleSubmitDecorator(event: any, originalCallback: any, reset: any) {
    const result = originalCallback(event);
    if (result && result.then) {
      return result.then((_res: any) => {
        reset();
      });
    }
    return result;
  }

  public render(): JSX.Element {
    const {
      classes,
      onSubmit,
      acceptedFiles,
      handleDrop,
      onRecaptchaChange,
      recaptchaKey,
    } = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        mutators={{
          setRecaptchaValue: (values, state, { changeValue }) => {
            changeValue(state, "reCaptchaResponse", () => values[0]);
          },
          setFieldTouched,
        }}
        render={({ handleSubmit, mutators, submitErrors, reset }) => (
          <form onSubmit={(e) => this.handleSubmitDecorator(e, handleSubmit, reset)}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Typography variant="subtitle1">Upload your log file</Typography>
              </Grid>
              <Grid item={true} xs={8}>
                <Dropzone
                  multiple={false}
                  ref={(node: any) => {
                    this.dropzoneRef = node;
                  }}
                  disableClick={true}
                  accept="text/plain"
                  onDrop={handleDrop}
                  className={classes.dropzone}
                >
                  <Card className={classes.dropzoneCard}>
                    <Typography variant="subtitle1">
                      {acceptedFiles.length ? acceptedFiles[0].name : "Browse or Drag & Drop File"}
                    </Typography>
                  </Card>
                </Dropzone>
              </Grid>
              <Grid item={true} xs={4}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => this.dropzoneRef.open()}
                  className={classes.browseButton}
                >
                  Browse
                </Button>
              </Grid>
              {submitErrors && (
                <Grid item={true} xs={12}>
                  {submitErrors.file && !!!acceptedFiles.length && (
                    <Typography color="secondary" align="center" className={classes.errorLabel}>
                      {submitErrors.file}
                    </Typography>
                  )}
                  {submitErrors.fileSize && acceptedFiles.length && (
                    <Typography color="secondary" align="center" className={classes.errorLabel}>
                      {submitErrors.fileSize}
                    </Typography>
                  )}
                </Grid>
              )}
              <Grid item={true} xs={8}>
                <Typography align="left">
                  Help us improve WebRTC by ranking your session's quality
                </Typography>
              </Grid>
              <Grid item={true} xs={4} className={classes.mediaQualityContainer}>
                <Field
                  name="mediaQuality"
                  type="text"
                  // tslint:disable-next-line:no-any
                  component={(propsField: any) => (
                    <MediaQuality
                      rating={propsField.input.value}
                      setRating={(rate) => {
                        propsField.input.onChange(rate);
                        mutators.setFieldTouched("mediaQuality", true);
                      }}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  component={TextFieldControl}
                  type="text"
                  multiline={true}
                  rows="5"
                  name="comments"
                  placeholder={
                    // tslint:disable-next-line:max-line-length
                    "To makes sure we can later check how we did, please add a short description of the scenario you are testing here"
                  }
                  validate={FormValidators.required()}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Field
                  component={TextFieldControl}
                  type="text"
                  name="email"
                  label="Your e-mail"
                  validate={FormValidators.composeValidators(
                    FormValidators.required(),
                    FormValidators.isEmail
                  )}
                />
              </Grid>
              <Grid item={true} xs={12} className={classes.recaptchaContainer}>
                <ReCAPTCHA
                  sitekey={"6LdYmQMTAAAAAOSCfm_3inwAMDdt4rR3GfvFpvl8"}
                  onChange={(key: string) => onRecaptchaChange(key)}
                />
              </Grid>
              {submitErrors && (
                <Grid item={true} xs={12}>
                  {submitErrors.reCaptchaResponse && !recaptchaKey && (
                    <Typography color="secondary" align="center" className={classes.errorLabel}>
                      {submitErrors.reCaptchaResponse}
                    </Typography>
                  )}
                </Grid>
              )}
              <Grid item={true} xs={12}>
                <Button type="submit" variant="contained" color="secondary">
                  Send for Analyze
                </Button>
              </Grid>
              <Grid item={true} xs={6}>
                <Link className={classes.link} to={LoginRoutes.Subscribe}>
                  <Typography color="secondary" align="left">
                    Subscribe for updates
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

const decorate = withStyles((_theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    forgotPasswordContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    signInButton: {
      width: "auto",
    },
    formContainer: {
      paddingTop: 0,
    },
    link: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:active": {
        textDecoration: "none",
      },
    },
    mediaQualityContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    dropzone: {
      height: "100%",
    },
    dropzoneCard: {
      border: "none",
      height: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingLeft: 10,
    },
    browseButton: {
      marginLeft: "auto",
      display: "block",
    },
    recaptchaContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    errorLabel: {
      color: "#a22a21",
    },
  })
);

export default decorate(AnalyzeDumpForm);
