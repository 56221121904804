import * as React from "react";
import GridControl from "./GridControl";
import withBreadcrumb from "../../components/withBreadcrumb";

class MonitorRunHistory extends React.Component {
  render() {
    return <GridControl {...this.props} />;
  }
}

export default withBreadcrumb(MonitorRunHistory);
