import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { RouteComponentProps, withRouter } from "react-router";
import { RunObject } from "./index";

type GridControlProps = {
  runNames: Array<RunObject>;
  selectedRunName?: RunObject | null;
  generateNewRunName: () => void;
  assignToHandler: () => void;
  // tslint:disable-next-line:no-any
  onSelectedRunNameChange: (event: any) => void;
  validated: boolean;
  anySelected: boolean;
  assigning: boolean;
};

export class GridControl extends React.PureComponent<
  RouteComponentProps<{}> & GridControlProps & WithStyles
> {
  upload: HTMLInputElement | null;

  render() {
    const {
      classes,
      runNames,
      generateNewRunName,
      assignToHandler,
      selectedRunName,
      onSelectedRunNameChange,
      validated,
      anySelected,
      assigning,
    } = this.props;

    return (
      <Toolbar className={classes.root}>
        <div>
          <Typography variant="body2">Please select the test to assign.</Typography>
          {validated && !anySelected && (
            <Typography className={classes.errorLabel}>
              You must select a test to assign.
            </Typography>
          )}
        </div>
        <div className={classes.controlsContainer}>
          <TextField
            select={true}
            SelectProps={{
              autoWidth: false,
            }}
            value={selectedRunName ? selectedRunName.runName : ""}
            className={classes.selectField}
            onChange={onSelectedRunNameChange}
            label={"Select machine"}
            margin="normal"
            helperText={validated && !selectedRunName && "Machine is required."}
            error={validated && !selectedRunName}
          >
            {runNames.map((runObj: RunObject) => (
              <MenuItem key={runObj.runName} value={runObj.runName}>
                {runObj.runName}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            data-cy="generate-to-btn"
            onClick={generateNewRunName}
          >
            Generate New
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            data-cy="assign-to-btn"
            onClick={assignToHandler}
            disabled={assigning}
          >
            Assign To
          </Button>
        </div>
      </Toolbar>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
    },
    controlsContainer: {
      justifyContent: "flex-end",
      alignItems: "center",
      display: "flex",
    },
    button: {
      marginTop: 20,
      marginLeft: theme.spacing(2),
    },
    selectField: {
      width: 245,
    },
    errorLabel: {
      color: "#f44336",
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}> & GridControlProps>(decorate(GridControl));
