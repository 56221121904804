import * as React from "react";
import { Theme, withStyles, createStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import Videocam from "@material-ui/icons/Videocam";
import Headset from "@material-ui/icons/Headset";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward";
import * as moment from "moment";

import StatusIcon from "../../../../components/Test/StatusIcon";
import { checkNan, formatDuration } from "../../../../helpers/testDetails";
import { InfoRow } from "./InfoRow";

interface ExpandChannelDetailsProps {
  // tslint:disable-next-line:no-any
  classes: any;
  // tslint:disable-next-line:no-any
  channel: any;
}

interface ExpandDetailsState {
  expanded: boolean;
}

type Props = ExpandChannelDetailsProps;

class ExpandChannelDetails extends React.Component<Props, ExpandDetailsState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.onExpand = this.onExpand.bind(this);
  }

  shouldComponentUpdate(_nextProps: Props, nextState: ExpandDetailsState) {
    return nextState.expanded !== this.state.expanded;
  }

  onExpand() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    const { channel, classes } = this.props;
    const isVideo = channel.channelType === "video";
    const isAudio = channel.channelType === "audio";
    const videoResolution = !!channel.data.videoResolution;
    const hasVideoData =
      !!channel.data.videoFramerate ||
      (!!channel.data.fps && channel.data.fps.hasOwnProperty("average")) ||
      videoResolution;
    const roundTrip = !!channel.data.rtt && !!channel.data.rtt.count;

    const getStartTime = (startTime: any) => {
      return startTime ? moment(startTime).format("HH:mm:ss") : "";
    };

    const getDuration = (samples: number, dataFrequency: number) => {
      return samples ? formatDuration(samples * 1000 * (dataFrequency || 1), "DHMS") : "0";
    };

    return (
      <Accordion
        style={{
          borderLeft: `7px solid ${channel.color}`,
        }}
        className={classes.expansionPanel}
        defaultExpanded={false}
        expanded={this.state.expanded}
        onChange={this.onExpand}
        // CollapseProps={{
        //   classes: {
        //     entered: classes.expandPanelDetails,
        //   },
        // }}
      >
        <AccordionSummary className={classes.expandHead} expandIcon={<ExpandMoreIcon />}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} md={3} className={classes.expandHeadFist}>
              {isAudio && <Headset className={classes.headsetIcon} />}
              {isVideo && <Videocam className={classes.cameraIcon} />}
              {channel.isOutgoing ? (
                <ArrowForward className={classes.arrowForwardIcon} />
              ) : (
                <ArrowBack className={classes.arrowBackIcon} />
              )}
              <Typography variant="body2">{channel.data.audioCoddec}</Typography>
            </Grid>
            <Grid item={true} md={2} xs={6} className={classes.expandHeadSecond}>
              <Typography variant="body2">
                {checkNan(
                  channel.data.bytes.average?.toLocaleString("en", {
                    maximumFractionDigits: 0,
                  })
                )}
              </Typography>
            </Grid>
            <Grid item={true} md={1} xs={6} className={classes.expandHeadFist}>
              <Typography variant="body2">kbps</Typography>
            </Grid>
            <Grid item={true} xs={12} md={6} className={classes.expandHeadSecond}>
              <Typography variant="body2">{channel.data.channelDisplayName}</Typography>
              {channel.error && (
                <Typography
                  style={{
                    marginLeft: 5,
                  }}
                >
                  {`${channel.error}`}
                </Typography>
              )}
              <StatusIcon
                status={channel.status}
                style={{
                  marginRight: -8,
                  marginLeft: 8,
                }}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container={true} spacing={2}>
            <Grid item={true} container={true} direction="row">
              <Grid direction="column" item={true} style={{ marginRight: 90 }}>
                <InfoRow
                  classes={classes}
                  items={["Start time", getStartTime(channel.data.startTime)]}
                />
                <InfoRow
                  classes={classes}
                  items={["Duration", getDuration(channel.data.samples, channel.dataFrequency)]}
                />
                {/*<InfoRow classes={classes} items={["Implementation", "libvpx"]} /> // TODO add for #5132*/}
              </Grid>
              {!!channel.data.connection && (
                <Grid direction="column">
                  <InfoRow
                    classes={classes}
                    items={["Connection", channel.data.connection.transport]}
                  />
                  <InfoRow
                    classes={classes}
                    items={["Local IP", channel.data.connection.localAddress]}
                  />
                  <InfoRow
                    classes={classes}
                    items={["Remote IP", channel.data.connection.remoteAddress]}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item={true}>
              <InfoRow classes={classes} isHeader items={["", "Total", "Average", "Variance"]} />
              <InfoRow
                classes={classes}
                items={[
                  "Data (Kbits)",
                  ((channel.data.totalBytes * 8) / 1000)?.toLocaleString("en", {
                    maximumFractionDigits: 0,
                  }),
                  channel.data.bytes.average?.toLocaleString("en", {
                    maximumFractionDigits: 0,
                  }),
                  channel.data.bytes.variance?.toLocaleString("en", {
                    maximumFractionDigits: 3,
                  }),
                ]}
              />
              <InfoRow
                classes={classes}
                items={[
                  "Packets",
                  channel.data.totalPackets &&
                    channel.data.totalPackets?.toLocaleString("en", {
                      maximumFractionDigits: 0,
                    }),
                  channel.data.packets.average?.toLocaleString("en", {
                    maximumFractionDigits: 0,
                  }),
                  channel.data.packets.variance?.toLocaleString("en", {
                    maximumFractionDigits: 3,
                  }),
                ]}
              />
              {!!channel.data.avgPacketSize && channel.data.avgPacketSize.count > 0 && (
                <InfoRow
                  classes={classes}
                  items={[
                    "Average packet size (bytes)",
                    channel.data.avgPacketSize?.toLocaleString("en", {
                      maximumFractionDigits: 0,
                    }),
                  ]}
                />
              )}
              <InfoRow
                classes={classes}
                items={[
                  "Jitter (msec)",
                  "",
                  channel.data.jitter.average?.toLocaleString("en", {
                    maximumFractionDigits: 0,
                  }),
                  channel.data.jitter.variance?.toLocaleString("en", {
                    maximumFractionDigits: 3,
                  }),
                ]}
              />
              {channel.isOutgoing && roundTrip && (
                <InfoRow
                  classes={classes}
                  items={[
                    "Round trip (msec)",
                    "",
                    channel.data.rtt.average
                      ? channel.data.rtt.average.toLocaleString("en", {
                          maximumFractionDigits: 0,
                        })
                      : "No data",
                    channel.data.rtt.variance
                      ? channel.data.rtt.variance.toLocaleString("en", {
                          maximumFractionDigits: 3,
                        })
                      : "No data",
                  ]}
                />
              )}
              {channel.channelType !== "data" && channel.packetLossPCT > 0.09 && (
                <InfoRow
                  classes={classes}
                  items={[
                    "Packets loss (%)",
                    channel.packetLossPCT?.toLocaleString("en", {
                      maximumFractionDigits: 1,
                    }),
                  ]}
                />
              )}
              {channel.channelType !== "data" && channel.data.packetLoss > 0 && (
                <InfoRow
                  classes={classes}
                  items={[
                    "Packets loss (#)",
                    channel.data.packetLoss.toLocaleString("en", {
                      maximumFractionDigits: 1,
                    }),
                  ]}
                />
              )}
            </Grid>
            {isVideo && (
              <Grid item={true} container={true}>
                {hasVideoData && (
                  <InfoRow
                    classes={classes}
                    items={[
                      "Frame rate",
                      channel.data.fps.average?.toLocaleString("en", {
                        maximumFractionDigits: 0,
                      }),
                    ]}
                  />
                )}
                <InfoRow
                  classes={classes}
                  items={[
                    "Video resolution",
                    channel.data.frameWidth
                      ? channel.data.frameWidth + "x" + channel.data.frameHeight
                      : "No data",
                  ]}
                />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const IconFontSize = {
  width: "1.2rem",
  height: "1.2rem",
  marginRight: "0.4rem",
};

const styles = (_theme: Theme) =>
  createStyles({
    panelRoot: {
      width: "calc(100% + 1.5rem)",
    },
    panelExpanded: {
      margin: 0,
    },
    panelTitle: {
      background: "#dde4eb",
      height: "3rem",
      color: "#444444",
      padding: "0.7em 1.1em 0.7em 1.1em",
      fontSize: "1.1em",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    panelBody: {
      padding: "1.5rem 1.3rem",
    },
    headerText: {
      color: "",
    },
    resultTableHead: {
      marginBottom: 5,
    },
    expandIcon: {
      fontSize: "2rem",
    },
    expansionPanel: {
      width: "100%",
      display: "block",
    },
    arrowBackIcon: {
      ...IconFontSize,
      color: "#3577c1",
    },
    arrowForwardIcon: {
      ...IconFontSize,
      color: "#a22a21",
    },
    cameraIcon: {
      ...IconFontSize,
      color: "#5F5F5F",
    },
    headsetIcon: {
      ...IconFontSize,
      color: "#5F5F5F",
    },
    expandPanelDetails: {
      overflow: "visible",
    },
    expandHeadFist: {
      display: "inline-flex",
      alignItems: "center",
    },
    expandHeadSecond: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    expandDetailHead: {},
    connectionString: {
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
    },
    connectionStringIcon: {
      margin: "0 5px",
    },
    rowItem: {
      width: 160,
      padding: 2,
      textAlign: "right",
      marginRight: 10,
    },
    rowItemLabel: {
      width: 120,
      padding: 2,
    },
  });

const decorate = withStyles(styles);

export default decorate(ExpandChannelDetails);
