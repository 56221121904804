import Grid from "@material-ui/core/Grid";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "../Tooltip";

const WarnStyle = {
  color: "#F1CD2B",
  fontWeight: 500,
} as React.CSSProperties;

const ErrorStyle = {
  color: "#a22a21",
  fontWeight: 500,
} as React.CSSProperties;

const CommentsContainer = {
  paddingTop: 0,
  paddingBottom: 0,
} as React.CSSProperties;

const CommentsWrap = {
  marginBottom: 10,
  marginTop: 10,
} as React.CSSProperties;

// tslint:disable-next-line:no-any
export default ({ data }: any) => {
  if (data.comments || data.textError || data.rtcSetAdditionalInfo) {
    data.textError = data.textError
      ? data.textError.replace('href="app/', 'href="/app/')
      : undefined;
    return (
      <Grid container={true} spacing={0}>
        {data.comments && (
          <Grid item={true} xs={12} style={CommentsContainer}>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <Tooltip
                content={"Custom comments added to overview by user"}
                display={true}
                displayType="block"
              >
                <Typography style={{ marginBottom: 5 }} variant="subtitle1">
                  Comments
                </Typography>
              </Tooltip>
            </Grid>
            <pre style={CommentsWrap}>{data.comments}</pre>
          </Grid>
        )}
        {data.textError && (
          <Grid item={true} xs={12}>
            <Typography variant="body2">
              <span
                id="rtc-error-line"
                style={data.textErrorLevel !== "warn" ? ErrorStyle : WarnStyle}
                dangerouslySetInnerHTML={{ __html: data.textError }}
              >
                {/* {data.textError} */}
              </span>
            </Typography>
          </Grid>
        )}
        {data.rtcSetAdditionalInfo && (
          <Grid item={true} xs={12}>
            <Typography variant="body2">
              <span>{data.rtcSetAdditionalInfo}</span>
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return null;
  }
};
