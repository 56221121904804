import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

type IActiveMonitor = {
  active: true;
  executeTest: any;
  lastRunDate: string;
  lastStatus:
    | "completed"
    | "warnings"
    | "finished"
    | "timeout"
    | "failure"
    | "service-failure"
    | "started";
  name: string;
  runCount: number;
  _id: string;
};

interface IActiveMonitorsBar {
  active?: number;
  total?: number;
  monitors: IActiveMonitor[];
}

const ActiveMonitorsBar = ({ active = 0, total = 0, monitors }: IActiveMonitorsBar) => {
  const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
      root: {
        height: 12,
        borderRadius: 6,
        display: "flex",
        overflow: "hidden",
        "& > div": {
          height: 12,
          flex: 1,
        },
      },
      background: {
        backgroundColor: "rgb(217, 236, 218)",
      },
      warning: {
        backgroundColor: "rgb(241, 205, 43)",
      },
      completed: {
        backgroundColor: "rgb(81, 142, 69)",
      },
      failure: {
        backgroundColor: "rgb(162, 42, 33)",
      },
      serviceFailure: {
        backgroundColor: "rgb(159, 159, 159)",
      },
      notRun: {
        backgroundColor: "rgb(159, 159, 159)",
      },
    })
  );

  const classes = useStyles();
  if (!active || !total) {
    return null;
  }

  const getBarClass = (status: IActiveMonitor["lastStatus"]) => {
    switch (status) {
      case "warnings":
        return classes.warning;
      case "completed":
        return classes.completed;
      case "finished":
        return classes.completed;
      case "failure":
        return classes.failure;
      case "timeout":
        return classes.failure;
      case "service-failure":
        return classes.serviceFailure;
      default:
        return classes.notRun;
    }
  };

  const filled = () => {
    return monitors.map((monitor) => {
      return (
        <Tooltip
          key={monitor._id}
          title={`Name: "${monitor.name}" Last Status: "${monitor.lastStatus}"`}
          placement="top"
        >
          <div className={getBarClass(monitor.lastStatus)} />
        </Tooltip>
      );
    });
  };

  const empty = () => {
    const arr: number[] = new Array(total - active).fill(0);
    return arr.map((item, index) => {
      return <div key={index + item} className={classes.background} />;
    });
  };

  return (
    <Grid item={true} xs={12}>
      <div className={classes.root}>
        {filled()}
        {empty()}
      </div>
    </Grid>
  );
};

export default ActiveMonitorsBar;
