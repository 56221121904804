import * as React from "react";
import * as classnames from "classnames";
import * as _ from "lodash";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Toolbar from "@material-ui/core/Toolbar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

import ChartSize, {
  ChartSizeProvider,
  chartSizes,
} from "src/components/Chart/components/ChartSize";

import Chart from "src/components/Chart";
// import Chart from "./Chart";
import { mediaType, charts, chartTypeMapper, medias, mediaIcons } from "./constants";

interface IChartsMedias {
  audio: any;
  video: any;
}

interface IChartsProps extends IChartsMedias {
  showAudio?: boolean;
  showVideo?: boolean;
}

interface IIterationChartProps extends IChartsMedias {
  name: string;
  media: string;
  chartTypes: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
    },
    chart: {},
    chartToolbar: {
      minHeight: 24,
      padding: `0 ${theme.spacing(1)}px`,
      display: "flex",
      justifyContent: "space-between",
      background: "#fff",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
    },
    divider: {
      margin: "16px -16px",
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      marginRight: theme.spacing(1),
      "& > button": {
        marginRight: theme.spacing(1),
      },
      marginBottom: theme.spacing(1),
    },
    labels: {
      display: "flex",
      flexDirection: "row",
      marginRight: theme.spacing(1),
    },
    label: {
      fontWeight: 400,
      "&:hover": {
        cursor: "pointer",
      },
    },
    selectedLabel: {
      fontWeight: 600,
    },
    spacer: {
      fontWeight: 700,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  })
);

const VSpacer = ({ idx, length }: any) => {
  const classes = useStyles();
  if (idx === length - 1) {
    return null;
  }
  return <span className={classes.spacer}>|</span>;
};

const Charts = (props: IChartsProps) => {
  const { audio, video, showAudio, showVideo } = props;
  const classes = useStyles();
  const [media, setMedia] = React.useState<string>(showVideo ? mediaType.video : mediaType.audio);

  React.useEffect(() => {
    if (showVideo) {
      setMedia(mediaType.video);
    } else {
      setMedia(mediaType.audio);
    }
  }, [showAudio, showVideo]);

  const handleMediaChange = (value: string) => {
    setMedia(value);
  };

  if (
    (Object.keys(audio?.charts || {}).length === 0 &&
      Object.keys(video?.charts || {}).length === 0) ||
    (!showAudio && !showVideo)
  ) {
    return null;
  }

  return (
    <ChartSizeProvider>
      <Grid container spacing={2}>
        <Grid item={true} xs={12}>
          <Card className={classes.card}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="subtitle1">Timeline</Typography>
              <div className={classes.buttons}>
                <ChartSize defaultSize={chartSizes.Compact} />
                <ButtonGroup color="default">
                  {medias.map((value: string) => {
                    const name = _.startCase(value);
                    const noData = !props[`show${name}`];
                    return (
                      <Button
                        key={value}
                        onClick={() => handleMediaChange(value)}
                        disabled={noData}
                      >
                        <Tooltip title={noData ? "No data" : name} placement="top">
                          <Icon
                            color={noData ? "disabled" : value === media ? "secondary" : "action"}
                          >
                            {mediaIcons[value]}
                          </Icon>
                        </Tooltip>
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </div>
            </Toolbar>
            <IterationChart
              {...props}
              name={"Throughput"}
              media={media}
              chartTypes={charts["throughput"]}
            />
            <IterationChart
              {...props}
              media={media}
              name={"Quality"}
              chartTypes={charts["quality"]}
            />
          </Card>
        </Grid>
      </Grid>
    </ChartSizeProvider>
  );
};

const IterationChart = (props: IIterationChartProps) => {
  const classes = useStyles();
  const [chartType, setChartType] = React.useState<string>(props.chartTypes[mediaType.audio][0]);

  React.useEffect(() => {
    // check if we can display current chart type for new media
    // if not - select default one
    if (props.chartTypes[props.media].indexOf(chartType) === -1) {
      setChartType(props.chartTypes[props.media][0]);
    }
    // eslint-disable-next-line
  }, [props.media]);

  const options = props[props.media]?.charts[chartType]?.options;
  const series = options?.series || [
    {
      label: "",
      data: [],
    },
  ];
  const tooltip = options?.tooltip;

  return (
    <div className={`${classes.chart} ${classes[`chart_${props.name.toLowerCase()}`]}`}>
      <Toolbar className={classes.chartToolbar}>
        <Typography variant="subtitle2">{props.name}</Typography>
        <div className={classes.labels}>
          {props.chartTypes[props.media].map((type: string, idx: number) => {
            return (
              <div key={type} style={{ display: "flex" }}>
                <Typography
                  key={type}
                  variant="subtitle2"
                  onClick={() => setChartType(type)}
                  className={classnames(classes.label, type === chartType && classes.selectedLabel)}
                >
                  {chartTypeMapper[type].label}
                </Typography>
                <VSpacer
                  key={type + "spacer"}
                  idx={idx}
                  length={props.chartTypes[props.media].length}
                />
              </div>
            );
          })}
        </div>
      </Toolbar>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          {/* <Chart
            options={{
              tooltip,
              series,
              yAxis: [
                {},
                {
                  linkedTo: 0,
                  visible: false,
                },
              ],
            }}
          /> */}
          <Chart
            id={props.name}
            title={chartTypeMapper[chartType].label}
            dataset={series}
            legend={props.name === "Quality"}
            options={{
              chart: {
                zoomType: "x",
              },
              yAxis: [
                {
                  title: {
                    text: chartTypeMapper[chartType].yAxisLabel,
                  },
                },
                {
                  linkedTo: 0,
                  visible: false,
                },
              ],
              tooltip,
              xAxis: {
                type: "datetime",
                labels: {
                  formatter: function (context: any) {
                    const value = context.value * 1000;
                    const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
                    const format = range < 3600 ? "%M:%S" : "%H:%M:%S";
                    if (value >= 0) {
                      return (window as any).Highcharts.dateFormat(format, value);
                    } else {
                      return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
                    }
                  },
                },
                events: {
                  afterSetExtremes: function (e: any) {
                    const charts = (window as any).Highcharts?.charts;
                    if (charts) {
                      const eMin = e.min;
                      const eMax = e.max;
                      (charts as Array<any>).forEach((c) => {
                        if (c) {
                          c.xAxis[0].setExtremes(eMin, eMax, true);
                        }
                      });
                    }
                  },
                },
              },
            }}
            width="100%"
            xAxisLabel={"Time"}
            suppressDirectionSortToLegend={props.name === "Throughput"}
            // showNoDataMessage
            syncLegends
            allowZeros
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(Charts, (props: IChartsProps, nextProps: IChartsProps) => {
  return JSON.stringify(props) === JSON.stringify(nextProps);
});
