// tslint:disable:no-any
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import * as _ from "lodash";

interface IValue {
  value: string | number;
  tooltip?: string;
  color?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
}
interface ILine {
  label: string;
  labelWidth?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  value?: IValue | Array<IValue>;
  valueComponent?: any;
  labelStyle?: React.CSSProperties;
}

const ValueDivider = () => (
  <Typography style={{ fontSize: "28px" }} color="initial" variant="h4">
    /
  </Typography>
);

const Line = ({ label, labelWidth, value, valueComponent, labelStyle }: ILine) => {
  if (value && !_.isArray(value) && !valueComponent) {
    value = [value] as Array<IValue>;
  }

  return (
    <Grid container={true} item={true} justify="space-between" spacing={0}>
      <Grid xs={labelWidth || 6} item={true} container={true} alignItems="center">
        <Typography variant="body2" style={labelStyle}>
          {label}
        </Typography>
      </Grid>
      <Grid item={true}>
        {valueComponent ? (
          valueComponent
        ) : (
          <Grid container={true} item={true} direction="row" spacing={0}>
            {value &&
              (value as Array<IValue>).map((val: IValue, idx: number) => {
                return (
                  <React.Fragment key={idx}>
                    <Tooltip title={val.tooltip || ""} placement="left">
                      <Typography
                        style={{ fontSize: "28px" }}
                        color={val.color || "primary"}
                        variant="h4"
                      >
                        {val.value}
                      </Typography>
                    </Tooltip>
                    {value && (value as Array<IValue>)[idx + 1] && <ValueDivider />}
                  </React.Fragment>
                );
              })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Line;
