import * as moment from "moment";

export function formatAsTimeAgo(time: Date | string, local?: Date, raw?: boolean): string {
  let customTime: Date | string | number = time;
  let customLocal: Date | string | number | undefined = local;

  if (!customTime) {
    return "never";
  }

  if (!customLocal) {
    customLocal = Date.now();
  }

  if (customTime instanceof Date) {
    customTime = (customTime as Date).getTime();
  } else if (typeof customTime === "string") {
    customTime = new Date(customTime).getTime();
  }

  if (customLocal instanceof Date) {
    customLocal = (customLocal as Date).getTime();
  } else if (typeof customLocal === "string") {
    customLocal = new Date(customLocal).getTime();
  }

  if (typeof customTime !== "number" || typeof customLocal !== "number") {
    return "";
  }

  const offset = Math.abs((customLocal - customTime) / 1000);
  // tslint:disable-next-line:no-any
  let span: any = [];
  const MINUTE = 60;
  const HOUR = 3600;
  // DAY = 86400,
  // WEEK = 604800,
  // MONTH = 2629744,
  // YEAR = 31556926,
  // DECADE = 315569260;

  if (offset <= MINUTE) {
    span = ["", raw ? "now" : "less than a minute"];
  } else if (offset < MINUTE * 60) {
    span = [Math.round(Math.abs(offset / MINUTE)), "min"];
  } else if (offset < HOUR * 24) {
    span = [Math.round(Math.abs(offset / HOUR)), "hr"];
  } else {
    return moment(customTime).format("MMM DD, YYYY - HH:mm");
  }
  /*
    else if (offset < (DAY * 7))       span = [ Math.round(Math.abs(offset / DAY)), 'day' ];
    else if (offset < (WEEK * 52))     span = [ Math.round(Math.abs(offset / WEEK)), 'week' ];
    else if (offset < (YEAR * 10))     span = [ Math.round(Math.abs(offset / YEAR)), 'year' ];
    else if (offset < (DECADE * 100))  span = [ Math.round(Math.abs(offset / DECADE)), 'decade' ];
    else                               span = [ '', 'a long customTime' ];
    */

  span[1] += span[0] === 0 || span[0] > 1 ? "s" : "";
  span = span.join(" ");

  if (raw === true) {
    return span;
  }
  return customTime <= customLocal ? span + " ago" : "in " + span;
}
