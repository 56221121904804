import * as React from "react";

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <div>
        <h1>Page you are looking for doesn't exist</h1>
      </div>
    );
  }
}
