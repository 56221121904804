import * as React from "react";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface ICiphersProps {
  data: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      marginRight: theme.spacing(1),
    },
    table: {
      width: "max-content",
      minWidth: 900,
    },
  })
);

const Ciphers = ({ data }: ICiphersProps) => {
  const classes = useStyles();
  if (!data) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="subtitle1" className={classes.title}>
        Ciphers:
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Audio</TableCell>
            <TableCell>Video</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>TLS Version</TableCell>
            <TableCell>{data.audio_tlsVersion}</TableCell>
            <TableCell>{data.video_tlsVersion}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>DTLS</TableCell>
            <TableCell>{data.audio_dtlsCipher}</TableCell>
            <TableCell>{data.video_dtlsCipher}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SRTP</TableCell>
            <TableCell>{data.audio_srtpCipher}</TableCell>
            <TableCell>{data.video_srtpCipher}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default Ciphers;
