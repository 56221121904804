import * as React from "react";
import { connect } from "react-redux";
import { withStyles, WithStyles, CircularProgress, Theme, createStyles } from "@material-ui/core";
import { NavbarHeight, DrawerWidth } from "../../../styles/Constants";

type StoreProps = {
  dataLoading: boolean;
};

class AppContentSpinner extends React.Component<StoreProps & WithStyles> {
  render() {
    const { classes, dataLoading } = this.props;

    if (dataLoading) {
      return (
        <div className={classes.container}>
          <div className={classes.overlay}>
            <CircularProgress className={classes.progress} size={70} />
          </div>
        </div>
      );
    }
    return null;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      position: "absolute",
      right: 0,
      bottom: 0,
      zIndex: 99999,
      width: "100%",
      height: `calc(100% - ${NavbarHeight}px)`,
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${DrawerWidth}px)`,
      },
      pointerEvents: "all",
    },
    overlay: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 99998,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    progress: {
      color: "rgba(70, 159, 257, 1)",
    },
  });

const decorate = withStyles(styles);

const mapStateToProps = (store: IStore) => ({
  dataLoading: store.dataLoading.dataLoading,
});

export default connect<StoreProps>(mapStateToProps)(decorate(AppContentSpinner));
