import { Navigator } from "./index";
import * as colors from "@material-ui/core/colors";

interface ColorMap {
  [key: string]: any;
}

const maxAvailableCount = 5000000;

export const getLastLine = (navigator: Navigator): Promise<number> => {
  let max = 0;
  return new Promise((resolve, _reject) => {
    getLines(navigator, maxAvailableCount, maxAvailableCount + 1)
      .then()
      .catch((err) => {
        const tmp = err.split("last available: ");
        if (tmp[1]) {
          max = parseInt(tmp[1], 10);
        }
        resolve(max);
      });
  });
};

export const getLines = (
  navigator: Navigator,
  from: number,
  to: number
): Promise<Array<string>> => {
  const count = to - from;
  return new Promise((resolve, reject) => {
    navigator.readLines(
      from,
      count,
      (
        err: string | null,
        _index: number,
        lines: Array<string>,
        _isEof: boolean,
        _progress: number
      ) => {
        if (err) {
          reject(err);
        }
        if (lines && lines.length) {
          try {
            resolve(lines);
          } catch (error) {
            reject();
          }
        } else {
          reject();
        }
      }
    );
  });
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomMuiColor = (): string => {
  const colorMap: ColorMap = { ...colors };
  const colorKeys = Object.keys(colorMap);
  const key = colorKeys[getRandomInt(0, colorKeys.length - 1)];
  const color = colorMap[key][700];
  return color;
};
