export const AUTHORIZE_USER = "AUTHORIZE_USER";
export const SET_DISTINCT_TESTS = "SET_DISTINCT_TESTS";
export const SET_DISTINCT_MONITOR = "SET_DISTINCT_MONITOR";
export const SET_MONITOR_SORT = "SET_MONITOR_SORT";
export const SET_COMPANIES = "SET_COMPANIES";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const PUSH_BREADCRUMB_NODE = "PUSH_BREADCRUMB_NODE";
export const RESET_BREADCRUMB = "RESET_BREADCRUMB";
export const IS_DATA_LOADING = "IS_DATA_LOADING";
