// tslint:disable:no-any
import * as React from "react";
import { WebRTCInternalsState } from "./index";
import { Typography } from "@material-ui/core";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import QuickLinks from "./components/QuickLinks";
import UserMedia from "./components/UserMedia";
import Connection from "./components/Connection";
import Graphs from "./components/Graphs";
import SessionData from "./components/SessionData";

type ViewProps = {
  getDumpFile: any;
  isLoading: boolean;
  isAdmin: boolean;
  replayAsWatchRTC: () => void;
};

class View extends React.PureComponent<
  WebRTCInternalsState & ViewProps & WithStyles<"card" | "divider">
> {
  render() {
    const {
      webRtcAnalytics,
      webrtcInternalsGraphs,
      error,
      getDumpFile,
      isLoading,
      isAdmin,
      isWatchRTC,
      replayAsWatchRTC,
    } = this.props;
    return (
      <>
        {webRtcAnalytics && (
          <QuickLinks
            getDumpFile={getDumpFile}
            webRtcAnalytics={webRtcAnalytics}
            isLoading={isLoading}
            isAdmin={isAdmin}
            isWatchRTC={isWatchRTC}
            replayAsWatchRTC={replayAsWatchRTC}
          />
        )}
        {webRtcAnalytics && <SessionData webRtcAnalytics={webRtcAnalytics} />}
        {webRtcAnalytics ? (
          <UserMedia media={webRtcAnalytics.getUserMedia} origin={webRtcAnalytics.origin} />
        ) : (
          <React.Fragment>
            {error === "" ? (
              <Typography variant="body2">Loading...</Typography>
            ) : (
              <Typography variant="body2">{error}</Typography>
            )}
          </React.Fragment>
        )}
        {webRtcAnalytics &&
          Object.keys(webRtcAnalytics.PeerConnections).map((key: string) => {
            return (
              <React.Fragment key={key}>
                <Connection
                  id={key}
                  title={key}
                  connection={webRtcAnalytics.PeerConnections[key]}
                  sessionData={webRtcAnalytics.sessionData}
                />
                <Graphs id={key} webrtcInternalsGraphs={webrtcInternalsGraphs} />
              </React.Fragment>
            );
          })}
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      height: "auto",
    },
    divider: {
      margin: "16px -16px",
    },
  });

export default withStyles(styles)(View);
