import * as React from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Fade from "@material-ui/core/Fade";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import RTCGrid from "src/components/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    card: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    tableContainer: {
      width: "max-content",
      padding: theme.spacing(3),
      display: "block",
      overflowY: "hidden",
      overflowX: "auto",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
      },
      "& td": {
        minWidth: 200,
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& td:first-child": {
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& td:last-child": {
        paddingRight: 8,
      },
    },
  })
);

interface ISessionData {
  webRtcAnalytics: any;
}

const mapLocalData = (data: any) => {
  const hardwareKeys = ["cores_count", "fps", "gpu", "isMobile", "tier"];
  const hardwareData: any[] = [];
  Object.keys(data.sessionData?.hardware || {}).forEach((hk) => {
    if (hardwareKeys.includes(hk)) {
      hardwareData.push({ key: hk, value: data.sessionData.hardware[hk]?.toString() });
    }
  });
  const userAgentData = { key: "user agent", value: data.userAgent };
  const mapped = [userAgentData, ...hardwareData];

  return mapped as any[];
};

const SessionData = (props: ISessionData) => {
  const classes = useStyles();

  const columnSchema: Array<ColumnSchema> = [
    {
      id: "key",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "",
    },
    {
      id: "value",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "",
    },
  ];

  return (
    <Fade in={true}>
      <Grid item={true} xs={12}>
        <Card className={classes.card}>
          <Grid container={true} spacing={5} className={classes.tableContainer}>
            <RTCGrid
              localData={mapLocalData(props.webRtcAnalytics)}
              columnSchema={columnSchema}
              rowProps={{
                className: classes.tableRow,
              }}
            />
          </Grid>
        </Card>
      </Grid>
    </Fade>
  );
};

export default SessionData;
