import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import AxiosFactory from "../../services/AxiosFactory";
import ApiPath from "../../constants/ApiPath";
import { AxiosResponse } from "axios";
import View from "./View.tsx";
import AuthService from "../../services/AuthService";
import SocketService from "../../services/SocketService";
import { FetchUser, ISetUserAction } from "../../actions/authAction";
import { ThunkDispatch } from "redux-thunk";
import { UserSettings } from "src/constants/RoutesNames";

export type AppStoreProps = {
  user: User;
};

export type AppStoreDispatch = {
  updateUser: () => void;
};

export type AppControllerState = {
  projects?: Array<Project>;
  selectedProject?: Project | null;
  setSelectedProject?: (projectId: string) => void;
};

export const ProjectsContext = React.createContext({
  projects: [],
} as AppControllerState);

class AppController extends React.Component<
  AppStoreProps & AppStoreDispatch & RouteComponentProps<{}>,
  AppControllerState
> {
  private initialState: AppControllerState = {};

  constructor(props: AppStoreProps & AppStoreDispatch & RouteComponentProps<{}>) {
    super(props);

    this.setSelectedProject = this.setSelectedProject.bind(this);

    this.state = {
      ...this.initialState,
      setSelectedProject: this.setSelectedProject,
    };
  }

  async componentDidMount() {
    if (this.props.user) {
      // tslint:disable-next-line: no-any
      const user = this.props.user as any;
      if (!user.loggedInAs && !user.acceptTerms) {
        // go to user-settings page
        this.props.history.push(UserSettings);
      }

      await this.setProject();
    }
  }

  public async setSelectedProject(projectId: string) {
    await this.setState({
      selectedProject:
        this.state.projects && this.state.projects.find((p: Project) => p._id === projectId),
    });
    await this.toggleUsersProject(projectId);
    SocketService._instance.refresh();
  }

  render() {
    return (
      <ProjectsContext.Provider value={this.state}>
        <View {...this.state} {...this.props} />
      </ProjectsContext.Provider>
    );
  }

  private async setProject() {
    const { user } = this.props;
    const axiosFactory = new AxiosFactory();

    const result: AxiosResponse<Array<Project>> = await axiosFactory.axios.get(
      ApiPath.api.projects.all,
      {
        params: {
          userId: user._id,
        },
      }
    );

    const projects = result.data.sort((a, b) => a.name_sort.localeCompare(b.name_sort));
    let selectedProject = null;

    if (!user.project && projects.length >= 1) {
      selectedProject = projects[0]; /*Assings the first project to user*/
      await this.toggleUsersProject(selectedProject._id); /*Updates user model with the project*/
    }

    projects.forEach((p: Project) => {
      if (user.project && p._id === user.project._id) {
        selectedProject = p;
      }
    });

    const project = await this.getProjectDetails(selectedProject && selectedProject._id);

    this.setState({
      projects,
      selectedProject: project,
    });
  }

  private async toggleUsersProject(projectId: string) {
    const axiosFactory = new AxiosFactory();

    try {
      const result: AxiosResponse<LoginResponse> = await axiosFactory.axios.post(
        `${ApiPath.api.user.changeProject}/${projectId}`,
        {
          id: projectId,
        }
      );

      const data = result.data;

      AuthService.setToken(data.token);
      AuthService.setExpires(data.expires);

      // TODO: socket update here
      await this.props.updateUser();

      this.props.history.replace("/");
    } catch (err) {
      // TODO: add global error handler
      // tslint:disable-next-line:no-console
      console.log(err);
    }
  }

  private async getProjectDetails(projectId: string | null): Promise<Project | null> {
    if (!projectId) {
      return null;
    }

    const axiosFactory = new AxiosFactory();

    try {
      const result: AxiosResponse<Project> = await axiosFactory.axios.get(
        `${ApiPath.api.projects.root}/projectSetup/${projectId}`
      );

      return result.data;
    } catch (err) {
      // TODO: add global error handler
      // tslint:disable-next-line:no-console
      console.log(err);
    }
    return null;
  }
}

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IStore, null, ISetUserAction>
): AppStoreDispatch => ({
  updateUser: () => dispatch(FetchUser()),
});

export default withRouter<RouteComponentProps<{}>>(
  connect<AppStoreProps, AppStoreDispatch, RouteComponentProps<{}>>(
    mapStateToProps,
    mapDispatchToProps
  )(AppController)
);
