// tslint:disable:no-any
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import View from "./View";
import { ChartsState } from "../index";
import { TestLogs } from "../../../../constants/RoutesNames";

export interface TestOverviewStoreProps {
  user: User;
}

export interface TestOverviewState {
  pendingRequest: boolean;
}

export interface TestOverviewProps {
  test: any;
  testRun: any;
  testDefinition: any;
  isReport: boolean;
  pushToFileNames: (item: string) => void;
  saveTestIteration?: (test: Test) => void;
  updateTest?: (test: Test) => void;
}

type Props = TestOverviewStoreProps & TestOverviewProps & RouteComponentProps<{}> & ChartsState;

class TestOverview extends React.Component<Props, TestOverviewState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pendingRequest: false,
    };

    this.setRating = this.setRating.bind(this);
  }

  async setRating(rating: number) {
    if (this.state.pendingRequest) {
      return;
    }
    this.setState({
      pendingRequest: true,
    });
    const newTestIteration = {
      ...this.props.test,
      mediaQuality: rating,
    };
    if (this.props.updateTest) {
      this.props.updateTest(newTestIteration);
    }
    try {
      if (this.props.saveTestIteration) {
        await this.props.saveTestIteration(newTestIteration);
      }
    } catch (e) {
      // TODO add error handler
    }
    this.setState({
      pendingRequest: false,
    });
  }

  onStatusClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      this.props.history.push(
        `${TestLogs}/${this.props.testRun._id}/${this.props.test.machine}/manual`
      );
      return false;
    }
    return true;
  };

  render() {
    return (
      <View
        {...this.props}
        {...this.state}
        setRating={this.setRating}
        onStatusClick={this.onStatusClick}
      />
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

export default withRouter<TestOverviewProps & ChartsState & RouteComponentProps<{}>>(
  connect<TestOverviewStoreProps, {}, RouteComponentProps<{}> & ChartsState & TestOverviewProps>(
    mapStateToProps
  )(TestOverview)
);
