import * as React from "react";
import { WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import SigninForm from "./SigninForm";
import AnalyzerBanner from "../../../components/auth/AnalyzerBanner";
import LoginSocialButtons from "../../../components/auth/LoginSocialButtons";
import FooterColors from "../../../components/auth/FooterColors";
import RemindPasswordModal from "./RemindPasswordModal";
import SubscribeModal from "./SubscribeModal";
import { ISignInPageState, ILoginForm } from "./index";

type StyledComponent = WithStyles<"root" | "title" | "orWrapper" | "orText" | "titleContainer">;

interface ViewProps {
  remindOpened: boolean;
  closeModal: () => void;
  onSubmit: (values: ILoginForm) => void;
}

const View = (props: ViewProps & ISignInPageState & StyledComponent) => {
  const { classes, isLoading, onSubmit } = props;

  return (
    <Grid container={true} direction="row" justify="center">
      <Grid item={true} xs={12} sm={6} md={5} lg={4}>
        <Slide direction="right" in={true} mountOnEnter={true} unmountOnExit={true}>
          <Paper className={classes.root} elevation={4}>
            <RemindPasswordModal
              emailRemind={props.emailRemind}
              isOpen={props.remindOpened}
              onClose={props.closeModal}
            />
            <SubscribeModal
              emailRemind={props.emailRemind}
              isOpen={props.subscribeOpened}
              onClose={props.closeModal}
            />
            <div className={classes.titleContainer}>
              <Typography className={classes.title} align="left" variant="h6">
                Login to testRTC
              </Typography>
            </div>
            <Grid container={true}>
              <Grid item={true} xs={12}>
                <SigninForm isLoading={isLoading} onSubmit={onSubmit} />
              </Grid>
              <Grid item={true} xs={12}>
                <div className={classes.orText}>
                  <Typography variant="body2">Or</Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item={true} xs={12}>
              <LoginSocialButtons />
            </Grid>
            <FooterColors />
          </Paper>
        </Slide>
      </Grid>
      <Grid item={true} xs={12} sm={5} lg={4}>
        <AnalyzerBanner />
      </Grid>
    </Grid>
  );
};

const decorate = withStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5),
      position: "relative",
    },
    orText: {
      margin: `0 0 ${theme.spacing(2)}px 0`,
      padding: "0 10px",
      textAlign: "center",
      textTransform: "uppercase",
    },
    title: {
      fontSize: "1.9rem",
      color: "white",
      fontWeight: 300,
      margin: 0,
      marginTop: theme.spacing(7),
      userSelect: "none",
    },
    titleContainer: {
      height: 150,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(5),
      margin: -theme.spacing(5),
      justifyContent: "center",
    },
  })
);

export default decorate(View);
