export type MediaType = "audio" | "video";
export const mediaType = {
  audio: "audio",
  video: "video",
};
export const medias = [mediaType.audio, mediaType.video];
export const mediaIcons = {
  [mediaType.audio]: "headset",
  [mediaType.video]: "videocam",
};

type ChartData = { [key in MediaType]: string[] };

interface ICharts {
  throughput: ChartData;
  quality: ChartData;
}

const chartTypeValues = {
  flotBits: "flotBits",
  flotPacket: "flotPacket",
  flotPacketLossNumber: "flotPacketLossNumber",
  flotPacketLossPct: "flotPacketLossPct",
  flotJitter: "flotJitter",
  delay: "delay",
  frameRate: "frameRate",
};

export const charts: ICharts = {
  throughput: {
    audio: [chartTypeValues.flotBits, chartTypeValues.flotPacket],
    video: [chartTypeValues.flotBits, chartTypeValues.flotPacket],
  },
  quality: {
    audio: [
      chartTypeValues.flotJitter,
      chartTypeValues.delay,
      chartTypeValues.flotPacketLossPct,
      chartTypeValues.flotPacketLossNumber,
    ],
    video: [
      chartTypeValues.flotJitter,
      chartTypeValues.delay,
      chartTypeValues.flotPacketLossPct,
      chartTypeValues.flotPacketLossNumber,
      chartTypeValues.frameRate,
    ],
  },
};

export const chartTypeMapper = {
  [chartTypeValues.flotBits]: {
    label: "Bitrate (ms)",
    yAxisLabel: "Kbits",
  },
  [chartTypeValues.flotPacket]: {
    label: "Packets",
    yAxisLabel: "Packets",
  },
  [chartTypeValues.flotJitter]: {
    label: "Jitter",
    yAxisLabel: "ms",
  },
  [chartTypeValues.flotPacketLossNumber]: {
    label: "Packets Loss (#)",
    yAxisLabel: "#",
  },
  [chartTypeValues.flotPacketLossPct]: {
    label: "Packet Loss (%)",
    yAxisLabel: "%",
  },
  [chartTypeValues.delay]: {
    label: "Round trip time",
    yAxisLabel: "ms",
  },
  [chartTypeValues.frameRate]: {
    label: "Frame Rate",
    yAxisLabel: "fps",
  },
};
