import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import AxiosFactory from "../../services/AxiosFactory";
import ApiPath from "../../constants/ApiPath";
import { ISetUserAction, UpdateUser } from "../../actions/authAction";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppStoreProps } from "src/containers/AppController";

const containerStyles = {
  marginBottom: 24,
  display: "flex",
  flexDirection: "row",
  alignItems: "start",
  justifyContent: "center",
} as React.CSSProperties;

const iconStyles = {
  padding: 0,
} as React.CSSProperties;

type State = {
  show: boolean;
};

type Screen = "dashboard" | "tests" | "testProperties";

type VideoProps = {
  projectId: string;
  screen: Screen;
  additionalCondition?: boolean;
};

type VideoDispatch = {
  updateUser: (user: User) => void;
};

export class Video extends React.Component<VideoProps & VideoDispatch & AppStoreProps, State> {
  // tslint:disable-next-line:no-any
  constructor(props: VideoProps & VideoDispatch & AppStoreProps) {
    super(props);

    this.state = {
      show: false,
    };
  }

  async componentDidMount() {
    try {
      if (this.props.user.user_info && this.props.user.user_info.video) {
        if (this.props.user.user_info.video[`hide_${this.props.screen}`]) {
          this.setState({ show: false });
          return;
        }
      }

      const axiosFactory = new AxiosFactory();
      const res = (await axiosFactory.axios.get(
        `${ApiPath.api.testDefinitions}/lastRun/${this.props.projectId}`
        // tslint:disable-next-line:no-any
      )) as any;
      if (res.data && res.data.days) {
        const condition = res.data.days >= 30;
        if (this.props.screen === "dashboard") {
          this.setState({
            show: condition && !!this.props.additionalCondition,
          });
        } else {
          this.setState({
            show: condition,
          });
        }
      } else {
        this.setState({ show: false });
      }
    } catch (err) {
      console.error(err);
      this.setState({ show: false });
    }
  }

  onClose = async () => {
    await this.setState({ show: false });
    let user_info = this.props.user.user_info;
    if (!user_info) {
      user_info = {
        video: {
          [`hide_${this.props.screen}`]: true,
        },
      };
    } else {
      user_info = {
        video: {
          ...this.props.user.user_info.video,
          [`hide_${this.props.screen}`]: true,
        },
      };
    }

    const user = {
      user_info,
    } as User;
    await this.props.updateUser(user);
  };

  render() {
    const { show } = this.state;
    const url =
      this.props.screen === "tests"
        ? "https://www.youtube.com/embed/NL9s7PsPra4"
        : this.props.screen === "testProperties"
        ? "https://www.youtube.com/embed/yce1s6nmAEw"
        : "https://www.youtube.com/embed/MhuYlV3iW7w";
    return show === true ? (
      <div style={containerStyles}>
        <IconButton style={iconStyles} onClick={this.onClose}>
          <Close />
        </IconButton>
        <iframe
          width="560"
          height="315"
          src={url}
          frameBorder="0"
          allowFullScreen={true}
          title="embed-video"
        />
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IStore, null, ISetUserAction>
): VideoDispatch => ({
  updateUser: (user: User) => dispatch(UpdateUser(user)),
});

export default connect<AppStoreProps, VideoDispatch>(mapStateToProps, mapDispatchToProps)(Video);
