import * as React from "react";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { RouteComponentProps, withRouter } from "react-router";
import { NewMonitoring } from "../../constants/RoutesNames";

type StyledComponent = WithStyles<"root" | "newTestButton">;

export class GridControl extends React.Component<RouteComponentProps<{}> & StyledComponent> {
  constructor(props: RouteComponentProps<{}> & StyledComponent) {
    super(props);

    this.toNewMonitoring = this.toNewMonitoring.bind(this);
  }

  toNewMonitoring() {
    this.props.history.push(NewMonitoring);
  }

  render() {
    const { classes } = this.props;

    return (
      <Toolbar className={classes.root}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.newTestButton}
          onClick={this.toNewMonitoring}
        >
          Create New Monitor
        </Button>
      </Toolbar>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "flex-end",
    },
    newTestButton: {
      marginLeft: theme.spacing(2),
    },
  });

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}>>(decorate(GridControl));
