import { AUTHORIZE_USER } from "../constants/ActionTypes";
import AxiosFactory from "../services/AxiosFactory";
import ApiPath from "../constants/ApiPath";
import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";

export interface ISetUserAction {
  type: string;
  payload: User;
}

export const SetUserAction = (data: User): ISetUserAction => {
  return {
    type: AUTHORIZE_USER,
    payload: data,
  };
};

export const FetchUser = (): ThunkAction<
  Promise<AxiosResponse>,
  IStore,
  null,
  ISetUserAction
> => async (dispatch: Dispatch<ISetUserAction>) => {
  const axiosFactory = new AxiosFactory();

  const result = await axiosFactory.axios.get(ApiPath.api.user.me);
  dispatch(SetUserAction(result.data));
  return Promise.resolve(result);
};

export const UpdateUser = (
  user: User
): ThunkAction<Promise<AxiosResponse>, IStore, null, ISetUserAction> => async (
  dispatch: Dispatch<ISetUserAction>
) => {
  const axiosFactory = new AxiosFactory();

  const result = await axiosFactory.axios.put(ApiPath.api.user.me, user);
  dispatch(SetUserAction(result.data));
  return Promise.resolve(result);
};
