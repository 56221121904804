export default class UsageGraphUtils {
  public static hoursAgo(n: number) {
    return new Date(Date.now() - n * 60 * 60 * 1000);
  }

  public static firstDayOfMonth() {
    // eslint-disable-next-line
    const d = new Date(Date.apply(null, arguments));
    d.setDate(1);
    return d.toISOString();
  }

  public static firstDayInPreviousMonth() {
    const d = new Date();
    return new Date(d.getFullYear(), d.getMonth() - 1, 1);
  }

  public static lastDayInPreviousMonth() {
    const d = new Date();
    d.setDate(1);
    d.setHours(-1);
    return d.toISOString();
  }

  public static daysAgo(days: number) {
    const d = new Date();
    d.setDate(d.getDate() - days);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  public static getStartEndDate(time: string) {
    let startDate;
    let endDate;

    switch (time) {
      case "thisPeriod":
        startDate = this.firstDayOfMonth();
        break;
      case "prevPeriod":
        startDate = this.firstDayInPreviousMonth();
        endDate = this.lastDayInPreviousMonth();
        break;
      case "6h":
        startDate = this.hoursAgo(6);
        break;
      case "12h":
        startDate = this.hoursAgo(12);
        break;
      case "24h":
        startDate = this.hoursAgo(24);
        break;
      case "7d":
        startDate = this.daysAgo(7);
        break;
      default:
      case "30d":
        startDate = this.daysAgo(30);
        break;
    }

    return {
      startDate: startDate,
      endDate: endDate || new Date(),
    };
  }

  public static intervals() {
    return [
      {
        label: "1m",
        value: "1m",
      },
      {
        label: "5m",
        value: "5m",
      },
      {
        label: "15m",
        value: "15m",
      },
      {
        label: "1h",
        value: "1h",
      },
      {
        label: "1d",
        value: "1d",
      },
      {
        label: "7d",
        value: "7d",
      },
      {
        label: "30d",
        value: "30d",
      },
    ];
  }

  public static planPeriods() {
    return [
      {
        label: "This Period",
        value: "thisPeriod",
      },
      {
        label: "Previous Period",
        value: "prevPeriod",
      },
      {
        label: "Last 30 days",
        value: "30d",
      },
      {
        label: "Last 7 days",
        value: "7d",
      },
      {
        label: "Last 24 hours",
        value: "24h",
      },
      {
        label: "Last 12 hours",
        value: "12h",
      },
      {
        label: "Last 6 hours",
        value: "6h",
      },
    ];
  }

  public static dimentions() {
    return {
      width: "100%",
      height: "400px",
    };
  }
}
