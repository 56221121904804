import * as React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import FileCopy from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";

import { CopyToClipboard } from "react-copy-to-clipboard";

import RTCGrid from "../../components/Grid";
import ConfirmDialog from "../../components/Dialog/Confirm";
import ApiPath from "../../constants/ApiPath";
import AxiosFactory from "../../services/AxiosFactory";

import * as moment from "moment";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
      marginTop: 20,
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  })
);

interface IAnalyzeRTCHistory {
  setNotification: (message: string) => void;
}

export default function AnalyzeRTCHistory({ setNotification }: IAnalyzeRTCHistory) {
  const gridRef = React.useRef(null);

  const classes = useStyles();
  const [idToDelete, setIdToDelete] = React.useState("");
  const columnSchema = [
    {
      id: "userRef",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "User Ref",
    },
    {
      id: "apiKey",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "API Key",
      render: (dataItem: any) => (
        <div>
          <span>{dataItem.apiKey}</span>
          <Tooltip title="Copy" placement="top-start">
            <IconButton edge="end" aria-label="Copy" size="small">
              <CopyToClipboard text={dataItem.apiKey}>
                <FileCopy onClick={() => setNotification("API Key is copied")} />
              </CopyToClipboard>
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      id: "createDate",
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: "Create Date",
      render: (dataItem: any) =>
        dataItem?.createDate
          ? moment(new Date(dataItem.createDate)).format("MMM DD, YYYY - HH:mm:ss")
          : "never",
    },
    {
      id: "lastUploadDate",
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: "Last Upload Date",
      render: (dataItem: any) =>
        dataItem?.lastUploadDate
          ? moment(new Date(dataItem.lastUploadDate)).format("MMM DD, YYYY - HH:mm:ss")
          : "never",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "Actions",
      render: (dataItem: any) => (
        <div>
          <IconButton
            edge="end"
            aria-label="Delete"
            size="small"
            onClick={() => onDeleteClick(dataItem._id)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const onDeleteClick = (id: string) => {
    setIdToDelete(id);
  };
  const onDeleteConfirmed = async () => {
    const axiosFactory = new AxiosFactory();
    try {
      setIdToDelete("");
      await axiosFactory.axios.delete(`${ApiPath.api.analyzeRTC}/${idToDelete}`);
      if (gridRef.current) {
        (gridRef.current as any).reloadData();
      }
      setNotification("Item deleted");
    } catch (e) {
      setNotification("Failed to delete item");
    }
  };

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography>analyzeRTC</Typography>
      </Toolbar>
      <Divider style={{ marginTop: 0 }} />
      <RTCGrid
        ref={gridRef}
        remoteDataBound={true}
        apiRoute={ApiPath.api.analyzeRTC}
        columnSchema={columnSchema}
        defaultSort={{
          order: "desc",
          orderBy: "createDate",
        }}
        pagination={true}
      />
      <ConfirmDialog
        open={idToDelete !== ""}
        title="Are you sure?"
        content="You will not be able to restore this item!"
        handleConfirm={onDeleteConfirmed}
        handleClose={() => setIdToDelete("")}
      />
    </Paper>
  );
}
