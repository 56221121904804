import * as React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import LegendItem from "./LegendItem";
import { useSeries } from "../hooks/useSeries";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    legend: {
      display: "flex",
      flexWrap: "wrap",
      paddingLeft: 18,
    },
  })
);

const LegendList = () => {
  const classes = useStyles();
  const { series } = useSeries();

  return (
    <div className={classes.legend}>
      {series.map((item: any) => {
        return <LegendItem key={item.name} item={item} />;
      })}
    </div>
  );
};

export default LegendList;
