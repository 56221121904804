import * as React from "react";
import * as classnames from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";

import { ReactSVG } from "react-svg";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import IconSpacer from "@material-ui/icons/FiberManualRecord";

import NavItemComponent from "./NavItemComponent";
import { Tooltip, Popover } from "@material-ui/core";

const NavItemDefault = (props: any) => {
  const {
    name,
    link,
    regularLink,
    Icon,
    svgIcon,
    IconStyles = {},
    IconClassName = "",
    disabled,
    hidden,
    isCollapsed,
    nestingLevel = 0,
    nestingOffset = 8,
    className,
    items = [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick = () => {},
  } = props;
  const classes = useStyles();
  const hasChildren = items && items.length > 0;

  const itemsAll = getItemsAll(items);
  const isActive =
    itemsAll.filter((item: any) => `${item.link}` === window.location.pathname).length > 0;
  const hasChildrenAndIsActive = hasChildren && isActive;

  const isOpen = hasChildrenAndIsActive || false;
  const [open, setOpen] = React.useState(isOpen);

  // This is a work around for fixing the collapsed item poper overflow bug
  React.useLayoutEffect(() => {
    window.dispatchEvent(new CustomEvent("resize"));
  });

  function handleClick() {
    setOpen(!open);
  }

  const ListItemIconInner =
    (svgIcon && <ReactSVG src={Icon} />) ||
    (!!Icon && <Icon />) ||
    (isCollapsed && <IconSpacer className={classes.iconSpacer} />) ||
    "";

  const nestingOffsetChildren = !isCollapsed ? nestingOffset + 16 : 16;

  const ListItemRoot = (
    <NavItemComponent
      link={link}
      className={classnames(
        classes.navItem,
        isCollapsed && classes.navItemCollapsed,
        hasChildrenAndIsActive && "active",
        className
      )}
      style={{
        paddingLeft: `${!ListItemIconInner ? nestingOffset + 40 : nestingOffset}px`,
      }}
      isCollapsed={isCollapsed}
      onClick={handleClick}
      regularLink={regularLink}
    >
      {!!ListItemIconInner && (
        <ListItemIcon style={IconStyles} className={classnames(classes.navItemIcon, IconClassName)}>
          {ListItemIconInner}
        </ListItemIcon>
      )}
      <ListItemText primary={name} disableTypography={true} />
      {hasChildren && !open && <IconExpandMore className={classes.iconToggle} />}
      {hasChildren && open && <IconExpandLess className={classes.iconToggle} />}
    </NavItemComponent>
  );

  const ListItemChildren = hasChildren ? (
    <div className={classnames(classes.navItemChildren)}>
      <Collapse in={open} timeout="auto" unmountOnExit={true}>
        {/* <Divider /> */}
        <List component="div" disablePadding={true}>
          {items.map((item: any) => (
            <NavItem
              {...item}
              isNested={true}
              nestingLevel={nestingLevel + 1}
              isCollapsed={isCollapsed}
              key={item.name || item.link}
              isOpen={open}
              nestingOffset={nestingOffsetChildren}
            />
          ))}
        </List>
      </Collapse>
    </div>
  ) : null;

  if (hidden && hidden()) {
    return null;
  }

  return (
    <div
      className={classnames(
        hasChildrenAndIsActive && classes.navItemWrapperActive,
        hasChildrenAndIsActive && isCollapsed && classes.navItemWrapperActiveCollapsed,
        disabled && disabled() && classes.navItemWrapperDisabled
      )}
      onClick={onClick}
    >
      {ListItemRoot}
      {ListItemChildren}
    </div>
  );
};

const NavItemCollapsed = (props: any) => {
  const {
    name,
    link,
    Icon,
    IconStyles = {},
    IconClassName = "",
    isCollapsed,
    className,
    items = [],
  } = props;
  const classes = useStyles();
  const hasChildren = items && items.length > 0;

  const itemsAll = getItemsAll(items);
  const hasChildrenAndIsActive =
    hasChildren &&
    itemsAll.filter((item: any) => `#${item.link}` === window.location.hash).length > 0;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    if (!hasChildren) {
      return false;
    }

    setAnchorEl(event.currentTarget);
    return true;
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "sidebar-nav-item-popper" : undefined;

  const ListItemIconInner =
    (!!Icon && <Icon />) || (isCollapsed && <IconSpacer className={classes.iconSpacer} />) || "";

  const ListItemRoot = (
    <Tooltip
      disableFocusListener={true}
      disableHoverListener={false}
      disableTouchListener={true}
      title={name}
      placement="right"
    >
      <NavItemComponent
        link={link}
        className={classnames(
          classes.navItem,
          classes.navItemCollapsed,
          hasChildrenAndIsActive && "active",
          open && "open",
          className
        )}
        isCollapsed={true}
        aria-describedby={id}
        onClick={handlePopoverOpen}
      >
        {!!ListItemIconInner && (
          <ListItemIcon
            style={IconStyles}
            className={classnames(classes.navItemIcon, IconClassName)}
          >
            {ListItemIconInner}
          </ListItemIcon>
        )}
        <ListItemText primary={name} disableTypography={true} style={{ visibility: "hidden" }} />
        {hasChildren && (
          <IconExpandLess
            className={classnames(classes.iconToggle, !open && classes.iconToggleInactive)}
          />
        )}
      </NavItemComponent>
    </Tooltip>
  );

  const ListItemChildren = hasChildren ? (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      classes={{
        paper: classes.navItemPoper,
      }}
    >
      <div className={classnames(classes.navItemChildren)}>
        <List component="div" disablePadding={true}>
          {items.map((item: any) => (
            <NavItem
              {...item}
              isNested={true}
              nestingLevel={0}
              isCollapsed={false}
              key={item.name || item.link}
              isOpen={open}
              onClick={!item.items || !item.items.length ? handlePopoverClose : undefined}
            />
          ))}
        </List>
      </div>
    </Popover>
  ) : null;

  return (
    <div
      className={classnames(
        hasChildrenAndIsActive && classes.navItemWrapperActive,
        hasChildrenAndIsActive && isCollapsed && classes.navItemWrapperActiveCollapsed
      )}
    >
      {ListItemRoot}
      {ListItemChildren}
    </div>
  );
};

const NavItem = (props: any) => {
  if (props.isCollapsed) {
    return <NavItemCollapsed {...props} />;
  } else {
    return <NavItemDefault {...props} />;
  }
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    // nested: {
    //   paddingLeft: theme.spacing(10),
    // },
    navItemWrapper: {
      position: "relative",
    },
    navItemWrapperActive: {
      // background: "rgba(0, 0, 0, 0.08)",
      // color: theme.palette.secondary.main,
    },
    navItemWrapperActiveCollapsed: {
      background: "rgba(0, 0, 0, 0.08)",
    },
    navItemWrapperDisabled: {
      opacity: 0.5,
      pointerEvents: "none",
    },
    navItemWrapperNested: {
      color: "green",
    },
    navItem: {
      position: "relative",
      transition: "all .23s ease",
      color: "#ADB0B7",
      fontSize: 13,
      textDecoration: "none",
      borderLeft: "4px solid transparent",
      // "&.MuiListItemText-root": {

      // },
      "&.active:not(.open)": {
        // borderLeft: "4px solid rgb(81, 142, 69)",
        backgroundColor: "#304c5d",
        color: "#53b6ef",
        "& .MuiListItemIcon-root .injected-svg": {
          fill: "#53b6ef",
          color: "#53b6ef",
        },
      },
      "&.open": {
        color: theme.palette.secondary.main,
        backgroundColor: "#304c5d",
        "& .MuiListItemIcon-root": {
          color: theme.palette.secondary.main,
        },
      },
    },
    navItemPoper: {
      // width: theme.sidebar.width,
      // color: theme.sidebar.color,
      // background: theme.sidebar.background,
    },
    navItemChildren: {
      transition: "background .23s ease",
      // position: "absolute",
      "& .active": {
        backgroundColor: "#2b3f4b !important",
      },
    },
    navItemChildrenActive: {
      background: "rgba(0, 0, 0, 0.1)",
    },
    navItemCollapsed: {
      whiteSpace: "nowrap",
      flexWrap: "nowrap",
      // width: theme.sidebar.widthCollapsed,
      "& $iconToggle": {
        position: "absolute",
        // bottom: -1,
        fontSize: 14,
        top: "50%",
        marginTop: "-0.5em",
        transform: "rotate(90deg)",
        right: "3px",
      },
      "&.active": {
        background: "rgba(0, 0, 0, 0.08)",
      },
    },

    navItemIcon: {
      "& svg": {
        height: 25,
        width: 25,
        fill: "#ADB0B7",
        margin: 0,
        "& g": {
          "& *": {
            stroke: "#ADB0B7",
            strokeWidth: 1.2,
          },
        },
      },
    },
    iconToggle: {},
    iconToggleInactive: {
      opacity: 0.35,
    },
    iconSpacer: {
      fontSize: 13,
      marginLeft: 6,
    },
  })
);

// ----------------------

// Flattened array of all children
function getItemsAll(items: any) {
  return items.reduce((allItems: any, item: any) => {
    // let res = allItems.concat([item])

    if (item.items && item.items.length) {
      return allItems.concat([item], getItemsAll(item.items));
    } else {
      return allItems.concat([item]);
    }
  }, []);
}

export default NavItem;
