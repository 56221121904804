import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DocsIcon from "@material-ui/icons/Description";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      [theme.breakpoints.down("xs")]: {
        minWidth: 40,
        padding: 0,
      },
    },
    icon: {
      [theme.breakpoints.up("xs")]: {
        marginRight: 5,
      },
    },
    text: {
      fontWeight: 500,
      letterSpacing: "0.02857em",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  })
);

const Docs = () => {
  const classes = useStyles();

  return (
    <Button
      href="https://testrtc.com/docs/"
      target="_blank"
      color="primary"
      data-cy="logout-btn"
      className={classes.root}
    >
      <DocsIcon className={classes.icon} />
      <Typography variant="body2" color="primary" className={classes.text}>
        {"Docs"}
      </Typography>
    </Button>
  );
};

export default Docs;
