// tslint:disable:no-any

import * as React from "react";
import { connect } from "react-redux";
import TestPropertyForm from "./TestPropertyForm";
import { AxiosError, AxiosResponse } from "axios";
import { FormApi } from "final-form";
import AxiosFactory from "../../services/AxiosFactory";
import ApiPath from "../../constants/ApiPath";
import {
  Tests,
  TestProperty as TestPropertyRoute,
  NewTestProperty,
  TestRunDetails,
} from "../../constants/RoutesNames";
import { RouteComponentProps } from "react-router";
import fileDownload from "js-file-download";
import { SetNotification, HideNotification } from "../../actions/notificationAction";
import withBreadcrumb, { WithBreadcrumb } from "../../components/withBreadcrumb";
import Video from "../../components/Video";
import ErrorDialog from "../../components/Dialog/Error";
import * as _ from "lodash";
import { AppToolbarContext } from "../Layout/components/AppToolbar/Context";

interface TestParameters {
  concurrentUsers: number;
  duration: number;
  iterationMode: "string";
  loopCount: number;
}

interface TestProfile {
  browser: string;
  firewall: string;
  location: string;
  media: string;
  network: string;
}

type RouteParams = {
  objectId: string;
};

interface TestPropertyProps {
  setNotification(message: string): void;
  hideNotification(): void;
}

export type TestPropertyState = {
  // TODO: remove any
  userConfig: any;
  newMode: boolean;
  initialValues: any;
  deleteDialog: boolean;
  autoOpenSet: boolean;
  isRunning: boolean;
  testRunError: string;
};

export interface ITestPropertyForm {
  _id: string;
  name: string;
  info?: string;
  parameters: TestParameters;
  testProfiles: Array<TestProfile>;
  project: string;
  runOptions: string;
  createDate: string;
  serviceUrl: string;
  testScript?: string;
  sessionSize: number;
  numberOfSessions?: number;
  webhook?: string;
}

class TestProperty extends React.Component<
  TestPropertyProps & RouteComponentProps<RouteParams> & WithBreadcrumb,
  TestPropertyState
> {
  static contextType = AppToolbarContext;
  constructor(props: TestPropertyProps & RouteComponentProps<RouteParams> & WithBreadcrumb) {
    super(props);

    this.state = {
      newMode: true,
      userConfig: null,
      initialValues: {
        parameters: {
          concurrentUsers: 1,
        },
        sessionSize: 1,
        numberOfSessions: 1,
      },
      deleteDialog: false,
      autoOpenSet: false,
      isRunning: false,
      testRunError: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onDuplicateButtonClick = this.onDuplicateButtonClick.bind(this);
    this.openSnackbar = this.openSnackbar.bind(this);
    this.exportHandler = this.exportHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onRunButtonClick = this.onRunButtonClick.bind(this);
  }

  componentDidMount() {
    // this.props.pushBreadcrumbNode(this.props.location.pathname, "Test Configuration");
    const newMode = this.props.location.pathname.includes(NewTestProperty);
    this.setState(
      {
        newMode,
      },
      async () => {
        if (!newMode) {
          await this.fetchItemValues();
          await this.isTestUsedByMonitor();
        }
        await this.fetchUserConfig();
      }
    );
  }

  async fetchItemValues() {
    const axiosFactory = new AxiosFactory();
    let result: any;
    await axiosFactory.axios
      .get(`${ApiPath.api.testDefinitions}/${this.props.match.params.objectId}`)
      .then((response: AxiosResponse) => {
        result = response;
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          this.props.history.push("/app/403");
        }
        return false;
      });
    const concurrentProbes =
      result?.data.parameters && result.data.parameters.concurrentUsers
        ? result.data.parameters.concurrentUsers
        : 1;
    const sessionSize = result?.data.sessionSize || 1;
    const numberOfSessions = Math.ceil(concurrentProbes / sessionSize);

    const values = {
      ...result?.data,
      parameters: {
        concurrentUsers: concurrentProbes,
      },
      sessionSize,
      numberOfSessions,
    };

    this.setState({
      ...this.state.initialValues,
      initialValues: values,
    });
    this.context.setPageInfo(values.name);
  }

  async isTestUsedByMonitor() {
    const axiosFactory = new AxiosFactory();
    const result: AxiosResponse = await axiosFactory.axios.get(
      `${ApiPath.api.monitors}/test-used-by-monitor/${this.props.match.params.objectId}`
    );
    if (result.data.monitorsList && this.state.initialValues.name) {
      this.openSnackbar(`This test script (${this.state.initialValues.name}) 
      is actively being used by a monitor. Any changes to it will be 
      reflected in the monitor's execution as well.`);
    }
  }

  async fetchUserConfig() {
    const axiosFactory = new AxiosFactory();
    const result: AxiosResponse = await axiosFactory.axios.get(ApiPath.api.userConfig);
    const proccessedInitialValues = this.prepareDataForDropdowns(
      this.state.initialValues,
      result.data.data
    );
    this.setState({
      userConfig: result.data,
      initialValues: {
        ...proccessedInitialValues,
        testProfiles: proccessedInitialValues.testProfiles || [
          {
            browser: result.data.data["docker-machines"][0].id,
            location: result.data.data["agent-locations"][0].id,
            network: result.data.data["network-profiles"][0].id,
            firewall: result.data.data["firewall-profiles"][0].id,
            media: result.data.data["media-list"][0].id,
          },
        ],
      },
    });
  }

  prepareDataForDropdowns(initialValues: any, config: any) {
    const values = _.cloneDeep(initialValues);
    const testProfiles: Array<any> = values.testProfiles || [];

    // if profile property does not exist in corresponding array of data which will be used
    // as source for dropdown list, must be an empty string to fire validation error on submit
    testProfiles.forEach((profile) => {
      profile.location = config["agent-locations"].some((x: any) => {
        return x.id === profile.location;
      })
        ? profile.location
        : "";
      profile.media = config["media-list"].some((x: any) => {
        return x.id === profile.media;
      })
        ? profile.media
        : "";
      profile.firewall = config["firewall-profiles"].some((x: any) => {
        return x.id === profile.firewall;
      })
        ? profile.firewall
        : "";
      profile.browser = config["docker-machines"].some((x: any) => {
        return x.id === profile.browser;
      })
        ? profile.browser
        : "";
      profile.network = config["network-profiles"].some((x: any) => {
        return x.id === profile.network;
      })
        ? profile.network
        : "";
    });
    return values;
  }

  async onDuplicateButtonClick() {
    const id = this.props.match.params.objectId || this.state.initialValues._id;
    const axiosFactory = new AxiosFactory();
    const result: AxiosResponse = await axiosFactory.axios.get(`${ApiPath.api.copy}/${id}`);
    this.openSnackbar(`Test copied: ${result.data.name}`);
    this.props.history.push(`${TestPropertyRoute}/${result.data._id}`);
    this.setState({
      initialValues: result.data,
    });
    this.context.setPageInfo(result.data.name);
  }

  exportHandler() {
    const json = JSON.stringify(this.state.initialValues, 0 as any, 4);
    const data = new Blob([json], { type: "application/json" });

    fileDownload(data as any, `${this.state.initialValues.name}.json`);
  }

  openSnackbar(message: string) {
    this.props.setNotification(message);
  }

  closeSnackbar() {
    this.props.hideNotification();
  }

  deleteHandler() {
    this.setState({
      deleteDialog: !this.state.deleteDialog,
    });
  }

  setError(error: string) {
    this.setState({ testRunError: error });
  }

  async onDelete() {
    const id = this.props.match.params.objectId || this.state.initialValues._id;
    const axiosFactory = new AxiosFactory();
    try {
      await axiosFactory.axios.delete(`${ApiPath.api.testDefinitions}/${id}`);
      this.openSnackbar("Test deleted");
    } catch (e) {
      this.openSnackbar("Failed to delete current test");
    }
    this.props.history.push(`${Tests}`);
  }

  async onRunButtonClick(
    _e: React.MouseEvent<HTMLElement>,
    values: ITestPropertyForm,
    dirty: boolean,
    invalid: boolean
  ) {
    if (!values.testScript) {
      this.openSnackbar("Could not run test because script is empty");
      return;
    }
    await this.setState({
      isRunning: true,
    });
    if (invalid) {
      await this.setState({
        isRunning: false,
      });
      return false;
    }

    const testResult = await this.onSubmit(values, undefined, dirty, true);
    // if has no props - could not save test
    if (!Object.keys(testResult || {}).length) {
      await this.setState({
        isRunning: false,
      });
      return false;
    }
    const testRunResult = await this.runTest(testResult.data);
    if (!testRunResult) {
      return false;
    }
    this.props.history.push(`${TestRunDetails}/${testRunResult._id}`);
    return false;
  }

  async runTest(test: any) {
    try {
      const axiosFactory = new AxiosFactory();
      const res = (await axiosFactory.axios.get(`${ApiPath.api.run}/${test._id}`)) as any;
      return res.data;
    } catch (err) {
      // tslint:disable-next-line:no-console
      if (err.response && err.response.data && err.response.data.message) {
        // this.openSnackbar(err.response.data.message);
        this.setError(err.response.data.message);
      } else {
        console.error(err);
      }
    }
  }

  render() {
    return (
      <>
        {this.state.initialValues && this.state.initialValues.project && (
          <Video projectId={this.state.initialValues.project} screen="testProperties" />
        )}
        {this.state.userConfig && (
          <TestPropertyForm
            onSubmit={this.onSubmit}
            onDuplicateButtonClick={this.onDuplicateButtonClick}
            exportHandler={this.exportHandler}
            deleteHandler={this.deleteHandler}
            onDelete={this.onDelete}
            onRunButtonClick={this.onRunButtonClick}
            {...this.state}
          />
        )}
        <ErrorDialog
          open={this.state.testRunError !== ""}
          title="Error"
          content={this.state.testRunError}
          handleClick={() => {
            this.setError("");
            this.setState({ isRunning: false });
          }}
        />
      </>
    );
  }

  private async onSubmit(
    values: ITestPropertyForm,
    formApi?: FormApi,
    _dirty?: boolean,
    _submitAndRun?: boolean
  ) {
    const axiosFactory = new AxiosFactory();
    try {
      values.webhook = values.webhook ? values.webhook : "";
      values.parameters.concurrentUsers = Number(values.parameters.concurrentUsers);
      const result = await axiosFactory.axios({
        url: this.state.newMode
          ? `${ApiPath.api.testDefinitions}`
          : `${ApiPath.api.testDefinitions}/${values._id}`,
        method: this.state.newMode ? "POST" : "PUT",
        data: {
          ...values,
          browserType: "Chrome",
        },
      });

      if ((formApi && formApi.getState().dirty) || _dirty) {
        if (formApi) {
          formApi.reset(values);
        }
        this.openSnackbar("Test saved!");
        setTimeout(() => {
          this.props.hideNotification();
        }, 5000);
      }
      if (this.state.newMode) {
        this.setState({
          ...this.state,
          initialValues: {
            ...values,
            project: result.data.project,
            _id: result.data._id,
          },
          newMode: false,
        });
        window.history.replaceState({}, "", `testProperties/${result.data._id}`);
      }
      return result;
    } catch (err) {
      if (err.response && (err.response.status === 409 || err.response.status === 303)) {
        this.openSnackbar(err.response.data.message);
      } else {
        this.openSnackbar("Failed to save test");
      }
      return {} as any;
    }
  }
}

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
  hideNotification: () => dispatch(HideNotification()),
});

const BreadcrumbNodes: Array<BreadcrumbNode> = [
  {
    link: Tests,
  },
];

export default withBreadcrumb(connect(null, mapDispatchToProps)(TestProperty), BreadcrumbNodes);
