// tslint:disable:no-any
import * as React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";

import Error from "@material-ui/icons/Error";
import Done from "@material-ui/icons/Done";

import { IProbes } from "../indexNew";
import Widget from "./Components/Widget";
import Line from "./Components/Line";
import LinearProgress from "./Components/LinearProgress";

interface IProbeRTC {
  probes?: IProbes;
}

const ProbeRTC = ({ probes }: IProbeRTC) => {
  if (!probes) {
    return null;
  }

  const runningProbesLinearValues = (probes.active / probes.activeLimit) * 100 || 0;
  const errorRunningProbesLinearValues = (probes.stoppedProbeRuns / probes.activeLimit) * 100 || 0;

  return (
    <Widget title="probeRTC" marginBottom={8}>
      <Line
        label="Running probes"
        value={[
          {
            value: probes.active.toLocaleString("en", {
              maximumFractionDigits: 0,
            }),
            tooltip: "Running Probes",
          },
          {
            value: (probes.activeLimit || 0).toLocaleString("en", {
              maximumFractionDigits: 0,
            }),
            tooltip: "Maximum Probes Available",
          },
        ]}
      />
      <LinearProgress
        value={runningProbesLinearValues}
        errorValue={errorRunningProbesLinearValues}
      />
      {probes.active > 0 && (
        <Line
          label="Status"
          valueComponent={
            <Tooltip
              title={`${
                probes.stoppedProbeRuns > 0
                  ? `${probes.stoppedProbeRuns} ${
                      probes.stoppedProbeRuns === 1 ? "probe is" : "probes are"
                    } active but not running`
                  : "All probes are working propely"
              }`}
            >
              <Badge
                badgeContent={probes.stoppedProbeRuns}
                color={probes.stoppedProbeRuns === 0 ? "primary" : "error"}
              >
                {probes.stoppedProbeRuns > 0 ? <Error /> : <Done />}
              </Badge>
            </Tooltip>
          }
        />
      )}
    </Widget>
  );
};

export default ProbeRTC;
