import * as React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

type ConrfirmDialogProps = {
  handleClick?: () => void;
  open: boolean;
  title?: string;
  content?: string;
};

// tslint:disable-next-line: no-shadowed-variable
const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorDialog = (props: ConrfirmDialogProps) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClick}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
        TransitionComponent={Transition}
      >
        {props.title && <DialogTitle id="error-dialog-title">{props.title}</DialogTitle>}
        {props.content && (
          <DialogContent>
            <DialogContentText
              id="error-dialog-description"
              dangerouslySetInnerHTML={{ __html: props.content }}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={props.handleClick} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ErrorDialog;
