import { environmentVariables } from "src/constants/EditorCompleteCommands";

export const requireAceAutocompleter = (data: Array<string>) => {
  return {
    getCompletions: (_editor: any, _session: any, _pos: any, _prefix: any, callback: any) => {
      const currentLineToken = _session.doc.$lines[_editor.selection.getCursor().row];
      if (
        currentLineToken.indexOf('client.include("') !== -1 &&
        currentLineToken.indexOf('("') === _editor.selection.getCursor().column - 2
      ) {
        callback(
          null,
          data.map((item) => {
            return {
              caption: item,
              value: `${item}`,
              meta: "asset",
            };
          })
        );
      }
    },
  };
};

export const commandsAceAutocompleter = (data: Array<string>) => {
  return {
    getCompletions: (_editor: any, _session: any, _pos: any, _prefix: any, callback: any) => {
      const currentLineToken = _session.doc.$lines[_editor.selection.getCursor().row].trim();
      const clientTokenArray = currentLineToken.split(".");
      if (
        (currentLineToken === "." ||
          (currentLineToken.length === 2 && currentLineToken.indexOf(".") === 0) ||
          (clientTokenArray.length === 2 && clientTokenArray[0] === "client")) &&
        currentLineToken.indexOf("include(") === -1
      ) {
        callback(
          null,
          data.map((item) => {
            return {
              caption: item,
              value: `${item}("")`,
              meta: "cmd",
            };
          })
        );
      }
      if (currentLineToken === "process.env.") {
        callback(
          null,
          environmentVariables.map((item) => {
            return {
              caption: item,
              value: item,
              meta: "env",
            };
          })
        );
      }
    },
  };
};
