import * as React from "react";
import { WithStyles, withStyles, createStyles } from "@material-ui/core/styles";

type StyledComponent = WithStyles<"root">;

type AppRootProps = {
  children: React.ReactNode;
};

const View = (props: AppRootProps & StyledComponent) => (
  <div className={props.classes.root}>{props.children}</div>
);

const styles = () =>
  createStyles({
    root: {
      flexGrow: 1,
      zIndex: 1,
      overflow: "hidden",
      position: "relative",
      display: "flex",
      height: "100%",
      width: "100%",
    },
  });

export default withStyles(styles)(View);
