import * as React from "react";
import { testStatusHelper } from "../../../../helpers/testStatusHelper";
import IconButton from "@material-ui/core/IconButton";
import { TestIteration } from "../../../../constants/RoutesNames";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Tooltip from "../../../../components/Tooltip";
import Bookmark from "@material-ui/icons/Bookmark";
import Typography from "@material-ui/core/Typography";
import Comment from "@material-ui/icons/Comment";
import MUITooltip from "@material-ui/core/Tooltip";

const BookMarkStyles = {
  color: "#a22a21",
} as React.CSSProperties;

const IconsContainerStyles = {
  display: "flex",
} as React.CSSProperties;

const CommentStyles = {
  color: "#3577c1",
} as React.CSSProperties;

export const replacer = (_match: string, _p1: string, _p2: string): string => {
  return `${_p1}:${_p2.substring(0, 2)}`;
};

export const unGroupedColumnSchema = (hoverClass: string): Array<ColumnSchema> => {
  return [
    {
      id: "statusId,machine",
      numeric: false,
      disablePadding: false,
      label: "Status",
      style: { maxWidth: "5%", width: "5%", padding: "5px 15px 5px 20px" },
      // tslint:disable-next-line:no-any
      render: (dataItem: any) => {
        const tooltip = dataItem.comments || dataItem.iterationComments;
        return (
          <div style={IconsContainerStyles}>
            {testStatusHelper(dataItem.status)}
            {dataItem.flag && <Bookmark style={BookMarkStyles} />}
            {tooltip && (
              <MUITooltip title={tooltip} placement="right-end">
                <Comment style={CommentStyles} />
              </MUITooltip>
            )}
          </div>
        );
      },
    },
    {
      id: "machine,runIndex",
      numeric: false,
      disablePadding: false,
      label: "Probe",
      style: { maxWidth: "5%", width: "5%", padding: "0px 5px" },
      // tslint:disable-next-line:no-any
      render: (dataItem: any) => {
        return (
          <div style={{ minWidth: "70px" }}>
            <Tooltip content={dataItem.machine !== undefined ? dataItem.machine : ""}>
              <span>{dataItem.machine !== undefined ? dataItem.machine.match(/\d+$/)[0] : ""}</span>
            </Tooltip>
            <span style={{ marginLeft: "5px" }}>
              <Tooltip content="Open in new tab">
                <IconButton
                  className={hoverClass}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    window.open(`${TestIteration}/${dataItem._id}`, "_blank");
                    e.stopPropagation();
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      id: "machine",
      numeric: false,
      disablePadding: false,
      label: "Session",
      style: { maxWidth: "10%", width: "5%", padding: "0", whiteSpace: "nowrap" },
      // tslint:disable-next-line:no-any
      render: (dataItem: any) => {
        let sessionTpl;

        if (dataItem.sessionSize > 1) {
          sessionTpl = `${dataItem.inSessionIdx}: ${dataItem.sessionSize} | ${dataItem.sessionIdx}`;
        } else {
          if (dataItem.inSessionIdx !== undefined) {
            sessionTpl = dataItem.inSessionIdx;
          } else {
            sessionTpl = <Typography variant="body2">?</Typography>;
          }
        }

        return sessionTpl;
      },
    },
    {
      id: "rank",
      numeric: true,
      disablePadding: true,
      label: "Score",
      sortable: true,
      style: { maxWidth: "5%", width: "5%", padding: "0px 5px" },
    },
    {
      id: "networkProfile",
      numeric: false,
      disablePadding: false,
      label: "Network",
      style: { maxWidth: "10%", padding: "0px 5px" },
    },
    {
      id: "firewallProfile",
      numeric: false,
      disablePadding: false,
      label: "Firewall",
      style: { maxWidth: "15%", padding: "0px 10px" },
    },
    {
      id: "browser,runIndex,machine",
      numeric: false,
      disablePadding: false,
      label: "Browser",
      style: { maxWidth: "15%", padding: "0px 10px" },
      // tslint:disable-next-line:no-any
      render: (dataItem: any) => {
        let browser: string;
        let title;

        if ([undefined, "unknown"].some((i) => i === dataItem.browser)) {
          browser = "Unknown";
          title = "?";
        } else {
          browser = dataItem.browser;
          title = dataItem.browser.replace(/([^-]+):([^-]+)/, replacer);
        }

        return (
          <Tooltip content={browser}>
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "location,runIndex,machine",
      numeric: false,
      disablePadding: false,
      label: "Location",
      style: { maxWidth: "10%", width: "10%", padding: "0px 10px" },
      render: (
        dataItem: any // tslint:disable-line
      ) => <span>{`${dataItem.location}`}</span>,
    },
    {
      id: "textError",
      numeric: false,
      disablePadding: false,
      label: "Reason",
      style: { width: "30%", padding: "0px 10px" },
      // tslint:disable-next-line: no-any
      render: (dataItem: any) => {
        const textError = dataItem.textError ? `${dataItem.textError.substring(0, 300)}` : "";
        const rtcSetAdditionalInfo = dataItem.rtcSetAdditionalInfo
          ? `${dataItem.rtcSetAdditionalInfo.substring(0, 300)}`
          : "";
        return textError.concat(rtcSetAdditionalInfo).substring(0, 300);
      },
    },
  ];
};
