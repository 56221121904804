// tslint:disable:no-any
import * as React from "react";
import View from "./View";
import AxiosFactory from "../../../services/AxiosFactory";
// import DbUtilsService from "../../../services/DbUtilsService";
import { RouteComponentProps, withRouter } from "react-router";
import * as _ from "lodash";

const DATA_SERIES_COLORS = [
  "#058DC7",
  "#50B432",
  "#ED561B",
  "#DDDF00",
  "#24CBE5",
  "#64E572",
  "#FF9655",
  "#FFF263",
  "#6AF9C4",
];

export type PerformanceState = {
  performanceData: any;
  cpuUsage: any;
  memoryUsage: any;
  cpuUsageByBrowser: any;
  memoryUsageByBrowser: any;
  networkUsage_packages: any;
  networkUsage_bytes: any;
  networkUsage: any;
  prepearingScreen: boolean;
  byProbe: any;
  byBrowser: any;
};

type RouteParams = {
  testRunId: string;
  objectId: string;
  iterationMachine: string;
  runIndex: string;
};

type PerformanceProps = {
  data: any;
  voiceStartTime?: any;
};

class Performance extends React.Component<
  PerformanceProps & RouteComponentProps<RouteParams>,
  PerformanceState
> {
  constructor(props: PerformanceProps & RouteComponentProps<RouteParams>) {
    super(props);

    this.state = {
      performanceData: null,
      cpuUsage: { dataset: [], options: {} },
      memoryUsage: { dataset: [], options: {} },
      cpuUsageByBrowser: { dataset: [], options: {} },
      memoryUsageByBrowser: { dataset: [], options: {} },
      networkUsage_packages: { dataset: [], options: {} },
      networkUsage_bytes: { dataset: [], options: {} },
      networkUsage: { dataset: [], options: {} },
      prepearingScreen: true,
      byProbe: { dataset: [], options: {} },
      byBrowser: { dataset: [], options: {} },
    };
  }

  async componentDidMount() {
    await this.loadPerformanceData();
    this.setState({ prepearingScreen: false });
  }

  async loadPerformanceData() {
    if (!this.props.data) {
      return;
    }
    const axiosFactory = new AxiosFactory();

    // const testIterationId = this.props.match.params.objectId;
    const { viewUrl } = this.props.data;
    const newState = {
      ...this.state,
    };

    if (viewUrl) {
      const answer = await axiosFactory.axios.get(viewUrl, {});
      newState.performanceData = answer.data;
      this.prepareSimpleChart(
        newState.memoryUsage,
        newState.performanceData,
        "memory",
        null,
        "Memory Usage (%)"
      );
      this.prepareSimpleChart(
        newState.cpuUsage,
        newState.performanceData,
        "cpu",
        null,
        "CPU Usage"
      );
      this.prepareSimpleChart(
        newState.cpuUsageByBrowser,
        newState.performanceData,
        "cpuUsedByBrowser",
        null,
        "CPU Usage"
      );
      this.prepareSimpleChart(
        newState.memoryUsageByBrowser,
        newState.performanceData,
        "memUsedByBrowser",
        this.transformBytesToKBytes,
        "Memory Usage"
      );
      this.prepareNetworkChart(
        newState.networkUsage_packages,
        newState.performanceData,
        "network",
        null,
        "Network (packages)",
        ["recvPackets", "sentPackets"]
      );
      this.prepareNetworkChart(
        newState.networkUsage_bytes,
        newState.performanceData,
        "network",
        null,
        "Network (bytes)",
        ["recvBytes", "sentBytes"]
      );
      this.setState({
        performanceData: answer.data,
      });
    } else {
      // const answer = await DbUtilsService.rpc("test_iterations", testIterationId);
      // newState.performanceData = answer.data.stat;
    }
    this.mergeGraphs(newState);
  }

  mergeGraphs(state: PerformanceState) {
    // merge by probe(usage) data
    const byProbe = { ...this.state.byProbe };
    byProbe.dataset = byProbe.dataset
      .concat(state.cpuUsage.dataset)
      .concat(state.memoryUsage.dataset);

    byProbe.options = {
      points: { show: false },
      lines: { show: true, fill: false },
      xAxis: {
        type: "datetime",
        labels: {
          formatter: function (context: any) {
            const value = context.value * 1000;
            const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
            const format = range < 3600 ? "%M:%S" : "%H:%M:%S";
            if (value >= 0) {
              return (window as any).Highcharts.dateFormat(format, value);
            } else {
              return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
            }
          },
        },
      },
    };

    // merge by browser data
    const byBrowser = { ...this.state.byBrowser };
    byBrowser.dataset = byBrowser.dataset
      .concat(state.cpuUsageByBrowser.dataset)
      .concat(state.memoryUsageByBrowser.dataset);

    byBrowser.dataset.forEach((d: any, index: number) => {
      d.yAxis = index;
    });

    byBrowser.options = {
      points: { show: false },
      lines: { show: true, fill: false },
      xAxis: {
        type: "datetime",
        labels: {
          formatter: function (context: any) {
            const value = context.value * 1000;
            const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
            const format = range < 3600 ? "%M:%S" : "%H:%M:%S";
            if (value >= 0) {
              return (window as any).Highcharts.dateFormat(format, value);
            } else {
              return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
            }
          },
        },
      },
      yAxis: [
        {
          title: {
            text: "%",
          },
        },
        {
          labels: {
            formatter: (e: any) => {
              return _.round(e.value / 1000);
            },
          },
          title: {
            text: "MBytes",
          },
          opposite: true,
        },
      ],
    };
    this.setState({
      byProbe,
      byBrowser,
    });
  }

  prepareGraphs(graphs: any) {
    const newState: PerformanceState = { ...this.state };
    const preparedGraphs: Array<any> = [];

    Object.keys(graphs).forEach((connection) => {
      Object.keys(graphs[connection]).forEach((report) => {
        const dataset = graphs[connection][report].series.map((serie: any, serieIdx: number) => {
          const data = {
            label: serie.name,
            color: DATA_SERIES_COLORS[serieIdx % DATA_SERIES_COLORS.length],
          } as any;

          let zero = true,
            // eslint-disable-next-line prefer-const
            pointsData = serie.data.map((point: any, idx: number) => {
              if (point) {
                zero = false;
              }
              return [idx, point];
            });

          if (!zero) {
            data.data = pointsData;
          }

          return data;
        });

        // Put all zero series to the end
        dataset.sort((a: any, b: any) => {
          if (!a.data && b.data) {
            return 1;
          } else if (a.data && !b.data) {
            return -1;
          } else {
            return 0;
          }
        });

        preparedGraphs.push({
          title: graphs[connection][report].title,
          options: {
            series: {
              lines: { show: true, lineWidth: 2 },
              points: { show: true, radius: 3 },
              shadowSize: 0,
            },
            grid: {
              hoverable: true,
              mouseActiveRadius: 10,
            },
            tooltip: {
              show: true,
              content: (yval: string) => {
                return "<strong>%x</strong><br/>%s — <strong>" + yval + "</strong>";
              },
            },
            yAxis: {
              formatter: (val: number) => {
                if (Math.abs(val) > 1000000) {
                  return (val / 1000000).toFixed(0) + "m";
                } else if (Math.abs(val) > 1000) {
                  return (val / 1000).toFixed(0) + "k";
                } else {
                  return val.toFixed(0);
                }
              },
            },
          },
          originalDataset: dataset,
          dataset: dataset,
          legend: dataset.map((serie: any) => ({
            label: serie.label,
            show: true,
            color: serie.color,
            zero: !serie.data,
          })),
        });
      });
    });
    newState.performanceData = preparedGraphs;
    this.setState(newState);
  }

  // handlers
  // pass ref to chart config and data with info about what to show
  prepareSimpleChart(chartCfg: any, data: any, field: string, transformFn: any, label?: string) {
    const voiceStartTimestamp = new Date(this.props.voiceStartTime).getTime();

    const defaultConfig = {
      points: { show: false },
      lines: { show: true, fill: false },
    };
    const voiceStartPerfIndex = data.findIndex((item: any) => item.ts >= voiceStartTimestamp);

    const numOfSteps = data.length;
    const stepValue = data[0].step; // step always the same

    // prepare data field
    chartCfg.dataset[0] = { data: [] };
    chartCfg.dataset[0].label = label;

    // prepare dataset
    let idx,
      step = voiceStartPerfIndex < 0 ? 0 : -(stepValue * voiceStartPerfIndex);
    for (idx = 0, step; idx < numOfSteps; idx++, step += stepValue) {
      // for (let partOfSecond = stepValue / 1000; partOfSecond < 1; partOfSecond += stepValue) {
      const current = data[idx];
      let value = transformFn ? transformFn(current[field]) : current[field];
      if (!value) {
        value = 0;
      }
      chartCfg.dataset[0].data.push([step / 1000, value]);
    }

    chartCfg.options = defaultConfig;
  }

  prepareNetworkChart(
    chartCfg: any,
    data: any,
    field: string,
    _transformFn: any,
    _label?: string,
    items?: Array<any>
  ) {
    const voiceStartTimestamp = new Date(this.props.voiceStartTime).getTime();
    const voiceStartPerfIndex = data.findIndex((item: any) => item.ts >= voiceStartTimestamp);

    const desiredInterface = "eth0";
    const defaultConfig = {
      xAxis: {
        type: "datetime",
        labels: {
          formatter: function (context: any) {
            const value = context.value * 1000;
            const range = context.chart?.xAxis[0]?.max || 0 - context.chart?.xAxis[0]?.min;
            const format = range < 3600 ? "%M:%S" : "%H:%M:%S";
            if (value >= 0) {
              return (window as any).Highcharts.dateFormat(format, value);
            } else {
              return `-${(window as any).Highcharts.dateFormat(format, Math.abs(value))}`;
            }
          },
        },
      },
      yAxis: {
        formatter: (val: number) => {
          if (Math.abs(val) > 1000000) {
            return _.round(val / 1000000, 0) + "m";
          } else if (Math.abs(val) > 1000) {
            return _.round(val / 1000, 0) + "k";
          } else {
            return _.round(val, 0);
          }
        },
      },
    };

    // get number of seconds
    const numOfSteps = data.length;
    const stepValue = data[0].step; // step always the same

    // we know how many series we need, it's 4
    // let sNum = Object.keys(data[0][field][desiredInterface]).length;
    if (items) {
      for (let idx = 0; idx < items.length; idx++) {
        // prepare data field
        chartCfg.dataset[idx] = { data: [] };
      }
    }

    // prepare dataset
    let _idx;
    if (items) {
      for (let sIdx = 0; sIdx < items.length; sIdx++) {
        let prevValue = 0;
        let lastValue = 0;
        let step = voiceStartPerfIndex < 0 ? 0 : -(stepValue * voiceStartPerfIndex);
        for (_idx = 0, step; _idx < numOfSteps; _idx++, step += stepValue) {
          const current = data[_idx][field];
          const next = data[_idx + 1] ? data[_idx + 1][field] : null;
          if (next !== null) {
            const xValue = step / 1000;
            const yValue =
              _label && _label?.indexOf("bytes") >= 0
                ? current[desiredInterface][items[sIdx]] / 125
                : current[desiredInterface][items[sIdx]];
            let newPoint;
            if (yValue > prevValue) {
              newPoint = [xValue, yValue - prevValue];
              lastValue = yValue - prevValue;
              prevValue = yValue;
            } else {
              newPoint = [xValue, lastValue];
            }

            chartCfg.dataset[sIdx].data[_idx] = newPoint;
            chartCfg.dataset[sIdx].label =
              // rename recvBytes and sentBytes as we transform them on line 440
              items[sIdx] === "recvBytes"
                ? "recvKbps"
                : items[sIdx] === "sentBytes"
                ? "sentKbps"
                : items[sIdx];
          }
        }
      }
    }

    chartCfg.options = defaultConfig;
  }

  transformBytesToKBytes(num: number) {
    const value = (num / 1024).toFixed(2);
    return parseFloat(value);
  }

  render() {
    return <View {...this.state} />;
  }
}

export default withRouter<PerformanceProps & RouteComponentProps<RouteParams>>(Performance);
