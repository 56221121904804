import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
// import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { FetchUser, ISetUserAction } from "../../actions/authAction";
import { Login as LoginRoutes } from "../../constants/RoutesNames";
import AuthService from "../../services/AuthService";
import { AxiosResponse } from "axios";
import { ThunkDispatch } from "redux-thunk";
import { ReactSVG } from "react-svg";
import LoaderIcon from "../../assets/images/loading-progress.svg";

interface IAuthProviderProps {
  user: User;
}

interface AuthProviderState {
  fetching: boolean;
}

interface IAuthProviderDispatch {
  authorize(): Promise<AxiosResponse>;
}

type StyledComponent = WithStyles<"circularProgress">;

const validNotLogin = /(reset|confirm-account|analyze|shared|report\/free\/[a-f\d]{24})/;

class AuthProvider extends React.Component<
  IAuthProviderProps & IAuthProviderDispatch & RouteComponentProps<{}> & StyledComponent,
  AuthProviderState
> {
  private initialState: AuthProviderState = {
    fetching: true,
  };

  constructor(
    props: IAuthProviderProps & IAuthProviderDispatch & StyledComponent & RouteComponentProps<{}>
  ) {
    super(props);

    this.state = this.initialState;
  }

  async componentDidMount() {
    await this.fetchUser();
  }

  async fetchUser() {
    await this.setState({
      fetching: true,
    });

    try {
      AuthService.collectDocumentCookies();

      await this.props.authorize();
      await this.setState({
        fetching: false,
      });
    } catch (e) {
      AuthService.logout();
      await this.setState({
        fetching: false,
      });

      const forceRedirect = validNotLogin.test(this.props.location.pathname);
      if (forceRedirect) {
        this.props.history.push(this.props.location.pathname);
        return;
      }

      if (window.location.pathname !== LoginRoutes.SignIn) {
        AuthService.setRedirectUrl(window.location.href);
      }
      this.props.history.push(LoginRoutes.SignIn);
    }
  }

  render() {
    const { classes } = this.props;
    const { fetching } = this.state;

    return fetching ? (
      <ReactSVG src={LoaderIcon} className={classes.circularProgress} />
    ) : (
      this.props.children
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    circularProgress: {
      position: "fixed",
      left: "calc(50% - 64px)",
      top: "calc(50% - 64px)",
    },
  });

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IStore, null, ISetUserAction>
): IAuthProviderDispatch => ({
  authorize: () => dispatch(FetchUser()),
});

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

const decorate = withStyles(styles);

export default withRouter<RouteComponentProps<{}>>(
  connect<IAuthProviderProps, IAuthProviderDispatch, RouteComponentProps<{}>>(
    mapStateToProps,
    mapDispatchToProps
  )(decorate(AuthProvider))
);
