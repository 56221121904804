import * as React from "react";
import { checkNan } from "../../../../helpers/testDetails";
import { WithStyles } from "@material-ui/core";

type CompWithStyles = WithStyles;

type InfoRowProps = {
  items: string[];
  isHeader?: boolean;
};

export const InfoRow = (props: CompWithStyles & InfoRowProps) => {
  const { items, isHeader, classes } = props;

  if (!items.length) {
    return null;
  }
  return (
    <div style={{ display: "flex", width: "100%" }}>
      {items.map((item, index) => {
        if (item === "" || checkNan(item)) {
          return (
            <div className={`${index ? classes.rowItem : classes.rowItemLabel}`} key={index}>
              {!index || isHeader ? <b>{item}</b> : item}
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default InfoRow;
