import * as React from "react";
import { merge } from "lodash";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface ChartProps {
  series: any[];
  dayNames: string[];
  width?: number;
}

export const RibbonChart = (props: ChartProps) => {
  const defaultOptions: Highcharts.Options = {
    legend: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    lang: {
      noData: "No ribbon data",
    },
    chart: {
      type: "column",
      height: 60,
      margin: 0,
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      width: props.width || document.body.offsetWidth < 1680 ? 200 : 300,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: props.dayNames,
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    tooltip: {
      // pointFormat:
      //   '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      useHTML: true,
      outside: true,
      formatter: function () {
        const points = this.points || [];
        let markup = `<span style="font-size: 10px;">${points[0].key} ( run count: ${points[0].total} )</span><br/>`;
        let index = 0;
        for (const point of points) {
          if (point.percentage) {
            const style = "font-size: 10px; margin-left: 5px";
            markup += `<span style='${style}'><span style='${style}; color: ${
              point.color
            }'>⬤ </span>${point.series.name}: ${point.y} (${Math.round(point.percentage)}%)</span>`;
            if (index !== 3) {
              markup += "<br/>";
            }
            index++;
          }
        }
        return markup;
      },
      padding: 4,
      shared: true,
    },
    plotOptions: {
      column: {
        stacking: "percent",
        groupPadding: 0,
      },
      series: {
        events: {
          mouseOut: function () {
            setTimeout(() => {
              this.chart.tooltip.hide();
            }, 1000);
          },
        },
      },
    },
  };

  const options: Highcharts.Options = merge(defaultOptions, { series: props.series });

  return <HighchartsReact options={options} highcharts={Highcharts} />;
};
