import * as React from "react";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { TestIterationState, TestIterationContext } from "./index";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Components from "./Components";
import { ReactSVG } from "react-svg";
import LoaderIcon from "../../assets/images/loading-progress.svg";
import { Typography } from "@material-ui/core";

type StyledComponent = WithStyles<"circularProgress" | "detailsContainer">;

class View extends React.Component<StyledComponent & TestIterationState> {
  render() {
    const { classes } = this.props;
    return (
      <TestIterationContext.Consumer>
        {({ test, testRun, isReport, testEvents, error, freeReport }) => {
          return (
            <React.Fragment>
              {error !== "" && (
                <div className={isReport || freeReport ? "" : classes.circularProgress}>
                  <Typography variant="h4">{error}</Typography>
                </div>
              )}
              {!test && error === "" && (
                <ReactSVG
                  src={LoaderIcon}
                  className={isReport || freeReport ? "" : classes.circularProgress}
                />
              )}
              {test && error === "" && (
                <Fade in={true}>
                  <Grid container={true} className={classes.detailsContainer}>
                    <Components
                      test={test}
                      testRun={testRun}
                      isReport={isReport}
                      testEvents={testEvents}
                    />
                  </Grid>
                </Fade>
              )}
            </React.Fragment>
          );
        }}
      </TestIterationContext.Consumer>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    circularProgress: {
      position: "fixed",
      left: "calc(50% - 64px)",
      top: "calc(50% - 64px)",
    },
    detailsContainer: {
      // maxWidth: 860,
      width: "100%",
      margin: "auto",
    },
  });

const decorate = withStyles(styles);

export default decorate(View);
