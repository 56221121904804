import * as React from "react";
import { RouteComponentProps } from "react-router";
import GridControl from "./GridControl";
import withBreadcrumb from "../../components/withBreadcrumb";
import { AppToolbarContext } from "../Layout/components/AppToolbar/Context";

class Assets extends React.Component<RouteComponentProps<{}>> {
  static contextType = AppToolbarContext;

  componentDidMount() {
    this.context.setPageInfo("");
  }
  render() {
    return <GridControl {...this.props} />;
  }
}

export default withBreadcrumb(Assets);
