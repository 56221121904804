import * as React from "react";
import { connect } from "react-redux";

import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import Hidden from "@material-ui/core/Hidden";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { DrawerWidth, NavbarHeight } from "src/styles/Constants";
import { AppControllerState, AppStoreProps } from "src/containers/AppController";
import { App as AppRoutes } from "src/constants/RoutesNames";

import UsersIcon from "@material-ui/icons/Group";
import ProjectsIcon from "@material-ui/icons/FolderOpen";
import ProbesSetupIcon from "@material-ui/icons/Storage";
import UsageGraphIcon from "@material-ui/icons/BarChart";
import AdminUtilsIcon from "@material-ui/icons/OpenWith";
import BillingDataIcon from "@material-ui/icons/Receipt";
import AnalyticsEventsIcon from "@material-ui/icons/Event";

import TestingRTCIcon from "src/assets/images/icons/svg/testingRTC.svg";
import UpRTCIcon from "src/assets/images/icons/svg/upRTC.svg";
import QualityRTCIcon from "src/assets/images/icons/svg/qualityRTC.svg";
import WatchRtcIcon from "src/assets/images/icons/svg/watchRTC.svg";
import ProbeRTCIcon from "src/assets/images/icons/svg/probeRTC.svg";
import AnalyzeRTCIcon from "src/assets/images/icons/svg/analyzeRTC.svg";

import HomeIcon from "src/assets/images/icons/svg/home.svg";
import SettingsIcon from "src/assets/images/icons/svg/settings.svg";
import MyProfileIcon from "src/assets/images/icons/svg/my-profile.svg";
import BillingIcon from "src/assets/images/icons/svg/billing.svg";

import NavList from "./NavList";

interface SidebarProps {
  mobileOpen: boolean;
  user: User;
  handleDrawerToggle(): void;
}

const Sidebar = (props: SidebarProps & AppControllerState & AppStoreProps) => {
  const { handleDrawerToggle, mobileOpen, selectedProject, user } = props;
  const classes = useStyles();
  const isAdmin = user?.role === "admin";

  const isQualityRtcEnabled = () => {
    return selectedProject?.qualityrtc_enable;
  };

  const isQualityRtcInvitesEnabled = () => {
    return selectedProject?.qualityrtc_inviteEnable;
  };

  const isOPPEnabled = () => {
    return selectedProject?.opp_enable;
  };

  const isWatchRTCEnabled = () => {
    return selectedProject?.watchrtc_enable;
  };

  const isWatchRTCLiveEnabled = () => {
    return selectedProject?.watchrtc_live_enable;
  };

  const accountIsSuspended = () => {
    return selectedProject && selectedProject.accountType === "Suspended";
  };

  const isMonitoringDisabled =
    accountIsSuspended() || (selectedProject && !selectedProject.enableMonitoring);

  const homeItems = [
    {
      name: "Home",
      link: AppRoutes.Main,
      Icon: HomeIcon,
      svgIcon: true,
    },
  ];
  const testingRTCItems = [
    {
      name: "testingRTC",
      link: AppRoutes.Tests,
      Icon: TestingRTCIcon,
      svgIcon: true,
      items: [
        {
          name: "Scripts",
          link: AppRoutes.Tests,
        },
        {
          name: "History",
          link: AppRoutes.TestRun,
        },
        {
          name: "Assets",
          link: AppRoutes.Assets,
          disabled: () => {
            return accountIsSuspended();
          },
        },
      ],
    },
  ];
  const monitorItems = [
    {
      name: "upRTC",
      link: isMonitoringDisabled ? AppRoutes.MonitorRun : AppRoutes.Monitoring,
      Icon: UpRTCIcon,
      svgIcon: true,
      items: [
        {
          name: "Monitors",
          link: AppRoutes.Monitoring,
          disabled: () => {
            return isMonitoringDisabled;
          },
        },
        {
          name: "History",
          link: AppRoutes.MonitorRun,
          disabled: () => {
            return accountIsSuspended();
          },
        },
      ],
    },
    {
      name: "watchRTC",
      link: AppRoutes.WatchRTCHighlights,
      Icon: WatchRtcIcon,
      svgIcon: true,
      hidden: () => {
        return !isWatchRTCEnabled();
      },
      items: [
        {
          name: "Highlights",
          link: AppRoutes.WatchRTCHighlights,
        },
        {
          name: "Trends",
          link: AppRoutes.WatchRTCTrends,
        },
        {
          name: "History",
          link: AppRoutes.WatchRTCRoom,
        },
        {
          name: "Live",
          link: AppRoutes.WatchRTCLive,
          hidden: () => {
            return !isWatchRTCLiveEnabled();
          },
        },
      ],
    },
  ];
  const supportItems = [
    {
      name: "qualityRTC",
      link: isQualityRtcInvitesEnabled() ? AppRoutes.QualityRTCInvites : AppRoutes.QualityRTC,
      Icon: QualityRTCIcon,
      svgIcon: true,
      hidden: () => {
        return !isQualityRtcEnabled();
      },
      items: [
        {
          name: "Invites",
          link: AppRoutes.QualityRTCInvites,
          hidden: () => !isQualityRtcInvitesEnabled(),
        },
        {
          name: "History",
          link: AppRoutes.QualityRTC,
        },
      ],
    },
    {
      name: "probeRTC",
      link: AppRoutes.OnPremiseProbe,
      Icon: ProbeRTCIcon,
      svgIcon: true,
      hidden: () => {
        return !isOPPEnabled();
      },
    },
    {
      name: "analyzeRTC",
      link: AppRoutes.AnalyzeDump,
      Icon: AnalyzeRTCIcon,
      svgIcon: true,
      disabled: () => {
        return accountIsSuspended();
      },
    },
  ];
  const optionsItems = [
    {
      name: "Settings",
      link: AppRoutes.Settings,
      Icon: SettingsIcon,
      svgIcon: true,
    },
    {
      name: "My profile",
      link: AppRoutes.MyProfile,
      Icon: MyProfileIcon,
      svgIcon: true,
      disabled: () => true,
    },
    {
      name: "Billing",
      link: AppRoutes.AccountBilling,
      Icon: BillingIcon,
      svgIcon: true,
      disabled: () => true,
    },
  ];
  const adminItems = [
    {
      name: "User Accounts",
      link: AppRoutes.UserAccounts,
      Icon: UsersIcon,
    },
    {
      name: "Projects",
      link: AppRoutes.Projects,
      Icon: ProjectsIcon,
    },
    {
      Icon: ProbesSetupIcon,
      name: "Probes",
      link: AppRoutes.Probes,
    },
    {
      name: "Probes Setup",
      link: AppRoutes.ProbesSetup,
      Icon: ProbesSetupIcon,
    },
    {
      name: "Dynamic Probes",
      link: AppRoutes.DynamicProbe,
      Icon: ProbesSetupIcon,
    },
    {
      name: "qualityRTC Server",
      link: AppRoutes.QualityRTCServer,
      Icon: QualityRTCIcon,
      svgIcon: true,
    },
    {
      name: "Admin Utils",
      link: AppRoutes.AdminUtils,
      Icon: AdminUtilsIcon,
    },
    {
      name: "Usage Graph",
      link: AppRoutes.UsageGraphs,
      Icon: UsageGraphIcon,
    },
    {
      name: "Billing Data",
      link: AppRoutes.BillingData,
      Icon: BillingDataIcon,
    },
    {
      name: "Analytics Events",
      link: AppRoutes.AnalyticsEvents,
      Icon: AnalyticsEventsIcon,
    },
  ];

  const renderDrawerList = () => {
    return (
      <div>
        <NavList items={homeItems} />
        {renderUserGroupHeader("TEST")}
        <NavList items={testingRTCItems} />
        {renderUserGroupHeader("MONITOR")}
        <NavList items={monitorItems} />
        {renderUserGroupHeader("SUPPORT")}
        <NavList items={supportItems} />
        {renderUserGroupHeader("OPTIONS")}
        <NavList items={optionsItems} />
        {isAdmin && (
          <>
            {renderAdminGroupHeader("ADMIN")}
            <NavList items={adminItems} />
          </>
        )}
      </div>
    );
  };

  const renderUserGroupHeader = (name: string) => {
    return (
      <ListSubheader disableSticky={true} className={classes.userGroupHeader}>
        <Typography variant={"body2"}>{name}</Typography>
      </ListSubheader>
    );
  };

  const renderAdminGroupHeader = (name: string) => {
    return (
      <ListSubheader disableSticky={true} className={classes.navListAdminHeader}>
        <Typography variant="body2">{name}</Typography>
      </ListSubheader>
    );
  };

  return (
    <>
      <Hidden lgUp={true}>
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            docked: classes.drawerDocked,
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {renderDrawerList()}
        </Drawer>
      </Hidden>
      <Hidden mdDown={true} implementation="css">
        <Drawer
          variant="permanent"
          open={true}
          classes={{
            docked: classes.drawerDocked,
            paper: classes.drawerPaper,
          }}
        >
          {renderDrawerList()}
        </Drawer>
      </Hidden>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerDocked: {
      height: `calc(100% - ${NavbarHeight}px)`,
      marginTop: NavbarHeight,
    },
    drawerPaper: {
      backgroundColor: "#273238",
      width: DrawerWidth,
      height: "100%",
      [theme.breakpoints.up("md")]: {
        position: "relative",
      },
    },
    navListAdminHeader: {
      color: theme.palette.primary.main,
      marginTop: theme.spacing(3),
    },
    userGroupHeader: {
      color: theme.palette.primary.main,
    },
  })
);

const mapStateToProps = (state: IStore) => ({
  user: state.userAuth.user,
});

export default connect(mapStateToProps, null, null, {
  pure: false,
})(Sidebar);
