import * as React from "react";
import { connect } from "react-redux";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import CssBaseline from "@material-ui/core/CssBaseline";
import Router from "./Router";
import SnackbarNotifier from "./containers/SnackbarNotifier";
import { SetNotification } from "./actions/notificationAction";

// ag-grid
import { LicenseManager } from "@ag-grid-enterprise/core";
import { ModuleRegistry } from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";

LicenseManager.setLicenseKey(
  // tslint:disable-next-line: max-line-length
  "CompanyName=testRTC LTD,LicensedApplication=testRTC,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-007696,ExpiryDate=8_April_2021_[v2]_MTYxNzgzNjQwMDAwMA==d147aa054e66c1a900057ed4add43efd"
);

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#6598d1",
      main: "#53b6ef",
      dark: "#3e76b2",
      contrastText: "#ffffff",
    },
    secondary: {
      light: green[500],
      main: "#559542",
      dark: green[800],
      contrastText: "#ffffff",
    },
    error: {
      main: "#A22A21",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: ".8125rem",
      },
    },
    MuiTableSortLabel: {
      icon: {
        height: 15,
        width: 15,
        marginLeft: 2,
        marginTop: 1,
      },
    },
  },
});

type AppDispatch = {
  setNotification(message: string, type?: string): void;
};

export type AppState = {
  error: Error | null;
};

export const AppContext = React.createContext({
  error: null,
} as AppState);

export class App extends React.Component<AppDispatch, AppState> {
  constructor(props: AppDispatch) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    ModuleRegistry.registerModules([ColumnsToolPanelModule, RowGroupingModule]);
  }

  componentDidCatch(error: Error, _info: React.ErrorInfo) {
    this.setState({
      error: error,
    });
    this.props.setNotification("Unexpected error occurred. Please, contact support.");
  }

  public render() {
    return (
      <AppContext.Provider value={this.state}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Router />
          <SnackbarNotifier />
        </MuiThemeProvider>
      </AppContext.Provider>
    );
  }
}

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any): AppDispatch => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

// tslint:disable-next-line:no-any
export default connect<any, AppDispatch, any>(null, mapDispatchToProps)(App);
