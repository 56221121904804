// tslint:disable:no-any
import * as React from "react";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { makeStyles, createStyles, Theme } from "@material-ui/core";

interface IWidget {
  hide?: boolean;
  title: string;
  action?: JSX.Element;
  children: any;
  marginBottom?: any;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    card: {
      padding: "20px 24px",
      "& h4": {
        fontWeight: 300,
      },
    },
    toolbar: {
      minHeight: 10,
      height: 18,
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    divider: {
      margin: "16px -24px",
    },
  })
);

const Widget = ({ hide, title, action, children, marginBottom }: IWidget) => {
  const classes = useStyles();

  if (hide) {
    return null;
  }

  return (
    <Fade in={true}>
      <Grid item={true} xs={12}>
        <Card className={classes.card} style={{ marginBottom: marginBottom }}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="subtitle1">{title}</Typography>
            {action && action}
          </Toolbar>
          <Divider className={classes.divider} />
          <Grid container={true} spacing={2}>
            {children}
          </Grid>
        </Card>
      </Grid>
    </Fade>
  );
};
export default Widget;
