import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import FormValidators from "src/helpers/form-validators";

// tslint:disable-next-line:no-any
function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

interface ISubscribeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
}

class SendFeedbackModal extends React.Component<ISubscribeModalProps> {
  public render(): JSX.Element {
    return (
      <Dialog
        open={this.props.isOpen}
        TransitionComponent={Transition}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Form
          onSubmit={this.props.onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="alert-dialog-slide-title">Send us feedback</DialogTitle>
              <DialogContent style={{ width: 600 }}>
                <DialogContentText id="alert-dialog-slide-description">
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                      <Field
                        component={TextFieldControl}
                        type="text"
                        multiline={true}
                        rows="5"
                        name="feedback"
                        validate={FormValidators.required()}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="secondary">
                  Send
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    );
  }
}

export default SendFeedbackModal;
