import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import RTCGrid from "../../components/Grid";
import ApiPath from "../../constants/ApiPath";
import { Assets } from "../../constants/RoutesNames";
import { RouteComponentProps } from "react-router";
import GridToolbar from "./GridToolbar";
import * as moment from "moment";
import AxiosFactory from "src/services/AxiosFactory";
import { connect } from "react-redux";
import { SetNotification } from "src/actions/notificationAction";

type StyledComponent = WithStyles<"root" | "tableRowItemHover" | "hoverActionBtnStyle">;

type DispatchProps = {
  setNotification: (message: string) => void;
};

export class GridControl extends React.Component<
  RouteComponentProps<{}> & DispatchProps & StyledComponent
> {
  grid: any = null;
  columnSchema: Array<ColumnSchema> = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "asset_type",
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: "Type",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: "Description",
    },
    {
      id: "version",
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: "Version",
    },
    {
      id: "updated",
      numeric: false,
      disablePadding: false,
      label: "Updated",
      // tslint:disable-next-line:no-any
      render: (dataItem: any) =>
        dataItem.updated ? moment(dataItem.updated).format("MMM DD, YYYY - HH:mm") : "never",
    },
  ];

  constructor(props: RouteComponentProps<{}> & DispatchProps & StyledComponent) {
    super(props);

    this.state = {
      filters: [],
      testRuns: [],
    };

    this.onRowClick = this.onRowClick.bind(this);
    this.onAssetImport = this.onAssetImport.bind(this);
  }

  onRowClick(e: React.MouseEvent<HTMLTableRowElement>, dataItem: GridModel) {
    this.props.history.push(`${Assets}/${dataItem._id}`);
    e.stopPropagation();
  }

  // tslint:disable-next-line: no-any
  async onAssetImport(file: any) {
    const formData = new FormData();
    formData.append("file", file);

    const axiosFactory = new AxiosFactory();
    try {
      const res = await axiosFactory.axios.post(`${ApiPath.api.assetImport}`, formData, {
        headers: { "content-type": "multipart/form-data" },
      });
      this.grid.reloadData();
      this.props.setNotification(`Asset Imported: ${res.data.name}`);
    } catch (e) {
      if (
        e.response &&
        e.response.status === 422 &&
        e.response.data &&
        typeof e.response.data === "string"
      ) {
        this.props.setNotification("Uploaded file is not asset.");
      } else {
        this.props.setNotification("Please upload correct test file.");
      }
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        <GridToolbar onAssetImport={this.onAssetImport} />
        <RTCGrid
          ref={(element: any) => (this.grid = element)}
          onRowClick={this.onRowClick}
          search={true}
          remoteDataBound={true}
          searchByLabel={"Name/Description/Version"}
          apiRoute={ApiPath.api.assets}
          columnSchema={this.columnSchema}
          defaultSort={{
            order: "asc",
            orderBy: "name",
          }}
          rowProps={{
            className: classes.tableRowItemHover,
          }}
          pagination={true}
          // eslint-disable-next-line no-restricted-globals
          location={location}
        />
      </Paper>
    );
  }
}

const styles = (_theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    tableRowItemHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f7f7f7",
        "& button": {
          visibility: "visible",
          pointerEvents: "all",
        },
      },
    },
    hoverActionBtnStyle: {
      padding: "0px",
      width: 35,
      minWidth: 35,
      height: 35,
      minHeight: 35,
      visibility: "hidden",
      pointerEvents: "none",
    },
  });

const decorate = withStyles(styles);

// tslint:disable-next-line: no-any
const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect(
  null,
  mapDispatchToProps
  // tslint:disable-next-line: no-any
)(decorate<any>(GridControl));
