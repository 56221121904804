// tslint:disable:no-any

import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import ApiPath from "../../constants/ApiPath";
import View from "./View";
import AxiosFactory from "../../services/AxiosFactory";
import { TestIteration } from "../../constants/RoutesNames";
import withBreadcrumb from "../../components/withBreadcrumb";
import { SetNotification } from "src/actions/notificationAction";
import { ProjectsContext, AppControllerState } from "src/containers/AppController";

const axiosFactory = new AxiosFactory();

export type AnalyzeDumpState = {
  acceptedFiles: Array<any>;
  rejectedFiles: Array<any>;
  uploadState: Array<any>;
  showAnalyzeRtcTable: boolean;
};

interface IAnalyzeDump {
  setNotification: (message: string) => void;
}

class AnalyzeDump extends React.Component<
  IAnalyzeDump & RouteComponentProps<{}>,
  AnalyzeDumpState
> {
  constructor(props: IAnalyzeDump & RouteComponentProps<{}>) {
    super(props);

    this.state = {
      acceptedFiles: [],
      rejectedFiles: [],
      uploadState: [],
      showAnalyzeRtcTable: false,
    };

    this.handleDrop = this.handleDrop.bind(this);
    this.handleResults = this.handleResults.bind(this);
  }

  UNSAFE_componentWillMount() {
    const defaultDateFilter = JSON.stringify([{ field: "createDate", value: "30" }]);
    window.history.replaceState({}, "", `?filter=${btoa(defaultDateFilter)}`);
  }

  async handleDrop(acceptedFiles: any, _rejectedFiles: any) {
    let index = this.state.acceptedFiles.length;

    await this.setState({
      acceptedFiles: [...this.state.acceptedFiles, ...acceptedFiles],
      uploadState: [
        ...this.state.uploadState,
        ...acceptedFiles.map((_f: any) => ({ pending: true })),
      ],
    });

    for (const file of acceptedFiles) {
      const formData = new FormData();

      formData.append("file", file);

      try {
        const { data } = await axiosFactory.axios.post(`${ApiPath.api.analyzeDatas}`, formData, {
          headers: { "content-type": "multipart/form-data" },
        });

        await this.setState({
          // eslint-disable-next-line
          uploadState: this.state.uploadState.map((f, j: number) =>
            j === index ? { ...data, pending: false } : f
          ),
        });

        index++;
      } catch (e) {
        await this.setState({
          // eslint-disable-next-line
          uploadState: this.state.uploadState.map((f, j: number) =>
            j === index ? { pending: false, error: true } : f
          ),
        });
      }
    }
  }

  handleResults(file: any, item: any) {
    const dumpType = this.gainDumpType(file.name);

    if (dumpType === "performance") {
      this.props.history.push(`app/logs/${dumpType}//${item._id}//`);
    } else {
      // could be dumpType === 'other' or any type
      this.props.history.push(`${TestIteration}/${item._id}`);
    }
  }

  toggleAnalyzeRtcTable = async () => {
    await this.setState({
      ...this.state,
      showAnalyzeRtcTable: !this.state.showAnalyzeRtcTable,
    });
  };

  render() {
    return (
      <ProjectsContext.Consumer>
        {(props: AppControllerState) => {
          if (!props.selectedProject) {
            return null;
          }
          return (
            <View
              {...props}
              {...this.state}
              handleDrop={this.handleDrop}
              handleResults={this.handleResults}
              toggleAnalyzeRtcTable={this.toggleAnalyzeRtcTable}
              {...this.props}
            />
          );
        }}
      </ProjectsContext.Consumer>
    );
  }

  private gainDumpType(fileName: string) {
    const isPerformance = fileName.indexOf("performance");
    return isPerformance !== -1 ? "performance" : "other";
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default connect(null, mapDispatchToProps)(withBreadcrumb(AnalyzeDump));
