import * as moment from "moment";
import "moment-duration-format";

export function formatDuration(duration: number, format: "DHMSms" | "DHMS" | "DHM" | "M"): string {
  switch (format) {
    case "DHMSms":
      const secondsText = duration < 1000 ? "ms" : "s";
      return (moment.duration(duration) as any).format(`D[d] H[h] m[m] s.SSS[${secondsText}]`);
    case "DHMS":
      return (moment.duration(duration) as any).format("D[d] H[h] m[m] s[s]");
    case "DHM":
      return (moment.duration(duration) as any).format("D[d] H[h] m[m]");
    case "M":
      return (moment.duration(duration) as any).format("m");
    default:
      return "";
  }
}

/*
// tslint:disable-next-line:no-any
export function calcChannelStat(stat: any) {
  return {
    packerErrorPCT: (stat.packetLoss * 100) / stat.totalPackets || 0,
    effectiveBitRate: stat.bytes / stat.voiceDuration,
  };
}
*/

// tslint:disable-next-line:no-any
export function formatScore(stat: any): string {
  const score = (stat && stat.rank && stat.rank ? Number(stat.rank) : 0) || 0;
  return score.toFixed(1);
}

export function formatNumber(value: unknown, maximumFractionDigits = 0): string {
  const val = Number(value) || 0;
  // return val.toLocaleString("en", { maximumFractionDigits });
  return val.toFixed(maximumFractionDigits);
}

// tslint:disable-next-line:no-any
export function checkNan(value: any) {
  if (value === "NaN" || Number.isNaN(value)) {
    return "";
  }
  return value;
}

export function getPerformanceAverages(stat: any) {
  const res = {};
  const { performance, performanceData } = stat;
  if (performance) {
    ["browserCpu", "browserMemory", "probeCpu", "probeMemory"].forEach((prop) => {
      res[prop] = performance[prop].avg || null;
    });
    return res;
  } else if (performanceData) {
    return performanceData;
  } else {
    return res;
  }
}
