import * as React from "react";
import * as _ from "lodash";

import LegendList from "./components/LegendList";
import InOutToggle from "./components/InOutToggle";
import ToggleAll from "./components/ToggleAll";

export interface ILegendProps {
  syncLegends?: boolean;
  suppressDirectionSortToLegend?: boolean;
  toggleAll?: boolean;
}

interface IInternalLegendProps extends ILegendProps {
  chartRef: any;
}

interface ILegendContext {
  chart: any;
  series: any[];
  syncLegends: boolean;
  suppressDirectionSortToLegend: boolean;
  toggleAll: boolean;
  setSeries: (series: any[]) => void;
}

export const LegendContext = React.createContext<ILegendContext>({} as any);

const Legend = (props: IInternalLegendProps) => {
  const [chart, setChart] = React.useState<any>();
  const [series, setSeries] = React.useState<any[]>([]);
  const [syncLegends, setSyncLegends] = React.useState<boolean>(false);
  const [suppressDirectionSortToLegend, setSuppressDirectionSortToLegend] = React.useState<boolean>(
    true
  );
  const [toggleAll, setToggleAll] = React.useState<boolean>(false);

  React.useEffect(() => {
    setSyncLegends(props.syncLegends as boolean);
    setSuppressDirectionSortToLegend(props.suppressDirectionSortToLegend as boolean);
    setToggleAll(props.toggleAll as boolean);
  }, []);

  React.useEffect(() => {
    if (props.chartRef?.chart?.series) {
      setChart(props.chartRef?.chart);
      setSeries(_.cloneDeep(props.chartRef.chart.series));
    }
  }, [props.chartRef]);

  return (
    <LegendContext.Provider
      value={{
        chart,
        series,
        syncLegends,
        suppressDirectionSortToLegend,
        toggleAll,
        setSeries,
      }}
    >
      <div className="custom-legend">
        <LegendList />
        <InOutToggle />
        <ToggleAll />
      </div>
    </LegendContext.Provider>
  );
};

export default Legend;
