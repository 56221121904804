import * as React from "react";
import { RouteProps } from "react-router";
import View from "./View.tsx";
import { getLocationTitle } from "../../constants/PageStateDictionary";

export type AppLayoutState = {
  mobileOpen: boolean;
};

export default class AppLayout extends React.Component<RouteProps, AppLayoutState> {
  private initialState: AppLayoutState = {
    mobileOpen: false,
  };

  constructor(props: RouteProps) {
    super(props);

    this.state = this.initialState;

    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  public handleDrawerToggle(): void {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  render() {
    const { location } = this.props;
    const title: string = location?.pathname ? getLocationTitle(location?.pathname) : "testRTC";
    document.title = title;

    return <View handleDrawerToggle={this.handleDrawerToggle} {...this.state} />;
  }
}
