import * as React from "react";
import Dropzone from "react-dropzone";

import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppControllerState } from "../AppController";

interface IDropSection {
  acceptedFiles: any[];
  uploadState: any[];
  handleResults: (file: any, item: any) => void;
  handleDrop: (accepted: any, rejected: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    divider: {
      margin: "16px -16px",
    },
    analyzeBody: {
      minHeight: 120,
    },
    list: {
      width: "100%",
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    fileName: {
      maxWidth: 500,
      whiteSpace: "normal",
      wordBreak: "break-all",
    },
    listItemControls: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    progress: {
      minWidth: 150,
      minHeight: 10,
    },
    resultsBtn: {
      marginLeft: theme.spacing(2),
    },
    dropzone: {
      userSelect: "none",
    },
    uploadIcon: {
      width: 400,
      height: "auto",
      display: "block",
    },
    uploadBtn: {
      margin: "10px 0",
    },
    input: {
      display: "none",
    },
  })
);

export default function DropSection({
  acceptedFiles,
  uploadState,
  handleResults,
  handleDrop,
  selectedProject,
}: IDropSection & AppControllerState) {
  const classes = useStyles();
  let dropzoneRef: any = null;
  return (
    <Grid item={true} xs={selectedProject?.analyzertc_enable ? 7 : 12}>
      <Dropzone
        ref={(node) => {
          dropzoneRef = node;
        }}
        disableClick={true}
        accept="text/plain, application/json"
        onDrop={handleDrop}
        className={classes.dropzone}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="subtitle1">
            <svg
              className={classes.uploadIcon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
            >
              <path d="M 8 2 C 6.169 2 4.5757656 3.2743906 4.1347656 5.0253906 C 3.0557656 5.1843906 2.1982031 6.0411562 2.0332031 7.1601562 C 0.83420313 7.5671563 0 8.706 0 10 C 0 11.654 1.346 13 3 13 L 6 13 L 6 12 L 3 12 C 1.897 12 1 11.103 1 10 C 1 9.051 1.6745156 8.2260625 2.6035156 8.0390625 L 3.0117188 7.921875 L 3 7.5 C 3 6.673 3.6732188 5.9990938 4.4492188 5.9960938 L 4.5507812 6.0058594 L 4.9824219 6.0058594 L 5.0449219 5.5742188 C 5.2539219 4.1062188 6.525 3 8 3 C 9.208 3 10.292672 3.7180781 10.763672 4.8300781 L 10.943359 5.2558594 L 11.380859 5.109375 C 11.606859 5.035375 11.809 5 12 5 C 13.103 5 14 5.897 14 7 C 14 7.233 13.953328 7.4739375 13.861328 7.7109375 L 13.716797 8.09375 L 14.0625 8.3125 C 14.6495 8.6845 15 9.314 15 10 C 15 11.103 14.103 12 13 12 L 9 12 L 9 13 L 13 13 C 14.654 13 16 11.654 16 10 C 16 9.101 15.600063 8.2633125 14.914062 7.6953125 C 14.971063 7.4643125 15 7.231 15 7 C 15 5.18 13.389187 3.6958281 11.492188 4.0488281 C 10.790188 2.7918281 9.463 2 8 2 z M 7.5 8 L 5 11 L 7 11 L 7 15 L 8 15 L 8 11 L 10 11 L 7.5 8 z" />
            </svg>
            {"Please drag and drop a WebRTC-internals file here."}
          </Typography>
          <Typography>Or simply click upload button.</Typography>
          <Button
            className={classes.uploadBtn}
            variant="contained"
            color="primary"
            component="span"
            onClick={(e: any) => {
              e.preventDefault();
              dropzoneRef.open();
            }}
          >
            Upload
          </Button>
          <Typography variant="caption">
            {"Not sure how to get webrtc-internals? Watch"}{" "}
            <a
              href="https://www.youtube.com/watch?v=sTij1EnYFJw"
              target="_blank"
              rel="noopener noreferrer"
            >
              this video
            </a>
          </Typography>
        </Toolbar>
        <Divider className={classes.divider} />
        <Grid container={true} spacing={5} className={classes.analyzeBody}>
          <List className={classes.list}>
            {acceptedFiles.map((file, i: number) => (
              <ListItem disabled={uploadState[i].error} key={i} className={classes.listItem}>
                <ListItemText primary={file.name} className={classes.fileName} />
                <div className={classes.listItemControls}>
                  <LinearProgress
                    className={classes.progress}
                    variant="buffer"
                    value={uploadState[i].pending ? 0 : 100}
                    valueBuffer={0}
                  />
                  <Button
                    disabled={uploadState[i].pending || uploadState[i].error}
                    color="secondary"
                    className={classes.resultsBtn}
                    onClick={() => handleResults(file, uploadState[i])}
                  >
                    {!uploadState[i].error ? "Results" : "Error"}
                  </Button>
                </div>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Dropzone>
    </Grid>
  );
}
