import * as React from "react";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextFieldControl from "../../components/form-elements/TextFieldControl";
import RadioControl from "../../components/form-elements/RadioControl";
import ConfirmDialog from "../../components/Dialog/Confirm";
import CheckboxControl from "../../components/form-elements/CheckboxControl";
import Paper from "@material-ui/core/Paper";
import FormLabel from "@material-ui/core/FormLabel";
import { MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { IMonitorPropertyForm, MonitorPropertyState } from "./index";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { RouteComponentProps, withRouter } from "react-router";
import FormValidators from "../../helpers/form-validators";
import { TestProperty } from "src/constants/RoutesNames";
import TestCronButton from "./TestCronButton";

interface TestPropertyFormProps extends RouteComponentProps<{}> {
  // TODO: remove any
  // tslint:disable-next-line:no-any
  userConfig: any;
  // TODO: remove any
  // tslint:disable-next-line:no-any
  initialValues?: any;
  newMode: boolean;
  onSubmit(values: IMonitorPropertyForm): void;
  deleteHandler(): void;
  onDelete(): void;
}

interface TestPropertyFormState {
  showEdit: boolean;
}

type StyledComponent = WithStyles<
  | "toolbar"
  | "divider"
  | "root"
  | "formRoot"
  | "addProfileBtnContainer"
  | "addProfileBtn"
  | "fieldLabel"
  | "formFieldGridItem"
  | "removeBtnContainer"
  | "removeBtn"
  | "greenBtn"
  | "saveBtnContainer"
  | "tooltipLabel"
  | "editBtn"
  | "iconSmall"
  | "checkboxContainer"
  | "alarmLabelContainer"
>;

const FORM_LABEL_WIDTH = 200;

class TestPropertyForm extends React.Component<
  TestPropertyFormProps & StyledComponent & MonitorPropertyState,
  TestPropertyFormState
> {
  constructor(props: TestPropertyFormProps & StyledComponent & MonitorPropertyState) {
    super(props);

    this.state = {
      showEdit: false,
    };
  }

  render() {
    const {
      classes,
      initialValues,
      onSubmit,
      newMode,
      deleteHandler,
      onDelete,
      deleteDialog,
      // newMode
    } = this.props;
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Paper className={classes.root}>
            <Toolbar className={classes.toolbar}>
              <Typography variant="subtitle1">Monitor Configuration</Typography>
            </Toolbar>
            <Divider className={classes.divider} />
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators,
                setTestScriptValue: (values, state, { changeValue }) => {
                  changeValue(state, "testScript", () => values[0]);
                },
              }}
              render={({ handleSubmit, values }) => (
                <form className={classes.formRoot} onSubmit={handleSubmit}>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} className={classes.saveBtnContainer}>
                      <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        className={classes.greenBtn}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.fieldLabel}>Execute Test Name</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          component={TextFieldControl}
                          select={true}
                          SelectProps={{
                            autoWidth: true,
                          }}
                          name="executeTest"
                          validate={FormValidators.required()}
                        >
                          {this.props.tests.map((t: Test, _i: number) => (
                            <MenuItem key={t._id} value={t._id}>
                              {t.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      {values.executeTest && (
                        <Button
                          type="button"
                          color="primary"
                          className={classes.greenBtn}
                          onClick={(e: React.MouseEvent<HTMLElement>) => {
                            window.open(`${TestProperty}/${values.executeTest}`);
                            e.stopPropagation();
                          }}
                        >
                          {"Edit"}
                        </Button>
                      )}
                    </Grid>
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.alarmLabelContainer}>Frequency</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          name="scheduleMode"
                          component={RadioControl}
                          options={[
                            {
                              value: "d",
                              label: "Run once a day",
                            },
                            {
                              value: "h",
                              label: "Run every hour",
                            },
                            {
                              value: "30",
                              label: "Run every 30 minutes",
                            },
                            {
                              value: "15",
                              label: "Run every 15 minutes",
                            },
                            {
                              value: "5",
                              label: "Run every 5 minutes",
                            },
                            {
                              value: "c",
                              label: "Cron",
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                    {values.scheduleMode === "c" && (
                      <>
                        <Grid item={true} xs={12} container={true}>
                          <Grid item={true}>
                            <FormLabel className={classes.fieldLabel}>Cron</FormLabel>
                          </Grid>
                          <Grid item={true} className={classes.formFieldGridItem}>
                            <Field
                              component={TextFieldControl}
                              type="text"
                              placeholder="*/30 * * * *"
                              name="scheduleCron"
                              helperText="some helper text"
                            />
                          </Grid>
                          <TestCronButton value={values.scheduleCron} />
                        </Grid>
                      </>
                    )}
                    <Grid item={true} xs={12} container={true}>
                      <Grid item={true}>
                        <FormLabel className={classes.alarmLabelContainer}>Alarm if</FormLabel>
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          name="testProfile.alarm.testFailed"
                          component={CheckboxControl}
                          label="Test Failed"
                          type="checkbox"
                        />
                      </Grid>
                      <Grid item={true} className={classes.formFieldGridItem}>
                        <Field
                          name="testProfile.alarm.monitorWarnings"
                          component={CheckboxControl}
                          label="Warnings in monitor run results"
                          type="checkbox"
                        />
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} className={classes.saveBtnContainer}>
                      {!newMode && (
                        <>
                          <Button
                            type="button"
                            color="primary"
                            className={classes.greenBtn}
                            onClick={deleteHandler}
                          >
                            Delete
                          </Button>
                          <ConfirmDialog
                            open={deleteDialog}
                            title="Are you sure?"
                            content="You will not be able to recover this monitor!"
                            handleConfirm={onDelete}
                            handleClose={deleteHandler}
                          />
                        </>
                      )}
                      <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        className={classes.greenBtn}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const decorate = withStyles((theme) =>
  createStyles({
    divider: {
      margin: "16px -16px",
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "felx",
      justifyContent: "space-between",
      alignItems: "center",
    },
    root: {
      width: "100%",
      height: "auto",
      padding: theme.spacing(3),
    },
    addProfileBtnContainer: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-start",
    },
    addProfileBtn: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: "#3e76b2",
        color: "white",
      },
      "&:not(:first-child)": {
        marginLeft: theme.spacing(2),
      },
    },
    fieldLabel: {
      display: "inline-block",
      fontSize: 13,
      padding: "10px 12px 0 0",
      textAlign: "right",
      width: FORM_LABEL_WIDTH,
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    formFieldGridItem: {
      flexGrow: 1,
    },
    removeBtnContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    removeBtn: {
      backgroundColor: "#d2d2d2",
      borderRadius: 3,
      color: "#a22a21",
    },
    greenBtn: {
      "&:not(:first-child)": {
        marginLeft: theme.spacing(1),
      },
    },
    editBtn: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    saveBtnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    tooltipLabel: {
      color: theme.palette.primary.main,
    },
    iconSmall: {
      fontSize: 20.5,
    },
    checkboxContainer: {
      padding: "0 8px !important",
    },
    alarmLabelContainer: {
      display: "inline-block",
      fontSize: 13,
      textAlign: "right",
      width: FORM_LABEL_WIDTH,
      padding: "18px 12px 0 0",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
  })
);

export default withRouter(decorate(TestPropertyForm));
