// tslint:disable:no-any
import * as React from "react";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import { TestIterationContext, TestIterationState } from "../../../index";
import OpenInNew from "@material-ui/icons/OpenInNew";
import fileDownload from "js-file-download";
import { App as AppRoutes } from "../../../../../constants/RoutesNames";
import { Link } from "react-router-dom";

type StyledComponent = WithStyles<
  | "saveIcon"
  | "newLinkIcon"
  | "list"
  | "listItemIcon"
  | "listItem"
  | "listItemLink"
  | "listItemLinkText"
>;

type LogListProps = {
  resourceList: Array<any>;
  hasLogs: boolean;
  standOutUrl?: string;
  stdout: boolean;
  assetsFileNames: Array<string>;
  test: any;
  testRun: any;
};

const webrtcInternalsFile = "webrtc_internals_dump.txt";
const getStatFile = "getstat_logs.json";

class LogList extends React.Component<LogListProps & StyledComponent> {
  downloadScript(testRun: any) {
    const data = new Blob([testRun.testScript], {
      type: "text/plain;charset=utf-8",
    });

    fileDownload(data as any, "test-script.js");
  }

  render() {
    const { hasLogs, resourceList, classes, testRun, test } = this.props;
    return (
      <div>
        <TestIterationContext.Consumer>
          {(props: TestIterationState) => (
            <>
              {!hasLogs && resourceList.length === 0 && (
                <Typography align={"center"} variant={"subtitle1"}>
                  No logs
                </Typography>
              )}
              <List className={classes.list}>
                {hasLogs &&
                  resourceList.length !== 0 &&
                  resourceList.map((file, i) => (
                    <ListItem key={i} className={classes.listItem}>
                      <ListItemIcon className={classes.listItemIcon}>
                        {file.downloadUrl ? (
                          <a
                            target="_blank"
                            href={file.downloadUrl}
                            download={`${props.test.machine}-${file.name}`}
                            rel="noopener noreferrer"
                          >
                            <Save className={classes.saveIcon} />
                          </a>
                        ) : (
                          <span />
                        )}
                      </ListItemIcon>
                      {file.name === webrtcInternalsFile || file.name === getStatFile ? (
                        <ListItemText>
                          <Link
                            className={classes.listItemLinkText}
                            // to={`${AppRoutes.WebrtcInternals}/${testRun._id}/${test._id}/${test.machine}/${test.runIndex}/${file.name}`}
                            to={`${AppRoutes.WebrtcInternals}/${test._id}/${file.name}`}
                          >
                            {file.name}
                          </Link>
                        </ListItemText>
                      ) : (
                        <ListItemText>
                          <a
                            target="_blank"
                            href={file.url}
                            className={classes.listItemLinkText}
                            rel="noopener noreferrer"
                          >
                            {file.name}
                          </a>
                        </ListItemText>
                      )}
                    </ListItem>
                  ))}
                {hasLogs && (
                  <ListItem className={classes.listItemLink}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <a
                        target="_blank"
                        className={classes.listItemLinkText}
                        href={`${AppRoutes.NightWatch}/${testRun._id}/${test._id}/${test.machine}/${test.runIndex}`}
                        rel="noopener noreferrer"
                      >
                        <OpenInNew className={classes.newLinkIcon} />
                      </a>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <a
                          target="_blank"
                          href={`${AppRoutes.NightWatch}/${testRun._id}/${test._id}/${test.machine}/${test.runIndex}`}
                          className={classes.listItemLinkText}
                          rel="noopener noreferrer"
                        >
                          Nightwatch log
                        </a>
                      }
                    />
                  </ListItem>
                )}
                {hasLogs && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <Save
                        onClick={() => this.downloadScript(props.testRun)}
                        className={classes.saveIcon}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <a
                          target="_blank"
                          href={`${AppRoutes.TestScript}/${testRun._id}/${test._id}/${test.machine}/${test.runIndex}`}
                          className={classes.listItemLinkText}
                          rel="noopener noreferrer"
                        >
                          Test Script
                        </a>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </>
          )}
        </TestIterationContext.Consumer>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    linkItem: {
      color: theme.palette.primary.main,
    },
    saveIcon: {
      color: theme.palette.primary.main,
      verticalAlign: "middle",
      margin: 0,
    },
    newLinkIcon: {
      color: theme.palette.primary.main,
      verticalAlign: "middle",
      margin: 0,
    },
    list: {
      padding: 0,
    },
    listItem: {
      padding: 8,
    },
    listItemLink: {
      padding: 8,
      cursor: "pointer",
    },
    listItemIcon: {
      marginRight: 0,
      cursor: "pointer",
    },
    listItemLinkText: {
      color: theme.palette.primary.main,
    },
  });

const decorate = withStyles(styles);

export default decorate(LogList);
