import * as React from "react";
import clsx from "clsx";

import ListItem from "@material-ui/core/ListItem";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { NavLink } from "react-router-dom";

export const NavItemLink = React.forwardRef((props: any, ref: any) => {
  const filteredProps = {
    ...props,
  };
  delete filteredProps.regularLink;
  return <NavLink exact={true} {...filteredProps} innerRef={ref} />;
});

// Can be a link, or button
export const NavItemComponent = React.forwardRef((props: any, ref: any) => {
  const { isCollapsed, ...newProps } = props;
  const classes = useStyles();

  const component = props.regularLink ? (
    <ListItem {...newProps} button={true} component={"a"} href={props.link} target="_blank" />
  ) : typeof props.link === "string" ? (
    <ListItem {...newProps} button={true} component={NavItemLink} to={props.link} />
  ) : (
    <ListItem {...newProps} button={true} />
  );

  return (
    <div ref={ref} className={clsx(isCollapsed && classes.navItemCollapsedWrapper)}>
      {component}
    </div>
  );
});

const useStyles = makeStyles((_theme: any) =>
  createStyles({
    navItemCollapsedWrapper: {
      // width: theme.sidebar.widthCollapsed,
    },
  })
);

export default NavItemComponent;
