// tslint:disable:no-any

import * as React from "react";
import { Theme, withStyles, WithStyles, Typography, createStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

type BreadcrumbProps = {
  breadcrumb: BreadcrumbReducerModel;
};

type StyledComponent = WithStyles<"node" | "wrapper" | "activeNode" | "icon">;

class Breadcrumb extends React.Component<BreadcrumbProps & StyledComponent> {
  render() {
    const { breadcrumb, classes } = this.props;

    return (
      <div className={classes.wrapper}>
        {breadcrumb.nodes.map((b: BreadcrumbNode, i: number) => (
          <NavLink
            key={i}
            to={b.link}
            className={classes.node}
            activeStyle={{
              fontWeight: 400,
              textDecoration: "none !important",
              color: "red !important",
            }}
          >
            {i > 0 && <Typography className={classes.icon}>/</Typography>}
            <Typography variant="body2">{b.text}</Typography>
          </NavLink>
        ))}
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(1),
      paddingLeft: 0,
      paddingTop: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      minHeight: 32,
    },
    node: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      textDecoration: "none",
      color: "rgba(0, 0, 0, 0.87)",
    },
    activeNode: {
      fontWeight: 600,
      textDecoration: "none",
      color: "red",
    },
    icon: {
      padding: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
    },
  });

const decorate = withStyles(styles);

const mapStateToProps = (store: IStore) => ({
  breadcrumb: store.breadcrumb,
});

export default connect(mapStateToProps)(decorate(Breadcrumb));
