import * as React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { useLegend } from "../hooks/useLegend";
import { useSeries } from "../hooks/useSeries";

export const useStyles = makeStyles((_theme: Theme) => createStyles({}));

const ToggleAll = () => {
  const { toggleAll } = useLegend();
  const { series, toggle } = useSeries();
  const [visible, setVisible] = React.useState(true);

  React.useEffect(() => {
    if (series.every((s) => s.visible)) {
      setVisible(true);
    }
    if (series.every((s) => !s.visible)) {
      setVisible(false);
    }
  }, [series]);

  const handleChange = () => {
    setVisible(!visible);

    const seriesNames = series.map((s) => s.name);
    toggle(seriesNames, !visible);
  };

  if (!toggleAll) {
    return null;
  }

  return (
    <Button
      style={{ maxWidth: "fit-content", marginTop: 10, marginLeft: 15 }}
      color={"primary"}
      size={"small"}
      variant={"outlined"}
      onClick={handleChange}
    >
      Toggle All
    </Button>
  );
};

export default ToggleAll;
