import * as React from "react";

export default class ForbiddenPage extends React.Component {
  render() {
    return (
      <div>
        <h1>You have no access to content on this page</h1>
      </div>
    );
  }
}
