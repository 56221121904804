import { PUSH_BREADCRUMB_NODE, RESET_BREADCRUMB } from "../constants/ActionTypes";
import { ISetBreadcrumbAction } from "../actions/breadcrumbActions";
// import { Main } from "../constants/RoutesNames";

const defaultState: BreadcrumbReducerModel = {
  nodes: [
    // {
    //   text: "Home",
    //   link: Main,
    // },
  ],
};

export default (state: BreadcrumbReducerModel = defaultState, action: ISetBreadcrumbAction) => {
  switch (action.type) {
    case PUSH_BREADCRUMB_NODE: {
      return {
        ...state,
        nodes: [...state.nodes, action.payload],
      };
    }
    case RESET_BREADCRUMB: {
      return defaultState;
    }
    default:
      return state;
  }
};
