import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

// tslint:disable-next-line:no-any
function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

interface IRemindPasswordModalProps {
  isOpen: boolean;
  emailRemind: string;
  onClose: () => void;
}

class RemindPasswordModal extends React.Component<IRemindPasswordModalProps> {
  public render(): JSX.Element {
    return (
      <Dialog
        open={this.props.isOpen}
        TransitionComponent={Transition}
        keepMounted={true}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Password Reset</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Instructions for accessing your account have been sent to {this.props.emailRemind}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

export default RemindPasswordModal;
