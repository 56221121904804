import * as React from "react";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import SvgIcon from "@material-ui/core/SvgIcon";

import { createStyles, makeStyles, Theme, styled } from "@material-ui/core/styles";
import { useChartSize } from "./useChartSize";
import { chartSizesArray, chartSizesLabels } from "./constants";

interface IChartSizeProps {
  defaultSize: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginRight: theme.spacing(1),
      "& > button": {
        marginRight: theme.spacing(1),
      },
    },
  })
);

const ChartHeightIconComponent = (props: any) => {
  const propsCopy = { ...props };
  if (propsCopy.color === "white") {
    propsCopy.color = undefined;
  }
  return (
    <SvgIcon {...propsCopy} style={{ transform: `scaleY(${propsCopy.height - 0.2})` }}>
      <path
        fillRule="evenodd"
        d="M413,326 L427,326 C429.761424,326 432,328.238576 432,331 C432,333.761424 429.761424,336 427,336 L413,336 C410.238576,336 408,333.761424 408,331 C408,328.238576 410.238576,326 413,326 Z M413,328 C411.343146,328 410,329.343146 410,331 C410,332.656854 411.343146,334 413,334 L427,334 C428.656854,334 430,332.656854 430,331 C430,329.343146 428.656854,328 427,328 L413,328 Z M413,312 L427,312 C429.761424,312 432,314.238576 432,317 C432,319.761424 429.761424,322 427,322 L413,322 C410.238576,322 408,319.761424 408,317 C408,314.238576 410.238576,312 413,312 Z M413,314 C411.343146,314 410,315.343146 410,317 C410,318.656854 411.343146,320 413,320 L427,320 C428.656854,320 430,318.656854 430,317 C430,315.343146 428.656854,314 427,314 L413,314 Z"
        transform={`translate(-408 -312)`}
      />
    </SvgIcon>
  );
};

const ChartHeightIcon = styled(ChartHeightIconComponent)(() => ({}));

const ChartSize = (props: IChartSizeProps) => {
  const classes = useStyles();
  const { chartSize, setChartSize } = useChartSize();

  React.useEffect(() => {
    setTimeout(() => {
      setChartSize(props.defaultSize, true);
    }, 500);
  }, [props.defaultSize]);

  return (
    <div className={classes.container}>
      {chartSizesArray.map((ch) => {
        return (
          <Tooltip key={ch} title={chartSizesLabels[ch]} placement="top">
            <Button
              onClick={() => {
                setChartSize(ch);
              }}
              color={chartSize === ch ? "secondary" : "default"}
              variant={chartSize === ch ? "contained" : "outlined"}
            >
              <ChartHeightIcon height={ch} color={chartSize === ch ? "white" : "action"} />
            </Button>
          </Tooltip>
        );
      })}
    </div>
  );
};

export * from "./constants";
export * from "./Context";
export default ChartSize;
